import { BaseModel } from './base-model';

export interface IAddress {
  subNumber: string;
  streetNumber: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export class Address extends BaseModel<Address> implements IAddress {
  subNumber: string;
  streetNumber: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;

  constructor() {
    super();
  }

  static getFullAddress(address: Address) {
    let fullAddress = '';
    if (address?.subNumber) {
      fullAddress = fullAddress + address.subNumber + '/';
    }

    if (address?.streetNumber) {
      fullAddress = fullAddress + address.streetNumber + ' ';
    }

    if (address?.street) {
      fullAddress = fullAddress + address.street;
    }

    if (address?.suburb) {
      fullAddress = fullAddress + ', ' + address.suburb;
    }
    return fullAddress;
  }

  static getCompleteFullAddress(address: Address): string {
    let fullAddress = this.getFullAddress(address);

    if (address?.state) {
      fullAddress = fullAddress + ', ' + address.state;
    }

    if (address?.postcode) {
      fullAddress = fullAddress + ' ' + address.postcode;
    }

    if (address?.country) {
      fullAddress = fullAddress + ', ' + address.country;
    }

    return fullAddress;
  }

  fromModel(oldModel: IAddress): Address {
    this.subNumber = oldModel.subNumber;
    this.streetNumber = oldModel.streetNumber;
    this.street = oldModel.street;
    this.suburb = oldModel.suburb;
    this.state = oldModel.state;
    this.postcode = oldModel.postcode;
    this.country = oldModel.country;

    return this;
  }

  getFullAddress(): string {
    let fullAddress = '';
    if (this.subNumber) {
      fullAddress = fullAddress + this.subNumber + '/';
    }

    if (this.streetNumber) {
      fullAddress = fullAddress + this.streetNumber + ' ';
    }

    if (this.street) {
      fullAddress = fullAddress + this.street;
    }

    if (this.suburb) {
      fullAddress = fullAddress + ', ' + this.suburb;
    }
    return fullAddress;
  }
}

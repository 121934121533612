// Deprecated, to be removed when old application-summary is removed
export enum GlobalVpaStatus {
  NotStarted,
  ApplicationStarted,
  AwaitingPaymentDetails,
  AwaitingSignatures,
  Cancelled,
  Unsubmitted,
  WaitingForApproval,
  ActionRequired,
  AmendingApplication,
  Approved,
  FullyPaid,
  CancellationRequested,
  Unknown,
  SignedAwaitingAuthority
}

<div class="bpay-bill-box" [ngClass]="{ 'with-border': !!withBorder }">
  <div style="display: flex;">
    <div>
      <img class="bpay-logo" src="https://cdn.campaignagent.com.au/BPAY_2012_PORT_BLUE.png" alt="Bpay Logo" />
    </div>
    <div class="bpay-details">
      <div>
        <div>
          <strong>Biller code:</strong>
          {{ billerCode }}
        </div>
        <div>
          <strong>Ref:</strong>
          {{ paymentReferenceCode }}
        </div>
      </div>
    </div>
  </div>
  <div class="bpay-footer">
    <div><strong>Telephone &amp; Internet Banking – BPAY®</strong></div>
    <div>
      Contact your bank or financial institution to make this payment from your cheque, savings or transaction account. More info:
      <a href="https://www.bpay.com.au" target="_blank">www.bpay.com.au</a>
    </div>
  </div>
</div>

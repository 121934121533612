import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vpa-help-section',
  templateUrl: './help-section.component.html',
  styleUrls: ['./help-section.component.scss']
})
export class HelpSectionComponent implements OnInit {
  // string to filter on
  @Input() helpText: string;
  @Input() offsetSectionSpacing: boolean;
  // hide and show variable.
  helpHide: boolean;
  // printable strings
  helpTitle: string;
  helpVideo: string;
  showHelpVideo: boolean;
  helpHtml: string;
  // main obj reference
  helpObj: Array<{
    section: string;
    title: string;
    html: string;
    videoId?: string;
  }>;

  constructor(public sanitize: DomSanitizer) { }

  ngOnInit() {
    this.helpObj = [
      {
        section: 'advertisingFunds',
        title: ' In this section, please provide the financial details for your advertising campaign.',
        html: `<p>
                  The <strong>Pay Now Date</strong> is the date the advertising campaign begins (This is generally the estimated date of photography).
                </p>
                <p>
                  The <strong>Pay Now Total</strong>  is the agreed cost of the advertising campaign (without the 5.9% VPAPay Fee).
                </p>
                <p>
                  The <strong>Pay Later Date</strong> is displayed for your information.
                  <br />
                  Later on, the vendor will have the opportunity to choose whether they would like to Pay Now or Pay Later.
                </p>
                <p>
                  The <strong>Pay Later Total</strong> is simply the Pay Now Total plus 5.9%.
                </p>`,
        videoId: null
      },
      {
        section: 'whenWillYouComplete',
        title: ' In this section, you specify when the application in it&apos;s entirety will be completed',
        html: `<p>
            This application will take around 5 minutes.
            </p>
            <p>
            If the vendors are ready to complete it now, select <strong>I&apos;ll do it now</strong>.
            </p>
            <p>To email each vendor a link to the application, select <strong>Vendors will do it later</strong>.
          </p>
          <p>You will be able to check whether they have completed it, or to send them a reminder to do so</p>`
      },
      {
        section: 'salesAuthority',
        title: ' In this section, we need a copy of the Sales Authority/Agreement.',
        html: `<p>Click the button to upload a clear photo of the first page of the Authority/Agreement.</p>
          <p><strong class="d-block">Take a photo</strong> You can use your Mobile phone or iPad to take a photo of the fist page of the Sales Authority/Agreement.<br />Alternatively, if you are on a desktop computer, you can scan the photos to your computer and select the file to upload.</p>`
      },
      {
        section: 'vendorsLater',
        title: ' In this section, you can edit the entire list of vendors and select a vendor to send the VPA application link to.',
        html: `<p>Please provide the details of every vendor that is going to fill out this Application. Each vendor’s full legal name, mobile number and email address must be correct.</p>
        <p>This application must be completed by a natural person, not an entity. If the vendor is an entity, have the person that signed the Sales Authority provide their details.</p>
        <p>If someone is signing on a vendor’s behalf – as Power of Attorney or executor of an estate – enter the vendor’s name followed by the words: “with [someone] as [POA or executor of their estate]”. Then provide a contactable mobile number and email address.</p>
        <p>Click on the pencil icon to edit any details, or to remove someone.</p>
        <p>Select <strong> + Someone Else</strong> to add more vendors.</p>
        <p>The highlighted vendor will be the first recipient of the Application. They will receive a link to the Application, fill out the details and sign. Once signed, a link will be emailed to the remaining vendors prompting them to sign.</p>`
      },
      {
        section: 'paymentPreference',
        title: 'In this section, you can choose to pay for VPA now, or later.',
        html: `<p>Slide to choose whether you would like to <strong>Pay Now</strong> or <strong>Pay Later</strong>. </p><p>The amount due and due date will adjust accordingly.
        </p>`
      },
      {
        section: 'applyVendors',
        title: 'In this section, you can choose to pay for VPA now, or later.',
        html: `<p>
          <p>Each vendor’s details should appear correctly here.
          <p>Click on the pencil icon to edit any details, or to remove someone.</p>
          <p>Please provide each vendor’s date of birth.<br> <em> We collect this information purely because we are required to under the Anti-Money Laundering and Counter-Terrorism Financing Act 2006(Cth).</em></p>
          <p> If you are acting on a vendor&apos;s behalf – as Power of Attorney or executor of an estate – please provide your date of birth, not the vendor&apos;</p>
          <p>Select <strong> + Someone Else</strong> to add more vendors.</p>
        </p>
        `
      },
      {
        section: 'paymentMethod',
        title: 'In this section, you can select you payment method.',
        html: `<p>Please select a payment method</p>`
      },
      {
        section: 'creditCard',
        title: 'In this section, you can select you payment method.',
        html: `
          <p>You can make a payment on one or multiple credit cards. Please enter the required fields for each card.</p>
          <p>Select <strong> + Add Credit Card Payment </strong> to validate the current credit card, and add another .</p>
        `
      },
      {
        section: 'eftDetails',
        title: 'In this section, you can see the account details for Campaign Agent.',
        html: `
          <p>These are the account details to which you can make payment. These will be emailed to you again later.</p>
        `
      },
      {
        section: 'bpayDetails',
        title: 'In this section, you can see the account details for Campaign Agent.',
        html: `
          <p>Below are the details you will require to make your BPAY payment. These will be emailed to you again later.</p>
          <p>To make your payment:</p>
          <ul>
            <li>Log in to your online or mobile banking</li>
            <li>Select BPAY, your preferred account, enter the below details and amount</li>
          </ul>
        `
      },
      {
        section: 'chequeDetails',
        title: 'In this section, you can see the account details for Campaign Agent.',
        html: `
          <p>If sending in your cheque to Campaign Agent by post, please write the sale property address on the back of the cheque.</p>
          <p>If you are banking the cheque directly into our trust account, please ensure you write the CRN as a reference so we can apply
          the payment to the correct campaign.</p>
        `
      },
      {
        section: 'dueDatesCredit',
        title: 'In this section, you can see a breakdown of dates and totals.',
        html: `
          <p>Here you can see the date and amount that will be deducted from the card/s entered above.</p>
        `
      },
      {
        section: 'dueDatesEft',
        title: 'In this section, you can see a breakdown of dates and totals.',
        html: `
          <p>You have until the <strong>Due Date</strong> to make payment. You will receive an SMS reminder before this date. </p>
          <p>If payment is not received by this date, your campaign will be converted to Pay Later.</p>
          <p>This will defer payment of your advertising costs until the Pay Later Date, but also the amount owing will incur a 5.9% fee.</p>`
      },
      {
        section: 'confirmInformation',
        title: 'In this section, you can see all the information to date.',
        html: `
          <p>Please confirm all the details of your application below.</p>`
      },
      {
        section: 'termsAndConditions',
        title: 'In this section, you must view and agree our terms and conditions.',
        html: `
          <p>Click the button below to view our terms and conditions.</p>
          <p>By scrolling down and clicking “Agree” you acknowledge that you have read and understood the terms and conditions, and agree to be bound by them.</p>`
      },
      {
        section: 'vendorsSignNow',
        title: 'In this section, The vendors need to sign the agreement.',
        html: ` <p>The last step to submitting your application is to sign.</p>
                <p>Please have each vendor provide their signature using their finger or a stylus. </p>
                <p>You can re-sign as many times as you need to. Only the last signature will be saved.</p>`
      },
      {
        section: 'listingSummary',
        title: 'In this section, you can see an overview of the current application.',
        html: ` <p>Welcome to the Listing Screen.</p>
                <p>Here you can see the status of the VPAPay application, download a PDF of the application and resend the application to a vendor.</p>`
      },
      {
        section: 'appTimeline',
        title: 'In this section, you can see a chronological order of events.',
        html: ` <p>Here you can see a timeline of the application. For example, you can quickly see which vendor has not completed the application.</p>`
      }
    ];

    // hide on default
    this.helpHide = false;
    // current object
    const selctedObj = this.helpObj.filter((x) => x.section === this.helpText);
    // title
    this.helpTitle = selctedObj[0].title;
    // html
    this.helpHtml = selctedObj[0].html;
    // if video // or null
    this.helpVideo = selctedObj[0].videoId || null;
    this.showHelpVideo = false;
  }
}

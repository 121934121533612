import { Injectable } from '@angular/core';
import { AppStateService, LoginDestination, SwitchableLogin, SwitchableLoginGroup } from '@ca/shared/app-state';
import { ApplicationLoginContext } from '@ca/shared/models';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SwitchAccountModalComponentState } from './switch-account-modal/switch-account-modal.component';

@Injectable({
  providedIn: 'root'
})
export class SwitchAccountModalService {
  readonly assumedUserId$: Observable<number> = this.appStateService.appState$.pipe(
    map((appState) => parseInt(appState['CampaignAgent.AssumedUserId'], 10)),
    distinctUntilChanged()
  );
  portalUser$: Observable<SwitchableLogin>;
  appUser$: Observable<SwitchableLogin>;
  portalLoginGroup$: Observable<SwitchableLoginGroup>;
  appLoginGroup$: Observable<SwitchableLoginGroup>;
  adminLoginGroup$: Observable<SwitchableLoginGroup>;
  
  constructor(private appStateService: AppStateService) {
    this.portalUser$ = this.getCurrentUserForDestination$(LoginDestination.Portal);
    this.appUser$ = this.getCurrentUserForDestination$(LoginDestination.App);
    this.portalLoginGroup$ = this.getLoginGroup$(LoginDestination.Portal);
    this.appLoginGroup$ = this.getLoginGroup$(LoginDestination.App);
    this.adminLoginGroup$ = this.getLoginGroup$(LoginDestination.Admin);
  }
  
  getSwitchAccountModalComponentState$(): Observable<SwitchAccountModalComponentState> {
    const userGroups$ = combineLatest([
      this.portalLoginGroup$,
      this.appLoginGroup$,
      this.adminLoginGroup$])
    .pipe(
      map(([portalLoginGroup, appLoginGroup, adminLoginGroup]) => {
        return {portalLoginGroup, appLoginGroup, adminLoginGroup};
      })
    );

    return combineLatest([
      this.assumedUserId$,
      this.appStateService.applicationLoginContext$,
      this.portalUser$,
      this.appUser$,
      userGroups$
    ])
    .pipe(
      map(([assumedUserId, loginContext, portalUser, appUser, userGroups]) => {
        if (loginContext === ApplicationLoginContext.Portal) {
          return {
            loginContext,
            currentUserId: assumedUserId,
            changeUserDestinationLogin: appUser,
            switchableLoginGroup: userGroups.portalLoginGroup,
            adminLoginGroup: userGroups.adminLoginGroup
          };
        }
        
        return {
          loginContext,
          currentUserId: assumedUserId,
          changeUserDestinationLogin: portalUser,
          switchableLoginGroup: userGroups.appLoginGroup,
          adminLoginGroup: userGroups.adminLoginGroup
        };
      })
    );
  }
  
  private getLoginGroup$(destination: LoginDestination): Observable<SwitchableLoginGroup> {
    return this.appStateService.switchableLogins$.pipe(
      map((switchableLogins) => {
        return {
          destination,
          logins: switchableLogins.filter((login) => login.Destination === destination)
        }
      })
    );
  }

  private getCurrentUserForDestination$(destination: LoginDestination): Observable<SwitchableLogin> {
    return combineLatest([
      this.assumedUserId$,
      this.appStateService.switchableLogins$
    ])
    .pipe(
      map(([assumedUserId, switchableLogins]) => {
        const users =
          switchableLogins.filter((user) => user.UserId === assumedUserId && user.Destination === destination);
    
        if (users && users.length > 0) {
          return users[0];
        }
    
        return null;
      })
    );
  }
}

<section class="payment-breakdown" *ngIf="state$ | async as state" [ngClass]="{ 'p-l-20': state.shouldPadLeft }">
  <div class="section-title">Payment Breakdown</div>
  <div class="section-description">Complete breakdown of payments being made</div>

  <vpa-help-section
    [helpText]="'dueDatesEft'"
    [offsetSectionSpacing]="true"
    *ngIf="state.isUsingEft || state.isUsingBpay"
  ></vpa-help-section>

  <div class="info">
    <div class="container">
      <label>
        Total Due
      </label>
      <span class="section-value">
        {{ state.totalAuthorisedAmount | currency }}
      </span>
    </div>
    <div class="container" *ngIf="state.isPaymentMethodCreditCard">
      <label>
        Total Added
      </label>
      <span class="section-value">
        {{ state.totalAdded | currency }}
      </span>
    </div>
    <div class="container" *ngIf="state.isPaymentMethodCreditCard">
      <label>
        Outstanding
      </label>
      <span
        class="section-value"
        [ngClass]="{
          'text-danger': state.amountOutstanding != 0,
          'text-success': state.amountOutstanding == 0
        }"
      >
        {{ state.amountOutstanding | currency }}
      </span>
    </div>
    <div class="container" *ngIf="!(state.useChargeOnSubmission && state.isPaymentMethodCreditCard)">
      <label>
        Due Date
      </label>
      <span class="section-value">
        {{ state.payNowPaymentDueDate | date: 'dd/MM/yyyy' }}
      </span>
    </div>
    <div class="container" *ngIf="state.paymentDetailsOptionType === paymentDetailsOptionType.Payway">
      <label>
        Total Surcharge
      </label>
      <span class="section-value">
        {{ state.totalSurcharges | currency }}
      </span>
    </div>
    <div
      class="alert alert-info border-blue m-b-0 m-t-10"
      *ngIf="state.isPaymentMethodCreditCard"
      role="alert"
      style="border: 2px solid #31708f; border-radius: 4px !important; display: flow-root; font-size: 18px;"
    >
      <i class="fa fa-lg fa-info-circle" style="float: left; margin-right: 10px; margin-top: 4px;"></i>
      <p>To avoid surcharges change your payment method to {{ state.surchargelessPaymentOptions }}</p>
    </div>
  </div>
</section>

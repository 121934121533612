export enum RepresentativeActingAs {
  PowerOfAttorney,
  Executor
}

export const RepresentativeActingAsOptions = [
  {
    value: RepresentativeActingAs[RepresentativeActingAs.PowerOfAttorney],
    label: 'Power of Attorney'
  },
  {
    value: RepresentativeActingAs[RepresentativeActingAs.Executor],
    label: 'Executor'
  }
];

export function getRepresentativeActingAsText(representativeActingAs: string) {
  if (
    representativeActingAs ===
    RepresentativeActingAs[RepresentativeActingAs.PowerOfAttorney]
  ) {
    return 'Power of Attorney';
  }

  if (
    representativeActingAs ===
    RepresentativeActingAs[RepresentativeActingAs.Executor]
  ) {
    return 'Executor';
  }
}

import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppSettingsService as NewAppSettingsService } from '@ca/shared/app-state';
import { GlobalExceptionInterceptor as NewGlobalExceptionInterceptor } from '@ca/shared/interceptors';
import { SharedMainNavModule } from '@ca/shared/main-nav';
import { CampaignAgentApplications } from '@ca/shared/models';
import * as Sentry from '@sentry/angular';
import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { SessionStateComponent } from './session-state/session-state.component';
import { ApplicationSpecificGuard } from './_guards/application-specific.guard';
import { AuthGuard } from './_guards/auth.guard';
import { EnvironmentGuard } from './_guards/environment.guard';
import { ApplicationModeInterceptor } from './_interceptors/applicationMode.interceptor';
import { AuthTokenInterceptor } from './_interceptors/authToken.interceptor';
import { ApiBaseUrlInterceptor } from './_interceptors/baseurl.interceptor';
import { DateHttpInterceptor } from './_interceptors/date.interceptor';
import { GlobalExceptionInterceptor } from './_interceptors/globalException.interceptor';
import { RefreshJwtInterceptor } from './_interceptors/refresh-jwt.interceptor';
import { TraceIdentifierInterceptor } from './_interceptors/traceIdentifier.interceptor';
import { AlertModalComponent } from './_shared/components/alert-modal/alert-modal.component';
import { Globals } from './_shared/models/global-variables';
import { AppSettingsService } from './_shared/services/app-settings-service';
import { SharedModule } from './_shared/shared.module';

const appSettingsInitializer = (appSettingsService: AppSettingsService, newAppSettingsService: NewAppSettingsService) => {
  return () => {
    return Promise.all([appSettingsService.setAppSettings(), newAppSettingsService.setAppSettings()]);
  };
};

@NgModule({
  declarations: [AppComponent, MainComponent, SessionStateComponent],
  imports: [BrowserModule, BrowserAnimationsModule, MDBBootstrapModulesPro.forRoot(), AppRoutingModule, SharedModule, SharedMainNavModule],
  entryComponents: [AlertModalComponent],
  providers: [
    MDBSpinningPreloader,
    DatePipe,
    Globals,
    AuthGuard,
    EnvironmentGuard,
    ApplicationSpecificGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceIdentifierInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiBaseUrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalExceptionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationModeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshJwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NewGlobalExceptionInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: 'currentAppName',
      useValue: 'legacy'
    },
    {
      provide: 'application',
      useValue: CampaignAgentApplications.Legacy
    },
    AppSettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: appSettingsInitializer,
      multi: true,
      deps: [AppSettingsService, NewAppSettingsService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vpa-session-state',
  templateUrl: './session-state.component.html',
  styleUrls: ['./session-state.component.scss']
})
export class SessionStateComponent implements OnInit {
  count: number;

  constructor() {}

  ngOnInit(): void {
    let counter = parseInt(sessionStorage.getItem('counter'), 10);
    if (!counter) {
      sessionStorage.setItem('counter', '1');
      this.count = 1;
      return;
    }

    this.count = ++counter;
    sessionStorage.setItem('counter', this.count.toString());
  }

  reload(): void {
    window.location.reload();
  }

  toVpaPay(): void {
    window.location.href = '/vpapay/session-state';
  }
}

export enum DateFormatOptions {
  DDMMYYYY = 'dd/mm/yyyy'
}

export function parseDateFromDateString(dateString: string, option: DateFormatOptions): Date {
  if (!dateString) {
    return null;
  }

  if (option === DateFormatOptions.DDMMYYYY) {
    return parseDateFormatDDMMYYYY(dateString);
  }

  return null;
}

// Format from '2019-02-01T00:00:00' to '01/02/2019'
export function reformatFromJsonString(dateString: string, option: DateFormatOptions): string {
  if (!dateString) {
    return '';
  }

  if (option === DateFormatOptions.DDMMYYYY) {
    const tIndex = dateString.indexOf('T');

    if (tIndex !== -1) {
      dateString = dateString.substring(0, tIndex);
    }
    let arr = dateString.split('-');
    arr = arr.reverse();
    dateString = arr.join('/');
    return dateString;
  }

  return '';
}

function parseDateFormatDDMMYYYY(dateString: string): Date {
  try {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    return new Date(year, month - 1, day);
  } catch (e) {
    return null;
  }
}

export function getDateFromDateString(dateString: string) {
  if (!dateString) {
    return null;
  }
  const firstSlashIndex = dateString.indexOf('/');
  const lastSlashIndex = dateString.lastIndexOf('/');
  const day = parseInt(dateString.substring(0, firstSlashIndex), 10);
  const month = parseInt(dateString.substring(firstSlashIndex + 1, lastSlashIndex), 10);
  const year = parseInt(dateString.substring(lastSlashIndex + 1), 10);

  return new Date(year, month - 1, day);
}

// This is a nightmare we need to find a cleaner way to handle date formats across the lifecycle of a date
export function convertToJsonString(date: any) {
  const _formatConvertedDate = (convertedDate: Date) => {
    return `${convertedDate.getFullYear()}-${convertedDate.getMonth() + 1}-${convertedDate.getDate()}`;
  };

  if (date instanceof Date) {
    return _formatConvertedDate(date as Date);
  }

  if (date.indexOf('/') !== -1) {
    return _formatConvertedDate(parseDateFromDateString(date, DateFormatOptions.DDMMYYYY));
  }

  return date;
}

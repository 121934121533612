import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'vpa-amend-manual-address',
  templateUrl: './amend-manual-address.component.html',
  styleUrls: ['./amend-manual-address.component.scss']
})
export class AmendManualAddressComponent implements OnInit {
  @Input() isSubmitted: boolean;
  @Input() isDisplayedInSections: boolean;
  @Input() ManualAddressGroup: FormGroup;
  @Input() shouldShowErrors: boolean;

  constructor() {}

  ngOnInit() {}

  get subNumber() {
    return this.ManualAddressGroup.get('subNumber');
  }

  get streetNumber() {
    return this.ManualAddressGroup.get('streetNumber');
  }

  get street() {
    return this.ManualAddressGroup.get('street');
  }

  get suburb() {
    return this.ManualAddressGroup.get('suburb');
  }

  get state() {
    return this.ManualAddressGroup.get('state');
  }

  get postcode() {
    return this.ManualAddressGroup.get('postcode');
  }

  get isValid(): boolean {
    return validateAmendManualAddressComponent(this.ManualAddressGroup);
  }
}

export function validateAmendManualAddressComponent(manualAddressGroup: FormGroup): boolean {
  return !manualAddressGroup.invalid;
}

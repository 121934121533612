import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@ca/shared/app-state';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ApplicationModeService } from '../_shared/services/application-mode.service';

@Injectable()
export class ApplicationModeInterceptor implements HttpInterceptor {
  constructor(private applicationModeService: ApplicationModeService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If or we're calling an external URL, just keep going.
    if (!req.url.startsWith(AppSettingsService.appSettings.baseApiUrl)) {
      return next.handle(req);
    }

    return this.applicationModeService.currentApplicationModeSubject.pipe(
      first(),
      switchMap((appMode) => {
        const newRequest = req.clone({
          headers: req.headers.set('Application-Mode', `${appMode.toString()}`)
        });

        return next.handle(newRequest);
      })
    );
  }
}

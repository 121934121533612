import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModalsModule } from '@ca/shared/modals';
import { SharedPipesModule } from '@ca/shared/pipes';
import { ResponsiveModalComponent } from './components/responsive-modal/responsive-modal.component';

@NgModule({
  imports: [CommonModule, SharedPipesModule, SharedModalsModule],
  declarations: [ResponsiveModalComponent],
  exports: [ResponsiveModalComponent]
})
export class BaseModalsModule {}

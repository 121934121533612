export class GlobalException {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: any;
  detail: string;

  constructor(status: number) {
    this.status = status;
  }
}

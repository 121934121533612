import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fileSaver from 'file-saver';
import { FormFileModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FileSaverService {
  constructor() {}

  saveAs(file: Blob, fileName: string): void {
    const savedFileName = `${Date.now()}-${fileName}`;
    fileSaver.saveAs(file, savedFileName, { type: file.type });
  }

  saveAsFromFileModel(file: FormFileModel): void {
    fileSaver.saveAs(`data:${file.fileType};base64,${file.fileContent}`, file.fileName, { type: file.fileType });
  }

  getUrlFromBlob(file: Blob): string {
    return URL.createObjectURL(file);
  }

  getUrlFromFileModel(file: FormFileModel): string {
    const obj = this.b64toBlob(file.fileContent, file.fileType);
    return URL.createObjectURL(obj);
  }

  saveAsWithName(response: HttpResponse<any>, defaultFileName: string = ''): void {
    const fileNameHeader = response.headers
      .get('Content-Disposition')
      .split('; ')
      .find((x) => x.includes('filename='));

    const fileName = fileNameHeader ? fileNameHeader.split('filename=')[1] : defaultFileName;
    this.saveAs(response.body, fileName);
  }

  private b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
}

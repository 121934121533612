import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationSpecificGuard implements CanActivate {
  constructor(private router: Router, private appStateService: AppStateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.appStateService.vpaApplicationId$, this.appStateService.sandBoxedCpn$]).pipe(
      map(([vpaApplicationId, sandBoxedCpn]) => {
        if ((!sandBoxedCpn && !vpaApplicationId) || this.isUrlForApplication(state, vpaApplicationId, sandBoxedCpn)) {
          return true;
        }

        this.router.navigate(['login']);
        return false;
      })
    );
  }

  private isUrlForApplication(state: RouterStateSnapshot, vpaApplicationId: number, cpn: string) {
    return state.url.includes(`/applications/${vpaApplicationId.toString()}/`) || state.url.includes(`/applications/${cpn}/`);
  }
}

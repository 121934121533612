import { CreditCardScheme } from 'apps/legacy/src/app/pnd/models/pnd-credit-card-scheme';
import { Guid } from 'guid-typescript';
import { AppSettingsService } from '../services/app-settings-service';
import { ApplicationMode } from './application-mode';

const getRouteUrl = (url: string, mode?: ApplicationMode) => {
  return mode === ApplicationMode.Training ? `training/${url}` : url;
};

class VpaPayManagementRouteResources {
  public static createApplication(cpn, mode: ApplicationMode): string {
    if (cpn) {
      return getRouteUrl(`vpa-pay-management/create-pnd/${cpn}`, mode);
    }
    return getRouteUrl(`vpa-pay-management/create-pnd`, mode);
  }

  public static createAdditionalApplication(cpn, mode: ApplicationMode): string {
    return getRouteUrl(`vpa-pay-management/additional/create-pnd/${cpn}`, mode);
  }

  public static createAgentContributions(cpnOrApplicationId: string, mode: ApplicationMode): string {
    if (cpnOrApplicationId) {
      return getRouteUrl(`vpa-pay-management/create-agent-contributions/${cpnOrApplicationId}`, mode);
    }
    return getRouteUrl(`vpa-pay-management/create-agent-contributions`, mode);
  }
}

class PayNowDirectRouteResources {
  public static pndPayNowPaymentType(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pnd/applications/${vpaApplicationId}/pay-now-payment-type`, mode);
  }

  public static pndBPayPayment(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pnd/applications/${vpaApplicationId}/bpay-payment`, mode);
  }

  public static pndCreditCardPayment = (vpaApplicationId: number, scheme: CreditCardScheme, mode: ApplicationMode): string =>
    getRouteUrl(`pnd/applications/${vpaApplicationId}/credit-card/${scheme}`, mode);

  public static pndPaymentSuccess = (vpaApplicationId: number, mode: ApplicationMode): string =>
    getRouteUrl(`pnd/applications/${vpaApplicationId}/payment-success`, mode);
}

class PayLaterRouteResources {
  public static aboutPayLater(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pay-later/applications/${vpaApplicationId}/about-pay-later`, mode);
  }

  public static confirmVendorDetails(applicationId: number, mode: ApplicationMode): string {
    return 'vpapay/' + getRouteUrl(`pay-later/applications/${applicationId}/confirm-vendor-details`, mode);
  }
}

class NextGenPaymentsPayLaterRouteResources {
  public static aboutPayLater(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pay-later/applications/${vpaApplicationId}/about-pay-later`, mode);
  }
}

class MarketingRouteResources {
  public static awaitingPaymentApplications(): string {
    return getRouteUrl(`marketing/awaiting-payment`);
  }

  public static actionRequired(): string {
    return getRouteUrl(`marketing/actions-required`);
  }

  public static downloadAgencyQrCode(): string {
    return getRouteUrl(`marketing/download-agency-qr-code`);
  }

  public static portalCampaignLists(campaignListName: string, searchTerm?: string): string {
    return campaignListName === 'search'
      ? getRouteUrl(`marketing/campaigns/search?searchTerm=${searchTerm}`)
      : getRouteUrl(`marketing/campaigns/${campaignListName}`);
  }
}

export class RouteResources {
  public static vpaPayManagementRouteResources = VpaPayManagementRouteResources;
  public static payNowDirectRouteResources = PayNowDirectRouteResources;
  public static payLaterRouteResources = PayLaterRouteResources;
  public static marketingRouteResources = MarketingRouteResources;
  public static nextGenPaymentsPayLaterRouteResources = NextGenPaymentsPayLaterRouteResources;

  public static listings(mode: ApplicationMode): string {
    return getRouteUrl(`listings`, mode);
  }

  public static smartCommissionAvailableForFunding(mode: ApplicationMode) {
    return getRouteUrl(`smart-commission/available-for-funding`, mode);
  }

  public static smartCommissionFundedCampaigns(mode: ApplicationMode) {
    return getRouteUrl(`smart-commission/funded`, mode);
  }

  public static smartCommissionAgentSoldCampaigns(mode: ApplicationMode) {
    return `${mode === ApplicationMode.Training ? 'training/' : ''}smart-commission/agent-sold-campaigns`;
  }

  public static smartCommissionSoldCampaignsAdminSearchList(mode: ApplicationMode) {
    return getRouteUrl(`smart-commission/sold-campaigns`, mode);
  }

  public static listAllCampaigns(mode: ApplicationMode): string {
    return getRouteUrl(`applications/all`, mode);
  }

  public static applicationSummary(id, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/summary`, mode);
  }

  public static applicationCpnSummary(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`applications/campaigns/${cpn}/summary`, mode);
  }

  public static createApplication(id, mode: ApplicationMode): string {
    if (id) {
      return getRouteUrl(`applications/${id}/create`, mode);
    }
    return getRouteUrl(`applications/create`, mode);
  }

  public static createPdfApplication(cpn: string, mode: ApplicationMode): string {
    if (cpn) {
      return getRouteUrl(`applications/${cpn}/create-pdf`, mode);
    }
    return getRouteUrl(`applications/create-pdf`, mode);
  }

  public static createAdditionalVpaApplyPdf(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`applications/additional/${cpn}/create-pdf`, mode);
  }

  public static reviewPdfApplication(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${vpaApplicationId}/review-pdf`, mode);
  }

  public static applyApplication(id, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/apply`, mode);
  }

  public static selectVendor(applicationId: number, mode: ApplicationMode): string {
    return 'vpapay/' + getRouteUrl(`pay-later/applications/${applicationId}/select-vendor`, mode);
  }

  public static classicApplyApplication(id: number, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/apply-classic`, mode);
  }

  public static payNow(id, mode: ApplicationMode) {
    return getRouteUrl(`applications/${id}/pay-now`, mode);
  }

  public static bankStatements(id: number, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/bank-statements`, mode);
  }

  public static signApplication(id: number, mode: ApplicationMode, useLegacyAgreement: boolean): string {
    return useLegacyAgreement ? getRouteUrl(`applications/${id}/sign`, mode) : getRouteUrl(`applications/${id}/signv2`, mode);
  }

  public static incompleteApplications(mode: ApplicationMode): string {
    return getRouteUrl('applications/incomplete', mode);
  }

  public static inProgressApplications(mode: ApplicationMode): string {
    return getRouteUrl('applications/in-progress', mode);
  }

  public static actionRequiredApplications(mode: ApplicationMode): string {
    return getRouteUrl('applications/action-required', mode);
  }

  public static amendApplication(id, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/amend`, mode);
  }

  public static classicAmendApplication(id: number, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/amend-classic`, mode);
  }

  public static vendorThankYouPage(cpn, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${cpn}/thank-you`, mode);
  }

  public static uploadBrfFilePage(): string {
    return 'byo-pay-now/brf/upload';
  }

  public static managePayments(): string {
    return 'finance/manage-payments';
  }

  public static manageApplicationPayments(applicationAggregateId: Guid): string {
    return `byo-pay-now/manage/${applicationAggregateId}`;
  }

  public static payNowDirectAwaitingPayment(): string {
    return `byo-pay-now/awaiting-payment`;
  }

  public static applicationCancelSuccess(): string {
    return '/applications/cancellation-success';
  }

  public static passwordlessSms(): string {
    return '/sms';
  }

  public static login(): string {
    return '/login';
  }

  public static logout(): string {
    return '/logout';
  }

  public static switchToAgentApp(): string {
    return '/agent-app';
  }

  public static switchToPortal(): string {
    return '/portal';
  }

  public static vendorLogout(): string {
    return '/pnd/vendor-logout';
  }

  public static casAgencyLiveCampaigns(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/Live2';
  }

  public static casAgencyMissingCrmData(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ImportFailures';
  }

  public static casAgencyCasAppInProgress(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/VpaApply';
  }

  public static casAgencyActionRequired(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ActionRequired';
  }

  public static casAgencyUnsubmittedCampaigns(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ByStatus/Unsubmitted';
  }

  public static casAgencyBeingReviewedCampaigns(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ByStatus/WaitingForApproval';
  }

  public static casAgencyInvalidCampaigns(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ByStatus/Invalid';
  }

  public static casAgencyActiveCampaigns(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ByStatus/Approved';
  }

  public static casAgencyRejectedCampaigns(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ByStatus/Rejected';
  }

  public static casAgencyWithdrawnCampaigns(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ByStatus/Withdrawn';
  }

  public static casAgencyCompleteCampaigns(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/campaigns/ByStatus/Completed';
  }

  public static casAgencyPaymentReminders(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/paymentReminders';
  }

  public static casAgencySettings(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/settings';
  }

  public static casappAgencySettings(): string {
    return '/users/agency-settings';
  }

  public static reconciliation(): string {
    return '/finance/reconciliation';
  }

  public static casAgencyArchive(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/Archive/List';
  }

  public static casAgencyReconciliationReport(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/reports/reconciliation';
  }

  public static casAgencyCasAppAccess(): string {
    return AppSettingsService.appSettings.casUrl + 'agency/vpaApply/agentSettings';
  }

  public static showImportFailureDetails(importFailureId: number): string {
    return `${AppSettingsService.appSettings.casUrl}agency/campaigns/ImportFailure/${importFailureId}`;
  }

  public static generateCampaignStatement(cpn: string): string {
    return `${AppSettingsService.appSettings.casUrl}agency/Statements/${cpn}/Generate`;
  }

  public static addVpaReceivable(campaignId: number): string {
    return `${AppSettingsService.appSettings.casUrl}agency/campaigns/${campaignId}/VpaReceivables`;
  }

  public static agencyUpdateVpaDetails(campaignId: number, vpaReceivableId: number): string {
    return `${AppSettingsService.appSettings.casUrl}agency/campaigns/${campaignId}/VpaReceivables/${vpaReceivableId}/Update`;
  }

  public static agencyUpdateTotalAuthorisedAmount(campaignId: number, vpaReceivableId: number): string {
    return `${AppSettingsService.appSettings.casUrl}agency/campaigns/${campaignId}/VpaReceivables/${vpaReceivableId}/UpdateTotalFundingAmount`;
  }

  public static agencyRequestCancellation(campaignId: number, vpaReceivableId: number): string {
    return `${AppSettingsService.appSettings.casUrl}agency/campaigns/${campaignId}/VpaReceivables/${vpaReceivableId}/RequestCancellation`;
  }

  public static directToCasVendorAdd(campaignId: number): string {
    return `${AppSettingsService.appSettings.casUrl}agency/campaigns/${campaignId}/ManageCampaign#manage_vendor_section?action=addVendor`;
  }

  public static directToCasVendorEdit(campaignId: number): string {
    return `${AppSettingsService.appSettings.casUrl}agency/campaigns/${campaignId}/ManageCampaign#manage_vendor_section`;
  }

  public static viewDocument(campaignId: number, documentId: number): string {
    return `${AppSettingsService.appSettings.casUrl}agency/campaigns/${campaignId}/documents/${documentId}`;
  }

  public static casPortalSwitchUser(token: string): string {
    return AppSettingsService.appSettings.casUrl + `Account/LoginWithToken?token=${token}`;
  }

  public static loginWithGoogleAccount(): string {
    return `${AppSettingsService.appSettings.casUrl}Account/LoginWithGoogleAccount`;
  }

  public static loginWithCasToken(): string {
    return `login/casLoginToken`;
  }

  public static adminAgencyGroupUsersList(networkId: number, agencyGroupId: number): string {
    return `${AppSettingsService.appSettings.casUrl}admin/onboarding/Networks/${networkId}/AgencyGroups/${agencyGroupId}#users`;
  }

  public static adminVpaReceivableReview(campaignId: number, vpaReceivableId: number): string {
    return `${AppSettingsService.appSettings.casUrl}admin/Campaigns/${campaignId}/VpaReceivables/${vpaReceivableId}/review`;
  }

  public static adminManageCampaign(campaignId: number): string {
    return `${AppSettingsService.appSettings.casUrl}admin/Campaigns/${campaignId}/manage`;
  }

  public static casAdminHome(): string {
    return `${AppSettingsService.appSettings.casUrl}admin/home`;
  }

  public static getListUsersUrl(): string {
    return '/users';
  }

  public static getCreateAgencyGroupUserUrl(): string {
    return '/users/create';
  }

  public static getEditAgencyGroupUserUrl(userId: number): string {
    return `/users/${userId}/edit`;
  }

  public static getCreateAgencyGroupUserAsAdminUrl(networkId: number, agencyGroupId: number): string {
    return `/users/network/${networkId}/agencyGroup/${agencyGroupId}/create`;
  }

  public static getEditAgencyGroupUserAsAdminUrl(networkId: number, agencyGroupId: number, userId: number): string {
    return `/users/network/${networkId}/agencyGroup/${agencyGroupId}/user/${userId}/edit`;
  }

  public static createAdditionalVpa(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`/additionalvpa/${cpn}/create`, mode);
  }

  public static createAdditionalVpaInVpaApply(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`/applications/additional/${cpn}/create`, mode);
  }

  public static createPDFAdditionalVpaInVpaApply(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`/applications/additional/${cpn}/create-pdf`, mode);
  }

  public static additionalVpaApplyStep(additionalVpaApplicationId: number, mode: ApplicationMode) {
    return getRouteUrl(`additionalvpa/${additionalVpaApplicationId}/apply`, mode);
  }

  public static additionalVpaContributionStep(additionalVpaApplicationId: number, mode: ApplicationMode) {
    return getRouteUrl(`additionalvpa/${additionalVpaApplicationId}/contributions`, mode);
  }

  public static additionalVpaSigningStep(additionalVpaApplicationId: number, mode: ApplicationMode) {
    return getRouteUrl(`additionalvpa/${additionalVpaApplicationId}/sign`, mode);
  }

  public static componentList = (): string => '/component-list';
}

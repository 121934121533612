import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { clone } from 'apps/legacy/src/app/_helpers/clone';
import { ApplicationLoginContext } from 'apps/legacy/src/app/_shared/models';
import { ApiResources } from 'apps/legacy/src/app/_shared/models/api-resources';
import LogRocket from 'logrocket';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { CreateUserEventRequest } from '../models/requests/';
import { PageEventTracker } from '../pageEventTracker';

@Injectable({
  providedIn: 'root'
})
export class UserEventsService {
  constructor(private http: HttpClient, private appStateService: AppStateService, private deviceDetectorService: DeviceDetectorService) {}

  addUserEventWithDeviceInfo(eventName: string, cpn: string = null, metaData: any = null) {
    this.addUserEvent(eventName, cpn, this.appendDeviceInfoToMetaData(metaData));
  }

  addUserEventWithCorrelation(eventName: string, correlationKey: string, cpn: string = null, metaData: any = null) {
    if (!metaData) {
      metaData = {};
    }
    metaData.correlationKey = correlationKey;

    this.addUserEvent(eventName, cpn, metaData);
  }

  addUserEventWithTiming(eventName: string, start: number, correlationKey: string, cpn: string = null, metaData: any = null) {
    if (!metaData) {
      metaData = {};
    }

    metaData.correlationKey = correlationKey;
    metaData.durationMs = Date.now() - start;

    this.addUserEvent(eventName, cpn, metaData);
  }

  addUserEvent(eventName: string, cpn: string = null, metaData: object = null) {
    try {
      this.appStateService.isAuthenticated$
        .pipe(
          first(),
          filter((isAuthenticated) => isAuthenticated),
          switchMap((_) => {
            return combineLatest([this.resolveUserContext$(), this.resolveUserInfo$()]).pipe(
              switchMap(([userContext, userInfo]) => {
                const userEvent: CreateUserEventRequest = {
                  EventName: eventName,
                  MetaData: metaData,
                  Cpn: cpn,
                  UserContext: userContext,
                  UserName: userInfo?.userName,
                  UserAggregateId: userInfo?.userAggregateId
                };

                return this.addUserEventByRequest(userEvent);
              })
            );
          })
        )
        .subscribe();
    } catch (e) {
      Sentry.captureException(e.originalError || e.error || e.message || e);
    }
  }

  resolveUserContext$(): Observable<string> {
    return combineLatest([this.appStateService.old_getCurrentUserType$(), this.appStateService.applicationLoginContext$]).pipe(
      map(([currentUserType, applicationLoginContext]) => {
        if (applicationLoginContext === ApplicationLoginContext.AgentApp) return 'AgentApp';

        return currentUserType;
      }),
      first()
    );
  }

  resolveUserInfo$(): Observable<any> {
    return combineLatest([this.appStateService.userName$, this.appStateService.actualUserAggregateId$]).pipe(
      map(([userName, userAggregateId]) => ({ userName, userAggregateId })),
      first()
    );
  }

  addPageTrackingEvent(tracker: PageEventTracker, eventSuffix: string, cpn: string = null, metaData: any = null) {
    this.addUserEventWithTiming(
      `${tracker.moduleName}.${tracker.pageName}.${eventSuffix}`,
      tracker.startTime,
      tracker.correlationKey,
      cpn,
      metaData
    );
  }

  addPageTrackingEventWithDeviceInformation(
    pageEventTracker: PageEventTracker,
    eventSuffix: string,
    cpn: string = null,
    metaData: any = null
  ) {
    this.addPageTrackingEvent(pageEventTracker, eventSuffix, cpn, this.appendDeviceInfoToMetaData(metaData));
  }

  addAnonymousUserEvent(token: string, verifier: string, eventName: string, cpn: string = null, metaData: object = null) {
    try {
      const userEvent: CreateUserEventRequest = {
        EventName: eventName,
        MetaData: metaData,
        Cpn: cpn,
        UserContext: null
      };

      return this.addAnonymousUserEventByRequest(token, verifier, userEvent).subscribe();
    } catch (e) {
      Sentry.captureException(e.originalError || e.error || e.message || e);
    }
  }

  addAnonymousUserEventWithDeviceInfo(token: string, verifier: string, eventName: string, cpn: string = null, metaData: object = null) {
    this.addAnonymousUserEvent(token, verifier, eventName, cpn, this.appendDeviceInfoToMetaData(metaData));
  }

  private addUserEventByRequest(userEvent: CreateUserEventRequest): Observable<HttpResponseBase> {
    return this.http.post<HttpResponseBase>(ApiResources.addUserEvent(), userEvent);
  }

  private addAnonymousUserEventByRequest(token: string, verifier: string, userEvent: CreateUserEventRequest): Observable<HttpResponseBase> {
    if (!token || !verifier) {
      return;
    }

    return this.http.post<HttpResponseBase>(ApiResources.addAnonymousUserEvent(token, verifier), userEvent);
  }

  private appendDeviceInfoToMetaData(metaData: any): any {
    const returnMetaData = metaData == null ? {} : clone(metaData);

    // For some reason, this can occasionally cause FireFox to flip out and throw errors because the user agent seems to change.
    // If that happens, just let it slide and keep going without the device info
    try {
      returnMetaData.deviceInfo = {
        summary: this.deviceDetectorService.getDeviceInfo(),
        isMobile: this.deviceDetectorService.isMobile(),
        isTablet: this.deviceDetectorService.isTablet(),
        isDesktop: this.deviceDetectorService.isDesktop(),
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        // Note: this indicates multi-touch capability. Changes to the user agent Apple
        // provides on devices makes this a datapoint to determine whether users are using a
        // Macbook or iPad
        maxTouchPoints: window.navigator.maxTouchPoints
      };
    } catch (e) {
      if (!(e instanceof TypeError && e.message === 'can\'t redefine non-configurable property "userAgent"')) {
        throw e;
      }
    }

    returnMetaData.logRocketUrl = LogRocket.sessionURL;

    return returnMetaData;
  }
}

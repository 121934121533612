import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpfrontPaymentMethod } from 'apps/legacy/src/app/_shared/models';
import { NumberValidators } from 'apps/legacy/src/app/_shared/validators';
import { ContributionsModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ContributionsFormService {
  constructor(private formBuilder: FormBuilder) {}

  mapModelToForm(model: ContributionsModel): FormGroup {
    const getCurrentPaymentMethod = (): string =>
      model.contributions.reduce((_, val) => val.paymentMethod, UpfrontPaymentMethod[UpfrontPaymentMethod.CreditCard]);

    return this.formBuilder.group({
      currentRequestedAmount: ['', NumberValidators.positiveNumber],
      currentPaymentMethod: [getCurrentPaymentMethod(), Validators.required]
    });
  }
}

import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class BaseComponent implements OnDestroy {
  protected ngUnsubscribe = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected unsubOnDestroy<T>() {
    return takeUntil<T>(this.ngUnsubscribe);
  }
}

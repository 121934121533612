import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vpa-fat-zebra-iframe',
  templateUrl: './fat-zebra-iframe.component.html',
  styleUrls: ['./fat-zebra-iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FatZebraIframeComponent implements OnInit {
  @Input() iframeUrl: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  sanitizedUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
  }
}

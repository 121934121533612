import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedAppStateModule } from '@ca/shared/app-state';
import { SharedUtilsValidationModule } from '@ca/shared/utils/validation';
import { SharedValidationDisplayModule } from '@ca/shared/validation-display';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { HelpSectionComponent } from './components/help-section/help-section.component';
import { ImpersonationBannerComponent } from './components/impersonation-banner/impersonation-banner.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';

@NgModule({
  imports: [CommonModule, SharedAppStateModule, ReactiveFormsModule, SharedValidationDisplayModule, SharedUtilsValidationModule],
  declarations: [LoadingSpinnerComponent, ImpersonationBannerComponent, DatepickerComponent, HelpSectionComponent],
  exports: [LoadingSpinnerComponent, ImpersonationBannerComponent, DatepickerComponent, HelpSectionComponent]
})
export class SharedBaseComponentsModule {}

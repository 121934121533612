import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VendorCompletionReceiptModel, VendorProgressLogModel } from '@ca/vpapay/management-components/models';
import {
  CreateAdditionalVpaApplyApplicationModel,
  CreateAdditionalVpaApplyResponseModel
} from 'apps/legacy/src/app/applications-shared/models/create-additional-vpa-apply-model';
import {
  ApiResources,
  CheckVendorSigningPropertiesRequestModel,
  CheckVendorSigningPropertiesResponseModel,
  DocumentSourceDomain
} from 'apps/legacy/src/app/_shared/models';
import { Observable } from 'rxjs';
import {
  ApplicationQrCodeResult,
  CalculatedCostsAndDueDatesModel,
  CreateApplicationModel,
  CreateApplicationResponse,
  FeedbackModel,
  UpdateOptInOptOutModel
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class SharedApplicationApiService {
  constructor(protected http: HttpClient) {}

  getApplicationRequest(cpn, apiConsumerVpaApplicationAggregateId?: string): Observable<CreateApplicationModel> {
    let params = {};

    if (apiConsumerVpaApplicationAggregateId) params = { ...params, apiConsumerVpaApplicationAggregateId };

    return this.http.get<CreateApplicationModel>(ApiResources.getCreateApplication(cpn), {
      params
    });
  }

  upsertCreateApplication(application: CreateApplicationModel): Observable<CreateApplicationResponse> {
    if (application.cpn) {
      return this.http.put<CreateApplicationResponse>(ApiResources.upsertCreateApplication(application.cpn), application);
    } else {
      return this.http.post<CreateApplicationResponse>(ApiResources.upsertCreateApplicationWithoutListing(), application);
    }
  }

  getApplicationDocument(cpn: string, documentId: number, documentSourceDomain: DocumentSourceDomain): Observable<Blob> {
    return this.http.get<Blob>(ApiResources.getApplicationDocument(cpn, documentId, documentSourceDomain.toString()), {
      responseType: 'blob' as 'json'
    });
  }

  calculateCostsAndDueDates(
    agencyId: number,
    payNowDate: Date,
    payNowTotal: number,
    vpaApplicationId?: number
  ): Observable<CalculatedCostsAndDueDatesModel> {
    return this.http.get<CalculatedCostsAndDueDatesModel>(
      ApiResources.calculateCostsAndDueDates(agencyId, payNowDate, payNowTotal, vpaApplicationId)
    );
  }

  checkVendorSigningProperties(
    vpaApplicationId: number,
    request: CheckVendorSigningPropertiesRequestModel
  ): Observable<CheckVendorSigningPropertiesResponseModel> {
    return this.http.post<CheckVendorSigningPropertiesResponseModel>(ApiResources.checkVendorSigningProperties(vpaApplicationId), request);
  }

  downloadVendorStatement = (cpn: string, applicationId: number): Observable<Blob> =>
    this.http.get<Blob>(`management/applications/${applicationId}/statements/${cpn}`, { responseType: 'blob' as 'json' });

  addFeedback = (vpaApplicationId: number, feedback: FeedbackModel): Observable<HttpResponseBase> =>
    this.http.post<HttpResponseBase>(ApiResources.addFeedback(vpaApplicationId), feedback);

  getAdditionalApplicationRequest = (
    cpn: string,
    apiConsumerVpaApplicationAggregateId?: string
  ): Observable<CreateAdditionalVpaApplyApplicationModel> => {
    let params = {};

    if (apiConsumerVpaApplicationAggregateId) params = { ...params, apiConsumerVpaApplicationAggregateId };

    return this.http.get<CreateAdditionalVpaApplyApplicationModel>(ApiResources.createAdditionalVpa(cpn), {
      params
    });
  };

  completeCreateAdditionalApplicationStep(
    request: CreateAdditionalVpaApplyApplicationModel
  ): Observable<CreateAdditionalVpaApplyResponseModel> {
    return this.http.put<CreateAdditionalVpaApplyResponseModel>(ApiResources.createAdditionalVpa(request.cpn), request);
  }

  getQrCodeForVendor = (vpaApplicationId: number): Observable<ApplicationQrCodeResult> =>
    this.http.get<ApplicationQrCodeResult>(ApiResources.getQrCodeForVendor(vpaApplicationId));

  getVendorProgressLogForApplication = (vpaApplicationId: number): Observable<VendorProgressLogModel> =>
    this.http.get<VendorProgressLogModel>(ApiResources.getVendorApplicationProgressLog(vpaApplicationId));

  getCompletionReceiptForApplication = (vpaApplicationId: number): Observable<VendorCompletionReceiptModel> =>
    this.http.get<VendorCompletionReceiptModel>(ApiResources.getVendorCompletionReceipt(vpaApplicationId));

  updateOptInOptOutForApplicant = (vpaApplicationId: number, applicantId: number, request: UpdateOptInOptOutModel): Observable<void> =>
    this.http.post<void>(ApiResources.updateOptInOptOut(vpaApplicationId, applicantId), request);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '@ca/shared/pipes';
import { AlertModalV2Component } from './components/alert-modal-v2/alert-modal-v2.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { CreateListingModalComponent } from './components/create-listing-modal/create-listing-modal.component';
import { PromptModalComponent } from './components/prompt-modal/prompt-modal.component';
import { ResponsiveModalV2Component } from './components/responsive-modal-v2/responsive-modal-v2.component';
import { ResponsiveModalComponent } from './components/responsive-modal/responsive-modal.component';
import { SelectCreateFundingRequestModalComponent } from './components/select-create-funding-request-modal/select-create-funding-request-modal.component';
import { SubmitApplicationModalComponent } from './components/submit-application-modal/submit-application-modal.component';
import { UnsignApplicationModalComponent } from './components/unsign-application-modal/unsign-application-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedPipesModule, ReactiveFormsModule],
  declarations: [
    AlertModalComponent,
    AlertModalV2Component,
    ResponsiveModalComponent,
    ResponsiveModalV2Component,
    PromptModalComponent,
    UnsignApplicationModalComponent,
    SubmitApplicationModalComponent,
    SelectCreateFundingRequestModalComponent,
    CreateListingModalComponent
  ],
  exports: [
    AlertModalComponent,
    AlertModalV2Component,
    ResponsiveModalComponent,
    ResponsiveModalV2Component,
    PromptModalComponent,
    UnsignApplicationModalComponent,
    SubmitApplicationModalComponent,
    SelectCreateFundingRequestModalComponent,
    CreateListingModalComponent
  ]
})
export class SharedModalsModule {}

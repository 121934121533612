import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUtilsValidationModule } from '@ca/shared/utils/validation';
import { ControlValidationMessageV2Component } from './components/control-validation-message-v2/control-validation-message-v2.component';
import { ControlValidationMessageComponent } from './components/control-validation-message/control-validation-message.component';

@NgModule({
  imports: [CommonModule, SharedUtilsValidationModule],
  declarations: [ControlValidationMessageComponent, ControlValidationMessageV2Component],
  exports: [ControlValidationMessageComponent, ControlValidationMessageV2Component]
})
export class SharedValidationDisplayModule {}

export class Representee {
  actingAs: string;
  actingOnBehalfOf: string;
  relationshipDescription: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  dateOfBirth?: string;
  address?: string;
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProblemDetail } from '../models/problem-detail';
import { HttpClient } from '@angular/common/http';
import { UnelectedVendorRequest, UnelectedVendorResponse } from '../models';
import { ApiResources } from '../models/api-resources';

@Injectable({
  providedIn: 'root'
})
export class UnelectedVendorErrorsService {
  private errors: Subject<ProblemDetail> = new Subject();

  constructor(private http: HttpClient) { }

  public onErrors(errors: ProblemDetail): void {
    this.errors.next(errors);
  }

  public clearErrors(): void {
    this.errors.next(null);
  }

  public get errors$(): Observable<ProblemDetail> {
    return this.errors.asObservable();
  }

  getUnelectedVendorError(request: UnelectedVendorRequest): Observable<UnelectedVendorResponse> {
    return this.http.get<UnelectedVendorResponse>(
      ApiResources.getUnelectedVendorError(request.token, request.verifier, request.vpaApplicationId)
    );
  }
}

<div class="container">
  <div class="row breadcrumbs">
    <div
      class="col"
      (click)="tryGoToCreateStep()"
      *ngIf="!(isVendor$ | async)"
      [ngClass]="{
        current: breadcrumbsClassState.isCreateCurrent,
        canVisit: !breadcrumbsClassState.isCreateCurrent
      }"
    >
      <div class="step-text d-flex flex-column">
        <div class="step-heading"></div>
        <div class="step-desc">Create</div>
      </div>
    </div>
    <div
      class="col"
      (click)="tryGoToApplyStep()"
      [ngClass]="{
        current: breadcrumbsClassState.isApplyCurrent,
        canVisit: canGoToApplyStep() && !breadcrumbsClassState.isApplyCurrent
      }"
    >
      <div class="step-text d-flex flex-column">
        <div class="step-heading"></div>
        <div class="step-desc">Pay Now or Pay Later?</div>
      </div>
    </div>
    <div
      class="col"
      *ngIf="showPaymentStepBreadcrumb"
      (click)="tryGoToPayStep()"
      [ngClass]="{
        current: breadcrumbsClassState.isPayCurrent,
        canVisit: canGoToPayStep() && !breadcrumbsClassState.isPayCurrent
      }"
    >
      <div class="step-text d-flex flex-column">
        <div class="step-heading"></div>
        <div class="step-desc">Payment</div>
      </div>
    </div>
    <div
      *ngIf="showSignStepBreadcrumb"
      class="col"
      (click)="tryGoToSignStep()"
      [ngClass]="{
        current: breadcrumbsClassState.isSignCurrent,
        canVisit: canGoToSignStep() && !breadcrumbsClassState.isSignCurrent
      }"
    >
      <div class="step-text d-flex flex-column">
        <div class="step-heading"></div>
        <div class="step-desc">Terms and Conditions</div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showModal">
  <div [ngClass]="{ v2: useV2Style }">
    <div class="ca-modal-v2-container" [ngClass]="forceFullHeight ? 'full-height' : ''">
      <div *ngIf="showBackdrop" class="ca-modal-backdrop-v2 modal-backdrop fade show"></div>
      <div
        class="ca-modal-v2 modal"
        [id]="modalId"
        tabindex="-1"
        role="dialog"
        [attr.aria-labelledby]="labelId"
        aria-hidden="true"
        (click)="closeModal()"
      >
        <div class="modal-dialog" [ngClass]="modalSize" role="document" (click)="$event.stopPropagation()">
          <div class="modal-content">
            <div class="modal-close-button" *ngIf="showModalCloseButton">
              <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-header" *ngIf="hasTitleContent">
              <h5 class="modal-title w-100" [id]="labelId">
                <ng-content select="[slot=title]"></ng-content>
              </h5>
              <ng-content select="[slot=additionalHeader]"></ng-content>
            </div>

            <div class="modal-body" *ngIf="hasBodyContent">
              <ng-content select="[slot=body]"></ng-content>
            </div>

            <div class="modal-footer" *ngIf="hasFooterContent">
              <ng-content select="[slot=footer]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

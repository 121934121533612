import { Address, BaseModel, VendorSigningPropertiesModel } from 'apps/legacy/src/app/_shared/models';
import { Representee } from '.';

export class ApplicationApplicant extends BaseModel<ApplicationApplicant> {
  address: Address = new Address();
  dateOfBirth: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  mobile: string;
  isDeleted = false;
  isRepresentative: boolean;
  vendorImportAggregateId: string;
  vpaApplicationVendorId: number;
  representations: Representee[] = [];
  companyDetails: CompanyDetails = new CompanyDetails();
  isElectedToApply?: boolean;
  signingProperties?: VendorSigningPropertiesModel;
  isNewVendor?: boolean;

  fromModel(oldModel: ApplicationApplicant) {
    if (oldModel.address) {
      this.address.fromModel(oldModel.address);
    }

    this.dateOfBirth = oldModel.dateOfBirth;
    this.emailAddress = oldModel.emailAddress;
    this.firstName = oldModel.firstName;
    this.lastName = oldModel.lastName;
    this.mobile = oldModel.mobile;
    this.isDeleted = oldModel.isDeleted;
    this.vendorImportAggregateId = oldModel.vendorImportAggregateId;
    this.vpaApplicationVendorId = oldModel.vpaApplicationVendorId;
    this.representations = oldModel.representations;
    this.isRepresentative = oldModel.representations.length > 0;
    this.isElectedToApply = oldModel.isElectedToApply;
    this.signingProperties = oldModel.signingProperties;

    return this;
  }
}

export class CompanyDetails {
  companyName: string;
  acn: string;
}

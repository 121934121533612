import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';

export const initSentry = (environmentName: string, versionNumber: string): void => {
  const httpEventsToDiscard = [
    '400 Bad Request', // Validation errors
    '401 Unauthorized', // JWT's expiring
    '500 Internal Server Error', // These ones are captured by the back-end
    '0 Unknown Error' // Request interrupted by an ongoing deployment
  ];

  Sentry.init({
    dsn: 'https://b8bf6bc2939d4208977ad97c84957c04@sentry.io/1769702',
    environment: environmentName,
    release: `CASapp-${versionNumber}`,
    debug: true,
    beforeSend(event, hint) {
      // Multiple events are raised each time a HTTP request fails - so we'll filter out this one
      // since the other one doesn't require any manipulation and gets grouped nicer (by status code)
      if (
        event.exception &&
        event.exception.values.some((e) => e.value.startsWith('Non-Error exception captured')) &&
        hint.originalException instanceof HttpErrorResponse
      ) {
        return null;
      }

      // Ignore errors from opening emails in Outlook
      if (event.exception && event.exception.values.some((e) => e.value.includes('Object Not Found Matching Id:'))) {
        return null;
      }

      // Filter out certain http errors which we either don't consider to be exceptional situations
      // or we have already captured in the back-end
      if (
        event.exception &&
        event.exception.values.some(
          (e) => e.value.startsWith('Http failure response for') && httpEventsToDiscard.some((x) => e.value.endsWith(x))
        )
      ) {
        return null;
      }

      // If a chunk (lazy-loaded module) fails to load, we'll force a refresh of the page to try and account
      // for transient network errors and then swallow the error. If it fails again on the same file, then we'll
      // let it log as normal since that implies that something is actually wrong
      if (event.exception && event.exception.values.some((e) => e.value.includes('ChunkLoadError'))) {
        const previousError = localStorage.getItem('previousChunkError');
        const currentError = event.exception.values.find((e) => e.value.includes('ChunkLoadError')).value;
        if (currentError !== previousError) {
          localStorage.setItem('previousChunkError', currentError);
          window.location.reload();
          return null;
        }
      }

      return event;
    }
  });
};

import { Pipe, PipeTransform } from '@angular/core';
import { ListingSearchType } from '../../_shared/models/listing-search-type';

@Pipe({
  name: 'listingSearchType'
})
export class ListingSearchTypePipe implements PipeTransform {
  transform(listingSearchType: ListingSearchType): string {
    switch (listingSearchType) {
      case ListingSearchType.MyListingsOnly:
        return 'My Listings';

      case ListingSearchType.AllListings:
        return 'All Listings';
    }
  }
}

import { Injectable } from '@angular/core';
import imageCompression from 'browser-image-compression';
import { FileProcessingResponse } from '../models/file-processing-response';
import { FormFileModel } from '../models/form-file-model';
import { FileValidationService } from './file-validation.service';

@Injectable({
  providedIn: 'root'
})
export class FormFileAsyncService {
  constructor(private fileValidationService: FileValidationService) {}

  async processFile(event: any): Promise<FileProcessingResponse> {
    if (event.target.value.length !== 0) {
      const uploadedFile = this.getLastFile(event);

      const response = new FileProcessingResponse();
      response.validationResponse = this.validateFile(uploadedFile);

      if (response.validationResponse.isValid) {
        response.file = await this.getFile(uploadedFile);
      }

      // clear the file input, so any file can be re-uploaded
      event.target.value = '';

      return response;
    }
  }

  private getFile(uploadedFile: File): Promise<FormFileModel> {
    const fileExtensionsToCompress = /(\.jpg|\.jpeg|\.png)$/i;

    if (fileExtensionsToCompress.test(uploadedFile.name)) {
      try {
        return this.getCompressedFile(uploadedFile);
      } catch {
        return this.getStandardFile(uploadedFile);
      }
    } else {
      return this.getStandardFile(uploadedFile);
    }
  }

  private getStandardFile(uploadedFile: File): Promise<FormFileModel> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        const content = fileReader.result as ArrayBuffer;
        const binaryString = new Uint8Array(content).reduce((data, byte) => data + String.fromCharCode(byte), '');

        const file = new FormFileModel();
        file.fileContent = window.btoa(binaryString);
        file.fileName = uploadedFile.name;
        file.fileType = uploadedFile.type;

        resolve(file);
      };

      fileReader.readAsArrayBuffer(uploadedFile);
    });
  }

  private getCompressedFile(uploadedFile: File): Promise<FormFileModel> {
    return new Promise(async (resolve, reject) => {
      const fileReader = new FileReader();

      const options = {
        maxSizeMB: 1,
        useWebWorker: true
      };

      fileReader.onloadend = () => {
        const base64 = fileReader.result as string;

        const file = new FormFileModel();
        file.fileContent = base64.split(',')[1];
        file.fileName = uploadedFile.name;
        file.fileType = uploadedFile.type;

        resolve(file);
      };

      const blob = await imageCompression(uploadedFile, options);
      fileReader.readAsDataURL(blob);
    });
  }

  private getLastFile(event): File {
    return event.target.files[event.target.files.length - 1];
  }

  private validateFile(file: File) {
    return this.fileValidationService.validateFile(file);
  }
}

import { Injectable } from '@angular/core';
import imageCompression from 'browser-image-compression';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { FileValidationResponse } from '../models/file-validation-response';
import { FormFileModel } from '../models/form-file-model';
import { CADocumentType, VpaApplicationDocumentModel } from '../models/vpa-application-document-model';
import { FileValidationService } from './file-validation.service';

@Injectable({
  providedIn: 'root'
})
export class FormFileService {
  private filesBeingProcessed: File[] = [];
  private filesBeingProcessedSubject: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);
  private processedFilesSubject: Subject<FormFileModel> = new Subject<FormFileModel>();
  private processedVpaApplicationDocumentsSubject: Subject<VpaApplicationDocumentModel> = new Subject<VpaApplicationDocumentModel>();

  areFilesBeingProcessed$: Observable<boolean> = this.filesBeingProcessedSubject.asObservable().pipe(map((files) => files.length > 0));
  processedFiles$: Observable<FormFileModel> = this.processedFilesSubject.asObservable().pipe(skipWhile((f) => f === null));
  processedVpaApplicationDocuments$: Observable<
    VpaApplicationDocumentModel
  > = this.processedVpaApplicationDocumentsSubject.asObservable().pipe(skipWhile((f) => f === null));

  constructor(private fileValidationService: FileValidationService) {}

  async processFile(event: any, fileArray: FormFileModel[]): Promise<FileValidationResponse> {
    if (event.target.value.length !== 0) {
      const uploadedFile = this.getLastFile(event);
      const fileValidationState = this.validateFile(uploadedFile);
      if (fileValidationState.isValid) {
        await this.addFileToArray(uploadedFile, fileArray);
      }

      // clear the file input, so any file can be re-uploaded
      event.target.value = '';

      return fileValidationState;
    }
  }

  async processFileForVpaApplicationDocuments(
    event: any,
    fileType: CADocumentType,
    fileArray: VpaApplicationDocumentModel[]
  ): Promise<FileValidationResponse> {
    if (event.target.value.length !== 0) {
      const uploadedFile = this.getLastFile(event);
      const fileValidationState = this.validateFile(uploadedFile);

      if (fileValidationState.isValid) {
        await this.addFileToArrayForVpaApplicationDocuments(uploadedFile, fileType, fileArray);
      }

      // clear the file input, so any file can be re-uploaded
      event.target.value = '';

      return fileValidationState;
    }
  }

  async addFileToArray(uploadedFile: File, fileArray: FormFileModel[]): Promise<void> {
    const fileExtensionsToCompress = /(\.jpg|\.jpeg|\.png)$/i;
    this.addFileToProcessingList(uploadedFile);

    if (fileExtensionsToCompress.test(uploadedFile.name)) {
      try {
        await this.addCompressedFile(uploadedFile, fileArray);
      } catch {
        this.addStandardFile(uploadedFile, fileArray);
      }
    } else {
      this.addStandardFile(uploadedFile, fileArray);
    }
  }

  async addFileToArrayForVpaApplicationDocuments(
    uploadedFile: File,
    fileType: CADocumentType,
    fileArray: VpaApplicationDocumentModel[]
  ): Promise<void> {
    const fileExtensionsToCompress = /(\.jpg|\.jpeg|\.png)$/i;
    this.addFileToProcessingList(uploadedFile);

    if (fileExtensionsToCompress.test(uploadedFile.name)) {
      try {
        await this.addCompressedFileForVpaApplicationDocuments(uploadedFile, fileType, fileArray);
      } catch {
        this.addStandardFileForVpaApplicationDocuments(uploadedFile, fileType, fileArray);
      }
    } else {
      this.addStandardFileForVpaApplicationDocuments(uploadedFile, fileType, fileArray);
    }
  }

  areFilesBeingProcessed(): boolean {
    return this.filesBeingProcessed.length > 0;
  }

  private addStandardFile(uploadedFile: File, fileArray: FormFileModel[]) {
    const fileReader = new FileReader();
    const file = new FormFileModel();

    fileReader.readAsArrayBuffer(uploadedFile);

    fileReader.onloadend = () => {
      const content = fileReader.result as ArrayBuffer;
      const binaryString = new Uint8Array(content).reduce((data, byte) => data + String.fromCharCode(byte), '');
      file.fileContent = window.btoa(binaryString);
      file.fileName = uploadedFile.name;
      file.fileType = uploadedFile.type;

      this.finishProcessingFile(file, fileArray);
      this.removeFileFromProcessingList(uploadedFile);
    };
  }

  private addStandardFileForVpaApplicationDocuments(
    uploadedFile: File,
    fileType: CADocumentType,
    fileArray: VpaApplicationDocumentModel[]
  ) {
    const fileReader = new FileReader();
    const file = new FormFileModel();

    fileReader.readAsArrayBuffer(uploadedFile);

    fileReader.onloadend = () => {
      const content = fileReader.result as ArrayBuffer;
      const binaryString = new Uint8Array(content).reduce((data, byte) => data + String.fromCharCode(byte), '');
      file.fileContent = window.btoa(binaryString);
      file.fileName = uploadedFile.name;
      file.fileType = uploadedFile.type;

      this.finishProcessingFileForVpaApplicationDocuments(file, fileType, fileArray);
      this.removeFileFromProcessingList(uploadedFile);
    };
  }

  private async addCompressedFile(uploadedFile: File, fileArray: FormFileModel[]) {
    const fileReader = new FileReader();
    const file = new FormFileModel();

    const options = {
      maxSizeMB: 1,
      useWebWorker: true
    };

    const blob = await imageCompression(uploadedFile, options);
    fileReader.readAsDataURL(blob);
    fileReader.onloadend = () => {
      const base64 = fileReader.result as string;
      file.fileContent = base64.split(',')[1];
      file.fileName = uploadedFile.name;
      file.fileType = uploadedFile.type;

      this.finishProcessingFile(file, fileArray);
      this.removeFileFromProcessingList(uploadedFile);
    };
  }

  private async addCompressedFileForVpaApplicationDocuments(
    uploadedFile: File,
    fileType: CADocumentType,
    fileArray: VpaApplicationDocumentModel[]
  ) {
    const fileReader = new FileReader();
    const file = new FormFileModel();

    const options = {
      maxSizeMB: 1,
      useWebWorker: true
    };

    const blob = await imageCompression(uploadedFile, options);
    fileReader.readAsDataURL(blob);
    fileReader.onloadend = () => {
      const base64 = fileReader.result as string;
      file.fileContent = base64.split(',')[1];
      file.fileName = uploadedFile.name;
      file.fileType = uploadedFile.type;

      this.finishProcessingFileForVpaApplicationDocuments(file, fileType, fileArray);
      this.removeFileFromProcessingList(uploadedFile);
    };
  }

  private removeFileFromProcessingList(file: File) {
    this.filesBeingProcessed = this.filesBeingProcessed.filter((f) => f !== file);
    this.filesBeingProcessedSubject.next(this.filesBeingProcessed);
  }

  private addFileToProcessingList(file: File): void {
    this.filesBeingProcessed.push(file);
    this.filesBeingProcessedSubject.next(this.filesBeingProcessed);
  }

  private finishProcessingFile(file: FormFileModel, fileArray: FormFileModel[]) {
    this.processedFilesSubject.next(file);
    fileArray.push(file);
  }

  private finishProcessingFileForVpaApplicationDocuments(
    file: FormFileModel,
    fileType: CADocumentType,
    fileArray: VpaApplicationDocumentModel[]
  ) {
    this.processedVpaApplicationDocumentsSubject.next({ file, type: fileType });
    fileArray.push({ file, type: fileType });
  }

  private getLastFile(event): File {
    return event.target.files[event.target.files.length - 1];
  }

  private validateFile(file: File) {
    return this.fileValidationService.validateFile(file);
  }

  getFileValidationStateForUploadBrf(event): FileValidationResponse {
    return this.fileValidationService.validateBrfFile(event);
  }

  getProcessedBrfFile(file): Promise<FormFileModel> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      const processedFile = new FormFileModel();

      fileReader.onload = () => {
        const content = fileReader.result as string;
        processedFile.fileName = file.name;
        processedFile.fileContent = content;
        resolve(processedFile);
      };
      fileReader.readAsText(file);
    });
  }
}

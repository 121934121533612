import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatePaywayCustomerRequest, CreatePaywayCustomerResponse } from '../models';
import { ApiResources } from '../models/api-resources';

@Injectable({
  providedIn: 'root'
})
export class CreateCardCustomerApiService {
  constructor(private http: HttpClient) {}

  createVpaApplyCreditCardCustomer(request: CreatePaywayCustomerRequest): Observable<CreatePaywayCustomerResponse> {
    return this.http.post<CreatePaywayCustomerResponse>(ApiResources.createVpaApplyPaywayCustomer(request.id), request);
  }

  createAdditionalVpaCreditCardCustomer(request: CreatePaywayCustomerRequest): Observable<CreatePaywayCustomerResponse> {
    return this.http.post<CreatePaywayCustomerResponse>(ApiResources.createAdditionalVpaCreditCardCustomer(request.id), request);
  }
}

import { clone, distinctUntilValueChanged } from '@ca/shared/helpers';
import { PageEventTracker } from '@ca/shared/user-events';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, takeUntil } from 'rxjs/operators';
import { IComponentState } from './component-state';

export abstract class BaseFacadeService<TComponentState extends IComponentState<TModel>, TModel> {
  protected state: TComponentState;
  protected stateSubject: BehaviorSubject<TComponentState> = new BehaviorSubject<TComponentState>(this.state);
  protected processingCompleteSubject = new Subject<void>();

  private stateUpdates: BehaviorSubject<(state: TComponentState) => TComponentState> = new BehaviorSubject(null);
  // This is used to process updates to the facade state, by having a subscription
  // process them one by one as if it were a queue - this takes care of any
  // concurrency issues, as well as ensures that any spreading of state will always
  // use the the most up to date values.
  private stateUpdatesSubscription: Subscription = this.stateUpdates
    .asObservable()
    .pipe(
      skipWhile((func) => func === null),
      this.unsubOnProcessingCompleted()
    )
    .subscribe((func) => this.setState(func(this.state)));

  state$: Observable<TComponentState> = this.stateSubject.asObservable().pipe(
    skipWhile((s) => !s),
    distinctUntilValueChanged()
  );

  model$: Observable<TModel> = this.state$.pipe(
    skipWhile((s) => !s.model),
    map((s) => s.model),
    distinctUntilValueChanged()
  );

  hasLoaded$: Observable<boolean> = this.state$.pipe(
    map((s) => s.model !== null),
    distinctUntilChanged()
  );
  isLoading$: Observable<boolean> = this.state$.pipe(
    map((s) => s.isLoading),
    distinctUntilChanged()
  );
  isSubmitted$: Observable<boolean> = this.state$.pipe(
    map((s) => s.isSubmitted),
    distinctUntilChanged()
  );

  protected pageEventTracker: PageEventTracker;
  protected pageEventTrackerSubject: BehaviorSubject<PageEventTracker> = new BehaviorSubject<PageEventTracker>(this.pageEventTracker);
  pageEventTracker$: Observable<PageEventTracker> = this.pageEventTrackerSubject.asObservable().pipe(
    skipWhile((s) => !s),
    distinctUntilValueChanged()
  );

  protected setPageEventTracker(moduleName: string, pageName: string) {
    this.pageEventTracker = new PageEventTracker(moduleName, pageName);
    this.pageEventTrackerSubject.next(this.pageEventTracker);
  }

  private setState(state: TComponentState): void {
    this.state = clone(state);
    this.stateSubject.next(this.state);
  }

  protected pushStateUpdate(stateFn: (state: TComponentState) => TComponentState): void {
    this.stateUpdates.next(stateFn);
  }

  protected setIsLoading(isLoading: boolean): void {
    this.pushStateUpdate((state) => ({
      ...state,
      isLoading
    }));
  }

  protected setIsSubmitted(isSubmitted: boolean): void {
    this.pushStateUpdate((state) => ({
      ...state,
      isSubmitted
    }));
  }

  processingCompleted() {
    this.processingCompleteSubject.next();
    this.processingCompleteSubject.complete();
  }

  protected unsubOnProcessingCompleted<T>() {
    return takeUntil<T>(this.processingCompleteSubject);
  }
}

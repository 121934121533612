import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  CreditCardWorkflowButtonDetails,
  CreditCardWorkflowStep,
  PaymentDetailsOptionType
} from 'apps/legacy/src/app/_shared/models';
import { PaywayPaymentComponentState } from '../payway-iframe/payway-iframe.component';

@Component({
  selector: 'vpa-credit-card-multiple-contributions-modal',
  templateUrl: './credit-card-multiple-contributions-modal.component.html',
  styleUrls: ['./credit-card-multiple-contributions-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardMultipleContributionsModalComponent implements OnInit {
  @Input() state: CreditCardWorkflowState;
  @Input() formData: CreditCardWorkflowFormData;

  @Output() backButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() nextButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() savePayWayPayment: EventEmitter<void> = new EventEmitter();

  workflowStepEnum = CreditCardWorkflowStep;
  paymentDetailsOptionType = PaymentDetailsOptionType;

  constructor() {}

  ngOnInit(): void {}

  onBackButtonClicked(): void {
    this.backButtonClicked.emit();
  }

  onForwardButtonClicked(): void {
    this.nextButtonClicked.emit();
  }

  onSavePaywayPayment(): void {
    this.savePayWayPayment.emit();
  }
}

export interface CreditCardWorkflowState {
  workflowStep: CreditCardWorkflowStep;
  title: string;
  numberOfCards: number;
  currentCardBeingProcessed: number;
  buttonDetails: CreditCardWorkflowButtonDetails;
  amountOutstanding: number;
  cardNumberWord: string;
  iframeUrl: string;
  isSubmitted: boolean;
  isTryingToPayMoreThanOutstanding: boolean;
  creditCardErrors: string[];
  showIframe: boolean;
  paywayState: PaywayPaymentComponentState;
  paymentDetailsOptionType: PaymentDetailsOptionType;
}

export interface CreditCardWorkflowFormData {
  form: FormGroup;
  currentRequestedAmountControl: AbstractControl;
  creditCardNumberOfCardsControl: AbstractControl;
}

import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVpaStatus } from '@ca/shared/models';

@Pipe({
  name: 'globalVpaStatus'
})
export class GlobalVpaStatusPipe implements PipeTransform {
  transform(globalVpaStatusDetails: GlobalVpaStatusDetails, legalTerms: string): string {
    switch (globalVpaStatusDetails.globalVpaStatus) {
      case GlobalVpaStatus[GlobalVpaStatus.SignedAwaitingAuthority]:
        return `Awaiting Document`;

      case GlobalVpaStatus[GlobalVpaStatus.NotStarted]:
        return 'Unsubmitted';

      case GlobalVpaStatus[GlobalVpaStatus.FullyPaid]:
      case GlobalVpaStatus[GlobalVpaStatus.Approved]:
        return 'Active';

      default:
        return globalVpaStatusDetails.description;
    }
  }
}

export class GlobalVpaStatusDetails {
  description: string;
  cssClass: string;
  globalVpaStatus: string;
  globalVpaSubStatus: string;
}

import { FormFileModel } from './form-file-model';

export class VpaApplicationDocumentModel {
  type: CADocumentType;
  indexInOwnArray?: number;
  file: FormFileModel;
}

export enum CADocumentType {
  SalesAuthority = 'SalesAuthority',
  MarketingSchedule = 'MarketingSchedule',
  VendorIdentification = 'VendorIdentification',
  LandTitle = 'LandTitle'
}

export function getCADocumentTypeDisplayText(type: CADocumentType) {
  switch (type) {
    case CADocumentType.SalesAuthority:
      return 'Sales Authority';
    case CADocumentType.MarketingSchedule:
      return 'Marketing Schedule';
    case CADocumentType.VendorIdentification:
      return 'Vendor Identification';
    case CADocumentType.LandTitle:
      return 'Land Title';
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import 'url-search-params-polyfill';
import { AppStateService } from './app-state-service';

@Injectable({
  providedIn: 'root'
})
export class AppThemeService {
  private currentThemeSubject: ReplaySubject<string> = new ReplaySubject();
  readonly currentTheme$ = this.currentThemeSubject.asObservable();

  private readonly baseSubdomains = ['cas-app', 'app', 'app-uat', 'app-training', 'localhost'];
  private themeFromSession: string = null;

  constructor(@Inject(DOCUMENT) private document: Document, private appStateService: AppStateService) {
    this.appStateService.brandingSubdomain$.subscribe((brand) => {
      this.themeFromSession = brand;
      this.loadStyle();
    });
  }

  loadStyle = () => {
    const themeName = this.getThemeName();
    this.currentThemeSubject.next(themeName);

    const style = this.getThemeLinkElement();
    style.href = `https://cdn.campaignagent.com.au/agency-branding/${themeName}.css`;
    style.onerror = () => (style.href = 'https://cdn.campaignagent.com.au/agency-branding/default.css');
  };

  getThemeName(): string {
    const themeFromHost = this.getThemeFromHost();
    const themeFromQuery = this.getThemeFromQuery();

    // We want the query parameter to have precedence over the session one for the component list
    return themeFromQuery || this.themeFromSession || themeFromHost || 'default';
  }

  isOnBaseSubdomain(): boolean {
    const { isBaseSubdomain } = this.getSubdomain();
    return isBaseSubdomain;
  }

  private getThemeLinkElement(): HTMLLinkElement {
    let style = this.document.getElementById('client-theme') as HTMLLinkElement;

    if (!style) {
      style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';

      const head = this.document.getElementsByTagName('head')[0];
      head.appendChild(style);
    }

    return style;
  }

  private getThemeFromHost(): string {
    const { subdomain, isBaseSubdomain } = this.getSubdomain();
    return isBaseSubdomain ? null : subdomain;
  }

  private getThemeFromQuery(): string {
    const uriParams = new URLSearchParams(window.location.search);
    return uriParams.get('brand');
  }

  private getSubdomain(): { subdomain: string; isBaseSubdomain: boolean } {
    const subdomain = window.location.hostname.split('.')[0];
    return { subdomain, isBaseSubdomain: this.baseSubdomains.indexOf(subdomain) >= 0 };
  }
}

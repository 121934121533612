import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettingsService, GetVpaApplySettingService, SwitchableLogin } from '@ca/shared/app-state';
import * as Sentry from '@sentry/browser';
import * as jwtDecode from 'jwt-decode';
import LogRocket from 'logrocket';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { toEnumNoCase } from './_helpers/enum-helpers';
import { AgencyGroupUserRoles, AppState, DisplayMode, RegenerateJwtResponse, UserTypes, VpaApplySettings } from './_shared/models';
import { AgencyGroupType } from './_shared/models/agency-group-type';
import { ApiResources } from './_shared/models/api-resources';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private appState: AppState;
  private currentUserType: string;
  private isPendingMfa: boolean;
  private hideSideMenu: boolean;
  private agencyUserName: string;
  private agencyUserEmail: string;
  private agencyGroupName: string;
  private assumedUserId: number;
  private actualUserId: number;
  private nameId: number;
  private sandBoxedCpn: string;
  private switchableLogins: SwitchableLogin[];
  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isImpersonating: Subject<boolean> = new BehaviorSubject(false);

  private appStateSubject: ReplaySubject<AppState> = new ReplaySubject();
  readonly appState$: Observable<AppState> = this.appStateSubject.asObservable().pipe(filter((a) => !!a));

  private authToken: string;

  isIllionBankStatementsEnabled$: Observable<boolean> = this.appStateSubject.pipe(
    switchMap((s) => this.getValueOrReloadToken(() => s.IllionBankStatementsSettings.IsIllionBankStatementsEnabled))
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private getVpaApplySettingService: GetVpaApplySettingService,
    private ngZone: NgZone
  ) {
    this.initialiseState();
  }

  saveAuthToken(authToken): void {
    localStorage.setItem('authToken', authToken);
    this.initialiseState();
  }

  initialiseState = (): void => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      return;
    }

    this.authToken = authToken;
    this.isAuthenticated.next(true);
    this.setStateFromAuthToken(authToken);
  };

  isInitialisedFromCurrentAuthToken = () => {
    if (!this.isAuthenticated.value) {
      return false;
    }

    return this.authToken === localStorage.getItem('authToken');
  };

  getDisplayMode(): DisplayMode {
    return toEnumNoCase(DisplayMode, this.activatedRoute.snapshot.queryParamMap.get('displayMode'));
  }

  get isLoggedIn$(): Observable<boolean> {
    return combineLatest([this.isAuthenticated$, this.getIsPendingMfa$()]).pipe(
      first(),
      map(([isAuthenticated, isPendingMfa]) => isAuthenticated && !isPendingMfa)
    );
  }

  getAuthToken(): string {
    return localStorage.getItem('authToken');
  }

  getIsAuthenticated(): boolean {
    return localStorage.getItem('authToken') !== null;
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  getIsPendingMfa$(): Observable<boolean> {
    return this.getValueOrReloadToken(() => this.isPendingMfa);
  }

  get hideSideMenu$(): Observable<boolean> {
    return this.getValueOrReloadToken(() => this.hideSideMenu);
  }

  getAgencyUserName$(): Observable<string> {
    return this.getValueOrReloadToken(() => this.agencyUserName);
  }

  getAgencyUserEmail$(): Observable<string> {
    return this.getValueOrReloadToken(() => this.agencyUserEmail);
  }

  getAgencyGroupName$(): Observable<string> {
    return this.getValueOrReloadToken(() => this.agencyGroupName);
  }

  getNameId$(): Observable<number> {
    return this.getValueOrReloadToken(() => this.nameId);
  }

  getSwitchableLogins$(): Observable<SwitchableLogin[]> {
    if (!this.switchableLogins) {
      return of([]);
    }
    return this.getValueOrReloadToken(() => JSON.parse(this.switchableLogins.toString()));
  }

  getListinglessApplicationsEnabled$(): Observable<boolean> {
    return this.isListinglessApplicationsEnabled$;
  }

  get agentImportAggregateId$(): Observable<string> {
    return this.getValueOrReloadTokenOnBadValue(() => this.appState.AgentImportAggregateId, undefined);
  }

  get isListinglessApplicationsEnabled$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(
      switchMap((settings) => this.getValueOrReloadToken(() => settings.IsListinglessApplicationsEnabled))
    );
  }

  get vpaApplicationVendorId$(): Observable<number> {
    return this.getValueOrReloadToken(() => this.appState.VpaApplicationVendorId).pipe(map((id) => parseInt(id, 10)));
  }

  get vpaApplicationId$(): Observable<number> {
    return this.getValueOrReloadToken(() => this.appState.VpaApplicationId).pipe(map((id) => parseInt(id, 10)));
  }

  get hasSuperUserRole$(): Observable<boolean> {
    return combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
      map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.SuperUser))
    );
  }

  get hasMarketingRole$(): Observable<boolean> {
    return combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
      map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.Marketing))
    );
  }

  get hasFinanceRole$(): Observable<boolean> {
    return combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
      map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.Finance))
    );
  }

  get hasAgentRole$(): Observable<boolean> {
    return combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
      map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.Agent))
    );
  }

  get hasSmartCommissionAdminRole$(): Observable<boolean> {
    return combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
      map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.SmartCommissionAdministrator))
    );
  }

  get hasPortalAccess$(): Observable<boolean> {
    return combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
      map(
        ([isAgencyGroupUser, roles]) =>
          isAgencyGroupUser &&
          (roles.includes(AgencyGroupUserRoles.SuperUser) ||
            roles.includes(AgencyGroupUserRoles.Marketing) ||
            roles.includes(AgencyGroupUserRoles.Finance))
      )
    );
  }

  get isCurrentUserAnAgent$(): Observable<boolean> {
    return combineLatest([this.old_getCurrentUserType$(), this.hasAgentRole$]).pipe(
      map(([currentUserType, hasAgentRole]) => currentUserType === UserTypes.Agent || hasAgentRole)
    );
  }

  get isCurrentUserAnAgencyGroupUser$(): Observable<boolean> {
    return this.old_getCurrentUserType$().pipe(map((currentUserType: string) => currentUserType === UserTypes.AgencyGroup));
  }

  get isCurrentUserAnApiSandboxedUser$(): Observable<boolean> {
    return this.old_getCurrentUserType$().pipe(map((currentUserType: string) => currentUserType === UserTypes.ApiSandBoxed));
  }

  get isCurrentUserAnAdminUser$(): Observable<boolean> {
    return this.old_getCurrentUserType$().pipe(map((currentUserType: string) => currentUserType === UserTypes.Admin));
  }

  get isCurrentUserAnyTypeOfVendor$(): Observable<boolean> {
    return combineLatest([this.isCurrentUserAVpaApplyVendor$, this.isCurrentUserAnAdditionalVpaApplicant$]).pipe(
      first(),
      map(
        ([isCurrentUserAVpaApplyVendor, isCurrentUserAnAdditionalVpaApplicant]) =>
          isCurrentUserAVpaApplyVendor || isCurrentUserAnAdditionalVpaApplicant
      )
    );
  }

  get isCurrentUserAVpaApplyVendor$(): Observable<boolean> {
    return this.old_getCurrentUserType$().pipe(map((currentUserType: string) => currentUserType === UserTypes.Vendor));
  }

  get isCurrentUserAnAdditionalVpaApplicant$(): Observable<boolean> {
    return this.old_getCurrentUserType$().pipe(map((currentUserType: string) => currentUserType === UserTypes.AdditionalVpaApplicant));
  }

  get isDigitalAdditionalVpaEnabled$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(
      switchMap((settings) => this.getValueOrReloadToken(() => settings.IsDigitalAdditionalVpaEnabled))
    );
  }

  get isSalesAuthorityMandatoryWhenCreatingNewApplication$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(
      switchMap((settings) => this.getValueOrReloadToken(() => settings.IsSalesAuthorityMandatoryWhenCreatingNewApplication))
    );
  }

  get isVpaAppliedEnabled$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(switchMap((settings) => this.getValueOrReloadToken(() => settings.IsVpaAppliedEnabled)));
  }

  get isPayNowSavingsShown$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(switchMap((settings) => this.getValueOrReloadToken(() => settings.IsPayNowSavingsShown)));
  }

  get isAgentAbleToViewOtherAgentsListing$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(
      switchMap((settings) => this.getValueOrReloadToken(() => settings.IsAgentAbleToViewOtherAgentsListing))
    );
  }

  get isVendorFlowEnabled$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(switchMap((settings) => this.getValueOrReloadToken(() => settings.IsVendorFlowEnabled)));
  }

  get isPayByChequeEnabled$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(switchMap((settings) => this.getValueOrReloadToken(() => settings.IsPayByChequeEnabled)));
  }

  get isBpayEnabled$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(switchMap((settings) => this.getValueOrReloadToken(() => settings.IsBpayEnabled)));
  }

  get canAgentsCreatePdfApplication$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(
      switchMap((settings) => this.getValueOrReloadToken(() => settings.CanAgentsCreatePdfApplication))
    );
  }

  get isSigningVendor$(): Observable<boolean> {
    return this.getValueOrReloadToken(() => this.appState.IsSigningVendor).pipe(
      map((isSigningVendor: string) => !!isSigningVendor && isSigningVendor.toLocaleLowerCase() === 'true')
    );
  }

  get isByoPayNowEnabled$(): Observable<boolean> {
    return this.getValueOrReloadToken(() => this.appState.IsByoPayNowEnabled).pipe(
      map((isByoPayNowEnabled: string) => !!isByoPayNowEnabled && isByoPayNowEnabled.toLocaleLowerCase() === 'true')
    );
  }

  get hasByoBpayUnreconciledEntries$(): Observable<boolean> {
    return this.getValueOrReloadToken(() => this.appState.HasByoBpayUnreconciledEntries).pipe(
      map(
        (hasByoBpayUnreconciledEntries: string) =>
          !!hasByoBpayUnreconciledEntries && hasByoBpayUnreconciledEntries.toLocaleLowerCase() === 'true'
      )
    );
  }

  get authorityDocumentIncludesMarketingSchedule$(): Observable<boolean> {
    return this.getValueOrReloadToken(() => this.appState.AuthorityDocumentIncludesMarketingSchedule).pipe(
      map(
        (authorityDocumentIncludesMarketingSchedule: string) =>
          !!authorityDocumentIncludesMarketingSchedule && authorityDocumentIncludesMarketingSchedule.toLocaleLowerCase() === 'true'
      )
    );
  }

  get isAgentSmartCommissionEnabled$(): Observable<boolean> {
    return this.getValueOrReloadToken(() => this.appState.IsAgentSmartCommissionEnabled).pipe(
      map(
        (isAgentSmartCommissionEnabled: string) =>
          !!isAgentSmartCommissionEnabled && isAgentSmartCommissionEnabled.toLocaleLowerCase() === 'true'
      )
    );
  }

  get incompleteApplicationStartDateDaysUntilDisplayedYellow$(): Observable<number> {
    return this.getVpaApplySettings$().pipe(
      switchMap((settings) => this.getValueOrReloadToken(() => settings.IncompleteApplicationStartDateDaysUntilDisplayedYellow))
    );
  }

  get incompleteApplicationStartDateDaysUntilDisplayedRed$(): Observable<number> {
    return this.getVpaApplySettings$().pipe(
      switchMap((settings) => this.getValueOrReloadToken(() => settings.IncompleteApplicationStartDateDaysUntilDisplayedRed))
    );
  }

  // This is deprecated because it just masks a value as an observable, instead of just being ovservable-based (which is what we want)
  // To be migrated to the new methods eventually, but needs to stay for now since things depend on it.
  old_getCurrentUserType$(): Observable<string> {
    return this.getValueOrReloadToken(() => this.currentUserType);
  }

  getCurrentUserType$(): Observable<string> {
    return this.appState$.pipe(
      map((appState) => appState['CampaignAgent.CurrentUserType']),
      distinctUntilChanged()
    );
  }

  // This is deprecated because it just masks a value as an observable, instead of just being ovservable-based (which is what we want)
  // To be migrated to the new methods eventually, but needs to stay for now since things depend on it.
  get old_assumedUserId$(): Observable<number> {
    return this.getValueOrReloadToken(() => parseInt(this.appState['CampaignAgent.AssumedUserId'], 10));
  }

  get assumedUserId$(): Observable<number> {
    return this.appState$.pipe(
      map((appState) => parseInt(appState['CampaignAgent.AssumedUserId'], 10)),
      distinctUntilChanged()
    );
  }

  get actualUserId$(): Observable<number> {
    return this.getValueOrReloadToken(() => parseInt(this.appState['CampaignAgent.ActualUserId'], 10));
  }

  get isImpersonating$(): Observable<boolean> {
    return this.isImpersonating.asObservable();
  }

  get sandBoxedCpn$(): Observable<string> {
    return this.getValueOrReloadToken(() => this.sandBoxedCpn);
  }

  get roles$(): Observable<string[]> {
    return this.getValueOrReloadToken(() => this.appState.role).pipe(
      map((role) => {
        if (Array.isArray(role)) {
          return role;
        }

        return [role];
      })
    );
  }

  get applicationLoginContext$(): Observable<string> {
    return this.getValueOrReloadToken(() => this.appState.ApplicationLoginContext);
  }

  get userName$(): Observable<string> {
    return this.getValueOrReloadToken(() => this.appState.unique_name);
  }

  get actualUserAggregateId$(): Observable<string> {
    return this.getValueOrReloadToken(() => this.appState.ActualUserAggregateIdClaimType);
  }

  get isPayLaterEnabled$(): Observable<boolean> {
    return this.getVpaApplySettings$().pipe(switchMap((settings) => this.getValueOrReloadToken(() => settings?.AllowPayLater)));
  }

  get agencyGroupType$(): Observable<AgencyGroupType> {
    return this.getValueOrReloadToken(() => this.appState.AgencyGroupType);
  }

  private getVpaApplySettings$(): Observable<VpaApplySettings> {
    return this.isCurrentUserAnAdminUser$.pipe(
      switchMap((isCurrentUserAnAdminUser) =>
        isCurrentUserAnAdminUser ? this.setAgencyGroupIdForAdmin() : this.getValueOrReloadToken(() => this.appState.VpaApplySettings)
      )
    );
  }

  private setAgencyGroupIdForAdmin() {
    if (!this.getVpaApplySettingService.hasBeenSetup) {
      this.getVpaApplySettingService.setAgencyGroupId(parseInt(localStorage.getItem('agencyGroupId'), 10));
    }
    return this.getVpaApplySettingService.vpaApplySettings$;
  }

  private setStateFromAuthToken(authToken: string): void {
    try {
      this.appState = jwtDecode<AppState>(authToken);
    } catch (e) {
      this.clearState();
      return;
    }

    this.currentUserType = this.appState['CampaignAgent.CurrentUserType'];
    this.assumedUserId = this.appState['CampaignAgent.AssumedUserId'];
    this.actualUserId = this.appState['CampaignAgent.ActualUserId'];
    this.hideSideMenu = this.appState['CampaignAgent.HideSideMenu'] && this.appState['CampaignAgent.HideSideMenu'].toLowerCase() === 'true';
    this.isPendingMfa = this.appState['CampaignAgent.PendingMfa'] && this.appState['CampaignAgent.PendingMfa'].toLowerCase() === 'true';

    if (this.currentUserType !== UserTypes.Admin) {
      localStorage.removeItem('agencyGroupId');
    }

    switch (this.currentUserType) {
      case UserTypes.Agent:
        this.agencyUserEmail = this.appState.email;
        this.agencyUserName = this.appState.unique_name;
        this.nameId = parseInt(this.appState.nameid, 10);
        this.agencyGroupName = this.appState['CampaignAgent.AgencyGroupName'];
        this.switchableLogins = this.appState.SwitchableLogins;
        if (AppSettingsService.appSettings.useLogRocket) {
          LogRocket.identify(this.appState.AgentImportAggregateId, {
            name: this.agencyUserName,
            email: this.agencyUserEmail,
            userType: 'Agent'
          });
        }

        Sentry.setUser({ email: this.agencyUserEmail });
        break;

      case UserTypes.ApiSandBoxed:
        this.agencyUserEmail = this.appState.email;
        this.agencyUserName = this.appState.unique_name;
        this.nameId = parseInt(this.appState.nameid, 10);
        this.agencyGroupName = this.appState['CampaignAgent.AgencyGroupName'];
        if (AppSettingsService.appSettings.useLogRocket) {
          LogRocket.identify(this.assumedUserId.toString(), {
            name: this.agencyUserName,
            email: this.agencyUserEmail,
            userType: 'Sandboxed User'
          });
        }
        this.sandBoxedCpn = this.appState['CampaignAgent.SandBoxedCpnClaimType'];

        Sentry.setUser({ email: this.agencyUserEmail });
        break;

      case UserTypes.AgencyGroup:
        this.agencyUserEmail = this.appState.email;
        this.agencyUserName = this.appState.unique_name;
        this.nameId = parseInt(this.appState.nameid, 10);
        this.agencyGroupName = this.appState['CampaignAgent.AgencyGroupName'];
        this.switchableLogins = this.appState.SwitchableLogins;
        if (AppSettingsService.appSettings.useLogRocket) {
          LogRocket.identify(this.assumedUserId.toString(), {
            name: this.agencyUserName,
            email: this.agencyUserEmail,
            userType: 'Agency Admin'
          });
        }

        Sentry.setUser({ email: this.agencyUserEmail });
        break;

      case UserTypes.Vendor:
        this.agencyUserName = this.appState.unique_name;
        this.nameId = parseInt(this.appState.nameid, 10);
        if (AppSettingsService.appSettings.useLogRocket) {
          LogRocket.identify(this.appState.VpaApplicationVendorId.toString(), {
            name: this.appState.unique_name,
            email: this.appState.email,
            userType: 'Vendor'
          });
        }
        this.sandBoxedCpn = this.appState['CampaignAgent.SandBoxedCpnClaimType'];

        Sentry.setUser({ email: this.appState.email });
        break;

      case UserTypes.VpaDotMobi:
        if (AppSettingsService.appSettings.useLogRocket) {
          LogRocket.identify(this.assumedUserId.toString(), {
            name: this.appState.unique_name,
            email: this.appState.email,
            userType: 'VpaDotMobi'
          });
        }

        Sentry.setUser({ email: this.appState.email });
        break;

      case UserTypes.Admin:
        if (AppSettingsService.appSettings.useLogRocket) {
          LogRocket.identify(this.assumedUserId.toString(), {
            name: this.appState.unique_name,
            email: this.appState.email,
            userType: 'Admin'
          });
        }
        this.isImpersonating.next(this.assumedUserId !== this.actualUserId);
        return;
    }

    // These are passed through as the string representation of it's JSON
    // So it doesn't get automatically deserialized
    if (this.appState.VpaApplySettings) {
      this.appState.VpaApplySettings = JSON.parse(this.appState.VpaApplySettings?.toString());
    }
    if (this.appState.IllionBankStatementsSettings) {
      this.appState.IllionBankStatementsSettings = JSON.parse(this.appState.IllionBankStatementsSettings.toString());
    }

    const userTypesAllowedElevio = [UserTypes.Admin, UserTypes.AgencyGroup, UserTypes.ApiSandBoxed];

    if (userTypesAllowedElevio.includes(this.currentUserType)) {
      const elevioLoad = (elev: any) => {
        // If this function is run after we have cleared an expired token, bail out and handle it on next login
        if (!this.appState) {
          return;
        }

        elev.setSettings({
          enabled: this.getDisplayMode() !== DisplayMode.BrowserExtension,
          hideLauncher: true
        });

        elev.initialize();
        elev.setUser({
          email: this.appState.email,
          user_hash: this.appState.ElevioUserHash,
          groups: this.appState.ElevioGroup
        });
      };

      this.ngZone.runOutsideAngular(() => {
        (window as any)._elev.on('load', elevioLoad.bind(this));
      });
    } else {
      this.ngZone.runOutsideAngular(() => {
        const elev = (window as any)._elev;
        elev.on('load', () => {
          elev.setSettings({
            autoInitialize: false,
            enabled: false
          });
        });
      });
    }

    this.appStateSubject.next(this.appState);

    this.isImpersonating.next(this.assumedUserId !== this.actualUserId);
  }

  clearState() {
    this.appState = null;
    this.currentUserType = null;
    this.isPendingMfa = false;
    this.hideSideMenu = false;
    this.agencyUserName = '';
    this.agencyUserEmail = '';
    this.agencyGroupName = '';
    this.switchableLogins = null;
    this.isImpersonating.next(false);
    this.isAuthenticated.next(false);
    this.appStateSubject.next(null);
    this.authToken = null;
    localStorage.clear();
  }

  private getValueOrReloadToken<T>(getter: () => T): Observable<T> {
    if (!this.getIsAuthenticated()) {
      return of(null);
    }

    try {
      return of(getter());
    } catch (e) {
      return this.regenerateJwt(getter);
    }
  }

  private getValueOrReloadTokenOnBadValue<T>(getter: () => T, valueToReloadOn: any): Observable<T> {
    try {
      const value = getter();
      if (value === valueToReloadOn) {
        return this.regenerateJwt(getter);
      }
      return of(value);
    } catch (e) {
      return this.regenerateJwt(getter);
    }
  }

  private regenerateJwt<T>(getter: () => T): Observable<T> {
    if (!this.getIsAuthenticated()) {
      return of(null);
    }

    return this.http.post<RegenerateJwtResponse>(ApiResources.regenerateJwt(), {}).pipe(
      map((res) => {
        this.saveAuthToken(res.authToken);
        return getter();
      }),
      catchError((err) => {
        console.log(err);
        this.clearState();
        this.router.navigate(['login']);
        return of(null);
      })
    );
  }

  setShowInstallPopup(value: boolean): void {
    localStorage.setItem('showInstallPopup', value ? 'true' : 'false');
  }

  getShowInstallPopup(): boolean {
    return localStorage.getItem('showInstallPopup') === 'true';
  }
}

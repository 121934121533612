import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormFileModel } from 'apps/legacy/src/app/_shared/models/form-file-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'vpa-additional-vpa-readonly-marketing-quotes',
  templateUrl: './additional-vpa-readonly-marketing-quotes.component.html',
  styleUrls: ['./additional-vpa-readonly-marketing-quotes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalVpaReadonlyMarketingQuotesComponent implements OnInit {
  @Input() documents$: Observable<FormFileModel[]>;
  @Output() downloadDocumentEvent: EventEmitter<FormFileModel> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  downloadDocument(document: FormFileModel, event: Event) {
    event.preventDefault();
    this.downloadDocumentEvent.emit(document);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vpa-resign-notification-modal',
  templateUrl: './resign-notification-modal.component.html',
  styleUrls: ['./resign-notification-modal.component.scss']
})
export class ResignNotificationModalComponent implements OnInit {
  @Input() triggerResignList: string[];
  @Input() isAgentApplicationFlow: boolean;
  @Input() referenceApplicationFlow: boolean;
  @Output() closeModalEvent = new EventEmitter();
  @Output() agreeUnsignEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  closeModal() {
    this.closeModalEvent.emit();
  }

  agreeUnsign() {
    this.agreeUnsignEvent.emit();
  }
}

export interface ResignNotificationModalState {
  triggerResignList: string[];
  isAgentApplicationFlow: boolean;
  referenceApplicationFlow: boolean;
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppSettingsService, AppStateService } from '@ca/shared/app-state';
import { CampaignAgentApplications } from '@ca/shared/models';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import {
  AgencyGroupUserRoles,
  ApiResources,
  ApplicationLoginContext,
  ApplicationMode,
  ApplicationModeItem,
  BadgeStyle,
  NavigationItem,
  NavigationMenuBadgeDataForAgencyGroupResponse,
  NavigationMenuBadgeDataForAgentResponse,
  RouteResources,
  UserTypes
} from '../models';
import { AgencyGroupType } from '../models/agency-group-type';
import { LoginDestination } from '../models/login-destination';
import { NavigationItemAction } from '../models/navigation-item-action';
import { NavigationItemConstants } from '../models/navigation-item-constants';
import { ApplicationModeService } from './application-mode.service';

@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  // tslint:disable-next-line: max-line-length
  constructor(
    private appStateService: AppStateService,
    private applicationModeService: ApplicationModeService,
    private http: HttpClient,
    @Inject('application') private campaignAgentApplication: CampaignAgentApplications
  ) {}

  private get navObservables(): Observable<[string, ApplicationMode]> {
    return combineLatest([this.appStateService.currentUserType$, this.applicationModeService.currentApplicationModeSubject]);
  }
  private refreshDataSubject: Subject<void> = new Subject<void>();

  private canSwitchToPortal$: Observable<boolean> = combineLatest([
    this.appStateService.switchableLogins$,
    this.appStateService.assumedUserId$,
    this.appStateService.appState$
  ]).pipe(
    map(([switchableLogins, assumedUserId, appState]) => {
      if (!appState || appState.ApplicationLoginContext === ApplicationLoginContext.Portal) return false;

      const portalUsers =
        switchableLogins &&
        switchableLogins.filter((user) => user.UserId === assumedUserId && user.Destination === LoginDestination.Portal);

      if (!portalUsers) {
        return false;
      }

      return portalUsers.length > 0;
    })
  );

  private canSwitchToAgentApp$: Observable<boolean> = combineLatest([
    this.appStateService.switchableLogins$,
    this.appStateService.assumedUserId$,
    this.appStateService.appState$
  ]).pipe(
    map(([switchableLogins, assumedUserId, appState]) => {
      if (!appState || appState.ApplicationLoginContext === ApplicationLoginContext.AgentApp) return false;

      const appUsers =
        switchableLogins && switchableLogins.filter((user) => user.UserId === assumedUserId && user.Destination === LoginDestination.App);

      if (!appUsers) {
        return false;
      }

      return appUsers.length > 0;
    })
  );

  private canShowSwitchAccount$: Observable<boolean> = combineLatest([
    this.appStateService.switchableLogins$,
    this.appStateService.assumedUserId$,
    this.appStateService.isCurrentUserAnAgencyGroupUser$,
    this.appStateService.isCurrentUserAnAdminUser$,
    this.appStateService.applicationLoginContext$
  ]).pipe(
    map(([switchableLogins, assumedUserId, isCurrentUserAnAgencyGroupUser, isCurrentUserAnAdminUser, applicationLoginContext]) => {
      const currentDestination =
        applicationLoginContext === ApplicationLoginContext.AgentApp ? LoginDestination.App : LoginDestination.Portal;
      const hasAccountToSwitch =
        switchableLogins && switchableLogins.findIndex((l) => l.Destination !== currentDestination || l.UserId !== assumedUserId) !== -1;

      return hasAccountToSwitch && (isCurrentUserAnAgencyGroupUser || isCurrentUserAnAdminUser);
    })
  );

  private static createApplicationModeItems(currentApplicationMode: ApplicationMode): ApplicationModeItem[] {
    return [
      new ApplicationModeItem(ApplicationMode.Live, 'Live Data', currentApplicationMode === ApplicationMode.Live),
      new ApplicationModeItem(ApplicationMode.Training, 'Training Data', currentApplicationMode === ApplicationMode.Training)
    ];
  }

  private static createNavMenuItemsForAdmin(): Observable<NavigationItem[]> {
    return of([
      {
        name: 'Return to CAS',
        icon: 'fas fa-check',
        link: {
          url: RouteResources.casAdminHome(),
          isInternalLink: false,
          campaignAgentApplications: null
        }
      },
      NavMenuService.createLogoutItem()
    ]);
  }

  private static setupAgencyAdminFinanceManagementItems(
    menuItems: NavigationItem[],
    roles: string[],
    isByoPayNowEnabled: boolean,
    hasByoBpayUnreconciledEntries: boolean,
    badgeData: NavigationMenuBadgeDataForAgencyGroupResponse,
    applicationMode: ApplicationMode,
    agencyGroupType: AgencyGroupType
  ) {
    const financeSubNavItems = [];

    if (roles.includes(AgencyGroupUserRoles.Finance)) {
      if (hasByoBpayUnreconciledEntries) {
        financeSubNavItems.push({
          name: 'Upload BPAY File',
          link: {
            url: RouteResources.uploadBrfFilePage(),
            isInternalLink: true,
            campaignAgentApplications: CampaignAgentApplications.Legacy
          }
        });
      }

      if (isByoPayNowEnabled) {
        financeSubNavItems.push({
          name: 'Reconciliation',
          link: {
            url: RouteResources.reconciliation(),
            isInternalLink: true,
            campaignAgentApplications: CampaignAgentApplications.Legacy
          }
        });

        financeSubNavItems.push({
          name: 'Manage Payments',
          link: {
            url: RouteResources.managePayments(),
            isInternalLink: true,
            campaignAgentApplications: CampaignAgentApplications.Legacy
          },
          badge: {
            content: badgeData.managePaymentsApplicationsCount > 0 ? badgeData.managePaymentsApplicationsCount.toString() : '',
            style: BadgeStyle.Info
          }
        });
      }

      if (agencyGroupType === AgencyGroupType.Sales) {
        financeSubNavItems.push({
          name: 'Upcoming Settlements',
          link: {
            url: RouteResources.casAgencyPaymentReminders(),
            isInternalLink: false,
            campaignAgentApplications: null
          },
          badge: {
            content: badgeData.upcomingSettlementCount > 0 ? badgeData.upcomingSettlementCount.toString() : '',
            style: BadgeStyle.Danger
          }
        });
      }

      if (badgeData.isLegacyReconciliationReportEnabled) {
        financeSubNavItems.push({
          name: 'Reconciliation Report',
          link: {
            url: RouteResources.casAgencyReconciliationReport(),
            isInternalLink: false,
            campaignAgentApplications: null
          }
        });
      }
    }

    if (
      badgeData.isAgentFundingEnabled &&
      roles.includes(AgencyGroupUserRoles.SmartCommissionAdministrator) &&
      AppSettingsService.appSettings.featureFlags.isSmartCommissionEnabled
    ) {
      financeSubNavItems.push({
        name: 'SmartCommission Management',
        link: {
          url: RouteResources.smartCommissionSoldCampaignsAdminSearchList(applicationMode),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: CampaignAgentApplications.SmartComm
        },
        badge: badgeData.isAuthorisationRequiredForAgentFunding
          ? {
              content: badgeData.agentFundingRequestsAwaitingAuthorisationCount.toString(),
              style: BadgeStyle.Danger
            }
          : null
      });
    }

    if (financeSubNavItems.length > 0) {
      menuItems.push({
        name: 'Finance Management',
        icon: 'fad fa-money-check-edit-alt',
        isExpandedByDefault: true,
        subNavItems: financeSubNavItems
      });
    }
  }

  private static createLogoutItem(): NavigationItem {
    return {
      name: 'Log Out',
      icon: 'far fa-sign-out',
      isUserDetailNavigation: true,
      link: {
        url: RouteResources.logout(),
        isInternalLink: true,
        campaignAgentApplications: CampaignAgentApplications.Legacy
      }
    };
  }

  private createSwitchAccountItem(): NavigationItem {
    return {
      name: 'Switch Account',
      icon: 'far fa-list',
      isUserDetailNavigation: true,
      action: NavigationItemAction.ToggleSwitchableAccountsMenu
    };
  }

  instantiateApplicationModeItems(): Observable<ApplicationModeItem[]> {
    return this.navObservables.pipe(
      switchMap(([currentUserType, currentApplicationMode]) => {
        switch (currentUserType) {
          case UserTypes.Agent:
            return of(NavMenuService.createApplicationModeItems(currentApplicationMode));

          case UserTypes.AgencyGroup:
            return this.createApplicationModeItemsForAgencyGroupUser(currentApplicationMode);

          default:
            return of([]);
        }
      })
    );
  }

  instantiateNavigationItems(): Observable<NavigationItem[]> {
    return this.navObservables.pipe(
      switchMap(([currentUserType, currentApplicationMode]) => {
        switch (currentUserType) {
          case UserTypes.Agent:
            return this.createNavMenuItemsForAgent(currentApplicationMode);

          case UserTypes.AgencyGroup:
            return this.appStateService.applicationLoginContext$.pipe(
              first(),
              switchMap((loginContext) =>
                loginContext === ApplicationLoginContext.Portal
                  ? this.createNavMenuItemsForAgencyAdmin()
                  : this.createNavMenuItemsForAgent(currentApplicationMode)
              )
            );

          case UserTypes.Admin:
            return NavMenuService.createNavMenuItemsForAdmin();

          default:
            return of([NavMenuService.createLogoutItem()]);
        }
      })
    );
  }

  refreshData(): void {
    this.refreshDataSubject.next();
  }

  onRefreshData$(): Observable<void> {
    return this.refreshDataSubject.asObservable();
  }

  private createApplicationModeItemsForAgencyGroupUser(currentApplicationMode: ApplicationMode): Observable<ApplicationModeItem[]> {
    return this.appStateService.applicationLoginContext$.pipe(
      first(),
      map((applicationLoginContext) => {
        return applicationLoginContext === ApplicationLoginContext.AgentApp
          ? NavMenuService.createApplicationModeItems(currentApplicationMode)
          : [];
      })
    );
  }

  private getBadgeDataForAgent$(): Observable<NavigationMenuBadgeDataForAgentResponse> {
    if (AppSettingsService.appSettings.featureFlags.showNewListingsComponent)
      return this.http.get<NavigationMenuBadgeDataForAgentResponse>('vpaApply/navMenuBadgeData/agent/v2');

    return this.http.get<NavigationMenuBadgeDataForAgentResponse>(ApiResources.getNavMenuBadgeDataForAgent());
  }

  private getBadgeDataForAgencyAdmin$(): Observable<NavigationMenuBadgeDataForAgencyGroupResponse> {
    return this.http.get<NavigationMenuBadgeDataForAgencyGroupResponse>(ApiResources.getNavMenuBadgeDataForAgencyGroup());
  }

  private createNavMenuItemsForAgent(currentApplicationMode: ApplicationMode): Observable<NavigationItem[]> {
    const appStateItems$ = combineLatest([
      this.appStateService.isByoPayNowEnabled$,
      this.appStateService.isAgentSmartCommissionEnabled$
    ]).pipe(
      map(([isByoPayNowEnabled, isAgentSmartCommissionEnabled]) => ({
        isByoPayNowEnabled,
        isAgentSmartCommissionEnabled
      }))
    );

    return combineLatest([
      this.getBadgeDataForAgent$(),
      appStateItems$,
      this.canShowSwitchAccount$,
      this.applicationModeService.currentApplicationMode$,
      this.canSwitchToPortal$
    ]).pipe(
      map(([badgeData, appStateItems, canShowSwitchAccount, applicationMode, canSwitchToPortal]) => {
        const menuItems: NavigationItem[] = [
          {
            name: NavigationItemConstants.MyListingName,
            icon: 'fad fa-list-alt',
            link: {
              url: AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                ? RouteResources.listAllCampaigns(currentApplicationMode)
                : RouteResources.listings(currentApplicationMode),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          },
          {
            name: NavigationItemConstants.InProgressName,
            icon: 'fad fa-sync-alt',
            link: {
              url: AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                ? RouteResources.inProgressApplications(currentApplicationMode)
                : RouteResources.incompleteApplications(currentApplicationMode),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge: this.getInProgressBadgeForAgent(badgeData)
          }
        ];

        if (appStateItems.isByoPayNowEnabled && !AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
          menuItems.push({
            name: NavigationItemConstants.AwaitingPaymentName,
            icon: 'fas fa-dollar-sign',
            link: {
              url: RouteResources.payNowDirectAwaitingPayment(),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge: {
              content: badgeData.awaitingPaymentApplicationsCount.toString(),
              style: BadgeStyle.Default
            }
          });
        }

        if (AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
          menuItems.push({
            name: NavigationItemConstants.ActionRequiredName,
            icon: 'fad fa-exclamation-triangle',
            link: {
              url: RouteResources.actionRequiredApplications(currentApplicationMode),
              isInternalLink: false,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge: {
              content: badgeData.actionRequiredCampaignsCount.toString(),
              style: BadgeStyle.Danger
            }
          });
        }

        if (AppSettingsService.appSettings.featureFlags.isSmartCommissionEnabled && appStateItems.isAgentSmartCommissionEnabled) {
          menuItems.push({
            name: 'SmartCommission',
            icon: 'fad fa-wallet',
            link: {
              url: RouteResources.smartCommissionAgentSoldCampaigns(applicationMode),
              isInternalLink: false,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.SmartComm
            }
          });
        }

        if (AppSettingsService.appSettings.showComponentList) {
          menuItems.push({
            name: `Component List`,
            icon: 'far fa-list-alt',
            link: {
              url: RouteResources.componentList(),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          });
        }

        if (canSwitchToPortal) {
          menuItems.push({
            name: 'Switch to Portal',
            icon: 'far fa-repeat',
            isUserDetailNavigation: true,
            link: {
              url: RouteResources.switchToPortal(),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          });
        }

        if (canShowSwitchAccount) {
          menuItems.push(this.createSwitchAccountItem());
        }

        menuItems.push(NavMenuService.createLogoutItem());

        return menuItems;
      })
    );
  }

  private createNavMenuItemsForAgencyAdmin(): Observable<NavigationItem[]> {
    const appStateItems$ = combineLatest([
      this.appStateService.roles$,
      this.appStateService.isByoPayNowEnabled$,
      this.appStateService.hasByoBpayUnreconciledEntries$,
      this.appStateService.agencyGroupType$
    ]).pipe(
      map(([roles, isByoPayNowEnabled, hasByoBpayUnreconciledEntries, agencyGroupType]) => ({
        roles,
        isByoPayNowEnabled,
        hasByoBpayUnreconciledEntries,
        agencyGroupType
      }))
    );

    return combineLatest([
      this.getBadgeDataForAgencyAdmin$(),
      this.applicationModeService.currentApplicationMode$,
      this.canSwitchToAgentApp$,
      this.canShowSwitchAccount$,
      appStateItems$
    ]).pipe(
      map(([badgeData, applicationMode, canSwitchToAgentApp, canShowSwitchAccount, appStateItems]) => {
        const getNavItemsForCampaigns = () => {
          switch (appStateItems.agencyGroupType) {
            case AgencyGroupType.Sales:
              return this.campaignNavItemsForSalesAgency(badgeData);
            case AgencyGroupType.Rentals:
              return this.campaignNavItemsForRentalAgency(badgeData);
            default:
              return this.campaignNavItemsForSalesAgency(badgeData);
          }
        };

        const awaitingVendorPaymentNavItemDetails = (): { Title: string; Index: number } => {
          switch (appStateItems.agencyGroupType) {
            case AgencyGroupType.Sales:
              return { Title: 'Awaiting Vendor Payment', Index: 3 };
            case AgencyGroupType.Rentals:
              return { Title: 'Awaiting Landlord Payment', Index: 1 };
            default:
              return { Title: 'Awaiting Vendor Payment', Index: 3 };
          }
        };

        const menuItems: NavigationItem[] = [
          {
            name: 'Campaigns',
            icon: 'fad fa-home',
            isExpandedByDefault: true,
            subNavItems: [...getNavItemsForCampaigns()]
          }
        ];

        if (appStateItems.isByoPayNowEnabled) {
          const awaitingVendorPaymentDetails = awaitingVendorPaymentNavItemDetails();

          menuItems[0].subNavItems.splice(awaitingVendorPaymentDetails.Index, 0, {
            name: awaitingVendorPaymentDetails.Title,
            link: {
              url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
                ? RouteResources.marketingRouteResources.portalCampaignLists('awaiting-payment')
                : RouteResources.marketingRouteResources.awaitingPaymentApplications(),
              isInternalLink: false,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.VpaPay
            },
            badge: {
              content: badgeData.awaitingPaymentApplicationsCount > 0 ? badgeData.awaitingPaymentApplicationsCount.toString() : '',
              style: BadgeStyle.Danger
            }
          });
        }

        if (
          AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled &&
          appStateItems.agencyGroupType !== AgencyGroupType.Rentals
        ) {
          menuItems[0].subNavItems.push({
            name: 'Complete',
            link: {
              url: RouteResources.marketingRouteResources.portalCampaignLists('complete'),
              isInternalLink: false,
              isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
              campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
                ? CampaignAgentApplications.VpaPay
                : null
            },
            badge: {
              content: badgeData.completed > 0 ? badgeData.completed.toString() : '',
              style: BadgeStyle.Info
            }
          });
        }

        NavMenuService.setupAgencyAdminFinanceManagementItems(
          menuItems,
          appStateItems.roles,
          appStateItems.isByoPayNowEnabled,
          appStateItems.hasByoBpayUnreconciledEntries,
          badgeData,
          applicationMode,
          appStateItems.agencyGroupType
        );

        if (canSwitchToAgentApp) {
          menuItems.push({
            name: 'Switch to Agent-App',
            isUserDetailNavigation: true,
            link: {
              url: RouteResources.switchToAgentApp(),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          });
        }

        if (canShowSwitchAccount) {
          menuItems.push(this.createSwitchAccountItem());
        }

        const otherSubNavItems = [];

        otherSubNavItems.push({
          name: 'Users',
          link: {
            url: RouteResources.getListUsersUrl(),
            isInternalLink: true,
            campaignAgentApplications: CampaignAgentApplications.Legacy
          }
        });

        if (
          AppSettingsService.appSettings.featureFlags.isDownloadAgencyQrCodeEnabled &&
          appStateItems.agencyGroupType !== AgencyGroupType.Rentals
        ) {
          otherSubNavItems.push({
            name: 'Download Payment Resources',
            link: {
              url: RouteResources.marketingRouteResources.downloadAgencyQrCode(),
              isInternalLink: false,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.VpaPay
            }
          });
        }

        menuItems.push({
          name: 'Other',
          icon: 'fad fa-cogs',
          isExpandedByDefault: true,
          subNavItems: otherSubNavItems
        });

        menuItems.push(NavMenuService.createLogoutItem());

        return menuItems;
      })
    );
  }

  campaignNavItemsForRentalAgency(badgeData: NavigationMenuBadgeDataForAgencyGroupResponse) {
    return [
      {
        name: 'In Progress',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('in-progress')
            : RouteResources.casAgencyCasAppInProgress(),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge: {
          content: badgeData.casAppInProgress > 0 ? badgeData.casAppInProgress.toString() : '',
          style: BadgeStyle.Info
        }
      },
      {
        name: 'Action Required',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('action-required')
            : RouteResources.marketingRouteResources.actionRequired(),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: CampaignAgentApplications.VpaPay
        },
        badge: {
          content: badgeData.actionRequired > 0 ? badgeData.actionRequired.toString() : '',
          style: BadgeStyle.Danger
        }
      }
    ];
  }

  campaignNavItemsForSalesAgency(badgeData: NavigationMenuBadgeDataForAgencyGroupResponse) {
    return [
      {
        name: 'All',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('all')
            : RouteResources.casAgencyLiveCampaigns(),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge: {
          content: badgeData.live > 0 ? badgeData.live.toString() : '',
          style: BadgeStyle.Info
        }
      },
      {
        name: 'Unsubmitted',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('not-started')
            : RouteResources.casAgencyUnsubmittedCampaigns(),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge: {
          content: badgeData.unsubmitted > 0 ? badgeData.unsubmitted.toString() : '',
          style: BadgeStyle.Info
        }
      },
      {
        name: 'In Progress',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('in-progress')
            : RouteResources.casAgencyCasAppInProgress(),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge: {
          content: badgeData.casAppInProgress > 0 ? badgeData.casAppInProgress.toString() : '',
          style: BadgeStyle.Info
        }
      },
      {
        name: 'Being Reviewed',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('waiting-for-approval')
            : RouteResources.casAgencyBeingReviewedCampaigns(),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge: {
          content: badgeData.beingReviewed > 0 ? badgeData.beingReviewed.toString() : '',
          style: BadgeStyle.Info
        }
      },
      {
        name: 'Action Required',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('action-required')
            : RouteResources.marketingRouteResources.actionRequired(),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: CampaignAgentApplications.VpaPay
        },
        badge: {
          content: badgeData.actionRequired > 0 ? badgeData.actionRequired.toString() : '',
          style: BadgeStyle.Danger
        }
      },
      {
        name: 'Active',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('active')
            : RouteResources.casAgencyActiveCampaigns(),
          isInternalLink: false,
          isDifferentAngularAppLink: true,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge: {
          content: badgeData.active > 0 ? badgeData.active.toString() : '',
          style: BadgeStyle.Info
        }
      }
    ];
  }

  createBottomNavMenuItemsForAgent(): Observable<NavigationItem[]> {
    const appStateItems$ = combineLatest([
      this.appStateService.isByoPayNowEnabled$,
      this.appStateService.applicationLoginContext$,
      this.appStateService.isAgentSmartCommissionEnabled$
    ]).pipe(
      map(([isByoPayNowEnabled, applicationLoginContext, isAgentSmartCommissionEnabled]) => ({
        isByoPayNowEnabled,
        applicationLoginContext,
        isAgentSmartCommissionEnabled
      }))
    );

    return combineLatest([
      this.getBadgeDataForAgent$(),
      appStateItems$,
      this.applicationModeService.currentApplicationMode$,
      this.canShowSwitchAccount$,
      this.canSwitchToPortal$
    ]).pipe(
      map(([badgeData, appStateItems, currentApplicationMode, canShowSwitchAccount, canSwitchToPortal]) => {
        let menuItems: NavigationItem[] = [];

        if (appStateItems.applicationLoginContext !== ApplicationLoginContext.AgentApp) {
          return menuItems;
        }

        menuItems = [
          {
            name: NavigationItemConstants.MyListingName,
            icon: 'fad fa-list-alt',
            link: {
              url: AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                ? RouteResources.listAllCampaigns(currentApplicationMode)
                : RouteResources.listings(currentApplicationMode),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          },
          {
            name: NavigationItemConstants.InProgressName,
            icon: 'fad fa-sync-alt',
            link: {
              url: AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                ? RouteResources.inProgressApplications(currentApplicationMode)
                : RouteResources.incompleteApplications(currentApplicationMode),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge: this.getInProgressBadgeForAgent(badgeData)
          }
        ];

        if (appStateItems.isByoPayNowEnabled && !AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
          menuItems.push({
            name: NavigationItemConstants.AwaitingPaymentName,
            icon: 'fas fa-clock',
            link: {
              url: RouteResources.payNowDirectAwaitingPayment(),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge: {
              content: badgeData.awaitingPaymentApplicationsCount.toString(),
              style: BadgeStyle.Info
            }
          });
        }

        if (AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
          menuItems.push({
            name: NavigationItemConstants.ActionRequiredName,
            icon: 'fad fa-exclamation-triangle',
            link: {
              url: RouteResources.actionRequiredApplications(currentApplicationMode),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge: {
              content: badgeData.actionRequiredCampaignsCount.toString(),
              style: BadgeStyle.Danger
            }
          });
        }

        if (AppSettingsService.appSettings.featureFlags.isSmartCommissionEnabled && appStateItems.isAgentSmartCommissionEnabled) {
          const moreMenu: NavigationItem[] = [
            {
              name: 'SmartCommission',
              icon: 'fad fa-wallet',
              link: {
                url: RouteResources.smartCommissionAgentSoldCampaigns(currentApplicationMode),
                isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.SmartComm,
                isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.SmartComm,
                campaignAgentApplications: CampaignAgentApplications.SmartComm
              }
            },
            {
              name: NavigationItemConstants.AccountName,
              icon: 'fad fa-user-circle',
              action: NavigationItemAction.ToggleAccountMenu
            }
          ];
          menuItems.push({
            name: 'More',
            icon: 'fad fa-ellipsis-h',
            action: NavigationItemAction.ToggleShowMoreMenu,
            subNavItems: moreMenu
          });
        } else {
          menuItems.push({
            name: NavigationItemConstants.AccountName,
            icon: 'fad fa-user-circle',
            action: NavigationItemAction.ToggleAccountMenu
          });
        }

        if (canSwitchToPortal) {
          menuItems.push({
            name: 'Switch to Portal',
            icon: 'far fa-repeat',
            isUserDetailNavigation: true,
            link: {
              url: RouteResources.switchToPortal(),
              isInternalLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          });
        }

        if (canShowSwitchAccount) {
          menuItems.push(this.createSwitchAccountItem());
        }

        menuItems.push(NavMenuService.createLogoutItem());

        return menuItems;
      })
    );
  }

  private getInProgressBadgeForAgent(badgeData: NavigationMenuBadgeDataForAgentResponse) {
    if (AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
      return {
        content: badgeData.inProgressCampaignsCount.toString(),
        style: BadgeStyle.Info
      };
    }

    if (badgeData.dangerIncompleteApplicationsCount > 0) {
      return {
        content: badgeData.dangerIncompleteApplicationsCount.toString(),
        style: BadgeStyle.Danger
      };
    }

    return {
      content: badgeData.incompleteApplicationsCount?.toString(),
      style: BadgeStyle.Info
    };
  }
}

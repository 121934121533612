<section class="payment-breakdown" *ngIf="contributionsRequest" [ngClass]="{ 'p-l-20': shouldPadLeft }">
  <div class="section-title">Payment Breakdown</div>
  <div class="section-description">Complete breakdown of payments being made</div>

  <vpa-help-section [helpText]="'dueDatesEft'" [offsetSectionSpacing]="true" *ngIf="isSelectedPaymentMethod('EFT')">
  </vpa-help-section>

  <div class="info">
    <div class="container">
      <label>
        Total Due
      </label>
      <span class="section-value">
        {{ totalAuthorisedAmount | currency }}
      </span>
    </div>
    <div class="container" [hidden]="!isUsingCreditCard">
      <label>
        Total Added
      </label>
      <span class="section-value">
        {{ totalAdded | currency }}
      </span>
    </div>
    <div class="container" [hidden]="!isUsingCreditCard">
      <label>
        Outstanding
      </label>
      <span class="section-value" [ngClass]="{
          'text-danger': outstanding != 0,
          'text-success': outstanding == 0
        }">
        {{ outstanding | currency }}
      </span>
    </div>
    <div class="container" *ngIf="!(contributionsRequest.useChargeOnSubmission && isUsingCreditCard)">
      <label>
        Due Date
      </label>
      <span class="section-value">
        {{ contributionsRequest.payNowPaymentDueDate | date: 'dd/MM/yyyy' }}
      </span>
    </div>
    <div class="container" [hidden]="!isUsingCreditCard">
      <label>
        Total Surcharge
      </label>
      <span class="section-value">
        {{ totalSurcharge | currency }}
      </span>
    </div>
  </div>
  <div class="alert alert-info border-blue m-b-0 m-t-10" [hidden]="!isUsingCreditCard" role="alert"
    style="border: 2px solid #31708f; border-radius: 4px !important; display: flow-root; font-size: 18px;">
    <i class="fa fa-lg fa-info-circle" style="float: left; margin-right: 10px; margin-top: 4px;"></i>
    <p>To avoid surcharges change your payment method to BPAY</p>
  </div>
</section>
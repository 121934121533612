<div class="ca-modal fade-in unelected-vendor-error-modal" *ngIf="unelectedVendorResponse">
  <div class="ca-dialog">
    <div class="ca-content auto-height warning-modal float-down">
      <div class="ca-header">
        <h5 class="modal-title w-100">Another vendor was nominated to complete the application</h5>
      </div>
      <div class="ca-body">
        <div class="inline-alert m-b-35">
          <i class="far fa-exclamation-circle"></i>
          <div class="message">
            Your agent
            <strong>{{ unelectedVendorResponse.agent }}</strong>
            at
            <strong>{{ unelectedVendorResponse.agency }}</strong>
            has nominated
            <strong>{{ unelectedVendorResponse.electedVendor }}</strong>
            to complete the initial application process. Once this has been done, all remaining vendors will receive a text message to sign
            the application before it is submitted for funding approval.
          </div>
        </div>
        <hr class="separator" />

        <div class="detailed-information">
          <strong>Detailed Information</strong>
          <br />
          <div>
            2 applicants can not complete an application at the same time. Some critical data may be uploaded at different moments in the
            process and cause severe issues.
          </div>
        </div>
      </div>
      <div class="ca-footer">
        <button type="button" class="btn btn-primary" mdbWavesEffect (click)="close()">
          Okay
        </button>
      </div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVpaSubStatus } from '@ca/shared/models';

@Pipe({
  name: 'globalVpaSubStatus'
})
export class GlobalVpaSubStatusPipe implements PipeTransform {
  transform(globalVpaSubStatus: string): string {
    return getGlobalVpaSubStatusDescription(globalVpaSubStatus);
  }
}

export function getGlobalVpaSubStatusDescription(globalVpaSubStatus: string): string {
  switch (globalVpaSubStatus) {
    case GlobalVpaSubStatus[GlobalVpaSubStatus.WaitingForAgent]:
      return 'Waiting For Agents';

    case GlobalVpaSubStatus[GlobalVpaSubStatus.WaitingForVendor]:
      return 'Waiting For Vendors';

    case GlobalVpaSubStatus[GlobalVpaSubStatus.Appraisal]:
      return 'Appraisal';

    case GlobalVpaSubStatus[GlobalVpaSubStatus.Draft]:
      return 'Draft';

    default:
      return '';
  }
}

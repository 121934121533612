import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CreditCardSchemeSurchargeOption } from 'apps/legacy/src/app/_shared/models/credit-card-scheme-surcharge-option';
import { PayNowDirectType } from 'apps/legacy/src/app/_shared/models/pay-now-direct-type';
import { IPaymentOption } from 'apps/legacy/src/app/_shared/services/contributions-facade.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'vpa-contribution-payment-method',
  templateUrl: './contribution-payment-method.component.html',
  styleUrls: ['./contribution-payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContributionPaymentMethodComponent implements OnInit {
  @Input() state$: Observable<IContributionPaymentMethodComponentState>;
  @Input() form$: Observable<FormGroup>;
  @Input() isByoPayNow: boolean;

  public PayNowDirectType = PayNowDirectType;

  constructor() {}

  ngOnInit() {}
}

export interface IContributionPaymentMethodComponentState {
  isDisplayedInSections: boolean;
  agencyProcessedPaymentInformationMessage: string;
  agencyProcessedPaymentInformationTitle: string;
  showCheque: boolean;
  paymentOptions: IPaymentOption[];
  fullPaymentMethod: string;
  isByoPayNow: boolean;
  isCustomSurchargeDisclaimerIsEnabled: boolean;
  customSurchargeText: string;
  brandLogoUrl: string;
  isPaymentTypeLocked: boolean;
  payNowDirectCreditCardType: PayNowDirectType;
  creditCardSchemeSurchargeOptions: CreditCardSchemeSurchargeOption;
  isPaymentMethodCreditCard: boolean;
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { ApplicationModeService, ApplicationRouteService, NavMenuService } from 'apps/legacy/src/app/_shared/services';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, first, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationLoginContext, ApplicationMode, ApplicationModeItem, NavigationItem, NavigationLink, UserTypes } from '../../models';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'vpa-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavigationComponent extends BaseComponent implements OnInit {
  menuOpen = false;
  agencyUserName$: Observable<string> = this.appStateService.getAgencyUserName$();
  agencyGroupName$ = this.appStateService.getAgencyGroupName$();
  isListinglessApplicationsEnabled$ = this.appStateService.isListinglessApplicationsEnabled$;
  isCurrentUserAVendor$ = this.appStateService.isCurrentUserAnyTypeOfVendor$;
  applicationLoginContext$ = this.appStateService.applicationLoginContext$;

  appModes$: Observable<ApplicationModeItem[]>;
  navItems$: Observable<NavigationItem[]>;

  isInPortalMode$: Observable<boolean> = this.appStateService.applicationLoginContext$.pipe(
    first(),
    map((loginContext) => loginContext === ApplicationLoginContext.Portal),
    startWith(false)
  );

  private stopUpdatingBadgeData = new Subject<boolean>();

  constructor(
    private appStateService: AppStateService,
    private applicationRouteService: ApplicationRouteService,
    private applicationModeService: ApplicationModeService,
    private navMenuService: NavMenuService
  ) {
    super();
  }

  ngOnInit(): void {
    this.appModes$ = this.navMenuService.instantiateApplicationModeItems();
    this.refreshNavItems();

    this.navMenuService
      .onRefreshData$()
      .pipe(this.unsubOnDestroy())
      .subscribe((_) => this.refreshNavItems());
  }

  setApplicationMode(newMode: ApplicationMode) {
    this.applicationModeService.currentApplicationMode$.pipe(first()).subscribe((currentAppMode) => {
      if (newMode !== currentAppMode) {
        this.applicationRouteService.switchApplicationMode(newMode);
      }
    });
  }

  navigateToItem(link: NavigationLink) {
    this.menuOpen = false;
    if (!link) {
      return;
    } else if (link.isInternalLink) {
      this.applicationRouteService.navigate(link.url, link.extras);
    } else if (link.isDifferentAngularAppLink) {
      window.location.href = `/${link.url}`;
    } else {
      window.location.href = link.url;
    }
  }

  refreshNavItems(): void {
    this.navItems$ = this.appStateService.getCurrentUserType$().pipe(
      first(),
      switchMap((currentUserType) => {
        switch (currentUserType) {
          case UserTypes.Agent:
            // Auto update the nav items with badge data every 60 seconds
            return timer(0, 60000).pipe(
              switchMap((_) => this.navMenuService.instantiateNavigationItems()),
              catchError((err, _) => {
                if (err instanceof HttpErrorResponse && err.status === 0) {
                  console.error(err);
                  this.stopUpdatingBadgeData.next(false);
                  this.stopUpdatingBadgeData.complete();
                  return of([]);
                }

                throwError(err);
              }),
              takeUntil(this.stopUpdatingBadgeData)
            );

          // For users that we don't need to update the data for
          default:
            return this.navMenuService.instantiateNavigationItems();
        }
      })
    );
  }
}

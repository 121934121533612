export class ApplicantTypes {
  public static REPRESENTATIVE = 'representative';
  public static DIRECTOR = 'director/trustee';
  public static VENDOR = 'vendor';
}

export function getApplicantType(isCompanyOnTitle: boolean, isFirstLetterUpperCase = true, isRepresentative = false) {
  if (isRepresentative) {
    return processWord(ApplicantTypes.REPRESENTATIVE, isFirstLetterUpperCase);
  }

  if (isCompanyOnTitle) {
    return processWord(ApplicantTypes.DIRECTOR, isFirstLetterUpperCase);
  }

  return processWord(ApplicantTypes.VENDOR, isFirstLetterUpperCase);
}

export function processWord(word: string, isFirstLetterUpperCase: boolean) {
  if (!isFirstLetterUpperCase) {
    return word;
  }

  return toTitleCase(word);
}

export function toTitleCase(str: string) {
  return str.toLowerCase().replace(/(?:^|[\s-/])\w/g, match => match.toUpperCase());
}

import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ValidationMessageResources } from '../models/validation-message-resources';

export class DateValidator {
  static validDate(control: AbstractControl): ValidationErrors {
    return control.value.type === 'invalidDate'
      ? {
          invalidDate: {
            message: ValidationMessageResources.invalidDate
          }
        }
      : null;
  }
}

export function dateRangeRequiredValidator(group: FormGroup) {
  return Object.values(group.value).some((v) => !v) ? { required: true } : null;
}

<div class="container" [ngClass]="customContainerClass">
  <div class="right">
    <button *ngIf="buttonText" (click)="onButtonClicked()" class="btn btn-primary">
      {{ buttonText }}
    </button>
  </div>
  <div class="left">
    <i class="fas {{ iconClass }}"></i>
  </div>
  <div class="main">
    <ng-content></ng-content>
  </div>
</div>

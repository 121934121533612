import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalException } from './global-exception';

@Injectable({
  providedIn: 'root'
})
export class GlobalExceptionService {
  private currentException: GlobalException;

  constructor() {}

  setCurrentExceptionFromHttpError(error: HttpErrorResponse): void {
    const exception: GlobalException = error.error;
    this.currentException = exception;
  }

  setCurrentException(error: GlobalException): void {
    this.currentException = error;
  }

  setCurrentExceptionFromMessage(errorMessage: string): void {
    const globalException: GlobalException = {
      status: null,
      detail: errorMessage,
      errors: null,
      title: null,
      traceId: null,
      type: null
    };

    this.currentException = globalException;
  }

  clearCurrentException = (): void => (this.currentException = null);

  getCurrentException = (): GlobalException => this.currentException;
}

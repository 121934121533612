export enum GlobalVpaStatus {
  NotStarted = 'NotStarted',
  ApplicationStarted = 'ApplicationStarted',
  AwaitingPaymentDetails = 'AwaitingPaymentDetails',
  AwaitingSignatures = 'AwaitingSignatures',
  Cancelled = 'Cancelled',
  Unsubmitted = 'Unsubmitted',
  WaitingForApproval = 'WaitingForApproval',
  ActionRequired = 'ActionRequired',
  AmendingApplication = 'AmendingApplication',
  Approved = 'Approved',
  FullyPaid = 'FullyPaid',
  CancellationRequested = 'CancellationRequested',
  Unknown = 'Unknown',
  SignedAwaitingAuthority = 'SignedAwaitingAuthority',
  AwaitingPdfApplication = 'AwaitingPdfApplication',
  Complete = 'Complete',
  Overpaid = 'Overpaid',
  PartiallyPaid = 'PartiallyPaid',
  NotPaid = 'NotPaid'
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { SessionStateComponent } from './session-state/session-state.component';
import { AuthGuard } from './_guards/auth.guard';
import { EnvironmentGuard } from './_guards/environment.guard';
import { AgentAppRedirectComponent } from './_shared/components/agent-app-redirect/agent-app-redirect-component';
import { HomeRoutingComponent } from './_shared/components/home-routing/home-routing-component';
import { LogoutComponent } from './_shared/components/log-out/logout-component';
import { PortalRedirectComponent } from './_shared/components/portal-redirect/portal-redirect-component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', component: HomeRoutingComponent },
      {
        path: 'additionalvpa',
        loadChildren: () => import('./additionalvpa/additional-vpa.module').then((mod) => mod.AdditionalVpaModule)
      },
      {
        path: 'applications',
        loadChildren: () => import('./applications/applications.module').then((mod) => mod.ApplicationsModule)
      },
      {
        path: 'listings',
        loadChildren: () => import('./listings/listings.module').then((mod) => mod.ListingsModule)
      },
      {
        path: 'training/applications',
        loadChildren: () => import('./applications/applications.module').then((mod) => mod.ApplicationsModule)
      },
      {
        path: 'training/listings',
        loadChildren: () => import('./listings/listings.module').then((mod) => mod.ListingsModule)
      },
      {
        path: 'training/vpa-pay-management',
        loadChildren: () => import('./vpa-pay-management/vpa-pay-management.module').then((mod) => mod.VpaPayManagementModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then((mod) => mod.UsersModule)
      },
      {
        path: 'components',
        loadChildren: () => import('./system/system.module').then((mod) => mod.SystemModule)
      },
      {
        path: 'byo-pay-now',
        loadChildren: () => import('./byoPayNow/byo-pay-now.module').then((mod) => mod.ByoPayNowModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./finance/finance.module').then((mod) => mod.FinanceModule)
      },
      {
        path: 'smart-commission',
        loadChildren: () => import('./smart-commission/smart-commission.module').then((mod) => mod.SmartCommissionModule)
      },
      {
        path: 'vpa-pay-management',
        loadChildren: () => import('./vpa-pay-management/vpa-pay-management.module').then((mod) => mod.VpaPayManagementModule)
      },
      {
        path: 'component-list',
        canActivate: [EnvironmentGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./componentList/componentList.module').then((mod) => mod.ComponentListModule)
          },
          {
            path: 'listings-demo',
            loadChildren: () => import('./listings-demo/listings-demo.module').then((mod) => mod.ListingsDemoModule)
          },
          {
            path: 'login-demo',
            loadChildren: () => import('./login-demo/login-demo.module').then((mod) => mod.LoginDemoModule)
          },
          {
            path: 'pay-later-agent-demo',
            loadChildren: () => import('./pay-later-agent/pay-later-agent-demo.module').then((mod) => mod.PayLaterAgentDemoModule)
          },
          {
            path: 'pnd-demo',
            loadChildren: () => import('./pnd-demo/pnd-demo.module').then((mod) => mod.PndDemoModule)
          },
          {
            path: 'finance-shared-demo',
            loadChildren: () => import('./finance-shared-demo/finance-shared-demo.module').then((mod) => mod.FinanceSharedDemoModule)
          },
          {
            path: 'marketing-shared-module-demo',
            loadChildren: () =>
              import('./marketing-shared-module-demo/marketing-shared-module-demo.module').then(
                (mod) => mod.MarketingSharedModuleDemoModule
              )
          },
          {
            path: 'returning-vendor-demo',
            loadChildren: () => import('./returning-vendor-demo/returning-vendor-demo.module').then((mod) => mod.ReturningVendorDemoModule)
          },
          {
            path: 'smart-commission-demo',
            loadChildren: () => import('./smart-commission-demo/smart-commission-demo.module').then((mod) => mod.SmartCommissionDemoModule)
          },
          {
            path: 'vpa-pay-management-demo',
            loadChildren: () =>
              import('./vpa-pay-management-demo/vpa-pay-management-demo.module').then((mod) => mod.VpaPayManagementDemoModule)
          },
          {
            path: 'uplifted-pnd-create-demo',
            loadChildren: () =>
              import('./uplifted-pnd-create-demo/uplifted-pnd-create-demo.module').then((mod) => mod.UpliftedPndCreateDemoModule)
          }
        ]
      }
    ]
  },
  {
    path: 'pnd',
    loadChildren: () => import('./pnd/pnd.module').then((mod) => mod.PndModule)
  },
  {
    path: 'training/pnd',
    loadChildren: () => import('./pnd/pnd.module').then((mod) => mod.PndModule)
  },
  {
    path: 'pay-later',
    loadChildren: () => import('./pay-later/pay-later.module').then((mod) => mod.PayLaterModule)
  },
  {
    path: 'training/pay-later',
    loadChildren: () => import('./pay-later/pay-later.module').then((mod) => mod.PayLaterModule)
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./login/login.module').then((mod) => mod.LoginModule)
  // },
  {
    path: 'login',
    loadChildren: () => import('@ca/login-components').then((mod) => mod.LoginComponentsModule)
  },
  { path: 'logout', pathMatch: 'full', component: LogoutComponent },
  { path: 'agent-app', pathMatch: 'full', component: AgentAppRedirectComponent },
  { path: 'portal', pathMatch: 'full', component: PortalRedirectComponent },
  {
    path: 'login',
    loadChildren: () => import('./system/system.module').then((mod) => mod.SystemModule)
  },
  {
    path: 'session-state',
    pathMatch: 'full',
    component: SessionStateComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

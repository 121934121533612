import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneNumberType } from '../models/phone-number-type-enum';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode: string, isRequired = true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);

      validNumber =
        phoneNumberUtil.isValidNumberForRegion(phoneNumber, regionCode) &&
        phoneNumberUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE;
    } catch (e) {
      validNumber = false;
    }

    if (!isRequired && !control.value?.toString().trim()) {
      return null;
    }

    return validNumber
      ? null
      : {
          invalidMobileNumber: {
            message: 'Must be a valid Australian mobile number'
          }
        };
  };
}

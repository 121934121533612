import { Component, OnInit } from '@angular/core';
import { SwitchableLogin } from '@ca/shared/app-state';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { SystemConstants } from 'apps/legacy/src/app/_shared/models/system-constants';
import { ApplicationLoginContext, RouteResources } from '../../models';
import { LoginDestination } from '../../models/login-destination';
import { ApplicationRouteService } from '../../services';
import { SwitchAccountService } from '../../services/switch-account.service';

@Component({
  selector: 'vpa-switch-account',
  templateUrl: './switch-account.component.html',
  styleUrls: ['./switch-account.component.scss']
})
export class SwitchAccountComponent implements OnInit {
  agencyGroupName$ = this.appStateService.getAgencyGroupName$();
  agencyUserName$ = this.appStateService.getAgencyUserName$();
  applicationLoginContext: ApplicationLoginContext;
  assumedUserId: number;
  switchableLogins: SwitchableLogin[];
  otherAgencyGroupUsers: SwitchableLogin[];
  portalUser: SwitchableLogin;
  appUser: SwitchableLogin;
  supportPhoneNumber: string;
  supportEmailAddress: string;
  canSwitchToCasAdmin: boolean;
  canSwitchToPortal: boolean;
  canSwitchToApp: boolean;

  canSwitchToOtherAgencyGroupUsers: boolean;

  constructor(
    private appStateService: AppStateService,
    private switchAccountService: SwitchAccountService,
    private applicationRouteService: ApplicationRouteService
  ) {}

  ngOnInit() {
    this.appStateService.old_assumedUserId$.subscribe((res) => (this.assumedUserId = res));
    this.appStateService.applicationLoginContext$.subscribe((res) => (this.applicationLoginContext = res));
    this.appStateService.getSwitchableLogins$().subscribe((res) => {
      this.switchableLogins = res;
      this.configureSwitchableAccounts();
    });
  }

  configureSwitchableAccounts() {
    this.supportPhoneNumber = SystemConstants.supportPhoneNumber;
    this.supportEmailAddress = SystemConstants.supportEmailAddress;

    this.canSwitchToCasAdmin = this.configureCanSwitchToCasAdmin();
    this.canSwitchToPortal = this.configureCanSwitchToPortal();
    this.canSwitchToApp = this.configureCanSwitchToApp();

    this.canSwitchToOtherAgencyGroupUsers = this.configureCanSwitchToOtherAgencyGroupUsers();
  }

  getDestination(destination: number) {
    return LoginDestination[destination];
  }

  switchToCasAdmin() {
    window.location.href = RouteResources.loginWithGoogleAccount();
  }

  switchToPortal(user: SwitchableLogin) {
    this.switchAccountService.getSwitchUserToken(user.UserId, user.Destination).subscribe((res) => {
      window.location.href = RouteResources.casPortalSwitchUser(res.token);
    });
  }

  switchToApp(user: SwitchableLogin) {
    this.switchAccountService.getSwitchUserToken(user.UserId, user.Destination).subscribe((res) => {
      this.switchAccountService.switchToApp(res.token).subscribe((tokenResponse) => {
        this.applicationRouteService.navigateToCasLoginToken(tokenResponse.token);
      });
    });
  }

  switchToOtherAgencyGroupUser(user: SwitchableLogin) {
    if (user.Destination === LoginDestination.Portal) {
      this.switchToPortal(user);
      return;
    }

    this.switchToApp(user);
  }

  configureCanSwitchToCasAdmin(): boolean {
    return this.switchableLogins && this.switchableLogins.some((user) => user.Destination === LoginDestination.Admin);
  }

  configureCanSwitchToPortal(): boolean {
    if (this.applicationLoginContext === ApplicationLoginContext.Portal) return false;

    const portalUsers =
      this.switchableLogins &&
      this.switchableLogins.filter((user) => user.UserId === this.assumedUserId && user.Destination === LoginDestination.Portal);

    if (!portalUsers) {
      return false;
    }

    if (portalUsers.length > 0) {
      this.portalUser = portalUsers[0];
    }
    return portalUsers.length > 0;
  }

  configureCanSwitchToApp(): boolean {
    if (this.applicationLoginContext === ApplicationLoginContext.AgentApp) return false;

    const appUsers =
      this.switchableLogins &&
      this.switchableLogins.filter((user) => user.UserId === this.assumedUserId && user.Destination === LoginDestination.App);

    if (!appUsers) {
      return false;
    }

    if (appUsers.length > 0) {
      this.appUser = appUsers[0];
    }
    return appUsers.length > 0;
  }

  configureCanSwitchToOtherAgencyGroupUsers(): boolean {
    this.otherAgencyGroupUsers =
      this.switchableLogins &&
      this.switchableLogins.filter((user) => user.UserId !== this.assumedUserId && user.Destination !== LoginDestination.Admin);

    return this.otherAgencyGroupUsers && this.otherAgencyGroupUsers.length > 0;
  }
}

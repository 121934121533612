import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AppSettingsService } from '@ca/shared/app-state';
import { basePathForApplication, CampaignAgentApplications, UserTypes } from '@ca/shared/models';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { CreditCardScheme } from 'apps/legacy/src/app/pnd/models';
import { Guid } from 'guid-typescript';
import { of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { UserEventsService } from '../../userEvents/services/userEvents.service';
import { ApplicationLoginContext, ApplicationMode, PaymentSettlementOption, VpaApplicationStatus } from '../models';
import { RouteResources } from '../models/route-resources';
import { ApplicationModeService } from './application-mode.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationRouteService {
  private applicationMode: ApplicationMode;
  private forgetCurrentUrlOnNextNavigation: boolean;

  constructor(
    private router: Router,
    private applicationModeService: ApplicationModeService,
    private appState: AppStateService,
    private userEventsService: UserEventsService,
    @Inject('application') private campaignAgentApplication: CampaignAgentApplications
  ) {
    this.applicationModeService.currentApplicationModeSubject.subscribe((appMode) => (this.applicationMode = appMode));
  }

  switchApplicationMode(mode: ApplicationMode) {
    this.navigate(
      AppSettingsService.appSettings.featureFlags.showNewListingsComponent
        ? RouteResources.listAllCampaigns(mode)
        : RouteResources.listings(mode)
    );
  }

  home() {
    const routeAgencyGroupUserToHome$ = () =>
      this.appState.applicationLoginContext$.pipe(
        first(),
        map((context) => {
          switch (context) {
            case ApplicationLoginContext.Portal:
              return () =>
                this.navigateWithApplicationRoute(
                  CampaignAgentApplications.VpaPay,
                  AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
                    ? RouteResources.marketingRouteResources.portalCampaignLists('action-required')
                    : RouteResources.marketingRouteResources.actionRequired()
                );

            case ApplicationLoginContext.AgentApp:
              return () =>
                this.navigate(
                  AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                    ? RouteResources.listAllCampaigns(this.applicationMode)
                    : RouteResources.listings(this.applicationMode)
                );
          }

          return;
        })
      );

    this.appState
      .old_getCurrentUserType$()
      .pipe(
        first(),
        switchMap((userType) => {
          switch (userType) {
            case UserTypes.AgencyGroup:
              return routeAgencyGroupUserToHome$();

            // It looks like at the moment only AgencyGroup users use this method, but just incase
            // default to the listings page to keep backwards compatability
            default:
              this.userEventsService.addUserEvent('Legacy.RouteToFallbackHomeDestination');
              return of(() =>
                this.navigate(
                  AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                    ? RouteResources.listAllCampaigns(this.applicationMode)
                    : RouteResources.listings(this.applicationMode)
                )
              );
          }
        })
      )
      .subscribe((routingFunc) => routingFunc());
  }

  login(extras?: NavigationExtras) {
    this.navigate(RouteResources.login(), extras);
  }

  logout() {
    this.navigate(RouteResources.logout());
  }

  vendorLogout() {
    this.navigate(RouteResources.vendorLogout());
  }

  casHome() {
    window.location.href = AppSettingsService.appSettings.casUrl + 'admin/campaigns/WaitingForApproval';
  }

  terminateImpersonation() {
    window.location.href = AppSettingsService.appSettings.casUrl + 'admin/users/impersonation/terminate';
  }

  applicationSummary(vpaApplicationId: number) {
    this.navigate(RouteResources.applicationSummary(vpaApplicationId, this.applicationMode));
  }

  applicationSummaryFromSigned(mode: ApplicationMode, vpaApplicationId: number) {
    this.navigate(RouteResources.applicationSummary(vpaApplicationId, mode));
  }

  createStepDemo() {
    this.navigate('component-list/uplifted-pnd-create-demo/demo');
  }

  applicationCpnSummary(cpn: string) {
    this.navigate(RouteResources.applicationCpnSummary(cpn, this.applicationMode));
  }

  applicationCancelSuccess() {
    this.navigate(RouteResources.applicationCancelSuccess());
  }

  createPdfApplication(cpn: string) {
    this.navigate(RouteResources.createPdfApplication(cpn, this.applicationMode));
  }

  createAdditionalVpaApplyPdf(cpn: string) {
    this.navigate(RouteResources.createAdditionalVpaApplyPdf(cpn, this.applicationMode));
  }

  reviewPdfApplication(vpaApplicationId: number) {
    this.navigate(RouteResources.reviewPdfApplication(vpaApplicationId, this.applicationMode));
  }

  applyStep(vpaApplicationId: number) {
    this.navigate(RouteResources.applyApplication(vpaApplicationId, this.applicationMode));
  }

  selectVendor(vpaApplicationId: number) {
    window.location.href = '/' + RouteResources.selectVendor(vpaApplicationId, this.applicationMode);
  }

  confirmVendorDetails(vpaApplicationId: number, vpaApplicationVendorId: number) {
    window.location.href = `/${RouteResources.payLaterRouteResources.confirmVendorDetails(
      vpaApplicationId,
      this.applicationMode
    )}?vpaApplicationVendorId=${vpaApplicationVendorId}`;
  }

  classicApplyStep(vpaApplicationId: number) {
    this.navigate(RouteResources.classicApplyApplication(vpaApplicationId, this.applicationMode));
  }

  classicAmendStep(vpaApplicationId: number, campaignId?: number, vpaReceivableId?: number) {
    this.navigate(RouteResources.classicAmendApplication(vpaApplicationId, this.applicationMode), {
      queryParams: { campaignId, vpaReceivableId }
    });
  }

  amendApplication(vpaApplicationId: number, campaignId?: number, vpaReceivableId?: number) {
    this.navigate(RouteResources.amendApplication(vpaApplicationId, this.applicationMode), {
      queryParams: { campaignId, vpaReceivableId }
    });
  }

  applyStepFromSigned(mode: ApplicationMode, vpaApplicationId: number, paymentOption?: PaymentSettlementOption) {
    this.router.navigate([RouteResources.applyApplication(vpaApplicationId, mode)], { queryParams: { 'payment-option': paymentOption } });
  }

  createAdditionalVpa(cpn: string) {
    this.navigate(RouteResources.createAdditionalVpa(cpn, this.applicationMode));
  }

  createAdditionalVpaInVpaApply(cpn: string) {
    this.navigate(RouteResources.createAdditionalVpaInVpaApply(cpn, this.applicationMode));
  }

  createPDFAdditionalVpaInVpaApply(cpn: string) {
    this.navigate(RouteResources.createPDFAdditionalVpaInVpaApply(cpn, this.applicationMode));
  }

  payNowStep(vpaApplicationId: number) {
    this.navigate(RouteResources.payNow(vpaApplicationId, this.applicationMode));
  }

  additionalVpaApplyStep(additionalVpaApplicationId: number) {
    this.navigate(RouteResources.additionalVpaApplyStep(additionalVpaApplicationId, this.applicationMode));
  }

  additionalVpaContributionStep(additionalVpaApplicationId: number) {
    this.navigate(RouteResources.additionalVpaContributionStep(additionalVpaApplicationId, this.applicationMode));
  }

  additionalVpaSigningStep(additionalVpaApplicationId: number) {
    this.navigate(RouteResources.additionalVpaSigningStep(additionalVpaApplicationId, this.applicationMode));
  }

  bankStatements(vpaApplicationId: number) {
    this.navigate(RouteResources.bankStatements(vpaApplicationId, this.applicationMode));
  }

  signStepOrBankStatements(vpaApplicationId: number, useLegacyAgreement: boolean): void {
    this.appState.isIllionBankStatementsEnabled$
      .pipe(
        first(),
        map((isBankStatementsEnabled) =>
          isBankStatementsEnabled
            ? this.navigate(RouteResources.bankStatements(vpaApplicationId, this.applicationMode))
            : this.navigate(RouteResources.signApplication(vpaApplicationId, this.applicationMode, useLegacyAgreement))
        )
      )
      .subscribe();
  }

  signStep(vpaApplicationId: number, useLegacyAgreement: boolean) {
    this.navigate(RouteResources.signApplication(vpaApplicationId, this.applicationMode, useLegacyAgreement));
  }

  goToThankYouPage(cpn: string) {
    this.navigate(RouteResources.vendorThankYouPage(cpn, this.applicationMode));
  }

  goToSummaryIfSigned(vpaApplicationId: number, vpaApplicationStatus: string) {
    if (vpaApplicationStatus === VpaApplicationStatus[VpaApplicationStatus.SignedAndSubmitted]) {
      this.navigate(RouteResources.applicationSummary(vpaApplicationId, this.applicationMode));
    }
  }

  passwordlessSms() {
    this.navigate(RouteResources.passwordlessSms(), {
      queryParamsHandling: 'preserve'
    });
  }

  navigateToUserList() {
    this.navigate(RouteResources.getListUsersUrl());
  }

  navigateToCreateAgencyGroupUser() {
    this.navigate(RouteResources.getCreateAgencyGroupUserUrl());
  }

  navigateToCasLoginToken(token: string) {
    this.navigate(RouteResources.loginWithCasToken(), { queryParams: { token } });
  }

  navigateToEditAgencyGroupUser(userId: number) {
    this.navigate(RouteResources.getEditAgencyGroupUserUrl(userId));
  }

  navigateToCreateAgencyGroupUserAsAdmin(networkId: number, agencyGroupId: number) {
    this.navigate(RouteResources.getCreateAgencyGroupUserAsAdminUrl(networkId, agencyGroupId));
  }

  navigateToEditAgencyGroupUserAsAdmin(networkId: number, agencyGroupId: number, userId: number) {
    this.navigate(RouteResources.getEditAgencyGroupUserAsAdminUrl(networkId, agencyGroupId, userId));
  }

  navigateToAdminAgencyGroupUserList(networkId: number, agencyGroupId: number) {
    this.navigate(RouteResources.adminAgencyGroupUsersList(networkId, agencyGroupId));
  }

  navigateToSuperUserAgencySettings() {
    this.navigate(RouteResources.casappAgencySettings());
  }

  navigateToVendorThankYouPage(cpn: string) {
    this.navigate(RouteResources.vendorThankYouPage(cpn, this.applicationMode));
  }

  navigateToUploadBrfFilePage() {
    this.navigate(RouteResources.uploadBrfFilePage());
  }

  navigateToManagePayments(extras?: NavigationExtras) {
    this.navigate(RouteResources.managePayments(), extras);
  }

  navigateToManageApplicationPayments(applicationAggregateId: Guid): void {
    this.navigate(RouteResources.manageApplicationPayments(applicationAggregateId));
  }

  reconciliation(extras?: NavigationExtras) {
    this.navigate(RouteResources.reconciliation(), extras);
  }

  payLaterAbout(applicationId: number) {
    if (AppSettingsService.appSettings.featureFlags.useCADPayLaterComponents) {
      this.navigateWithApplicationRoute(
        CampaignAgentApplications.NextGenPayments,
        RouteResources.nextGenPaymentsPayLaterRouteResources.aboutPayLater(applicationId, this.applicationMode)
      );
    } else {
      this.navigate(RouteResources.payLaterRouteResources.aboutPayLater(applicationId, this.applicationMode));
    }
  }

  pndPayNowPaymentType(applicationId: number) {
    this.navigate(RouteResources.payNowDirectRouteResources.pndPayNowPaymentType(applicationId, this.applicationMode));
  }

  pndBPayPayment(applicationId: number) {
    this.navigate(RouteResources.payNowDirectRouteResources.pndBPayPayment(applicationId, this.applicationMode));
  }

  pndCreditCardPayment = (applicationId: number, scheme: CreditCardScheme) =>
    this.navigate(RouteResources.payNowDirectRouteResources.pndCreditCardPayment(applicationId, scheme, this.applicationMode));

  pndPaymentSuccess = (applicationId: number) =>
    this.navigate(RouteResources.payNowDirectRouteResources.pndPaymentSuccess(applicationId, this.applicationMode));

  navigate(url: string, extras?: NavigationExtras) {
    if (this.forgetCurrentUrlOnNextNavigation) {
      extras = extras ? extras : ({} as NavigationExtras);
      extras.replaceUrl = true;
      this.forgetCurrentUrlOnNextNavigation = false;
    }

    this.router.navigate([url], extras);
  }

  navigateWithApplicationRoute(app: CampaignAgentApplications, url: string, extras?: NavigationExtras) {
    if (app === this.campaignAgentApplication) {
      this.router.navigate([url], extras);
    } else {
      // Make sure the url does not have leading '/'
      let path = `${basePathForApplication(app)}/${url}`;
      if (app !== CampaignAgentApplications.Legacy) {
        // Add preceding '/' to remove the base href for the current application
        path = `/${path}`;
      }
      if (!!extras?.queryParams) {
        path = Object.keys(extras.queryParams).reduce(
          (prev, curr, idx) => `${prev}${idx === 0 ? '?' : '&'}${curr}=${extras.queryParams[curr]}`,
          path
        );
      }
      window.location.href = path;
    }
  }

  navigateToUrl(url: string): Promise<boolean> {
    return this.router.navigate([url]);
  }

  ForgetCurrentUrlOnNextNavigation() {
    this.forgetCurrentUrlOnNextNavigation = true;
  }

  createManualApplication() {
    this.createStep();
  }

  createStep(cpn: string = null, extras?: NavigationExtras) {
    this.navigate(RouteResources.createApplication(cpn, this.applicationMode), extras);
  }

  createAgentContributions(cpn: string = null, extras?: NavigationExtras) {
    this.navigate(RouteResources.vpaPayManagementRouteResources.createAgentContributions(cpn, this.applicationMode), extras);
  }

  createStepFromSigned(mode: ApplicationMode, cpn: string) {
    this.navigate(RouteResources.createApplication(cpn, mode));
  }

  createPndApplication(cpn: string = null, extras?: NavigationExtras) {
    this.navigate(this.getCreatePndApplicationRoute(cpn), extras);
  }

  getCreatePndApplicationRoute(cpn: string = null): string {
    return RouteResources.vpaPayManagementRouteResources.createApplication(cpn, this.applicationMode);
  }

  createAdditionalPndApplication(cpn: string, extras?: NavigationExtras) {
    this.navigate(RouteResources.vpaPayManagementRouteResources.createAdditionalApplication(cpn, this.applicationMode), extras);
  }
}

import { VendorSigningState } from '@ca/shared/models';

export interface UnsignedFieldMetadata {
  vendorId: number;
  fieldName: string;
}

export const mapSigningStatesToEventMetadata = (signingStates: VendorSigningState[]): UnsignedFieldMetadata[] =>
  signingStates.reduce(
    (prev, curr) => [
      ...prev,
      ...curr.changes.map((fc) => ({
        vendorId: curr.vpaApplicationVendorId,
        fieldName: fc.name
      }))
    ],
    []
  );

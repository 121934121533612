import { FundingCosts, PaymentSettlementOption } from 'apps/legacy/src/app/_shared/models';

export class ApplicationFundingDetails {
  requestedAmount: number;
  startDate: Date;
  paymentSettlementOption?: PaymentSettlementOption;
  payNowPaymentDueDate?: Date;
  payLaterPaymentDueDate?: Date;
  costs?: FundingCosts;
  vpaProductType?: string;
  canRequestPayNow: boolean;
  sellingPriceLowerLimit: number;
  sellingPriceUpperLimit: number;
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'vpa-bpay-bill-box',
  templateUrl: './bpay-bill-box.component.html',
  styleUrls: ['./bpay-bill-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BpayBillBoxComponent extends BaseComponent implements OnInit {
  @Input() billerCode: string;
  @Input() paymentReferenceCode: string;
  @Input() withBorder: boolean;

  constructor() {
    super();
  }

  ngOnInit() {}
}

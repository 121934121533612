export class PagingParameters {
  nextPage?: boolean;
  previousPage?: boolean;
  pageNumber?: number;
}

export class PaginationInfo {
  pageNumber: number;
  pageSize: number;
  total: number;
  hasMorePages: boolean;
}

export class PagedResponse<T> extends PaginationInfo {
  results: T[];
}

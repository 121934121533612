import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginDestination } from '@ca/shared/app-state';
import { Observable } from 'rxjs';
import { SwitchUserTokenResponse } from './switch-user-token-response';

@Injectable({
  providedIn: 'root'
})
export class SwitchAccountService {
  constructor(private http: HttpClient) {}

  getSwitchUserToken(userId: number, destination: LoginDestination): Observable<SwitchUserTokenResponse> {
    const loginDestination: string = LoginDestination[destination];
    return this.http.post<SwitchUserTokenResponse>(`user/authentication/switchUserAccounts/${userId}/${loginDestination}`, null);
  }

  switchToApp(token: string): Observable<SwitchUserTokenResponse> {
    return this.http.get<SwitchUserTokenResponse>(`user/authentication/Token?token=${token}`);
  }
}

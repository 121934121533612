import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { CountryISO } from 'ngx-intl-tel-input';
import { PhoneNumberType } from '../PhoneNumberType';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode: string, isRequired = true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);

      validNumber =
        phoneNumberUtil.isValidNumberForRegion(phoneNumber, regionCode) &&
        phoneNumberUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE;
    } catch (e) {
      validNumber = false;
    }

    if (!isRequired && !control.value?.toString().trim()) {
      return null;
    }

    return validNumber
      ? null
      : {
          invalidMobileNumber: {
            message: 'Must be a valid Australian mobile number'
          }
        };
  };
}

export function IsAMobileNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;

    if (!value || value?.number === null) {
      return null;
    }

    const countriesWithSameFixedLineAndMobileFormat = [CountryISO.UnitedStates.toUpperCase()];

    let isValidNumber = false;
    const countryCode = value.countryCode?.toUpperCase() ?? CountryISO.Australia.toUpperCase();

    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(value.nationalNumber, countryCode);

      const numberType = phoneNumberUtil.getNumberType(phoneNumber);
      let isValidNumberType = numberType === PhoneNumberType.MOBILE;

      if (countriesWithSameFixedLineAndMobileFormat.indexOf(countryCode) >= 0) {
        isValidNumberType = isValidNumberType || numberType === PhoneNumberType.FIXED_LINE_OR_MOBILE;
      }

      isValidNumber = phoneNumberUtil.isValidNumberForRegion(phoneNumber, countryCode) && isValidNumberType;
    } catch (e) {
      isValidNumber = false;
    }

    return isValidNumber
      ? null
      : {
          notAMobileNumber: {
            message: 'Must be a mobile number'
          }
        };
  };
}

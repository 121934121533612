import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApplicationMode } from '@ca/shared/application-mode';
import { BaseComponent } from '@ca/shared/base-components';
import { SystemConstants } from '@ca/shared/models';
import { NavigationItem } from '@ca/shared/navigation-menu';
import { Observable } from 'rxjs';
import { SwitchAccountModalComponentState } from '../../switch-account-modal/switch-account-modal.component';
import { HeaderNavFacadeService } from './services/facades/header-nav.facade.service';

@Component({
  selector: 'ca-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
  providers: [HeaderNavFacadeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNavComponent extends BaseComponent implements OnInit {
  agencyUserName$: Observable<string> = this.facade.agencyUserName$;
  agencyGroupName$: Observable<string> = this.facade.agencyGroupName$;
  isCurrentUserAVendor$: Observable<boolean> = this.facade.isCurrentUserAVendor$;
  showTrainingSwitch$: Observable<boolean> = this.facade.showTrainingSwitch$;
  isTrainingMode$: Observable<boolean> = this.facade.isTrainingMode$;
  navItems$: Observable<NavigationItem[]> = this.facade.navItems$;
  userDetailsNavItems$: Observable<NavigationItem[]> = this.facade.userDetailsNavItems$;
  isInPortalMode$: Observable<boolean> = this.facade.isInPortalMode$;
  showMenu$: Observable<boolean> = this.facade.showMenu$;

  switchAccountModalComponentState$: Observable<SwitchAccountModalComponentState> = this.facade.switchAccountModalComponentState$;
  showSwitchAccountsModal$: Observable<boolean> = this.facade.showSwitchAccountsModal$;
  canShowPortalSwitchAccountMenu$: Observable<boolean> = this.facade.canShowPortalSwitchAccountMenu$;

  searchForm$: Observable<FormGroup> = this.facade.form$;

  isSmartCommModule = this.facade.isSmartCommModule();
  supportPhoneNumber = SystemConstants.supportPhoneNumber;

  constructor(@Inject(DOCUMENT) private document: Document, private facade: HeaderNavFacadeService, private ngZone: NgZone) {
    super();
  }

  ngOnInit(): void {
    this.facade.init(window.innerWidth);
  }

  toggleMenu() {
    this.facade.toggleMenu();
  }

  setApplicationMode(newMode: ApplicationMode) {
    this.facade.setApplicationMode(newMode);
  }

  changeApplicationMode($event) {
    this.facade.changeApplicationMode($event.currentTarget.checked);
  }

  onClickNavItem(navItem: NavigationItem) {
    this.facade.onClickNavItem(navItem);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.facade.configureMenuForScreenSize(window.innerWidth);
  }

  onSwitchAccountsModalClosed(): void {
    this.facade.onSwitchAccountsModalClosed();
  }

  onLoginSelected(): void {
    this.facade.onLoginSelected();
  }

  submit() {
    this.facade.submitSearch();
  }

  showHelpMenu() {
    this.facade.onShowHelpMenu();

    // From Elevio page
    // "required to use an NgZone so that Elevio doesn't interfere with Angular's change tracking."
    this.ngZone.runOutsideAngular(() => {
      (window as any)._elev.open();
    });
  }

  toggleSwitchableAccountsMenu() {
    this.facade.toggleSwitchableAccountsMenu();
  }
}

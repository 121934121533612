<div class="row" style="margin-top: 17px;">
  <div class="col-12 font-20">Amount for this card: {{ state.amountRequested | currency }}</div>
  <div class="col-12 font-14 test-credit-card" *ngIf="state.isTrainingMode">
    <span>
      Use the following credit card for the payment of your training application
    </span>
    <div class="row">
      <div class="col-4 col-md-3">Card number:</div>
      <div class="col-8 col-md-9">4564710000000004</div>
    </div>
    <div class="row">
      <div class="col-4 col-md-3">Security code:</div>
      <div class="col-8 col-md-9">847</div>
    </div>
    <div class="row">
      <div class="col-4 col-md-3">Expiration:</div>
      <div class="col-8 col-md-9">02/29</div>
    </div>
  </div>
  <div class="col-12">
    <div id="payway-credit-card" [hidden]="state.isPaywayDisabled"></div>
  </div>
</div>
<button [hidden]="!state.isPaywayCreditCardInputValid" (click)="onSavePayment()" class="btn btn-primary m-t-20 m-b-20">
  Add Credit Card Payment
</button>
<div class="validation-message" *ngIf="state.paywayErrorMessage">
  {{ state.paywayErrorMessage }}
</div>

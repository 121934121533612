export class CreatePaywayCustomerResponse {
  vpaApplicationId: number;
  partialCardNumber: string;
  cardholderName: string;
  cardScheme: string;
  paywayCustomerAggregateId: string;
  contributionCorrelationId: string;
  error: string;
  creditCardSurcharge: number;
}

import { Injectable } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Observable, ReplaySubject } from 'rxjs';
import { ModalContent } from '../models/modalContent';

@Injectable({
  providedIn: 'root'
})
export class ModalContentService {
  private content: ReplaySubject<ModalContent> = new ReplaySubject(1);

  modalRef: MDBModalRef;
  content$: Observable<ModalContent> = this.content.asObservable();

  constructor() {}

  public setContent = (modal: ModalContent): void => this.content.next(modal);

  public clearContent = (): void => this.content.next(null);
}

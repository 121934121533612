import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class DateHttpInterceptor implements HttpInterceptor {
  dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/; // format: dd/mm/yyyy
  constructor(private datePipe: DatePipe) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const body = req.body;
    this.convertToIsoDateFormat(body);
    return next.handle(req);
  }

  convertToIsoDateFormat(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isDate(value)) {
        const dateData = value.split('/');
        body[key] = this.datePipe.transform(
          new Date(dateData[2], dateData[1] - 1, dateData[0]),
          'yyyy-MM-dd'
        );
      } else if (typeof value === 'object') {
        this.convertToIsoDateFormat(value);
      }
    }
  }

  isDate(value) {
    if (value === null || value === undefined) {
      return false;
    }

    return this.dateFormat.test(value);
  }
}

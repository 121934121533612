export enum GlobalVpaSubStatus {
  WaitingForAgent = 'WaitingForAgent',
  WaitingForVendor = 'WaitingForVendor',
  WaitingForPdf = 'WaitingForPdf',
  Appraisal = 'Appraisal',
  Draft = 'Draft',
  WaitingForLandlord = 'WaitingForLandlord',
  PayLater = 'PayLater',
  PayNow = 'PayNow',
  WaitingForDocuments = 'WaitingForDocuments',
  WaitingForBpayPayment = 'WaitingForBpayPayment',
  ErrorSendingEmail = 'ErrorSendingEmail',
  WaitingForTenant = 'WaitingForTenant',
  WaitingForPropertyManager = 'WaitingForPropertyManager'
}

export function getFriendlyDisplayForGlobalVpaSubStatus(status: GlobalVpaSubStatus) {
  switch (status) {
    case GlobalVpaSubStatus.WaitingForAgent:
      return 'Waiting for Agent';
    case GlobalVpaSubStatus.WaitingForVendor:
      return 'Waiting for Vendor';
    case GlobalVpaSubStatus.WaitingForPdf:
      return 'Awaiting PDF Upload';
    case GlobalVpaSubStatus.Appraisal:
      return 'Appraisal';
    case GlobalVpaSubStatus.Draft:
      return 'Waiting for Agent';
    case GlobalVpaSubStatus.WaitingForLandlord:
      return 'Waiting for Landlord';
    case GlobalVpaSubStatus.PayLater:
      return 'Pay Later';
    case GlobalVpaSubStatus.PayNow:
      return 'Pay Now';
    case GlobalVpaSubStatus.WaitingForDocuments:
      return 'Awaiting Document Upload';
    case GlobalVpaSubStatus.WaitingForBpayPayment:
      return 'Awaiting BPAY Payment';
    case GlobalVpaSubStatus.ErrorSendingEmail:
      return 'Error Sending Email';
    case GlobalVpaSubStatus.WaitingForTenant:
      return 'Waiting for Tenant';
    case GlobalVpaSubStatus.WaitingForPropertyManager:
      return 'Waiting for PM';
    case null:
      return '';
    default:
      return status;
  }
}

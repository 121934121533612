import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateResult, Months, VpaDatepickerModel } from '../../models/vpa-date';

@Component({
  selector: 'vpa-datepicker',
  templateUrl: './vpa-datepicker.component.html',
  styleUrls: ['./vpa-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VpaDatePickerComponent),
      multi: true
    }
  ]
})
export class VpaDatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() disabled = false;
  @Input() currentValue: DateResult;
  @Input() isReadOnly: boolean;
  currentMonth: string;
  dateOfBirth: DateResult;
  dateModel: VpaDatepickerModel;
  selectedDay: number;
  selectedMonth: string;
  selectedYear: number;

  constructor() {}

  onChange = (dateOfBirth: DateResult) => {};
  onTouched = () => {};

  ngOnInit() {
    this.dateModel = this.buildDatePicker();
    if (this.currentValue) {
      // This initial if statement is required because the date on certain pages is not properly converted
      // to a DateResult. Quick solution to unblock a client, but ensuring the proper type is received
      // should be investigated
      if (!this.currentValue.type) {
        const date = (this.currentValue as unknown) as Date;
        this.selectedDay = date.getDate();
        this.selectedMonth = this.getMonths().find((m) => m.number === date.getMonth()).text;
        this.selectedYear = date.getFullYear();
      } else if (this.currentValue.type !== 'invalidDate') {
        const dateValue = this.currentValue.data;
        this.selectedDay = dateValue.getDate();
        this.selectedMonth = this.getMonths().find((m) => m.number === dateValue.getMonth()).text;
        this.selectedYear = dateValue.getFullYear();
      }
    }
  }

  writeValue(dateOfBirth: DateResult): void {
    switch (dateOfBirth?.type) {
      case 'ok':
        this.dateOfBirth = { type: 'ok', data: dateOfBirth.data };
        this.onChange(this.dateOfBirth);
        return;
      case 'invalidDate':
        this.dateOfBirth = { type: 'invalidDate' };
        this.onChange(this.dateOfBirth);
        return;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  buildDatePicker(): VpaDatepickerModel {
    return {
      years: this.getyears(),
      days: this.getDays(),
      months: this.getMonths().map((month) => month.text)
    };
  }

  setMonth(e: any) {
    this.selectedMonth = e.target.value !== '0' ? e.target.value : null;
    if (this.selectedMonth) {
      this.validateDate(this.selectedYear, this.getMonths().find((m) => m.text === this.selectedMonth).number, this.selectedDay);
    }
  }

  setDay(e: any) {
    this.selectedDay = e.target.value !== '0' ? e.target.value : null;
    if (this.selectedMonth) {
      this.validateDate(this.selectedYear, this.getMonths().find((m) => m.text === this.selectedMonth).number, this.selectedDay);
    }
  }

  setYear(e: any) {
    this.selectedYear = e.target.value !== '0' ? e.target.value : null;
    if (this.selectedMonth) {
      this.validateDate(this.selectedYear, this.getMonths().find((m) => m.text === this.selectedMonth).number, this.selectedDay);
    }
  }

  validateDate(year: number, month: number, day: number) {
    const date = new Date(year, month, day);
    if (this.fullDateSelected(year, month, day)) {
      if (this.dateIsValid(date, year.toString(), month.toString(), day.toString())) {
        return this.writeValue({ type: 'ok', data: date });
      } else if (this.dateIsInvalid(date, year.toString(), month.toString(), day.toString())) {
        return this.writeValue({ type: 'invalidDate' });
      }
    }
    return this.writeValue({ type: 'invalidDate' });
  }

  dateIsValid = (date: Date, year: string, month: string, day: string): boolean =>
    date.getDate().toString() === day && date.getMonth().toString() === month && date.getFullYear().toString() === year;

  dateIsInvalid = (date: Date, year: string, month: string, day: string): boolean =>
    date.getDate().toString() !== day || date.getMonth().toString() !== month || date.getFullYear().toString() !== year;

  fullDateSelected(year: number, month: number, day: number) {
    if (year && month + 1 && day) {
      return true;
    }

    return false;
  }

  getyears(): number[] {
    const currentyear = new Date();
    const firstselectableyear = new Date();
    firstselectableyear.setFullYear(1900, 0, 1);
    const datediff = currentyear.getFullYear() - firstselectableyear.getFullYear();
    const years = [];
    for (let i = 0; i < datediff; i++) {
      years.push(currentyear.getFullYear() - i);
    }
    return years;
  }

  getDays(): number[] {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }
    return days;
  }

  getMonths(): Months[] {
    return [
      {
        text: 'Jan',
        number: 0
      },
      {
        text: 'Feb',
        number: 1
      },
      {
        text: 'Mar',
        number: 2
      },
      {
        text: 'Apr',
        number: 3
      },
      {
        text: 'May',
        number: 4
      },
      {
        text: 'Jun',
        number: 5
      },
      {
        text: 'Jul',
        number: 6
      },
      {
        text: 'Aug',
        number: 7
      },
      {
        text: 'Sep',
        number: 8
      },
      {
        text: 'Oct',
        number: 9
      },
      {
        text: 'Nov',
        number: 10
      },
      {
        text: 'Dec',
        number: 11
      }
    ];
  }
}

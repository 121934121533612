import { Injectable } from '@angular/core';
import { FileValidationResponse } from '../models/file-validation-response';

@Injectable({
  providedIn: 'root'
})
export class FileValidationService {
  constructor() {}

  validateFile(file: File): FileValidationResponse {
    if (this.isValidSize(file) && this.isValidType(file)) {
      return this.validFileResponse;
    }

    if (!this.isValidSize(file)) {
      return this.getInvalidFileSizeResponse(file);
    }

    if (!this.isValidType(file)) {
      return this.invalidTypeResponse;
    }
  }

  validateBrfFile(event): FileValidationResponse {
    if (event.length > 1) {
      return this.invalidOneFileResponse;
    }

    const file = Array.from(event)[0] as File;
    const validTypes = ['brf'];
    if (!this.isValidCustomType(file, validTypes)) {
      return this.invalidCustomTypeResponse(validTypes);
    }

    if (!this.isValidSize(file)) {
      return this.getInvalidFileSizeResponse(file);
    }

    return this.validFileResponse;
  }

  private isValidSize(file: File) {
    return file.size < this.maxFileSizeInBytes && file.size > 0;
  }

  private getInvalidFileSizeResponse(file: File) {
    if (file.size >= this.maxFileSizeInBytes)
      return {
        isValid: false,
        errorMessage: 'File must be less than 10MB'
      };

    if (file.size <= 0)
      return {
        isValid: false,
        errorMessage: 'File is empty or corrupted (size = 0)'
      };

    return null;
  }

  private isValidType(file: File) {
    return this.validFileTypes.find((x) => x === this.getFileExtension(file)) !== undefined;
  }

  private isValidCustomType(file: File, validTypes: string[]) {
    return validTypes.map((t) => t.toLowerCase()).find((x) => x === this.getFileExtension(file)) !== undefined;
  }

  private getFileExtension(file: File) {
    const fileArray = file.name.split('.');
    return fileArray[fileArray.length - 1].toLowerCase();
  }

  private get validFileTypes() {
    return ['png', 'jpeg', 'jpg', 'tiff', 'pdf'];
  }

  private get maxFileSizeInBytes(): number {
    const maxSizeMB = 10;
    return maxSizeMB * 1024 * 1024;
  }

  private get validFileResponse() {
    return {
      isValid: true,
      errorMessage: ''
    };
  }

  private get invalidTypeResponse() {
    return {
      isValid: false,
      errorMessage: 'File must be of type, png, jpeg, tiff or pdf'
    };
  }

  private invalidCustomTypeResponse(validTypes: string[]) {
    const displayTypes: string = validTypes.join(', ');
    return {
      isValid: false,
      errorMessage: `The file must be of type: ${displayTypes}`
    };
  }

  private get invalidOneFileResponse() {
    return {
      isValid: false,
      errorMessage: 'Please select and upload one file at a time'
    };
  }
}

export interface Badge {
  content: string;
  style: BadgeStyle;
}

export enum BadgeStyle {
  Danger = 'danger',
  Default = 'default', // Deprecated

  // For CAD only
  Info = 'info',
  Success = 'success'
}

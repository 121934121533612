<section class="section-card">
  <div class="row">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-2 p-0">
          <img class="w-100" src="https://cdn.campaignagent.com.au/BPAY_2012_PORT_BLUE.png" />
        </div>
        <div class="col-9 m-b-10">
          <div class="section-title">Pay by BPAY</div>
          <div class="section-description">
            We will email you payment instructions upon completion of this process.
          </div>
        </div>
      </div>
    </div>

    <vpa-contribution-payment-breakdown class="col-lg-6" [state$]="contributionPaymentBreakdown$"></vpa-contribution-payment-breakdown>
  </div>
</section>

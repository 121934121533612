import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// This service exists for the sole purpose of making the protractor e2e tests happy. For some reason, protractor is not
// able to load anything outside of legacy (i.e. libs/shared). We'll still need to initialise this service in the app module
// and make sure it picks up the right config values as some classes might need to reference this version of the service.
// For the time being, the only affected class seems to be legacy\src\app\_shared\models\route-resources.ts.
// For that reason, we're only porting the casUrl property which is the only one that is used by RouteResources.
// All other classes must use the AppSettingsService in the shared app-state library.

export interface AppSettings {
  casUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  static defaultAppSettings: AppSettings = {
    casUrl: 'https://localhost/'
  };

  public static appSettings: AppSettings = AppSettingsService.defaultAppSettings;
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  setAppSettings(): Promise<AppSettings> {
    return this.httpClient
      .get<AppSettings>('appSettings/appSettings.json')
      .toPromise()
      .then((config) => (AppSettingsService.appSettings = config));
  }
}

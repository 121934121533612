import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../_shared/components/base-component/base-component';
import { AlertModalSign } from '../../../_shared/models/alert-modal-sign';

@Component({
  selector: 'ca-responsive-modal',
  templateUrl: './responsive-modal.component.html',
  styleUrls: ['./responsive-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsiveModalComponent extends BaseComponent {
  @Input() modalType?: AlertModalSign = null;
  @Input() showModal: boolean;
  @Input() hasTitleContent: boolean;
  @Input() hasBodyContent: boolean;
  @Input() hasFooterContent: boolean;
  @Input() isWarningModal = true;
  @Input() size = null;
  @ViewChild('titleSlot') titleSlot: ElementRef;
  @ViewChild('bodySlot') bodySlot: ElementRef;
  @ViewChild('footerSlot') footerSlot: ElementRef;

  readonly alertModalSign = AlertModalSign;

  constructor() {
    super();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listNames'
})
export class ListNamesPipe implements PipeTransform {
  transform(list: string[]): any {
    if (!list || list.length === 0) {
      return '';
    }

    if (list.length === 1) {
      return list[0];
    }

    if (list.length === 2) {
      return `${list[0]} & ${list[1]}`;
    }

    return list.join(', ');
  }
}

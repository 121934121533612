export class ApplicationResignFieldConstants {
  public static FIRST_NAME_CHANGE = 'Vendor First Name';
  public static LAST_NAME_CHANGE = 'Vendor Last Name';
  public static PAY_NOW_AMOUNT_CHANGE = 'Pay Now Total';
  public static PAY_NOW_DATE_CHANGE = 'Pay Now Date';
  public static REPRESENTATIVE_ACTING_AS_CHANGE = 'Vendor Representation';
  public static ACTING_ON_BEHALF_OF_VENDOR_NAME_CHANGE = 'Acting on Behalf of Name';
  public static IS_COMPANY_ON_TITLE_CHANGE = 'Vendor Listed is a Company or Trust';
  public static PAYMENT_SETTLEMENT_OPTION_CHANGE = 'Payment Preference';
  public static CONTRIBUTIONS_CHANGE = 'Payment Breakdown';
  public static ADDRESS = 'Address';

  public static addedRole = (role: string) => `${role} Added`;
  public static removedRole = (role: string) => `${role} Removed`;
  public static firstNameChanged = (role: string) => `${role} First Name`;
  public static lastNameChanged = (role: string) => `${role} Last Name`;
  public static representativeActingAsChanged = (role: string) => `${role} Representation`;
}

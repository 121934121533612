<ca-responsive-modal-v2
  *ngIf="content$ | async as content"
  [showModal]="true"
  [hasTitleContent]="true"
  [hasBodyContent]="true"
  [hasFooterContent]="true"
  [showBackdrop]="false"
>
  <ng-container slot="title">
    {{ content.title }}
  </ng-container>
  <ng-container slot="body">
    <div [innerHTML]="content.message | sanitise: 'html'"></div>
  </ng-container>
  <ng-container slot="footer">
    <button type="button" class="btn btn-secondary" mdbWavesEffect (click)="onCancel(content)" *ngIf="content.showCancelButton">
      {{ content.cancelButtonWording }}
    </button>
    <button type="button" class="btn btn-primary float-right" mdbWavesEffect (click)="onSuccess(content)">
      {{ content.proceedButtonWording }}
    </button>
  </ng-container>
</ca-responsive-modal-v2>

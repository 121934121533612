import { FormGroup } from '@angular/forms';
import { Address } from '@ca/shared/models';

// Note continuing to use flags for the purposes of the demo as this is what we were using before
// Once we productionise this we should use an enum for the purposes of state transition
export interface CreateListingModalComponentState {
  form: FormGroup;
  listings: Listing[];
  selectedListing: Listing;
  showCreateNewListingOption: boolean;
  showListingCreationOptions: boolean;
  showBackButton: boolean;
  vendorLabel: string;
  showPdfCreationOption: boolean;
  canCreateTenantRequest: boolean;
  showPropertySelection: boolean;
  createNewButtonLabel: string;
  searchAddressDisclaimer: string;
}

export interface Listing {
  vpaApplicationId: number;
  listingImportAggregateId: string;
  vpaApplicationStartedBy: string;
  vpaApplicationStatus: string;
  listingStatus: ListingStatus | null;
  cpn: string;
  address: Address;
  // vendors: Vendor[];
  createDateTime: Date;
  saleMethodDetails: SaleMethodDetails;
  lastUpdatedOn: Date;
  legalTerms: LegalTerms;
  globalVpaStatusDetails: GlobalVpaStatusDetails;
  additionalVpaApplicationId: number;
  hasAdditionalVpa: boolean;
  agencyName: string;
}

export enum ListingStatus {
  Current = 'Current',
  Settled = 'Settled',
  Sold = 'Sold',
  Withdrawn = 'Withdrawn',
  Cancelled = 'Cancelled',
  Other = 'Other',
  Appraisal = 'Appraisal',
  MissedAppraisal = 'MissedAppraisal',
  Secret = 'Secret'
}

export interface SaleMethodDetails {
  auctionDate: Date;
  saleMethod: string;
}

export interface LegalTerms {
  authority: string;
  salesAuthoritySubheading: string;
}

export interface GlobalVpaStatusDetails {
  description: string;
  cssClass: string;
  globalVpaStatus: string;
  globalVpaSubStatus: string;
}

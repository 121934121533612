import { ApplicationMode } from '@ca/shared/application-mode';

export class ApplicationModeItem {
  constructor(applicationMode: ApplicationMode, displayName: string, isActive: boolean) {
    this.applicationMode = applicationMode;
    this.displayName = displayName;
    this.isActive = isActive;
  }
  applicationMode: ApplicationMode;
  displayName: string;
  isActive: boolean;
}

import { AlertModalSign, VendorSigningState } from 'apps/legacy/src/app/_shared/models';
import { UnsignFlowStep } from './unsign-flow-step';
import { VendorToNominate } from './vendor-to-nominate';

export interface UnsignApplicationModalState {
  showModal: boolean;
  vendorsToUnsign: VendorSigningState[];
  vendorNamesToUnsign: string;
  step: UnsignFlowStep;
  nominatedVendorName: string;
  isNominatedVendorBeingUnsigned: boolean;
  isSendingInvitationToMultipleVendors: boolean;
  modalIcon: AlertModalSign;
  vendorsToNominateFrom: VendorToNominate[];
  selectedVendorName: string;
}

export const createUnsignApplicationModalState = (
  vendorsToUnsign: VendorSigningState[],
  showUnsignApplicationModal: boolean,
  unsigningModalStep: UnsignFlowStep,
  nominatedVendorName: string,
  nominatedVendorId: number,
  vendorsToNominateFrom?: VendorToNominate[],
  selectedVendorIndex?: number
): UnsignApplicationModalState => {
  const isNominatedVendorBeingUnsigned = vendorsToUnsign.some((v) => v.vpaApplicationVendorId === nominatedVendorId);
  return {
    vendorsToUnsign,
    nominatedVendorName,
    isNominatedVendorBeingUnsigned,
    showModal: showUnsignApplicationModal,
    step: unsigningModalStep,
    vendorNamesToUnsign: vendorsToUnsign.reduce((prev, curr, ind, arr) => {
      if (ind === 0) {
        return curr.firstName;
      } else if (ind === arr.length - 1) {
        return `${prev} and ${curr.firstName}`;
      } else {
        return `${prev}, ${curr.firstName}`;
      }
    }, ''),
    isSendingInvitationToMultipleVendors: !isNominatedVendorBeingUnsigned && vendorsToUnsign.length > 1,
    modalIcon: unsigningModalStep === UnsignFlowStep.SelectNominatedVendor ? undefined : AlertModalSign.Warning,
    vendorsToNominateFrom: vendorsToNominateFrom ?? [],
    selectedVendorName: (vendorsToNominateFrom ?? []).find((v) => v.index === selectedVendorIndex)?.firstName
  };
};

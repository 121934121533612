export enum GlobalVpaAlertLevel {
  Info = 'Info',
  Warning = 'Warning',
  Danger = 'Danger',
  Success = 'Success',
  None = 'None'
}

export function mapFromGlobalVpaAlertLevelToCssClass(globalVpaAlertLevel: GlobalVpaAlertLevel) {
  switch (globalVpaAlertLevel) {
    case GlobalVpaAlertLevel.Info:
      return 'neutral';
    case GlobalVpaAlertLevel.Warning:
      return 'warning';
    case GlobalVpaAlertLevel.Danger:
      return 'error';
    case GlobalVpaAlertLevel.Success:
      return 'success';
    default:
      return 'neutral';
  }
}

import { CreditCardWorkflowStep } from './credit-card-workflow-step';

export interface CreditCardWorkflowButtonDetails {
  forwardButtonText?: string;
  backButtonText: string;
}

export const mapCreditCardWorkflowButtonDetails = (
  targetStep: CreditCardWorkflowStep,
  isPayNowDirectEnabled: boolean,
  isFirstCard: boolean
): CreditCardWorkflowButtonDetails => {
  switch (targetStep) {
    case CreditCardWorkflowStep.SelectNumberOfCards:
      return selectNumberOfCardsButtonDetails;

    case CreditCardWorkflowStep.SelectAmountForCard:
      return isPayNowDirectEnabled && !isFirstCard
        ? extraPayNowDirectSelectAmountForCardButtonDetails
        : standardSelectAmountForCardButtonDetails;

    case CreditCardWorkflowStep.FatZebraIframe:
    case CreditCardWorkflowStep.PayWayIframe:
      return iframeButtonDetails;
  }
};

export const selectNumberOfCardsButtonDetails: CreditCardWorkflowButtonDetails = { forwardButtonText: 'Next', backButtonText: 'Cancel' };
export const standardSelectAmountForCardButtonDetails: CreditCardWorkflowButtonDetails = {
  forwardButtonText: 'Next',
  backButtonText: 'Back'
};
export const extraPayNowDirectSelectAmountForCardButtonDetails: CreditCardWorkflowButtonDetails = {
  forwardButtonText: 'Next',
  backButtonText: 'I will pay the rest tomorrow'
};
export const iframeButtonDetails: CreditCardWorkflowButtonDetails = { backButtonText: 'Back' };

import { Injectable } from '@angular/core';
import {
  AmendApplicationModel,
  CreateApplicationModel,
  CreateApplicationVendor,
  IApplication,
  IHasContributions,
  IHasFundingDetails,
  IHasVendorQuestions,
  Representee
} from 'apps/legacy/src/app/applications-shared/models';
import { ApplyApplicationModel, ContributionsModel } from 'apps/legacy/src/app/applications/models';
import { getApplicantType } from 'apps/legacy/src/app/_helpers/applicant-type-helpers';
import { DateFormatOptions, reformatFromJsonString } from 'apps/legacy/src/app/_helpers/date-helpers';
import { ApplicationResignFieldConstants } from '../models/application-resign-field-constants';

@Injectable({
  providedIn: 'root'
})
export class NotifyApplicationResignService {
  constructor() {}

  checkCreateStepRequireResigning(originalApplication: CreateApplicationModel, currentApplication: CreateApplicationModel) {
    const triggerResignList = [
      ...this.checkFundingDetailsForChanges(originalApplication, currentApplication),
      ...this.checkAddressDetails(originalApplication, currentApplication),
      ...this.checkCreateVendorsForChanges(originalApplication, currentApplication),
      ...this.checkCreateQuestions(originalApplication, currentApplication),
      ...this.checkCreateRepresentativesForChanges(originalApplication, currentApplication)
    ];

    return triggerResignList;
  }

  checkApplyStepRequireResigning(originalApplication: ApplyApplicationModel, currentApplication: ApplyApplicationModel) {
    return [
      ...this.checkFundingDetailsForChanges(originalApplication, currentApplication),
      ...this.checkRepresentativesForChanges(originalApplication, currentApplication),
      ...this.checkApplyVendorsForChanges(originalApplication, currentApplication),
      ...this.checkPaymentSettlementOptionsForChanges(originalApplication, currentApplication),
      ...this.checkVendorQuestionsForChanges(originalApplication, currentApplication)
    ];
  }

  checkContributionsStepRequireResigning(
    originalContributionsRequest: ContributionsModel,
    currentContributionsRequest: ContributionsModel
  ) {
    return [...this.checkContributionsForChanges(originalContributionsRequest, currentContributionsRequest)];
  }

  checkAmendRequiresResigning(
    originalAmendRequest: AmendApplicationModel,
    currentAmendRequest: AmendApplicationModel,
    originalContributionsModel: ContributionsModel,
    currentContributionsModel: ContributionsModel
  ): string[] {
    if (!originalAmendRequest.hasAnySigned) {
      return [];
    }
    const triggerResignList = [
      ...this.checkAddressDetails(originalAmendRequest, currentAmendRequest),
      ...this.checkFundingDetailsForChanges(originalAmendRequest, currentAmendRequest),
      ...this.checkPaymentSettlementOptionsForChanges(originalAmendRequest, currentAmendRequest),
      ...this.checkContributionsForChanges(originalContributionsModel, currentContributionsModel),
      ...this.checkCreateVendorsForChanges(originalAmendRequest, currentAmendRequest),
      ...this.checkCreateQuestions(originalAmendRequest, currentAmendRequest),
      ...this.checkCreateRepresentativesForChanges(originalAmendRequest, currentAmendRequest)
    ];

    return triggerResignList;
  }

  private checkRepresentativesForChanges(originalApplication: ApplyApplicationModel, currentApplication: ApplyApplicationModel): string[] {
    const fieldsThatRequireResigning = [];

    const originalRepresentatives = originalApplication.vendors.filter((v) => v.representations?.length > 0);
    const currentRepresentatives = currentApplication.vendors.filter((v) => v.representations?.length > 0);

    originalRepresentatives
      .filter((or) => or.vpaApplicationVendorId !== null)
      .forEach((originalRepresentative) => {
        const currentRepresentative = currentRepresentatives.find(
          (cr) => cr.vpaApplicationVendorId === originalRepresentative.vpaApplicationVendorId
        );

        if (currentRepresentative) {
          if (originalRepresentative.firstName !== currentRepresentative.firstName) {
            if (
              !this.hasFieldAlreadyBeenAdded(
                fieldsThatRequireResigning,
                ApplicationResignFieldConstants.firstNameChanged("Representative's")
              )
            ) {
              fieldsThatRequireResigning.push(ApplicationResignFieldConstants.firstNameChanged("Representative's"));
            }
          }

          if (originalRepresentative.lastName !== currentRepresentative.lastName) {
            if (
              !this.hasFieldAlreadyBeenAdded(
                fieldsThatRequireResigning,
                ApplicationResignFieldConstants.lastNameChanged("Representative's")
              )
            ) {
              fieldsThatRequireResigning.push(ApplicationResignFieldConstants.lastNameChanged("Representative's"));
            }
          }

          this.checkRepresentationForChanges(
            originalRepresentative.representations,
            currentRepresentative.representations,
            fieldsThatRequireResigning
          );
        }
      });

    return fieldsThatRequireResigning;
  }

  private checkAddressDetails(originalApplication: IApplication, currentApplication: IApplication): string[] {
    const fieldsThatRequireResigning = [];
    if (
      originalApplication.address.country !== currentApplication.address.country ||
      originalApplication.address.postcode !== currentApplication.address.postcode ||
      originalApplication.address.state !== currentApplication.address.state ||
      originalApplication.address.street !== currentApplication.address.street ||
      originalApplication.address.streetNumber !== currentApplication.address.streetNumber ||
      originalApplication.address.subNumber !== currentApplication.address.subNumber ||
      originalApplication.address.suburb !== currentApplication.address.suburb
    ) {
      fieldsThatRequireResigning.push(ApplicationResignFieldConstants.ADDRESS);
    }

    return fieldsThatRequireResigning;
  }

  private checkFundingDetailsForChanges(originalFundingDetails: IHasFundingDetails, currentFundingDetails: IHasFundingDetails): string[] {
    const fieldsThatRequireResigning = [];

    if (originalFundingDetails.fundingDetails.requestedAmount !== currentFundingDetails.fundingDetails.requestedAmount) {
      fieldsThatRequireResigning.push(ApplicationResignFieldConstants.PAY_NOW_AMOUNT_CHANGE);
    }

    if (originalFundingDetails.fundingDetails.startDate !== currentFundingDetails.fundingDetails.startDate) {
      const originDateString = reformatFromJsonString(
        originalFundingDetails.fundingDetails.startDate.toString(),
        DateFormatOptions.DDMMYYYY
      );

      if (originDateString !== currentFundingDetails.fundingDetails.startDate.toString()) {
        fieldsThatRequireResigning.push(ApplicationResignFieldConstants.PAY_NOW_DATE_CHANGE);
      }
    }

    return fieldsThatRequireResigning;
  }

  private checkCreateQuestions(
    originalQuestions: CreateApplicationModel | AmendApplicationModel,
    currentQuestions: CreateApplicationModel | AmendApplicationModel
  ): string[] {
    const fieldsThatRequireResigning = [];

    if (originalQuestions.vendorQuestions.isCompanyOnTitle !== currentQuestions.vendorQuestions.isCompanyOnTitle) {
      fieldsThatRequireResigning.push(ApplicationResignFieldConstants.IS_COMPANY_ON_TITLE_CHANGE);
    }

    return fieldsThatRequireResigning;
  }

  private checkVendorQuestionsForChanges(originalQuestions: IHasVendorQuestions, currentQuestions: IHasVendorQuestions): string[] {
    const fieldsThatRequireResigning = [];

    if (originalQuestions.vendorQuestions.isCompanyOnTitle !== currentQuestions.vendorQuestions.isCompanyOnTitle) {
      fieldsThatRequireResigning.push(ApplicationResignFieldConstants.IS_COMPANY_ON_TITLE_CHANGE);
    }

    return fieldsThatRequireResigning;
  }

  private checkApplyVendorsForChanges(originalModel: ApplyApplicationModel, currentModel: ApplyApplicationModel): string[] {
    const fieldsThatRequireResigning = [];
    const originalVendors = originalModel.vendors;
    const currentVendors = currentModel.vendors;

    originalVendors
      .filter((ov) => ov.vpaApplicationVendorId !== null)
      .forEach((originalVendor) => {
        const currentVendor = currentVendors.find((cv) => cv.vpaApplicationVendorId === originalVendor.vpaApplicationVendorId);

        if (currentVendor) {
          if (originalVendor.firstName !== currentVendor.firstName) {
            if (!this.hasFieldAlreadyBeenAdded(fieldsThatRequireResigning, ApplicationResignFieldConstants.FIRST_NAME_CHANGE)) {
              fieldsThatRequireResigning.push(ApplicationResignFieldConstants.FIRST_NAME_CHANGE);
            }
          }

          if (originalVendor.lastName !== currentVendor.lastName) {
            if (!this.hasFieldAlreadyBeenAdded(fieldsThatRequireResigning, ApplicationResignFieldConstants.LAST_NAME_CHANGE)) {
              fieldsThatRequireResigning.push(ApplicationResignFieldConstants.LAST_NAME_CHANGE);
            }
          }
        }
      });

    return fieldsThatRequireResigning;
  }

  private checkCreateVendorsForChanges(
    originalApplication: CreateApplicationModel | AmendApplicationModel,
    currentApplication: CreateApplicationModel | AmendApplicationModel
  ) {
    const originalVendors = originalApplication.vendors.filter(
      (v) => !v.isDeleted && (!v.representations || v.representations.length === 0)
    );
    const currentVendors = currentApplication.vendors.filter((v) => !v.isDeleted && (!v.representations || v.representations.length === 0));

    const isCompanyOnTitle = currentApplication.vendorQuestions.isCompanyOnTitle;

    return this.checkCreateApplicantsForChanges(originalVendors, currentVendors, isCompanyOnTitle, false);
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  private checkCreateRepresentativesForChanges(
    originalApplication: CreateApplicationModel | AmendApplicationModel,
    currentApplication: CreateApplicationModel | AmendApplicationModel
  ) {
    const originalNotDeletedRepresentatives = originalApplication.vendors.filter(
      (v) => !v.isDeleted && v.representations && v.representations.length !== 0
    );
    const currentNotDeletedRepresentatives = currentApplication.vendors.filter(
      (v) => !v.isDeleted && v.representations && v.representations.length !== 0
    );

    const isCompanyOnTitle = currentApplication.vendorQuestions.isCompanyOnTitle;

    return this.checkCreateApplicantsForChanges(
      originalNotDeletedRepresentatives,
      currentNotDeletedRepresentatives,
      isCompanyOnTitle,
      true
    );
  }

  private checkCreateApplicantsForChanges(
    originalApplicants: CreateApplicationVendor[],
    currentApplicants: CreateApplicationVendor[],
    isCompanyOnTitle: boolean,
    isRepresentative: boolean
  ) {
    const role = getApplicantType(isCompanyOnTitle, true, isRepresentative);

    const fieldsThatRequireResigning: string[] = [];

    originalApplicants
      .filter((oa) => oa.vpaApplicationVendorId !== null)
      .forEach((originalApplicant) => {
        const currentApplicant = currentApplicants.find((ca) => ca.vpaApplicationVendorId === originalApplicant.vpaApplicationVendorId);

        if (currentApplicant) {
          if (originalApplicant.firstName !== currentApplicant.firstName) {
            if (!this.hasFieldAlreadyBeenAdded(fieldsThatRequireResigning, ApplicationResignFieldConstants.firstNameChanged(role))) {
              fieldsThatRequireResigning.push(ApplicationResignFieldConstants.firstNameChanged(role));
            }
          }

          if (originalApplicant.lastName !== currentApplicant.lastName) {
            if (!this.hasFieldAlreadyBeenAdded(fieldsThatRequireResigning, ApplicationResignFieldConstants.lastNameChanged(role))) {
              fieldsThatRequireResigning.push(ApplicationResignFieldConstants.lastNameChanged(role));
            }
          }

          if (isRepresentative) {
            this.checkRepresentationForChanges(
              originalApplicant.representations,
              currentApplicant.representations,
              fieldsThatRequireResigning
            );
          }
        }
      });

    return fieldsThatRequireResigning;
  }

  private checkRepresentationForChanges(
    originalRepresentees: Representee[],
    currentRepresentees: Representee[],
    fieldsThatRequireResigning: string[]
  ): string[] {
    if (originalRepresentees.length > currentRepresentees.length) {
      fieldsThatRequireResigning.push(ApplicationResignFieldConstants.removedRole('Representation'));
      return fieldsThatRequireResigning;
    }

    if (originalRepresentees.length < currentRepresentees.length) {
      fieldsThatRequireResigning.push(ApplicationResignFieldConstants.addedRole('Representation'));
      return fieldsThatRequireResigning;
    }

    for (let i = 0; i < originalRepresentees.length; i++) {
      const original = originalRepresentees[i];
      const current = currentRepresentees[i];

      if (original.actingAs !== current.actingAs) {
        if (
          !this.hasFieldAlreadyBeenAdded(
            fieldsThatRequireResigning,
            ApplicationResignFieldConstants.representativeActingAsChanged('Representative')
          )
        ) {
          fieldsThatRequireResigning.push(ApplicationResignFieldConstants.representativeActingAsChanged('Representative'));
        }
      }

      if (original.actingOnBehalfOf !== current.actingOnBehalfOf) {
        if (
          !this.hasFieldAlreadyBeenAdded(fieldsThatRequireResigning, ApplicationResignFieldConstants.ACTING_ON_BEHALF_OF_VENDOR_NAME_CHANGE)
        ) {
          fieldsThatRequireResigning.push(ApplicationResignFieldConstants.ACTING_ON_BEHALF_OF_VENDOR_NAME_CHANGE);
        }
      }
    }

    return fieldsThatRequireResigning;
  }

  private checkPaymentSettlementOptionsForChanges(
    originalApplication: IHasFundingDetails,
    currentApplication: IHasFundingDetails
  ): string[] {
    return originalApplication.fundingDetails.paymentSettlementOption !== currentApplication.fundingDetails.paymentSettlementOption
      ? [ApplicationResignFieldConstants.PAYMENT_SETTLEMENT_OPTION_CHANGE]
      : [];
  }

  private checkContributionsForChanges(originalContributions: IHasContributions, currentContributions: IHasContributions) {
    return JSON.stringify(originalContributions.contributions) !== JSON.stringify(currentContributions.contributions)
      ? [ApplicationResignFieldConstants.CONTRIBUTIONS_CHANGE]
      : [];
  }

  private hasFieldAlreadyBeenAdded(fieldsThatRequireResigning: string[], field: string) {
    return fieldsThatRequireResigning.find((x) => {
      return x === field;
    });
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PaymentDetailsOptionType } from 'apps/legacy/src/app/_shared/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'vpa-contribution-payment-breakdown',
  templateUrl: './contribution-payment-breakdown.component.html',
  styleUrls: ['./contribution-payment-breakdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContributionPaymentBreakdownComponent implements OnInit {
  @Input() state$: Observable<IContributionPaymentBreakdownComponentState>;
  paymentDetailsOptionType = PaymentDetailsOptionType;

  constructor() {}

  ngOnInit() {}
}

export interface IContributionPaymentBreakdownComponentState {
  payNowPaymentDueDate: Date;
  paymentDetailsOptionType: PaymentDetailsOptionType;
  totalAuthorisedAmount: number;
  totalAdded: number;
  totalSurcharges: number;
  amountOutstanding: number;
  offsetSectionSpacing: boolean;
  shouldPadLeft: boolean;
  useChargeOnSubmission: boolean;
  surchargelessPaymentOptions: string;
  isPaymentMethodCreditCard: boolean;
}

<div *ngIf="isShow()" style="color: red" id="global-exception-display">
  <ul>
    <li
      *ngFor="let message of getErrorMessages(globals.exception)"
      class="error-pill text-uppercase font-13 strong-lite m-t-10"
    >
      {{ message }}
    </li>
  </ul>
</div>

<vpa-unelected-vendor-error-modal
  *ngIf="showUnelectedVendorError()"
></vpa-unelected-vendor-error-modal>

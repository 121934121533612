<div class="notranslate">
  <span class="select">
    <select class="datepicker" (change)="setDay($event)">
      <option value="0" disabled [selected]="!selectedDay">Day</option>
      <option *ngFor="let day of dateModel.days" [selected]="day === selectedDay">{{ day }}</option>
    </select>
  </span>
  <span class="select">
    <select class="datepicker" (change)="setMonth($event)">
      <option value="0" disabled [selected]="!selectedMonth">Month</option>
      <option *ngFor="let month of dateModel.months" [selected]="month === selectedMonth">{{ month }}</option>
    </select>
  </span>
  <span class="select">
    <select class="datepicker" (change)="setYear($event)">
      <option value="0" disabled [selected]="!selectedMonth">Year</option>
      <option *ngFor="let year of dateModel.years" [selected]="year === selectedYear">{{ year }}</option>
    </select>
  </span>
</div>

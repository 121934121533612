import { Injectable } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Observable, ReplaySubject } from 'rxjs';
import { ModalContent } from '../models/modal-content';

@Injectable({
  providedIn: 'root'
})
export class ModalContentService {
  private content: ReplaySubject<ModalContent> = new ReplaySubject(1);
  private useV2Style: ReplaySubject<boolean> = new ReplaySubject(1);

  modalRef: MDBModalRef;
  content$: Observable<ModalContent> = this.content.asObservable();
  useV2Style$: Observable<boolean> = this.useV2Style.asObservable();

  constructor() {}

  public setContent = (modal: ModalContent): void => this.content.next(modal);

  public clearContent = (): void => this.content.next(null);

  public setUseV2Style = (value: boolean): void => this.useV2Style.next(value);
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TimelineItem } from 'apps/legacy/src/app/applications/models/vendor-thank-you-model';
import { IThankyouRentalMaintenanceState } from '../../../applications/services/facades/vendor-thank-you/vendor-thank-you-facade.service';

@Component({
  selector: 'vpa-horizontal-application-timeline',
  templateUrl: './horizontal-application-timeline.component.html',
  styleUrls: ['./horizontal-application-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalApplicationTimelineComponent implements OnInit {
  @Input() timelineItems: TimelineItem[];
  @Input() payLaterPaymentDueDate: Date;
  @Input() rentalMaintenanceState: IThankyouRentalMaintenanceState;

  constructor() {}

  ngOnInit() {}
}

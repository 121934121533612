import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export class InvalidNaturalPersonDto {
  index: number;
  invalidName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ValidateNaturalPersonService {
  readonly invalidWords = ['pty', 'ltd', 'trust', 'trustee', 'atf', 'holdings', 'inc', 'incorporated', 'enterprise', 'group', 'limited'];
  constructor() { }

  validateApplicantNames(applicants: AbstractControl[]): InvalidNaturalPersonDto {
    for (let i = 0; i < applicants.length; i++) {
      if (this.doesNameMatchInvalidWord(applicants[i])) {
        return {
          index: i,
          invalidName: `${this.applicantFirstName(applicants[i])} ${this.applicantLastName(applicants[i])}`
        };
      }
    }

    return null;
  }

  private doesNameMatchInvalidWord(applicant: AbstractControl): boolean {
    return this.isACompany(this.applicantFirstName(applicant)) || this.isACompany(this.applicantLastName(applicant));
  }

  public isACompany(name: string): boolean {
    const regex = new RegExp(`\\b(${this.invalidWords.join('|')})\\b`, 'i');
    return regex.test(name);
  }

  private applicantFirstName(applicant: AbstractControl): string {
    return applicant.get('firstName').value;
  }

  private applicantLastName(applicant: AbstractControl) {
    return applicant.get('lastName').value;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppSettingsService } from '@ca/shared/app-state';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { UserTypes } from '../_shared/models';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private appStateService: AppStateService) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // if we are running pact, don't worry about auth for now
    if (AppSettingsService.appSettings.ignoreAuth) {
      return of(true);
    }

    if (!this.appStateService.getIsAuthenticated()) {
      return of(this.handleAuthenticationFailure(state.url));
    }

    return this.appStateService.old_getCurrentUserType$().pipe(
      first(),
      switchMap((userType) => {
        if (userType === UserTypes.Admin) {
          return of(true);
        }

        return this.checkIsPendingMfa$(state.url);
      })
    );
  }

  private checkIsPendingMfa$(returnUrl: string): Observable<boolean> {
    return this.appStateService.getIsPendingMfa$().pipe(
      first(),
      map((isPendingMfa) => {
        if (!this.appStateService.getIsAuthenticated() || isPendingMfa) {
          return this.handleAuthenticationFailure(returnUrl);
        }

        return true;
      })
    );
  }

  private handleAuthenticationFailure(returnUrl: string): boolean {
    this.router.navigate(['login'], { queryParams: { returnUrl } });
    return false;
  }
}

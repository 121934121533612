import { Injectable } from '@angular/core';
import { Address } from '@ca/shared/models';
import { merge, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppThemeService } from './app-theme-service';

@Injectable({
  providedIn: 'root'
})
export class BrandHeaderService {
  private readonly addressSubject: ReplaySubject<Address> = new ReplaySubject();
  readonly address$ = this.addressSubject.asObservable();

  readonly fallbackToDefaultLogoUrlSubject: ReplaySubject<string> = new ReplaySubject();
  readonly fallbackToDefaultLogoUrl$ = this.fallbackToDefaultLogoUrlSubject.asObservable();

  logoUrl$ = merge(this.themeService.currentTheme$, this.fallbackToDefaultLogoUrl$).pipe(
    map((theme) => `https://cdn.campaignagent.com.au/agency-branding/agency-images/${theme}`)
  );

  constructor(private themeService: AppThemeService) {}

  onError = () => {
    console.log('error was called');
    this.fallbackToDefaultLogoUrlSubject.next('default');
  };

  setAddress = (address: Address): void => this.addressSubject.next(address);
}

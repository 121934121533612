<div class="form-group">
  <div class="input-group">
    <a (click)="toggleModal($event)" class="manual-text" id="manual-address-button">Enter address manually</a>
    <input
      placeholder="search for location"
      autocorrect="off"
      autocomplete="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      class="form-control no-border"
      [class.white-bg]="isV2"
      #search
      [formControl]="addressSearchControl"
      style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;"
    />
    <div class="input-group-append invis-primary-color">
      <button (click)="toggleModal($event)" id="manual-address-button">
        Enter address manually
      </button>
    </div>
  </div>
  <div class="validation-message" *ngIf="isSubmitted && addressSearchControl.valid && addressGroup.invalid">
    <span>You must enter a valid address</span>
  </div>
  <div
    class="validation-message"
    *ngIf="!isSubmitted && !(hasSelectedAnAddress$ | async) && addressSearchControl.valid && (addressFieldLostFocus$ | async)"
  >
    <span>Please select an address from the dropdown list or use the "Enter address manually" button</span>
  </div>

  <vpa-control-validation-message
    [control]="addressSearchControl"
    [fieldName]="'Address'"
    [isSubmitted]="isSubmitted"
  ></vpa-control-validation-message>
</div>

<vpa-manual-address-modal
  *ngIf="showModal"
  [modalAddressGroup]="addressGroup"
  [shouldShowErrors]="shouldShowModalErrors"
  (addressSaved)="saveAddress()"
  (modalClosed)="toggleModal()"
></vpa-manual-address-modal>

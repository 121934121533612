import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { RepresentativeAndVendorFormService } from 'apps/legacy/src/app/applications-shared/services';
import { Observable } from 'rxjs';
import { InvalidNaturalPersonDto } from '../../services/validate-natural-person.service';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'vpa-confirm-natural-person-modal',
  templateUrl: './confirm-natural-person-modal.component.html',
  styleUrls: ['./confirm-natural-person-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmNaturalPersonModalComponent extends BaseComponent implements OnInit {
  @Input() showModal$: Observable<void>;
  @Input() invalidPersonDetails: InvalidNaturalPersonDto;
  @Input() vendors: FormArray;
  @Output() confirmApplicantIsANaturalPerson = new EventEmitter();

  showModal: boolean;

  constructor(private vendorsFormService: RepresentativeAndVendorFormService) {
    super();
  }

  ngOnInit() {
    this.showModal$.pipe(this.unsubOnDestroy()).subscribe((_) => {
      this.showModal = true;
    });
  }

  closeModal() {
    this.showModal = false;
    this.confirmApplicantIsANaturalPerson.emit();
  }

  confirm() {
    this.showModal = false;
    this.clearName();
  }

  private clearName() {
    this.vendorsFormService.clearNameAtIndex(this.vendors, this.invalidPersonDetails.index);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AlertModalV2Component } from '../components/alert-modal-v2/alert-modal-v2.component';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';
import { AlertModalSign } from '../models/alert-modal-sign';
import { AmendApplicationSuccessType } from '../models/amend-application-success-type-model';
import { ModalContent } from '../models/modal-content';
import { ModalContentService } from './modal-content.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalRef: MDBModalRef;
  constructor(private mdbModalService: MDBModalService, private modalContentService: ModalContentService, private router: Router) {}

  // JD: Continuing to create success modals in here seems needless
  // Unless something is custom use this from now on
  openGenericSuccessModal(successMessage: string, useV2Style: boolean = false) {
    const content = new ModalContent();
    content.message = successMessage;
    if (useV2Style) {
      this.setupAlertModalWithV2Style(content);
      return;
    }
    this.setupAlertModal(content);
  }

  openAlert(title: string, message: string) {
    const content = new ModalContent();
    content.title = title;
    content.message = message;
    content.sign = null;
    this.setupAlertModal(content);
  }

  openAlertV2(title: string, message: string) {
    const content = new ModalContent();
    content.title = title;
    content.message = message;
    content.sign = null;
    this.setupAlertModalV2(content);
  }

  openSendEmailToElectedVendorSuccessModal(vendorName: string) {
    const content = new ModalContent();
    content.message = 'The completion link has been sent to ' + vendorName;
    this.setupAlertModal(content);
  }

  openVendorAlreadySignedModal(vendorName: string) {
    const content = new ModalContent();
    content.message = `Hello ${vendorName}, you have already signed this agreement. You will be sent a welcome pack when the agreement has been approved`;
    this.setupAlertModal(content);
  }

  openSendEmailToVendorWarningModal() {
    const content = new ModalContent();
    content.title = 'Could not email invitation to vendor';
    content.message = `Your application was saved successfully, however email invitations could not be sent to vendors.  Check the vendor email addresses are correct, then click resend Links.`;
    content.sign = AlertModalSign.Warning;
    this.setupAlertModal(content);
  }

  openSmartCommissionRequestSuccessfulModel() {
    const content = new ModalContent();
    content.message = `Success! Your funding is on its way`;
    this.setupAlertModal(content);
  }

  openSendEmailToNominatedAdditionalVpaApplicant() {
    const content = new ModalContent();
    content.message = 'VPA email has been sent to the nominated vendor';
    this.setupAlertModal(content);
  }

  openVendorRequestMobileNumberUpdateSuccess() {
    const content = new ModalContent();
    content.message = 'Thank you, your request has been sent. Our customer support team will be in touch.';
    this.setupAlertModal(content);
  }

  openVendorClickToSignModal(listingFullAddress: string) {
    const content = new ModalContent();
    content.message = 'You have signed the VPA application for ' + listingFullAddress;
    this.setupAlertModal(content);
  }

  openSubmitPdfAgreement(listingFullAddress: string) {
    const content = new ModalContent();
    content.message = `You have submitted the PDF agreement for ${listingFullAddress}`;
    this.setupAlertModal(content);
  }

  openAmendApplicationSuccessModal(amendApplicationSuccessType) {
    const templates = {
      BeingReviewed: 'Your changes are saved to the application. CampaignAgent will review the campaign.',
      VendorsInvited: 'Your changes are saved to the application. The vendor will receive a link to acknowledge and resign the agreement.',
      SendBackToAgent:
        'Your changes are saved to the application. The sales agent must have the vendor acknowledge and resign the agreement.',
      AmendWhenAwaitingSignatures: 'Your changes are saved to the application.'
    };

    const content = new ModalContent();

    switch (amendApplicationSuccessType) {
      case AmendApplicationSuccessType[AmendApplicationSuccessType.BeingReviewed]:
        content.message = templates.BeingReviewed;
        break;
      case AmendApplicationSuccessType[AmendApplicationSuccessType.VendorsInvited]:
        content.message = templates.VendorsInvited;
        break;
      case AmendApplicationSuccessType[AmendApplicationSuccessType.SendBackToAgent]:
        content.message = templates.SendBackToAgent;
        break;
      default:
        content.message = templates.AmendWhenAwaitingSignatures;
        break;
    }

    this.setupAlertModal(content);
  }

  openAgentCompleteApplicationModal(listingFullAddress: string) {
    const content = new ModalContent();
    content.message = 'You have submitted a VPA application for ' + listingFullAddress;
    this.setupAlertModal(content);
  }

  openUnsavedChangesWarningModal(route: string) {
    const content = new ModalContent();
    content.title = 'Unsaved Changes';
    content.message = 'You have unsaved changes, if you leave before saving your changes will be lost.';
    content.successAction = () => this.router.navigate([route]);
    content.proceedButtonWording = 'Lose';
    content.sign = AlertModalSign.Warning;
    content.showCancelButton = true;
    content.cancelButtonWording = 'Keep';
    this.setupAlertModal(content);
  }

  openCancelCancellationRequestIsSuccessful() {
    const content = new ModalContent();
    content.message = 'Cancelling the cancellation request is successful!';
    this.setupAlertModal(content);
  }

  openCancelFundingRequestIsSuccessful() {
    const content = new ModalContent();
    content.message = 'Funding successfully cancelled!';
    content.isWarningModal = false;
    this.setupAlertModalWithV2Style(content);
  }

  openPayNowDirectHasBeenConvertedToPayLater() {
    const content = new ModalContent();
    content.message =
      'You have successfully converted this application to Pay Later. Fill in the remainder of this application or send the application to a vendor to complete later.';
    this.setupAlertModal(content);
  }

  openPdfApplicationDownloadedSuccessfulModal() {
    const content = new ModalContent();
    content.title = 'A PDF application has been downloaded';
    content.message = `You can now save it to a file directory, email it to a vendor, or print it our for the vendor to sign.

    Once the vendor has completed the PDF application and signed it, it can be uploaded and submitted to CampaignAgent for review.
    `;
    this.setupAlertModal(content);
  }

  openPdfApplicationSaveChangesSuccessfulModal() {
    const content = new ModalContent();
    content.message = 'Your changes are saved.';
    this.setupAlertModal(content);
  }

  openSubmitFeedbackSuccessfulModal() {
    const content = new ModalContent();
    content.message = 'Thank you for your feedback!';
    this.setupAlertModal(content);
  }

  openUploadBrfFileSuccessfulModal() {
    const content = new ModalContent();
    content.message = 'Your BPAY Remittance file has been uploaded successfully.';
    this.setupAlertModal(content);
  }

  openCreditCardNumberChangedModal(successAction: () => void, cancelAction: () => void): void {
    const content = new ModalContent();
    content.title = 'Unsaved Changes';
    content.message = 'You have already entered credit cards, if you change now these will be discarded.';
    content.successAction = successAction;
    content.proceedButtonWording = 'Proceed';
    content.sign = AlertModalSign.Warning;
    content.showCancelButton = true;
    content.cancelButtonWording = 'Keep current cards';
    content.cancelAction = cancelAction;
    this.setupAlertModal(content);
  }

  openFailedVendorEmailResent(email: string) {
    const content = new ModalContent();
    content.message = `Email resent to ${email}`;
    this.setupAlertModal(content);
  }

  openFailedVendorEmailArchived(email: string) {
    const content = new ModalContent();
    content.message = `Email to ${email} archived.`;
    this.setupAlertModal(content);
  }

  openYesNoConfirmModal(message: string, successAction: () => void, cancelAction: () => void): void {
    const content = new ModalContent();

    content.title = 'Confirm';
    content.message = message;
    content.sign = null;

    content.proceedButtonWording = 'Yes';
    content.successAction = successAction;

    content.showCancelButton = true;
    content.cancelButtonWording = 'No';
    content.cancelAction = cancelAction;

    this.setupAlertModal(content);
  }

  openYesNoConfirmModalV2(
    message: string,
    successAction: () => void,
    cancelAction: () => void,
    proceedButtonText: string = 'Yes',
    cancelButtonText: string = 'No',
    title: string = 'Confirm'
  ): void {
    const content = new ModalContent();

    content.title = title;
    content.message = message;
    content.sign = null;

    content.proceedButtonWording = proceedButtonText;
    content.successAction = successAction;

    content.showCancelButton = true;
    content.cancelButtonWording = cancelButtonText;
    content.cancelAction = cancelAction;

    this.setupAlertModalV2(content);
  }

  openArchiveCampaignSuccessfulModal(campaignNumber: number) {
    const content = new ModalContent();
    content.message = `Successfully archived ${campaignNumber} campaign${campaignNumber > 1 ? 's' : ''}`;
    this.setupAlertModalWithV2Style(content);
  }

  openArchiveCampaignFailModal(errorMessage: string) {
    const content = new ModalContent();
    content.title = 'Error';
    content.sign = AlertModalSign.Error;
    content.message = errorMessage;
    this.setupAlertModalWithV2Style(content);
  }

  private setupAlertModal(content: ModalContent) {
    this.modalContentService.setContent(content);
    this.modalRef = this.mdbModalService.show(AlertModalComponent);
  }

  private setupAlertModalWithV2Style(content: ModalContent) {
    this.modalContentService.setContent(content);
    this.modalContentService.setUseV2Style(true);
    this.modalRef = this.mdbModalService.show(AlertModalV2Component);
  }

  private setupAlertModalV2(content: ModalContent) {
    this.modalContentService.setContent(content);
    this.modalRef = this.mdbModalService.show(AlertModalV2Component);
  }
}

export class CheckVendorSigningPropertiesResponseModel {
  isApplicationComplete: boolean;
  willCauseUnsigning: boolean;
  vendorSigningStates: VendorSigningState[];
}

export class VendorSigningState {
  vpaApplicationVendorId: number;
  firstName: string;
  vendorSigningEffect: VendorSigningEffect;
  changes: FieldChange[];
}

export class FieldChange {
  name: string;
  newValue: string;
  originalValue: string;
}

export enum VendorSigningEffect {
  NoChange = 'NoChange',
  Unsign = 'Unsign',
  ReSign = 'ReSign'
}

import * as uuid from 'uuid';

/*
A utility class to make it easer to raise event events relating to page views.
*/
export class PageEventTracker {
  startTime: number;
  correlationKey: string;
  cpn: string;
  constructor(public moduleName: string, public pageName: string) {
    this.startTime = Date.now();
    this.correlationKey = uuid.v4();
  }
}

<div *ngIf="filteredItems$ | async as filteredItems" class="row">
  <div class="form-group col-12 p-l-0">
    <span class="input-label d-inline-block col-12">{{ label }}</span>
    <div class="input-group col-6">
      <input type="text" class="form-control" [formControl]="formControl" placeholder="Search" />

      <div class="input-group-append">
        <i class="fa fa-search search-icon"></i>
      </div>
    </div>
  </div>

  <div *ngIf="filteredItems.length === 0" class="text-center m-t-20">
    No results found.
  </div>

  <div class="listing-cards col-12" *ngIf="filteredItems.length > 0">
    <mdb-card class="mt-2" *ngFor="let item of itemsToDisplay$ | async">
      <mdb-card-body (click)="onItemClicked(item)">
        <div class="row">
          <div class="col-sm-12 col-md-7 text-left p-r-0">
            <div class="card-listing-title overflow-ellipsis">
              {{ item.name }}
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
    <span class="float-right font-13 m-t-10">{{ filteredItems.length }} found</span>
  </div>
</div>

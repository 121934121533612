<div class="ca-modal fade-in">
  <div class="ca-dialog" role="document">
    <div class="ca-content auto-height input-modal float-down">
      <div class="ca-header">
        <h5 class="modal-title w-100">Manual Address</h5>
      </div>
      <div class="ca-body" style="overflow: initial;">
        <vpa-amend-manual-address
          [ManualAddressGroup]="modalAddressGroup"
          [isSubmitted]="isSubmitted"
          [isDisplayedInSections]="false"
        ></vpa-amend-manual-address>
      </div>
      <div class="ca-footer">
        <button type="button" class="btn btn-secondary m-r-10" aria-label="Close" (click)="close()" mdbWavesEffect>
          Cancel
        </button>
        <button id="manual-address-submit" type="button" class="btn btn-primary " mdbWavesEffect (click)="save()">
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<ca-responsive-modal
  *ngIf="showModal"
  [showModal]="showModal"
  [hasTitleContent]="true"
  [hasBodyContent]="true"
  [hasFooterContent]="true"
  [isWarningModal]="false"
  [useV2Style]="true"
>
  <div slot="title">
    Vendor name matches a company or trust
  </div>
  <div slot="body">
    <div>CampaignAgent requires a natural person on the VPAPay Agreement.</div>
    <div class="m-t-20">
      Is
      <strong>{{ invalidPersonDetails.invalidName }}</strong>
      a company or trust?
    </div>
  </div>
  <div slot="footer">
    <button type="button" class="btn btn-secondary m-r-10" mdbWavesEffect (click)="closeModal()">No</button>
    <button type="button" id="company-trust-btn" class="btn btn-primary m-r-10" mdbWavesEffect (click)="confirm()">Yes</button>
  </div>
</ca-responsive-modal>

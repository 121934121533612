export class ValidationMessageResources {
  public static emailRequired = 'Email is required';
  public static emailInvalid = 'You must use a valid email address';
  public static passwordRequired = 'Password is required';
  public static mobileInvalid = 'Must be a valid Australian mobile number';
  public static invalidDate = 'Invalid Date';
  public static invalidPattern = 'Invalid pattern';
  public static httpError500 =
    'There seems to be an error, our development team has been alerted. If the issue persists please call 1300 882 622';
  public static contributionNotEquateToTotalAmountError =
    // tslint:disable-next-line: max-line-length
    'There is an outstanding amount, if you are paying by credit card please remember to click the Add Credit Card Payment button above';
}

import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { AlertModalSign } from '../../models/alert-modal-sign';
import { ModalContent } from '../../models/modalContent';
import { ModalContentService } from '../../services/modal-content.service';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'vpa-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent extends BaseComponent implements OnInit {
  content$: Observable<ModalContent> = this.modalService.content$;
  alertModalSign = AlertModalSign;

  constructor(public modalRef: MDBModalRef, private modalService: ModalContentService) {
    super();
  }

  ngOnInit(): void {}

  onSuccess(content: ModalContent): void {
    this.modalRef.hide();
    this.modalService.clearContent();

    if (content.successAction) {
      content.successAction();
    }
  }

  onCancel(content: ModalContent): void {
    this.modalRef.hide();
    this.modalService.clearContent();

    if (content.cancelAction) {
      content.cancelAction();
    }
  }
}

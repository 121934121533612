/// <reference types="@types/googlemaps" />
import { MapsAPILoader } from '@agm/core';
import {} from '@agm/core/services/google-maps-types';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { clone } from 'apps/legacy/src/app/_helpers/clone';
import { getAddressFromAutocompleteResponse } from 'apps/legacy/src/app/_helpers/google-maps-helper';
import { distinctUntilValueChanged } from 'apps/legacy/src/app/_helpers/rxjs-helpers';
import { Address } from 'apps/legacy/src/app/_shared/models';
import { SystemConstants } from 'apps/legacy/src/app/_shared/models/system-constants';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skipWhile } from 'rxjs/operators';
// Declare google ambient
declare var google: any;

@Component({
  selector: 'vpa-manual-address',
  templateUrl: './manual-address.component.html',
  styleUrls: ['./manual-address.component.scss']
})
export class ManualAddressComponent implements OnInit {
  @Input() addressSearchControl: FormControl;
  @Input() addressGroup: FormGroup;
  @Input() isSubmitted: boolean;
  @Input() isV2: boolean;

  @Output() enterManualAddressClicked = new EventEmitter<void>();
  @Output() addressNotSelected = new EventEmitter<void>();
  @Output() addressSelectionChanged = new EventEmitter<Address>();

  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;

  latitude: number;
  longitude: number;
  zoom: number;
  geocoder: google.maps.Geocoder;
  showModal = false;
  shouldShowModalErrors = false;

  componentState: IManualAddressComponentInternalState = {
    hasSelectedAnAddress: false,
    addressFieldLostFocus: false
  };

  componentStateSubject = new BehaviorSubject<IManualAddressComponentInternalState>(this.componentState);
  componentState$: Observable<IManualAddressComponentInternalState> = this.componentStateSubject.asObservable().pipe(
    skipWhile((s) => !s),
    distinctUntilValueChanged()
  );

  hasSelectedAnAddress$: Observable<boolean> = this.componentState$.pipe(
    map((s) => s.hasSelectedAnAddress),
    distinctUntilChanged()
  );

  addressFieldLostFocus$: Observable<boolean> = this.componentState$.pipe(
    map((s) => s.addressFieldLostFocus),
    distinctUntilChanged()
  );

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit() {
    this.configureAutocompleteListener();
  }

  toggleModal(event: Event = null) {
    if (event) {
      event.preventDefault();
    }

    this.showModal = !this.showModal;

    if (event && this.showModal) {
      this.enterManualAddressClicked.emit();
    }
  }

  saveAddress() {
    const address = this.mapFormToAddress();

    this.addressSearchControl.patchValue(address.getFullAddress());
    this.addressSelectionChanged.emit(address);

    this.toggleModal();
  }

  private configureAutocompleteListener() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: 'AU' },
        types: ['geocode']
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          const address = getAddressFromAutocompleteResponse(place);
          this.mapAddressToForm(address);

          this.pushStateUpdate({
            ...this.componentState,
            hasSelectedAnAddress: true,
            addressFieldLostFocus: false
          });

          if (this.addressGroup.invalid) {
            this.shouldShowModalErrors = true;
            this.toggleModal();
            return;
          }

          this.addressSelectionChanged.emit(address);
        });
      });
    });

    this.addressSearchControl.valueChanges.pipe(skipWhile(() => this.componentState.hasSelectedAnAddress === false)).subscribe(() => {
      this.pushStateUpdate({
        ...this.componentState,
        hasSelectedAnAddress: false,
        addressFieldLostFocus: false
      });
    });

    fromEvent<any>(this.searchElementRef.nativeElement, 'blur')
      .pipe(
        skipWhile(() => !this.addressSearchControl.value),
        debounceTime(500),
        map(() => this.onSearchInputBlur())
      )
      .subscribe();
  }

  private onSearchInputBlur() {
    this.pushStateUpdate({
      ...this.componentState,
      addressFieldLostFocus: true
    });

    if (
      !this.isSubmitted &&
      !this.componentState.hasSelectedAnAddress &&
      this.addressSearchControl.valid &&
      this.componentState.addressFieldLostFocus
    ) {
      this.addressNotSelected.emit();
    }
  }

  private mapAddressToForm(address: Address) {
    this.subNumber.patchValue(this.getSubNumber());
    this.streetNumber.patchValue(address.streetNumber);
    this.street.patchValue(address.street);
    this.suburb.patchValue(address.suburb);
    this.state.patchValue(address.state);
    this.postcode.patchValue(address.postcode);
    this.country.patchValue(address.country);
  }

  private mapFormToAddress() {
    return new Address().fromModel({
      subNumber: this.subNumber.value,
      streetNumber: this.streetNumber.value,
      street: this.street.value,
      suburb: this.suburb.value,
      state: this.state.value,
      postcode: this.postcode.value,
      country: SystemConstants.defaultCountry
    });
  }

  private getSubNumber() {
    const subNumber = new RegExp('(.*?)(?=[/-])').exec(this.addressSearchControl.value);
    if (!subNumber) {
      return null;
    }

    return subNumber[0];
  }

  private pushStateUpdate(stateUpdate: IManualAddressComponentInternalState) {
    this.componentState = clone(stateUpdate);
    this.componentStateSubject.next(this.componentState);
  }

  get subNumber() {
    return this.addressGroup.get('subNumber');
  }

  get streetNumber() {
    return this.addressGroup.get('streetNumber');
  }

  get street() {
    return this.addressGroup.get('street');
  }

  get suburb() {
    return this.addressGroup.get('suburb');
  }

  get state() {
    return this.addressGroup.get('state');
  }

  get postcode() {
    return this.addressGroup.get('postcode');
  }

  get country() {
    return this.addressGroup.get('country');
  }

  isValid = (): boolean => !this.addressGroup.invalid;
}

interface IManualAddressComponentInternalState {
  hasSelectedAnAddress: boolean;
  addressFieldLostFocus: boolean;
}

export interface IManualAddressComponentState {
  addressSearchControl: FormControl;
  addressGroup: FormGroup;
  isSubmitted: boolean;
}

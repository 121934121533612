export enum VpaApplicationStatus {
  ApplyInProgress = 'ApplyInProgress',
  WaitingForPayment = 'WaitingForPayment',
  WaitingForSignature = 'WaitingForSignature',
  SignedAndSubmitted = 'SignedAndSubmitted',
  Cancelled = 'Cancelled',
  EmptyTrainingApplicationCreated = 'EmptyTrainingApplicationCreated',
  SignedAwaitingAuthority = 'SignedAwaitingAuthority',
  AwaitingPdfApplication = 'AwaitingPdfApplication',
  Complete = 'Complete'
}

import { Address } from './address';

export enum PayLaterFundingOption {
  OnThe5Th,
  OnThe25Th
}

export class CompanyDetails {
  companyName: string;
  acn: string;
}

export enum PaymentSettlementOption {
  PayNow = 'PayNow',
  PayLater = 'PayLater'
}

export enum VpaPaymentSettlementOption {
  PayNow = 'Pay Now',
  PayLater = 'Pay Later'
}

export class Representee {
  actingAs: string;
  actingOnBehalfOf: string;
  relationshipDescription?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  dateOfBirth?: string;
  address?: string;
  mobile?: string;
  addressModel?: Address;
}

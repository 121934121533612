import { DocumentSourceDomain } from '.';

export class FormFileModel {
  documentId: number;
  fileContent: string;
  fileName: string;
  fileType: string;
  documentSourceDomain: DocumentSourceDomain;
  vpaApplicationId: number;
}

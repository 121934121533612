import {
  HttpEvent, HttpHandler,

  HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';

@Injectable()
export class RefreshJwtInterceptor implements HttpInterceptor {

  constructor(private appStateService: AppStateService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            const newToken = event.headers.get('RefreshJwt');
            if(newToken !== null && newToken !== undefined)
                this.appStateService.saveAuthToken(newToken);
        }
        return event;
      })
    );
  }
}

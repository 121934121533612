import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChequePaymentDetailsOption } from 'apps/legacy/src/app/_shared/models';
import { Observable } from 'rxjs';
import { IContributionPaymentBreakdownComponentState } from '../contribution-payment-breakdown/contribution-payment-breakdown.component';

@Component({
  selector: 'vpa-cheque-payment',
  templateUrl: './cheque-payment.component.html',
  styleUrls: ['./cheque-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChequePaymentComponent implements OnInit {
  @Input() paymentDetailsOption: ChequePaymentDetailsOption;
  @Input() contributionPaymentBreakdown$: Observable<IContributionPaymentBreakdownComponentState>;

  constructor() {}

  ngOnInit(): void {}
}

export enum CreditCardScheme {
  Amex = 'Amex',
  VisaMasterCard = 'VisaMasterCard'
}

export const mapToCreditCardScheme = (creditCardScheme: string): CreditCardScheme => {
  switch (creditCardScheme) {
    case 'Amex':
      return CreditCardScheme.Amex;
    case 'Visa':
    case 'MasterCard':
      return CreditCardScheme.VisaMasterCard;
    default:
      return null;
  }
};

<div class="ca-alert ca-impersonate" role="alert" *ngIf="userIsImpersonating">
  <div class="impersonate-wrapper">
    You are impersonating
    <strong>{{ agencyUserName$ | async }}</strong>
    from
    <strong>{{ agencyGroupName$ | async }}</strong>
    .
    <button class="btn btn-outline" (click)="stop()">STOP</button>
  </div>
</div>

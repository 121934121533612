import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { ApplicationsApiService } from 'apps/legacy/src/app/applications/services';
import { UserEventsService } from 'apps/legacy/src/app/userEvents/services/userEvents.service';
import { floatingPointSafeCalculation, formatWithOrdinalSuffix } from 'apps/legacy/src/app/_helpers/number-helpers';
import { distinctUntilValueChanged } from 'apps/legacy/src/app/_helpers/rxjs-helpers';
import { CreatePaywayCustomerResponse } from 'apps/legacy/src/app/_shared/models/create-payway-customer-response';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, first, map, skipWhile, startWith } from 'rxjs/operators';
import { IContributionPaymentBreakdownComponentState } from '../components/contributions/contribution-payment-breakdown/contribution-payment-breakdown.component';
import { IContributionPaymentMethodComponentState } from '../components/contributions/contribution-payment-method/contribution-payment-method.component';
import { IContributionsComponentState } from '../components/contributions/contributions.component';
import {
  CreditCardProcessorMode,
  ICreditCardFormData,
  ICreditCardState
} from '../components/contributions/credit-card-details/credit-card-details.component';
import { CreditCardWorkflowState } from '../components/contributions/credit-card-details/credit-card-multiple-contributions-modal/credit-card-multiple-contributions-modal.component';
import { PaywayPaymentComponentState } from '../components/contributions/credit-card-details/payway-iframe/payway-iframe.component';
import {
  AutomaticContributionModel,
  CreditCardWorkflowStep,
  FatZebraContributionModel,
  IContribution,
  ICreditCardContribution,
  mapCreditCardFlowText,
  mapCreditCardWorkflowButtonDetails,
  PaymentDetailsOption,
  PaymentDetailsOptionType,
  PaywayContributionModel,
  PaywayPaymentDetailsOption,
  ProblemDetailTypes,
  UpfrontPaymentMethod
} from '../models';
import { FatZebraTokenResponse } from '../models/fat-zebra/fat-zebra-token-response';
import { FatZebraTransaction } from '../models/fat-zebra/fat-zebra-transaction';
import { ValidationMessageResources } from '../models/validation-message-resources';
import { ApplicationModeService } from './application-mode.service';
import { BaseFacadeService, IComponentState } from './base-facade.service';
import { ContributionsFormService } from './contributions-form.service';
import { HeaderNavContentService } from './header-nav-content.service';
import { ModalService } from './modal.service';
import { PaywayService } from './payway.service';

@Injectable({
  providedIn: 'root'
})
export class ContributionsFacadeService extends BaseFacadeService<IContributionsState, IContributionsComponentState> {
  state: IContributionsState = {
    isChequeEnabled: false,
    paymentOptions: [],
    model: null,
    isLoading: false,
    isSubmitted: false,
    isPaywayDisabled: false,
    isPaywayCreditCardInputValid: false,
    paywayErrorMessage: '',
    showCreditCardIframe: false,
    fatZebraIframeUrl: null,
    isByoPayNow: false,
    creditCardErrors: null,
    creditCardWorkflowState: null,
    currentCreditCardWorkflowStep: CreditCardWorkflowStep.SelectNumberOfCards,
    currentCreditCardBeingProcessed: 1,
    showCreditCardModal: false,
    isFormUpdateFromUserInput: false,
    isPaymentMethodCreditCard: false
  };

  private form: FormGroup = null;
  private formSubject: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);
  form$: Observable<FormGroup> = this.formSubject.asObservable();

  private clearFatZebraErrorsSubject: Subject<void> = new Subject();
  clearFatZebraErrors$ = this.clearFatZebraErrorsSubject.asObservable();

  private processFatZebraContributionSubject: Subject<FatZebraContributionModel> = new Subject();
  processFatZebraContribution$ = this.processFatZebraContributionSubject.asObservable();

  currentPaymentDetailsOptionType$: Observable<PaymentDetailsOptionType> = combineLatest([this.state$, this.form$]).pipe(
    skipWhile(([state, _]) => !state.model),
    map(([state, form]) => this.currentPaymentMethod(state, form)),
    distinctUntilChanged()
  );

  paymentDetailsOptions$: Observable<PaymentDetailsOption[]> = this.state$.pipe(
    map((state) => state.model.paymentDetailsOptions),
    distinctUntilValueChanged()
  );

  contributions$: Observable<IContribution[]> = this.state$.pipe(
    map((state) => state.model.contributions),
    distinctUntilValueChanged()
  );
  nominatedContributions$: Observable<IContribution[]> = combineLatest([this.contributions$, this.state$, this.form$]).pipe(
    map(([contributions, state, form]) => this.getContributionsOfType(contributions, this.currentPaymentMethod(state, form))),
    distinctUntilValueChanged()
  );
  isPaywayDisabled$: Observable<boolean> = this.state$.pipe(
    map((state) => state.isPaywayDisabled),
    distinctUntilChanged()
  );
  validationError$: Observable<string> = this.state$.pipe(
    map((state) =>
      state.model.isSubmitted && !this.isValid() ? ValidationMessageResources.contributionNotEquateToTotalAmountError : null
    ),
    distinctUntilChanged()
  );
  isPaymentMethodCreditCard$: Observable<boolean> = this.state$.pipe(
    map((state) => state.isPaymentMethodCreditCard),
    distinctUntilChanged()
  );

  private baseContributionsSummary$: Observable<IContributionPaymentBreakdownComponentState> = combineLatest([
    this.state$,
    this.form$
  ]).pipe(
    map(([state, form]) => ({
      payNowPaymentDueDate: state.model.payNowPaymentDueDate,
      paymentDetailsOptionType: form.get('currentPaymentMethod').value,
      totalAuthorisedAmount: state.model.totalAuthorisedAmount,
      totalAdded: this.calculateTotalAdded(state, form),
      totalSurcharges: this.calculateTotalSurcharges(state, form),
      amountOutstanding: this.calculateAmountOutstanding(state, form),
      offsetSectionSpacing: false,
      shouldPadLeft: false,
      useChargeOnSubmission: state.model.useChargeOnSubmission,
      surchargelessPaymentOptions: this.getSurchargelessPaymentOptions(state),
      isPaymentMethodCreditCard: state.isPaymentMethodCreditCard,
      brandLogoUrl: state.model.brandLogoUrl
    })),
    distinctUntilValueChanged()
  );

  contributionPaymentMethodComponentState$: Observable<IContributionPaymentMethodComponentState> = this.state$.pipe(
    map((state) => ({
      isDisplayedInSections: state.model.isDisplayedInSections,
      agencyProcessedPaymentInformationMessage: state.model.agencyProcessedPaymentInformationMessage,
      agencyProcessedPaymentInformationTitle: state.model.agencyProcessedPaymentInformationTitle,
      showCheque: state.isChequeEnabled,
      paymentOptions: state.paymentOptions,
      isByoPayNow: state.isByoPayNow,
      isCustomSurchargeDisclaimerIsEnabled: state.model.isCustomSurchargeDisclaimerIsEnabled,
      customSurchargeText: state.model.customSurchargeText,
      brandLogoUrl: state.model.brandLogoUrl,
      isPaymentTypeLocked: state.model.contributions.some(
        (c) => (c as ICreditCardContribution)?.hasBeenPaid || (c as FatZebraContributionModel)?.transactionError
      ),
      fullPaymentMethod: state.model.paymentDetailsOptions
        .filter((pdo) => !pdo.canHaveMultipleContributions)
        .map((pdo) => pdo.label)
        .reduce((prev, curr, ind, arr) => {
          if (ind === 0) {
            return curr;
          } else if (ind === arr.length - 1) {
            return `${prev} or ${curr}`;
          } else {
            return `${prev}, ${curr}`;
          }
        }, ''),
      payNowDirectCreditCardType: state.model.payNowDirectCreditCardType,
      creditCardSchemeSurchargeOptions: state.model.creditCardSchemeSurchargeOptions,
      isPaymentMethodCreditCard: state.isPaymentMethodCreditCard
    })),
    distinctUntilValueChanged()
  );

  creditCardContributionsSummaryState$: Observable<IContributionPaymentBreakdownComponentState> = this.baseContributionsSummary$;

  eftContributionsSummaryState$: Observable<IContributionPaymentBreakdownComponentState> = this.baseContributionsSummary$.pipe(
    map((baseContributionsSummary) => ({
      ...baseContributionsSummary,
      totalSurcharges: 0,
      shouldPadLeft: true,
      useChargeOnSubmission: false
    })),
    distinctUntilValueChanged()
  );

  bpayContributionsSummaryState$: Observable<IContributionPaymentBreakdownComponentState> = this.baseContributionsSummary$.pipe(
    map((baseContributionsSummary) => ({
      ...baseContributionsSummary,
      totalSurcharges: 0,
      shouldPadLeft: true,
      useChargeOnSubmission: false
    })),
    distinctUntilValueChanged()
  );

  chequeContributionsSummaryState$: Observable<IContributionPaymentBreakdownComponentState> = this.baseContributionsSummary$.pipe(
    map((baseContributionsSummary) => ({
      ...baseContributionsSummary,
      totalSurcharges: 0,
      shouldPadLeft: true,
      useChargeOnSubmission: false
    })),
    distinctUntilValueChanged()
  );

  creditCardState$: Observable<ICreditCardState> = combineLatest([
    this.state$,
    this.form$,
    this.applicationModeService.isTrainingMode$,
    this.currentPaymentDetailsOptionType$
  ]).pipe(
    map(([state, form, isTrainingMode, paymentDetailsOptionType]) => {
      const amountOutstanding = this.calculateAmountOutstanding(state, form);

      const creditCardErrors = this.getCreditCardErrors(state);

      return {
        showIframe: state.showCreditCardIframe && form.get('creditCardAmountWorkflowType').value === 'one' && amountOutstanding > 0,
        iframeUrl: state.fatZebraIframeUrl,
        isSubmitted: state.isSubmitted,
        creditCardContributions: this.getCurrentPaymentModeContributions(state.model.contributions),
        creditCardErrors,
        showCreditCardInputs: form.get('creditCardAmountWorkflowType').value === 'one',
        showModal: state.showCreditCardModal,
        allowSubmission: state.model.allowSubmission,
        fatZebraErrors: state.model.fatZebraErrors,
        failedFatZebraReferences: state.model.failedFatZebraReferences,
        showUnpaidContributionsNotification:
          !state.showCreditCardModal && form.value.creditCardAmountWorkflowType === 'multiple' && amountOutstanding !== 0,
        creditCardWorkflowState: {
          amountOutstanding,
          paymentDetailsOptionType,
          workflowStep: state.currentCreditCardWorkflowStep,
          currentCardBeingProcessed: state.currentCreditCardBeingProcessed,
          title: this.calculateCreditCardTitle(state.currentCreditCardWorkflowStep, state.currentCreditCardBeingProcessed),
          numberOfCards: form.get('creditCardNumberOfCards').value,
          buttonDetails: mapCreditCardWorkflowButtonDetails(
            state.currentCreditCardWorkflowStep,
            state.isByoPayNow,
            state.currentCreditCardBeingProcessed === 1
          ),
          cardNumberWord: this.calculateCreditCardNumberWord(state.currentCreditCardBeingProcessed),
          iframeUrl: state.fatZebraIframeUrl,
          isSubmitted: state.isSubmitted,
          isTryingToPayMoreThanOutstanding: form.get('currentRequestedAmount').value > this.calculateAmountOutstanding(state, form),
          creditCardErrors,
          showIframe: state.showCreditCardIframe,
          paywayState: this.getPaywayState(state, form, isTrainingMode)
        },
        creditCardProcessorMode: this.calculateCreditCardProcessorMode(this.currentPaymentMethod(state, form)),
        paywayState: this.getPaywayState(state, form, isTrainingMode),
        surchargelessPaymentOptions: this.getSurchargelessPaymentOptions(state),
        creditCardChargeScheduleType: state.model.creditCardChargeScheduleType,
        paymentSubmissionExplanationText: state.model.paymentSubmissionExplanationText,
        paymentSubmissionButtonText: state.model.paymentSubmissionButtonText,
        isByoPayNow: state.model.isByoPayNow,
        creditCardFlowText: mapCreditCardFlowText(state.model.isByoPayNow),
        isPaymentTypeLocked: state.model.contributions.some(
          (c) => (c as ICreditCardContribution)?.hasBeenPaid || (c as FatZebraContributionModel)?.transactionError
        )
      };
    }),
    distinctUntilValueChanged()
  );

  creditCardFormData$: Observable<ICreditCardFormData> = this.form$.pipe(
    map((form) => ({
      form,
      workflowFormData: {
        form,
        currentRequestedAmountControl: form.get('currentRequestedAmount'),
        creditCardNumberOfCardsControl: form.get('creditCardNumberOfCards')
      }
    }))
  );

  fatZebraErrors$: Observable<string[]> = this.state$.pipe(
    map((s) => s.model.fatZebraErrors),
    distinctUntilValueChanged()
  );

  private paywayOptions = {
    publishableApiKey: '',
    style: {
      'div.payway-card': {
        border: '0px',
        margin: '10px 0px',
        'max-width': '375px',
        width: '100% !important',
        'border-radius': '0em',
        'background-color': 'white',
        height: '225px !important'
      },

      '.payway-card label': {
        'font-weight': 400,
        'font-family': '"Roboto", sans-serif',
        'font-size': '15px !important',
        color: '#989898',
        'line-height': '18px',
        'text-transform': 'capitalize !important'
      },
      '.payway-card legend': { color: '#999' },
      '.payway-card select': { height: '37px', width: '3em !important' },
      '.payway-card select option': { color: '#333' },
      '.payway-card input, .payway-card select': {
        'font-family': 'roboto',
        'font-size': '14px',
        color: '#3E3F42',
        'line-height': '22px',
        background: '#fff',
        'margin-top': '5px',
        border: '1px solid #E2E5ED',
        'border-radius': '4px',
        'box-shadow': 'inset 0 1px 2px 0 rgba(102,113,123,0.2)'
      },
      '.payway-card .payway-csc': {
        left: '225px !important',
        right: 'initial !important',
        top: '33px',
        'text-align': 'left',
        width: '100%'
      },
      '.payway-card label.payway-csc': {
        top: '33px',
        'text-align': 'left',
        width: '8em'
      },
      '.payway-card .payway-expiration .payway-year': {
        right: '-30px !important'
      },
      '.payway-card label.payway-csc input': {
        left: '0px !important',
        width: '100%'
      },
      '.payway-card label.payway-number': {
        left: '0px !important'
      },
      '.payway-card label.payway-name': {
        left: '0px !important',
        bottom: '90px !important',
        width: '13em !important'
      },
      '.payway-card label.payway-number input': {
        width: '13em !important'
      },
      '.payway-card label.payway-name input': {
        width: '13em !important'
      },
      '.payway-card input.payway-name,.payway-number-formatted': {
        width: '100% !important'
      },
      '.payway-card .payway-expiration': {
        left: '225px !important',
        top: '110px !important',
        width: '6em'
      },
      '.payway-card label.payway-expiration': {
        width: '6em'
      },
      '.payway-card .payway-expiration .payway-month, .payway-card .payway-expiration .payway-year': {
        width: '3.5em !important'
      },
      '.payway-card .payway-creditcard-expirationseparator': {
        display: 'none'
      },
      '.payway-card .payway-creditcard-testfacility': {
        left: 'initial !important',
        right: '0px !important',
        'font-size': '10px !important'
      }
    },
    tokenMode: 'callback',
    onValid() {
      this.pushStateUpdate((state) => ({
        ...state,
        isPaywayCreditCardInputValid: true
      }));
    },
    onInvalid() {
      this.pushStateUpdate((state) => ({
        ...state,
        isPaywayCreditCardInputValid: false
      }));
    }
  };
  private hasAddedPaywayScript = false;

  // TODO - log events somewhere
  constructor(
    private appStateService: AppStateService,
    private applicationModeService: ApplicationModeService,
    private paywayService: PaywayService,
    private formService: ContributionsFormService,
    private applicationsApiService: ApplicationsApiService,
    private modalService: ModalService,
    private userEventsService: UserEventsService,
    headerNavContentService: HeaderNavContentService
  ) {
    super(headerNavContentService);

    this.paywayOptions.onValid = this.paywayOptions.onValid.bind(this);
    this.paywayOptions.onInvalid = this.paywayOptions.onInvalid.bind(this);
  }

  initialise(model$: Observable<IContributionsComponentState>, updateAutomaticContributionAmounts$: Observable<void>): void {
    combineLatest([model$, this.appStateService.isPayByChequeEnabled$])
      .pipe(first())
      .subscribe(([model, isPayByChequeEnabled]) => {
        this.pushStateUpdate((state) => ({
          ...state,
          model,
          isChequeEnabled: isPayByChequeEnabled,
          isByoPayNow: model.isByoPayNow,
          paymentOptions: model.paymentDetailsOptions.map((pdo) => ({
            paymentMethod: pdo.type,
            displayName: pdo.label
          })),
          showCreditCardModal: false,
          creditCardProcessorMode: CreditCardProcessorMode.FatZebra
        }));

        this.form = this.formService.mapToForm(
          model.initialPaymentDetailsOption,
          this.calculateAmountOutstanding(this.state, this.form),
          this.getCurrentPaymentModeContributions(model.contributions).length
        );
        this.formSubject.next(this.form);
        this.form.valueChanges.pipe(this.unsubOnProcessingCompleted()).subscribe((_) => this.formSubject.next(this.form));

        if (this.state.isPaymentMethodCreditCard && this.form.get('creditCardAmountWorkflowType').value === 'one') {
          this.initialiseCreditCardIframe();
        }

        this.subscribeToPaywayResponse();
        this.subscribeToCreditSelection();
        this.subscribeToCreditCardControls();

        if (updateAutomaticContributionAmounts$) {
          updateAutomaticContributionAmounts$
            .pipe(this.unsubOnProcessingCompleted())
            .subscribe((_) => this.updateAutomaticContributionAmounts());
        }

        model$
          .pipe(
            // There is something weird going on with this when we don't do the deep compare.
            // There are cases where pushing while the incoming model is the same as the current model
            // will cause inconsistencies for some reason.
            filter((m) => JSON.stringify(m) !== JSON.stringify(this.state.model)),
            this.unsubOnProcessingCompleted()
          )
          .subscribe((m) =>
            this.pushStateUpdate((state) => ({
              ...state,
              model: {
                ...m
              }
            }))
          );
      });
  }

  async setupPayway(): Promise<void> {
    const paywayPaymentDetailsOptions = this.state.model.paymentDetailsOptions.find(
      (pdo) => pdo.type === PaymentDetailsOptionType.Payway
    ) as PaywayPaymentDetailsOption;
    if (paywayPaymentDetailsOptions && !this.hasAddedPaywayScript) {
      this.hasAddedPaywayScript = true;
      await this.paywayService.addPaywayScript();
      this.paywayOptions.publishableApiKey = paywayPaymentDetailsOptions.publicKey;
      this.paywayService.createCreditCardFrame(this.paywayOptions);
    }
  }

  getPaywayState(state: IContributionsState, form: FormGroup, isTrainingMode: boolean): PaywayPaymentComponentState {
    const amountOutstanding = this.calculateAmountOutstanding(state, form);
    const currentRequestedAmountControl = form.get('currentRequestedAmount');

    return {
      isTrainingMode,
      amountOutstanding,
      amountRequested: currentRequestedAmountControl.value,
      paywayContributions: this.getPaywayContributions(state.model.contributions),
      isSubmitted: state.isSubmitted,
      isRequestedAmountGreaterThanOutstanding: currentRequestedAmountControl.value > amountOutstanding,
      isPaywayDisabled: state.isPaywayDisabled,
      isPaywayCreditCardInputValid: state.isPaywayCreditCardInputValid,
      paywayErrorMessage: state.paywayErrorMessage,
      isFullyPaid: amountOutstanding === 0,
      useChargeOnSubmission: state.model.useChargeOnSubmission
    };
  }

  getSurchargelessPaymentOptions(state: IContributionsState) {
    return state.model.paymentDetailsOptions
      .filter((pdo) => !pdo.canHaveMultipleContributions)
      .map((pdo) => pdo.label)
      .reduce((prev, curr, ind, arr) => {
        if (ind === 0) {
          return curr;
        } else if (ind === arr.length - 1) {
          return `${prev} or ${curr}`;
        } else {
          return `${prev}, ${curr}`;
        }
      }, '');
  }

  removeContribution(contribution: IContribution): void {
    if ((contribution as PaywayContributionModel).paywayCustomerAggregateId) {
      this.pushStateUpdate((state) => ({
        ...state,
        model: {
          ...state.model,
          contributions: state.model.contributions.filter(
            (c) =>
              (c as PaywayContributionModel).paywayCustomerAggregateId !==
              (contribution as PaywayContributionModel).paywayCustomerAggregateId
          )
        }
      }));
    }

    if ((contribution as FatZebraContributionModel).fatZebraTransactionId) {
      this.pushStateUpdate((state) => ({
        ...state,
        model: {
          ...state.model,
          contributions: state.model.contributions.filter(
            (c) =>
              (c as FatZebraContributionModel).fatZebraTransactionId !== (contribution as FatZebraContributionModel).fatZebraTransactionId
          )
        }
      }));
    }

    if (this.getCurrentPaymentModeContributions(this.state.model.contributions).length === 0) {
      if (this.form.get('creditCardAmountWorkflowType').value === 'one') {
        // this ensure payway initialises since the form value won't change and fire the event that would do it
        this.initialiseCreditCardIframe();
      }
      this.formService.resetCreditCardWorkflowType(this.form);
    }

    this.formService.resetCurrentRequestAmount(this.form, this.calculateAmountOutstanding(this.state, this.form));
  }

  onFatZebraTokenisationSuccess(tokenResponse: FatZebraTokenResponse) {
    this.setIsLoading(true);
    const currentRequestedAmount = this.form.get('currentRequestedAmount').value;

    this.applicationsApiService
      .processCaPayFatZebraTokenPayment(this.state.model.id, {
        ...tokenResponse,
        amount: currentRequestedAmount
      })
      .pipe(
        finalize(() => this.setIsLoading(false)),
        map((res) => {
          const newContribution = this.getFatZebraContributionFromCaPayContributionModel(tokenResponse, res);
          this.pushStateUpdate((state) => ({
            ...state,
            model: {
              ...state.model,
              contributions: [...state.model.contributions, newContribution]
            },
            showCreditCardIframe: false,
            fatZebraIframeUrl: null
          }));
          const amountOutstanding = this.calculateAmountOutstanding(this.state, this.form);
          this.formService.resetCurrentRequestAmount(this.form, amountOutstanding);
          this.pushStateUpdate((state) => ({
            ...state,
            currentCreditCardWorkflowStep:
              amountOutstanding === 0 ? CreditCardWorkflowStep.Complete : CreditCardWorkflowStep.SelectAmountForCard,
            currentCreditCardBeingProcessed: state.currentCreditCardBeingProcessed + 1,
            showCreditCardModal: amountOutstanding !== 0 && newContribution.hasBeenPaid
          }));
        })
      )
      .subscribe(
        () => {},
        (err) => {
          if (err.error?.type === ProblemDetailTypes.FatZebraProcessingError) {
            this.pushStateUpdate((state) => ({
              ...state,
              showCreditCardIframe: false,
              fatZebraIframeUrl: null,
              creditCardErrors: Object.keys(err.error.errors).reduce((acc, val) => [...acc, ...err.error.errors[val]], [])
            }));
          }

          return of();
        }
      );
  }

  onFatZebraAuthorizationApproved(transaction: FatZebraTransaction) {
    const newContribution = this.getFatZebraContributionFromTransaction(transaction);
    this.pushStateUpdate((state) => ({
      ...state,
      model: {
        ...state.model,
        contributions: [...state.model.contributions, newContribution]
      },
      showCreditCardIframe: false,
      fatZebraIframeUrl: null,
      creditCardErrors: null
    }));

    const amountOutstanding = this.calculateAmountOutstanding(this.state, this.form);
    this.formService.resetCurrentRequestAmount(this.form, amountOutstanding);
    this.pushStateUpdate((state) => ({
      ...state,
      currentCreditCardWorkflowStep: amountOutstanding === 0 ? CreditCardWorkflowStep.Complete : CreditCardWorkflowStep.SelectAmountForCard,
      currentCreditCardBeingProcessed: state.currentCreditCardBeingProcessed + 1,
      showCreditCardModal: amountOutstanding !== 0
    }));

    this.processFatZebraContributionSubject.next(newContribution);
  }

  onFatZebraAuthorizationDeclined(cardNumber: string) {
    this.pushStateUpdate((state) => ({
      ...state,
      showCreditCardIframe: false,
      creditCardErrors: [
        `Sorry your credit card with number: ${cardNumber} was declined. Please try again with a different card or choose from one of our alternate payment options.`
      ]
    }));
    this.initialiseCreditCardIframe();
  }

  trySavePayment(): void {
    if (this.form.invalid || this.form.get('currentRequestedAmount').value > this.calculateAmountOutstanding(this.state, this.form)) {
      return;
    }

    this.pushStateUpdate((state) => ({
      ...state,
      paywayErrorMessage: '',
      isPaywayDisabled: true
    }));

    this.paywayService.getPaywayToken(this.state.model.id, this.form.get('currentRequestedAmount').value, this.state.model.sourceDomain);
  }

  onCreditCardNextButtonClicked(): void {
    switch (this.state.currentCreditCardWorkflowStep) {
      case CreditCardWorkflowStep.SelectNumberOfCards:
        if (!this.form.get('creditCardNumberOfCards').valid) {
          return this.setIsSubmitted(true);
        }

        return this.pushStateUpdate((state) => ({
          ...state,
          currentCreditCardWorkflowStep: CreditCardWorkflowStep.SelectAmountForCard,
          isSubmitted: false
        }));

      case CreditCardWorkflowStep.SelectAmountForCard:
        if (
          !this.form.get('currentRequestedAmount').valid ||
          this.form.get('currentRequestedAmount').value > this.calculateAmountOutstanding(this.state, this.form)
        ) {
          return this.setIsSubmitted(true);
        }

        if (this.state.currentCreditCardBeingProcessed !== 1) {
          this.userEventsService.addUserEvent('Application.PayNow.PayWithAnotherCard', this.state.model.cpn);
        }

        if (this.currentPaymentMethod(this.state, this.form) === PaymentDetailsOptionType.Payway) {
          this.pushStateUpdate((state) => ({
            ...state,
            currentCreditCardWorkflowStep: CreditCardWorkflowStep.PayWayIframe,
            isSubmitted: false,
            fatZebraIframeUrl: null
          }));
          this.initialiseCreditCardIframe();

          return;
        } else {
          this.initialiseCreditCardIframe();
          return this.pushStateUpdate((state) => ({
            ...state,
            currentCreditCardWorkflowStep: CreditCardWorkflowStep.FatZebraIframe,
            isSubmitted: false,
            fatZebraIframeUrl: null
          }));
        }
    }
  }

  onCreditCardBackButtonClicked(): void {
    switch (this.state.currentCreditCardWorkflowStep) {
      case CreditCardWorkflowStep.SelectNumberOfCards:
        this.formService.resetCreditCardWorkflowType(this.form);
        this.pushStateUpdate((state) => ({
          ...state,
          showCreditCardModal: false
        }));
        return;

      case CreditCardWorkflowStep.SelectAmountForCard:
        if (this.state.isByoPayNow && this.state.currentCreditCardBeingProcessed !== 1) {
          this.userEventsService.addUserEvent('Application.PayNow.PayRemainderTomorrow', this.state.model.cpn);
        }
        if (this.getCurrentPaymentModeContributions(this.state.model.contributions).length === 0) {
          return this.pushStateUpdate((state) => ({
            ...state,
            currentCreditCardWorkflowStep: CreditCardWorkflowStep.SelectNumberOfCards
          }));
        }

        return this.pushStateUpdate((state) => ({
          ...state,
          showCreditCardModal: false
        }));

      case CreditCardWorkflowStep.FatZebraIframe:
        return this.pushStateUpdate((state) => ({
          ...state,
          currentCreditCardWorkflowStep: CreditCardWorkflowStep.SelectAmountForCard,
          fatZebraIframeUrl: null,
          creditCardErrors: null
        }));

      case CreditCardWorkflowStep.PayWayIframe:
        return this.pushStateUpdate((state) => ({
          ...state,
          currentCreditCardWorkflowStep: CreditCardWorkflowStep.SelectAmountForCard
        }));
    }
  }

  onOpenCreditCardModal(): void {
    this.pushStateUpdate((state) => ({
      ...state,
      showCreditCardModal: true,
      currentCreditCardWorkflowStep: CreditCardWorkflowStep.SelectAmountForCard,
      currentCreditCardCardBeingProcessed: this.getCurrentPaymentModeContributions(state.model.contributions).length + 1
    }));
  }

  onTryAnotherCard(): void {
    this.pushStateUpdate((state) => {
      const validContributions = state.model.contributions.filter(
        (c) =>
          !c.paymentDetailsOptionType ||
          !state.model.failedFatZebraReferences.find((fzr) => fzr.referenceCode === (c as FatZebraContributionModel).fatZebraReferenceCode)
      );

      return {
        ...state,
        showCreditCardModal: true,
        currentCreditCardWorkflowStep: CreditCardWorkflowStep.SelectAmountForCard,
        currentCreditCardCardBeingProcessed: this.getCurrentPaymentModeContributions(validContributions).length + 1,
        model: {
          ...state.model,
          contributions: validContributions
        }
      };
    });

    this.clearFatZebraErrorsSubject.next();
    this.formService.resetCurrentRequestAmount(this.form, this.calculateAmountOutstanding(this.state, this.form));
  }

  isValid = () => this.calculateAmountOutstanding(this.state, this.form) === 0;

  private updateAutomaticContributionAmounts(): void {
    const currentPaymentMethod = this.currentPaymentMethod(this.state, this.form);
    if (
      this.state.model.paymentDetailsOptions.filter((p) => {
        return p.type === currentPaymentMethod && p.canHaveMultipleContributions;
      }).length !== 0
    ) {
      return;
    }

    const newContribution = new AutomaticContributionModel();
    newContribution.amount = this.state.model.totalAuthorisedAmount;
    newContribution.paymentMethod = currentPaymentMethod;
    newContribution.paymentDetailsOptionType = currentPaymentMethod;

    // This filter operation needs to be cleaned up as this won't scale
    this.pushStateUpdate((state) => ({
      ...state,
      model: {
        ...state.model,
        contributions: state.model.contributions
          .filter(
            (c) =>
              c.paymentMethod === UpfrontPaymentMethod[UpfrontPaymentMethod.CreditCard] ||
              c.paymentMethod === UpfrontPaymentMethod[UpfrontPaymentMethod.FatZebra]
          )
          .concat([newContribution])
      }
    }));
  }

  private subscribeToPaywayResponse() {
    this.paywayService.response$.pipe(this.unsubOnProcessingCompleted()).subscribe((res) => {
      if (res.error) {
        this.pushStateUpdate((state) => ({
          ...state,
          creditCardErrors: [res.error],
          isPaywayDisabled: false
        }));
      } else {
        this.pushStateUpdate((state) => ({
          ...state,
          model: {
            ...state.model,
            contributions: state.model.contributions.concat([this.createPaywayContribution(res)])
          },
          showCreditCardIframe: false,
          fatZebraIframeUrl: null,
          creditCardErrors: null,
          isPaywayDisabled: false
        }));

        const amountOutstanding = this.calculateAmountOutstanding(this.state, this.form);
        this.formService.resetCurrentRequestAmount(this.form, amountOutstanding);
        this.pushStateUpdate((state) => ({
          ...state,
          currentCreditCardWorkflowStep:
            amountOutstanding === 0 ? CreditCardWorkflowStep.Complete : CreditCardWorkflowStep.SelectAmountForCard,
          currentCreditCardBeingProcessed: state.currentCreditCardBeingProcessed + 1,
          showCreditCardModal: amountOutstanding !== 0
        }));
      }
    });

    this.paywayService.requestFailed$.subscribe((err) => {
      this.pushStateUpdate((state) => ({
        ...state,
        isLoading: false,
        isPaywayDisabled: false,
        creditCardErrors: err.messages
      }));
      this.initialiseCreditCardIframe();
    });
  }

  private subscribeToCreditSelection(): void {
    this.form
      .get('currentPaymentMethod')
      .valueChanges.pipe(this.unsubOnProcessingCompleted(), startWith(this.form.get('currentPaymentMethod').value))
      .subscribe((paymentMethod) => {
        const currentPaymentDetailsOption = this.state.model.paymentDetailsOptions.find((pdo) => pdo.type === paymentMethod);
        const shouldAddContribution =
          !currentPaymentDetailsOption.canHaveMultipleContributions &&
          !this.hasContributionOfType(this.state, currentPaymentDetailsOption.type);

        let newState = {
          ...this.state,
          isPaymentMethodCreditCard:
            this.calculateCreditCardProcessorMode(currentPaymentDetailsOption.type) !== CreditCardProcessorMode.None
        };

        if (shouldAddContribution) {
          const contribution = new AutomaticContributionModel();
          contribution.amount = this.state.model.totalAuthorisedAmount;
          contribution.paymentMethod = paymentMethod;
          contribution.paymentDetailsOptionType = currentPaymentDetailsOption.type;

          newState = {
            ...newState,
            model: {
              ...newState.model,
              contributions: [...newState.model.contributions, contribution]
            }
          };
        }

        this.pushStateUpdate(() => ({
          ...newState
        }));

        this.formService.resetCurrentRequestAmount(this.form, this.calculateAmountOutstanding(this.state, this.form));

        if (this.state.isPaymentMethodCreditCard) {
          this.initialiseCreditCardIframe();
        }
      });
  }

  private subscribeToCreditCardControls(): void {
    const resetFormUpdateStateAndPerformAction = (workflowType: string, successAction: () => void): void => {
      if (!this.state.isFormUpdateFromUserInput) {
        return this.pushStateUpdate((state) => ({
          ...state,
          isFormUpdateFromUserInput: true
        }));
      }

      if (this.getCurrentPaymentModeContributions(this.state.model.contributions).length === 0) {
        return successAction();
      }

      this.modalService.openCreditCardNumberChangedModal(successAction, () => {
        this.pushStateUpdate((state) => ({
          ...state,
          isFormUpdateFromUserInput: false
        }));
        this.form.patchValue({
          creditCardAmountWorkflowType: workflowType === 'one' ? 'multiple' : 'one'
        });
      });
    };

    this.form
      .get('creditCardAmountWorkflowType')
      .valueChanges.pipe(
        this.unsubOnProcessingCompleted(),
        startWith(this.form.get('creditCardAmountWorkflowType').value),
        distinctUntilChanged(),
        filter((value) => value === 'one' && this.state.isPaymentMethodCreditCard)
      )
      .subscribe((value) =>
        resetFormUpdateStateAndPerformAction(value, () => {
          this.pushStateUpdate((state) => ({
            ...state,
            fatZebraIframeUrl: null,
            creditCardErrors: null,
            model: {
              ...state.model,
              contributions: state.model.contributions.filter(
                (c) =>
                  c.paymentDetailsOptionType !== PaymentDetailsOptionType.FatZebra &&
                  c.paymentDetailsOptionType !== PaymentDetailsOptionType.Payway
              )
            }
          }));

          this.formService.resetCurrentRequestAmount(this.form, this.calculateAmountOutstanding(this.state, this.form));
          this.initialiseCreditCardIframe();
          this.clearFatZebraErrorsSubject.next();
        })
      );

    this.form
      .get('creditCardAmountWorkflowType')
      .valueChanges.pipe(
        this.unsubOnProcessingCompleted(),
        distinctUntilChanged(),
        filter((value) => value === 'multiple' && this.state.isPaymentMethodCreditCard)
      )
      .subscribe((value) =>
        resetFormUpdateStateAndPerformAction(value, () => {
          this.pushStateUpdate((state) => ({
            ...state,
            showCreditCardModal: true,
            creditCardErrors: null,
            model: {
              ...state.model,
              contributions: state.model.contributions.filter(
                (c) =>
                  c.paymentDetailsOptionType !== PaymentDetailsOptionType.FatZebra &&
                  c.paymentDetailsOptionType !== PaymentDetailsOptionType.Payway
              )
            },
            currentCreditCardBeingProcessed: 1,
            currentCreditCardWorkflowStep: CreditCardWorkflowStep.SelectNumberOfCards
          }));
          this.formService.resetCurrentRequestAmount(this.form, this.calculateAmountOutstanding(this.state, this.form));
          this.clearFatZebraErrorsSubject.next();
        })
      );

    this.pushStateUpdate((state) => ({
      ...state,
      isFormUpdateFromUserInput: true
    }));
  }

  private createPaywayContribution(data: CreatePaywayCustomerResponse): PaywayContributionModel {
    const newContribution = new PaywayContributionModel();
    newContribution.amount = this.form.get('currentRequestedAmount').value;
    newContribution.paywayCustomerAggregateId = data.paywayCustomerAggregateId;
    newContribution.paymentMethod = UpfrontPaymentMethod[UpfrontPaymentMethod.CreditCard];
    newContribution.partialCardNumber = data.partialCardNumber;
    newContribution.cardholderName = data.cardholderName;
    newContribution.cardScheme = data.cardScheme;
    newContribution.creditCardSurcharge = data.creditCardSurcharge;
    newContribution.transactionSurcharge = data.creditCardSurcharge;
    newContribution.paymentDetailsOptionType = PaymentDetailsOptionType.Payway;
    return newContribution;
  }

  private currentPaymentMethod = (state: IContributionsState, form: FormGroup): PaymentDetailsOptionType =>
    form ? form.get('currentPaymentMethod').value : this.initialPaymentPaymentMethod(state);

  private initialPaymentPaymentMethod = (state: IContributionsState): PaymentDetailsOptionType => {
    const isBpayAnOption = state.model.paymentDetailsOptions.some((val) => val.type === PaymentDetailsOptionType.Bpay);
    return state.model.contributions.reduce(
      (_, value) => value.paymentDetailsOptionType,
      isBpayAnOption ? PaymentDetailsOptionType.Bpay : state.model.paymentDetailsOptions.find((_, ind) => ind === 0).type
    );
  };

  private calculateTotalAdded = (state: IContributionsState, form: FormGroup) =>
    state.model.contributions
      .filter((c) => c.paymentDetailsOptionType === this.currentPaymentMethod(state, form))
      .reduce((sum, value) => sum + value.amount, 0);

  private calculateTotalSurcharges = (state: IContributionsState, form: FormGroup) =>
    state.model.contributions
      .filter((c) => c.paymentDetailsOptionType === this.currentPaymentMethod(state, form))
      .reduce((prev, curr) => prev + ((curr as PaywayContributionModel).creditCardSurcharge || 0), 0);

  private calculateAmountOutstanding(state: IContributionsState, form: FormGroup) {
    const amountRemaining = state.model.totalAuthorisedAmount - this.calculateTotalAdded(state, form);

    return floatingPointSafeCalculation(amountRemaining);
  }

  private hasContributionOfType = (state: IContributionsState, paymentDetailsOptionType: PaymentDetailsOptionType) =>
    state.model.contributions.some((c) => c.paymentDetailsOptionType === paymentDetailsOptionType);

  private getFatZebraContributionFromTransaction(transaction: FatZebraTransaction): FatZebraContributionModel {
    const currentRequestedAmount = this.form.get('currentRequestedAmount').value;

    return {
      amount: currentRequestedAmount,
      cardholderName: transaction.cardholderName,
      fatZebraCardExpiry: transaction.expiry,
      fatZebraReferenceCode: transaction.referenceCode,
      fatZebraToken: transaction.token,
      fatZebraTransactionId: transaction.transactionId,
      contributionId: null,
      paymentDetailsOptionType: PaymentDetailsOptionType.FatZebra,
      paymentMethod: UpfrontPaymentMethod[UpfrontPaymentMethod.FatZebra],
      partialCardNumber: transaction.partialCardNumber,
      cardScheme: transaction.cardScheme,
      fatZebraTransactionAggregateId: null,
      transactionSurcharge: transaction.amount - currentRequestedAmount,
      creditCardSurcharge: transaction.amount - currentRequestedAmount,
      hasBeenPaid: false,
      transactionError: null,
      canDeleteContribution: false
    };
  }

  private getFatZebraContributionFromCaPayContributionModel(
    tokenResponse: FatZebraTokenResponse,
    caPayContributionModel: FatZebraContributionModel
  ): FatZebraContributionModel {
    return {
      amount: caPayContributionModel.amount,
      cardholderName: tokenResponse.cardholderName,
      fatZebraCardExpiry: tokenResponse.expiry,
      fatZebraReferenceCode: caPayContributionModel.fatZebraReferenceCode,
      fatZebraToken: tokenResponse.token,
      fatZebraTransactionId: null,
      contributionId: caPayContributionModel.contributionId,
      paymentDetailsOptionType: PaymentDetailsOptionType.FatZebra,
      paymentMethod: UpfrontPaymentMethod[UpfrontPaymentMethod.FatZebra],
      partialCardNumber: tokenResponse.partialCardNumber,
      cardScheme: tokenResponse.cardScheme,
      fatZebraTransactionAggregateId: caPayContributionModel.fatZebraTransactionAggregateId,
      transactionSurcharge: caPayContributionModel.transactionSurcharge,
      creditCardSurcharge: caPayContributionModel.creditCardSurcharge,
      hasBeenPaid: caPayContributionModel.hasBeenPaid,
      transactionError: caPayContributionModel.transactionError,
      canDeleteContribution: caPayContributionModel.canDeleteContribution
    };
  }

  private calculateCreditCardTitle = (targetStep: CreditCardWorkflowStep, currentCard: number): string => {
    switch (targetStep) {
      case CreditCardWorkflowStep.SelectNumberOfCards:
        return 'How many cards would you like to pay with?';

      case CreditCardWorkflowStep.SelectAmountForCard:
        return `How much would you like to spend on your ${formatWithOrdinalSuffix(currentCard)} card`;

      case CreditCardWorkflowStep.FatZebraIframe:
      case CreditCardWorkflowStep.PayWayIframe:
        return 'Enter your credit card details for this card';
    }
  };

  private calculateCreditCardNumberWord = (cardNumber: number): string => {
    switch (cardNumber) {
      case 1:
        return 'first';

      case 2:
        return 'second';

      default:
        return 'next';
    }
  };

  private calculateCreditCardProcessorMode = (paymentDetailsOptionType: PaymentDetailsOptionType): CreditCardProcessorMode => {
    switch (paymentDetailsOptionType) {
      case PaymentDetailsOptionType.FatZebra: {
        return CreditCardProcessorMode.FatZebra;
      }

      case PaymentDetailsOptionType.Payway: {
        return CreditCardProcessorMode.PayWay;
      }

      default: {
        return CreditCardProcessorMode.None;
      }
    }
  };

  private initialiseCreditCardIframe() {
    this.setIsLoading(true);

    if (this.currentPaymentMethod(this.state, this.form) === PaymentDetailsOptionType.FatZebra) {
      this.applicationsApiService
        .getFatZebraIframeUrl(this.state.model.id, this.form.get('currentRequestedAmount').value)
        .pipe(
          this.unsubOnProcessingCompleted(),
          finalize(() => this.setIsLoading(false))
        )
        .subscribe((res) => {
          this.pushStateUpdate((state) => ({
            ...state,
            showCreditCardIframe: true,
            fatZebraIframeUrl: res.iframeUrl
          }));
        });
    } else if (this.currentPaymentMethod(this.state, this.form) === PaymentDetailsOptionType.Payway) {
      this.pushStateUpdate((state) => ({
        ...state,
        showCreditCardIframe: true,
        isPaywayCreditCardInputValid: false
      }));
      this.hasAddedPaywayScript = false;
      this.setupPayway();
      this.setIsLoading(false);
    }
  }

  private getContributionsOfType = (contributions: IContribution[], paymentDetailsOptionType: PaymentDetailsOptionType): IContribution[] =>
    contributions.filter((c) => c.paymentDetailsOptionType === paymentDetailsOptionType);

  private getCurrentPaymentModeContributions = (contributions: IContribution[]): IContribution[] =>
    this.getContributionsOfType(contributions, this.currentPaymentMethod(this.state, this.form));

  private getPaywayContributions = (contributions: IContribution[]): IContribution[] =>
    this.getContributionsOfType(contributions, PaymentDetailsOptionType.Payway);

  private getCreditCardErrors(state: IContributionsState): string[] {
    const errors = [
      ...(state.creditCardErrors !== null ? state.creditCardErrors : []),
      ...this.getCurrentPaymentModeContributions(state.model.contributions)
        .filter((c) => 'transactionError' in c)
        .map((c) => c as FatZebraContributionModel)
        .filter((c) => c.transactionError !== null)
        .map((c) => c.transactionError)
    ];

    return errors.length ? errors : null;
  }
}

interface IContributionsState extends IComponentState<IContributionsComponentState> {
  isChequeEnabled: boolean;
  paymentOptions: IPaymentOption[];
  isPaywayDisabled: boolean;
  isPaywayCreditCardInputValid: boolean;
  paywayErrorMessage: string;
  showCreditCardIframe: boolean;
  fatZebraIframeUrl: string;
  creditCardErrors: string[];
  creditCardWorkflowState: CreditCardWorkflowState;
  currentCreditCardWorkflowStep: CreditCardWorkflowStep;
  currentCreditCardBeingProcessed: number;
  showCreditCardModal: boolean;
  isFormUpdateFromUserInput: boolean;
  isByoPayNow: boolean;
  isPaymentMethodCreditCard: boolean;
}

export interface IPaymentOption {
  paymentMethod: string;
  displayName: string;
}

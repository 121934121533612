<div class="container">
  <div class="row breadcrumbs">
    <div
      *ngFor="let breadcrumbItem of visibleBreadcrumbItems"
      class="col"
      [ngClass]="{
        current: breadcrumbItem.isCurrent,
        complete: breadcrumbItem.isComplete
      }"
    >
      <span>{{ breadcrumbItem.label }}</span>
    </div>
  </div>
</div>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from '@ca/shared/app-state';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../_shared/models/global-variables';

@Injectable()
export class ApiBaseUrlInterceptor implements HttpInterceptor {
  private globalExceptionRequestUrls = ['/unelectedVendorErrorReport'];

  constructor(private router: Router, private globals: Globals) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.clearGlobalException(req);

    // If we are specifying an absolute URL, just let it through
    if (req.url.startsWith('http')) {
      return next.handle(req);
    }

    const newRequest = req.clone({
      url: `${AppSettingsService.appSettings.baseApiUrl}/api/${req.url}`
    });
    return next.handle(newRequest).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(['logout']);
          return EMPTY;
        } else {
          return throwError(err);
        }
      })
    );
  }

  clearGlobalException(req: HttpRequest<any>): void {
    const shouldClearException = !this.globalExceptionRequestUrls.some((url) => req.url.includes(url));

    // clear global errors
    if (shouldClearException) {
      this.globals.exception = null;
    }
  }
}

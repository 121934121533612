import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidationMessageResources } from '../validation-message-resources';

export function JointFundingAmountAtLeastOneProvidedValidator(
  marketingAmountFormControlName: string,
  stagingAmountFormControlName: string
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const marketingAmount = parseFloat(control.get(marketingAmountFormControlName).value || 0);
    const stagingAmount = parseFloat(control.get(stagingAmountFormControlName).value || 0);

    return !marketingAmount && !stagingAmount
      ? {
          atLeastOneFundingAmount: {
            message: ValidationMessageResources.atLeastOneFundingAmount
          }
        }
      : null;
  };
}

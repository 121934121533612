export interface PaymentDetailsOption {
  label: string;
  type: PaymentDetailsOptionType;
  canHaveMultipleContributions: boolean;
  isCreditCard: boolean;
}

export enum PaymentDetailsOptionType {
  Eft = 'Eft',
  Bpay = 'Bpay',
  Cheque = 'Cheque',
  Payway = 'Payway',
  FatZebra = 'FatZebra'
}

export interface PaywayPaymentDetailsOption extends PaymentDetailsOption {
  publicKey: string;
}

export interface BpayPaymentDetailsOption extends PaymentDetailsOption {
  billerCode: string;
  paymentReferenceCode: string;
}

export interface ChequePaymentDetailsOption extends PaymentDetailsOption {
  chequeAccountName: string;
  address: string;
  paymentReferenceCode: string;
}

export interface EftPaymentDetailsOption extends PaymentDetailsOption {
  accountName: string;
  accountNumber: string;
  bankName: string;
  bsb: string;
  paymentReferenceCode: string;
}

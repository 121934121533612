<div class="main-nav-toggle" (click)="menuOpen = !menuOpen" *ngIf="!(isCurrentUserAVendor$ | async)">
  <i class="fa fa-bars"></i>
</div>
<div class="ca-page-wrapper" *ngIf="!(isCurrentUserAVendor$ | async)">
  <nav class="ca-sidebar-wrapper slide-left" [hidden]="!menuOpen" style="background: #102845;">
    <div class="ca-sidebar-content">
      <div class="ca-sidebar-logo">
        <img src="/assets/img/new-ca-logo.svg" />
      </div>

      <div class="ca-sidebar-header" *ngIf="!(isInPortalMode$ | async)">
        <div class="user-info">
          <span class="user-name">
            {{ agencyUserName$ | async }}
          </span>
          <span class="user-role">{{ agencyGroupName$ | async }}</span>
        </div>
      </div>

      <div class="row main-nav-mode">
        <div class="col-6 main-nav-mode-item" *ngFor="let mode of appModes$ | async" style="background: inherit; color: inherit;">
          <a class="{{ mode.isActive ? 'active' : '' }}" (click)="setApplicationMode(mode.applicationMode)">
            {{ mode.displayName }}
          </a>
        </div>
      </div>

      <div class="ca-sidebar-menu">
        <ul>
          <li *ngFor="let nav of navItems$ | async" [ngClass]="{ 'sidebar-dropdown active': nav.subNavItems }">
            <a (click)="navigateToItem(nav.link)">
              <i class="{{ nav.icon }}"></i>
              <span class="top-level-menu-item-label" [ngClass]="{ heavy: nav.subNavItems }">{{ nav.name }}</span>
              <span
                *ngIf="nav.badge"
                class="ca-badge float-right mt-1"
                [ngClass]="{ 'blue-bubble': nav.badge.style === 'success', 'red-bubble': nav.badge.style === 'danger' }"
              >
                {{ nav.badge.content }}
              </span>
            </a>
            <div class="sidebar-submenu">
              <ul *ngIf="nav.subNavItems">
                <li *ngFor="let subNav of nav.subNavItems" [ngClass]="{ heavy: subNav.badge?.style === 'danger' && subNav.badge?.content }">
                  <a (click)="navigateToItem(subNav.link)">
                    <span [ngClass]="{ 'sub-nav-name': subNav.badge }">{{ subNav.name }}</span>
                    <span
                      *ngIf="subNav.badge"
                      class="ca-badge float-right"
                      [ngClass]="{ 'blue-bubble': subNav.badge.style === 'success', 'red-bubble': subNav.badge.style === 'danger' }"
                    >
                      {{ subNav.badge.content }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="main-nav-blackout fade-in" (click)="menuOpen = !menuOpen" [hidden]="!menuOpen"></div>
</div>

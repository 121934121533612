import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AgencyGroupType, AppSettingsService, AppStateService, LoginDestination } from '@ca/shared/app-state';
import { ApplicationMode, ApplicationModeService } from '@ca/shared/application-mode';
import { AgencyGroupUserRoles, ApplicationLoginContext, CampaignAgentApplications, RouteResources, UserTypes } from '@ca/shared/models';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, startWith, switchMap } from 'rxjs/operators';
import { ApplicationModeItem } from './application-mode-item';
import { BadgeStyle } from './badge';
import { NavigationItem } from './navigation-item';
import { NavigationItemAction } from './navigation-item-action';
import { NavigationItemConstants } from './navigation-item-constants';
import { NavigationMenuBadgeDataForAgencyGroupResponse } from './navigation-menu-badge-data-for-agency-group-response';
import { NavigationMenuBadgeDataForAgentResponse } from './navigation-menu-badge-data-for-agent-response';

@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  constructor(
    private appStateService: AppStateService,
    private applicationModeService: ApplicationModeService,
    private http: HttpClient,
    @Inject('application') private campaignAgentApplication: CampaignAgentApplications
  ) {}

  private get navObservables(): Observable<[string, ApplicationMode]> {
    return combineLatest([this.appStateService.currentUserType$, this.applicationModeService.applicationMode$]);
  }
  private refreshDataSubject: Subject<void> = new Subject<void>();

  private canSwitchToPortal$: Observable<boolean> = combineLatest([
    this.appStateService.switchableLogins$,
    this.appStateService.assumedUserId$,
    this.appStateService.appState$
  ]).pipe(
    map(([switchableLogins, assumedUserId, appState]) => {
      if (!appState || appState.ApplicationLoginContext === ApplicationLoginContext.Portal) return false;

      const portalUsers =
        switchableLogins &&
        switchableLogins.filter((user) => user.UserId === assumedUserId && user.Destination === LoginDestination.Portal);

      if (!portalUsers) {
        return false;
      }

      return portalUsers.length > 0;
    })
  );

  private canSwitchToAgentApp$: Observable<boolean> = combineLatest([
    this.appStateService.switchableLogins$,
    this.appStateService.assumedUserId$,
    this.appStateService.appState$
  ]).pipe(
    map(([switchableLogins, assumedUserId, appState]) => {
      if (!appState || appState.ApplicationLoginContext === ApplicationLoginContext.AgentApp) return false;

      const appUsers =
        switchableLogins && switchableLogins.filter((user) => user.UserId === assumedUserId && user.Destination === LoginDestination.App);

      if (!appUsers) {
        return false;
      }

      return appUsers.length > 0;
    })
  );

  private canShowSwitchAccount$: Observable<boolean> = combineLatest([
    this.appStateService.switchableLogins$,
    this.appStateService.assumedUserId$,
    this.appStateService.isCurrentUserAnAgencyGroupUser$,
    this.appStateService.isCurrentUserAnAdminUser$,
    this.appStateService.applicationLoginContext$
  ]).pipe(
    map(([switchableLogins, assumedUserId, isCurrentUserAnAgencyGroupUser, isCurrentUserAnAdminUser, applicationLoginContext]) => {
      const currentDestination =
        applicationLoginContext === ApplicationLoginContext.AgentApp ? LoginDestination.App : LoginDestination.Portal;
      const hasAccountToSwitch =
        switchableLogins && switchableLogins.findIndex((l) => l.Destination !== currentDestination || l.UserId !== assumedUserId) !== -1;

      return hasAccountToSwitch && (isCurrentUserAnAgencyGroupUser || isCurrentUserAnAdminUser);
    })
  );

  private setupAgencyAdminFinanceManagementItems(
    menuItems: NavigationItem[],
    roles: string[],
    isByoPayNowEnabled: boolean,
    hasByoBpayUnreconciledEntries: boolean,
    badgeData: NavigationMenuBadgeDataForAgencyGroupResponse,
    applicationMode: ApplicationMode,
    agencyGroupType: AgencyGroupType
  ) {
    const financeSubNavItems = [];

    if (roles.includes(AgencyGroupUserRoles.Finance)) {
      if (hasByoBpayUnreconciledEntries) {
        financeSubNavItems.push({
          name: 'Upload BPAY File',
          link: {
            url: RouteResources.uploadBrfFilePage(),
            isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
            isDifferentAngularAppLink: true,
            campaignAgentApplications: CampaignAgentApplications.Legacy
          }
        });
      }

      if (isByoPayNowEnabled) {
        financeSubNavItems.push({
          name: 'Reconciliation',
          link: {
            url: RouteResources.reconciliation(),
            isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
            isDifferentAngularAppLink: true,
            campaignAgentApplications: CampaignAgentApplications.Legacy
          }
        });

        financeSubNavItems.push({
          name: 'Manage Payments',
          link: {
            url: RouteResources.managePayments(),
            isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
            isDifferentAngularAppLink: true,
            campaignAgentApplications: CampaignAgentApplications.Legacy
          },
          badge: badgeData === null ? null : this.getManagePaymentBadge(badgeData)
        });
      }

      if (agencyGroupType === AgencyGroupType.Sales) {
        financeSubNavItems.push({
          name: 'Upcoming Settlements',
          link: {
            url: RouteResources.casAgencyPaymentReminders(AppSettingsService.appSettings.casUrl),
            isInternalLink: false, // MVC
            campaignAgentApplications: null
          },
          badge:
            badgeData === null
              ? null
              : {
                  content: badgeData.upcomingSettlementCount > 0 ? badgeData.upcomingSettlementCount.toString() : '',
                  style: BadgeStyle.Danger
                }
        });
      }

      if (badgeData?.isLegacyReconciliationReportEnabled) {
        financeSubNavItems.push({
          name: 'Reconciliation Report',
          link: {
            url: RouteResources.casAgencyReconciliationReport(AppSettingsService.appSettings.casUrl),
            isInternalLink: false, // MVC
            campaignAgentApplications: null
          }
        });
      }
    }

    if (
      badgeData?.isAgencyFundingEnabled &&
      roles.includes(AgencyGroupUserRoles.SmartCommissionAdministrator) &&
      AppSettingsService.appSettings.featureFlags.isSmartCommissionEnabled
    ) {
      financeSubNavItems.push({
        name: 'Available SmartCommission Agency Funding',
        link: {
          url: RouteResources.smartCommissionAvailableForFunding(applicationMode),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.Legacy,
          campaignAgentApplications: CampaignAgentApplications.Legacy
        }
      });
      financeSubNavItems.push({
        name: 'Agency Funded SmartCommission Campaigns',
        link: {
          url: RouteResources.smartCommissionFundedCampaigns(applicationMode),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.Legacy,
          campaignAgentApplications: CampaignAgentApplications.Legacy
        }
      });
    }

    if (
      badgeData?.isAgentFundingEnabled &&
      roles.includes(AgencyGroupUserRoles.SmartCommissionAdministrator) &&
      AppSettingsService.appSettings.featureFlags.isSmartCommissionEnabled
    ) {
      financeSubNavItems.push({
        name: 'SmartCommission Management',
        link: {
          url: RouteResources.smartCommissionSoldCampaignsAdminSearchList(applicationMode),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.SmartComm,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.SmartComm,
          campaignAgentApplications: CampaignAgentApplications.SmartComm
        },
        badge:
          badgeData === null
            ? null
            : badgeData.isAuthorisationRequiredForAgentFunding
            ? {
                content:
                  badgeData.agentFundingRequestsAwaitingAuthorisationCount > 0
                    ? badgeData.agentFundingRequestsAwaitingAuthorisationCount.toString()
                    : '',
                style: BadgeStyle.Danger
              }
            : null
      });
    }

    if (financeSubNavItems.length > 0) {
      menuItems.push({
        name: 'Finance Management',
        icon: 'fad fa-money-check-edit-alt',
        isExpandedByDefault: true,
        subNavItems: financeSubNavItems
      });
    }
  }

  private getManagePaymentBadge(badgeData: NavigationMenuBadgeDataForAgencyGroupResponse) {
    if (badgeData.awaitingPaymentApplicationsCount > 0) {
      return {
        content: badgeData.managePaymentsApplicationsCount.toString(),
        style: BadgeStyle.Danger
      };
    }

    if (badgeData.managePaymentsApplicationsCount > 0) {
      return {
        content: badgeData.managePaymentsApplicationsCount.toString(),
        style: BadgeStyle.Info
      };
    }

    return null;
  }

  private createLogoutItem(): NavigationItem {
    return {
      name: 'Log Out',
      icon: 'far fa-sign-out',
      isUserDetailNavigation: true,
      link: {
        url: RouteResources.logout(),
        isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
        isDifferentAngularAppLink: true,
        campaignAgentApplications: CampaignAgentApplications.Legacy
      }
    };
  }

  private createSwitchAccountItem(): NavigationItem {
    return {
      name: 'Switch Account',
      icon: 'far fa-list',
      isUserDetailNavigation: true,
      action: NavigationItemAction.ToggleSwitchableAccountsMenu
    };
  }

  private createApplicationModeItems(currentApplicationMode: ApplicationMode): ApplicationModeItem[] {
    return [
      new ApplicationModeItem(ApplicationMode.Live, 'Live Data', currentApplicationMode === ApplicationMode.Live),
      new ApplicationModeItem(ApplicationMode.Training, 'Training Data', currentApplicationMode === ApplicationMode.Training)
    ];
  }

  private createNavMenuItemsForAdmin(): Observable<NavigationItem[]> {
    return of([
      {
        name: 'Return to CAS',
        icon: 'fas fa-check',
        link: {
          url: RouteResources.casAdminHome(AppSettingsService.appSettings.casUrl),
          isInternalLink: false,
          campaignAgentApplications: null
        }
      },
      this.createLogoutItem()
    ]);
  }

  instantiateApplicationModeItems(): Observable<ApplicationModeItem[]> {
    return this.navObservables.pipe(
      switchMap(([currentUserType, currentApplicationMode]) => {
        switch (currentUserType) {
          case UserTypes.Agent:
            return of(this.createApplicationModeItems(currentApplicationMode));

          case UserTypes.AgencyGroup:
            return this.createApplicationModeItemsForAgencyGroupUser(currentApplicationMode);

          default:
            return of([]);
        }
      })
    );
  }

  instantiateNavigationItems(initalLoad: boolean): Observable<NavigationItem[]> {
    return this.navObservables.pipe(
      switchMap(([currentUserType, currentApplicationMode]) => {
        switch (currentUserType) {
          case UserTypes.Agent:
            return this.createNavMenuItemsForAgent(currentApplicationMode, initalLoad);

          case UserTypes.AgencyGroup:
            return this.appStateService.applicationLoginContext$.pipe(
              first(),
              switchMap((loginContext) =>
                loginContext === ApplicationLoginContext.Portal
                  ? this.createNavMenuItemsForAgencyAdmin(initalLoad)
                  : this.createNavMenuItemsForAgent(currentApplicationMode, initalLoad)
              )
            );

          case UserTypes.Admin:
            return this.createNavMenuItemsForAdmin();

          default:
            return of([this.createLogoutItem()]);
        }
      })
    );
  }

  refreshData(): void {
    this.refreshDataSubject.next();
  }

  onRefreshData$(): Observable<void> {
    return this.refreshDataSubject.asObservable();
  }

  private createApplicationModeItemsForAgencyGroupUser(currentApplicationMode: ApplicationMode): Observable<ApplicationModeItem[]> {
    return this.appStateService.applicationLoginContext$.pipe(
      first(),
      map((applicationLoginContext) => {
        return applicationLoginContext === ApplicationLoginContext.AgentApp ? this.createApplicationModeItems(currentApplicationMode) : [];
      })
    );
  }

  private getInitialBadgeDataForAgent$(): Observable<NavigationMenuBadgeDataForAgentResponse> {
    // lint detects startWith(null) as the wrong function signature, which is deprecated, by lint.
    // typecast null to work around this.
    return this.getBadgeDataForAgent$().pipe(startWith(null as NavigationMenuBadgeDataForAgentResponse));
  }

  private getBadgeDataForAgent$(): Observable<NavigationMenuBadgeDataForAgentResponse> {
    if (AppSettingsService.appSettings.featureFlags.showNewListingsComponent)
      return this.http.get<NavigationMenuBadgeDataForAgentResponse>('vpaApply/navMenuBadgeData/agent/v2');

    return this.http.get<NavigationMenuBadgeDataForAgentResponse>('vpaApply/navMenuBadgeData/agent?OnlyCountVpaApply=false');
  }

  private getInitialBadgeDataForAgencyAdmin$(): Observable<NavigationMenuBadgeDataForAgencyGroupResponse> {
    // lint detects startWith(null) as the wrong function signature, which is deprecated, by lint.
    // typecast null to work around this.
    return this.getBadgeDataForAgencyAdmin$().pipe(startWith(null as NavigationMenuBadgeDataForAgencyGroupResponse));
  }

  private getBadgeDataForAgencyAdmin$(): Observable<NavigationMenuBadgeDataForAgencyGroupResponse> {
    return this.http.get<NavigationMenuBadgeDataForAgencyGroupResponse>('vpaApply/navMenuBadgeData/agencygroup');
  }

  private createNavMenuItemsForAgent(currentApplicationMode: ApplicationMode, initalLoad: boolean): Observable<NavigationItem[]> {
    const appStateItems$ = combineLatest([
      this.appStateService.isByoPayNowEnabled$,
      this.appStateService.switchableLogins$,
      this.appStateService.isAgentSmartCommissionEnabled$
    ]).pipe(
      map(([isByoPayNowEnabled, switchableLogins, isAgentSmartCommissionEnabled]) => ({
        isByoPayNowEnabled,
        switchableLogins,
        isAgentSmartCommissionEnabled
      }))
    );

    return combineLatest([
      initalLoad ? this.getInitialBadgeDataForAgent$() : this.getBadgeDataForAgent$(),
      appStateItems$,
      this.canShowSwitchAccount$,
      this.applicationModeService.applicationMode$,
      this.canSwitchToPortal$
    ]).pipe(
      map(([badgeData, appStateItems, canShowSwitchAccount, applicationMode, canSwitchToPortal]) => {
        const menuItems: NavigationItem[] = [
          {
            name: NavigationItemConstants.MyListingName,
            icon: 'fad fa-list-alt',
            link: {
              url: AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                ? RouteResources.listAllCampaigns(currentApplicationMode)
                : RouteResources.listings(currentApplicationMode),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          },
          {
            name: NavigationItemConstants.InProgressName,
            icon: 'fad fa-sync-alt',
            link: {
              url: AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                ? RouteResources.inProgressApplications(currentApplicationMode)
                : RouteResources.incompleteApplications(currentApplicationMode),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge: badgeData === null ? null : this.getInProgressBadgeForAgent(badgeData)
          }
        ];

        if (appStateItems.isByoPayNowEnabled && !AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
          menuItems.push({
            name: NavigationItemConstants.AwaitingPaymentName,
            icon: 'fas fa-dollar-sign',
            link: {
              url: RouteResources.payNowDirectAwaitingPayment(),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge:
              badgeData === null
                ? null
                : {
                    content: badgeData.awaitingPaymentApplicationsCount.toString(),
                    style: BadgeStyle.Info
                  }
          });
        }

        if (AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
          menuItems.push({
            name: NavigationItemConstants.ActionRequiredName,
            icon: 'fad fa-exclamation-triangle',
            link: {
              url: RouteResources.actionRequiredApplications(currentApplicationMode),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge:
              badgeData === null
                ? null
                : {
                    content: badgeData.actionRequiredCampaignsCount.toString(),
                    style: BadgeStyle.Danger
                  }
          });
        }

        if (AppSettingsService.appSettings.featureFlags.isSmartCommissionEnabled && appStateItems.isAgentSmartCommissionEnabled) {
          menuItems.push({
            name: 'SmartCommission',
            icon: 'fad fa-wallet',
            link: {
              url: RouteResources.smartCommissionAgentSoldCampaigns(applicationMode),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.SmartComm,
              isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.SmartComm,
              campaignAgentApplications: CampaignAgentApplications.SmartComm
            }
          });
        }

        if (AppSettingsService.appSettings.showComponentList) {
          menuItems.push({
            name: `Component List`,
            icon: 'far fa-list-alt',
            link: {
              url: RouteResources.componentList(),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          });
        }

        if (canSwitchToPortal) {
          menuItems.push({
            name: 'Switch to Portal',
            icon: 'far fa-repeat',
            isUserDetailNavigation: true,
            link: {
              url: RouteResources.switchToPortal(),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          });
        }

        if (canShowSwitchAccount) {
          menuItems.push(this.createSwitchAccountItem());
        }

        menuItems.push(this.createLogoutItem());

        return menuItems;
      })
    );
  }

  private createNavMenuItemsForAgencyAdmin(initalLoad: boolean): Observable<NavigationItem[]> {
    const appStateItems$ = combineLatest([
      this.appStateService.roles$,
      this.appStateService.isByoPayNowEnabled$,
      this.appStateService.hasByoBpayUnreconciledEntries$,
      this.appStateService.agencyGroupType$
    ]).pipe(
      map(([roles, isByoPayNowEnabled, hasByoBpayUnreconciledEntries, agencyGroupType]) => ({
        roles,
        isByoPayNowEnabled,
        hasByoBpayUnreconciledEntries,
        agencyGroupType
      }))
    );

    return combineLatest([
      initalLoad ? this.getInitialBadgeDataForAgencyAdmin$() : this.getBadgeDataForAgencyAdmin$(),
      appStateItems$,
      this.canShowSwitchAccount$,
      this.applicationModeService.applicationMode$,
      this.canSwitchToAgentApp$
    ]).pipe(
      map(([badgeData, appStateItems, canShowSwitchAccount, applicationMode, canSwitchToAgentApp]) => {
        const getNavItemsForCampaigns = () => {
          switch (appStateItems.agencyGroupType) {
            case AgencyGroupType.Sales:
              return this.campaignNavItemsForSalesAgency(badgeData);
            case AgencyGroupType.Rentals:
              return this.campaignNavItemsForRentalAgency(badgeData);
            default:
              return this.campaignNavItemsForSalesAgency(badgeData);
          }
        };

        const awaitingVendorPaymentNavItemDetails = (): { Title: string; Index: number } => {
          switch (appStateItems.agencyGroupType) {
            case AgencyGroupType.Sales:
              return { Title: 'Awaiting Vendor Payment', Index: 3 };
            case AgencyGroupType.Rentals:
              return { Title: 'Awaiting Landlord Payment', Index: 1 };
            default:
              return { Title: 'Awaiting Vendor Payment', Index: 3 };
          }
        };

        const menuItems: NavigationItem[] = [
          {
            name: 'Campaigns',
            icon: 'fad fa-home',
            isExpandedByDefault: true,
            subNavItems: [...getNavItemsForCampaigns()]
          }
        ];

        if (appStateItems.isByoPayNowEnabled) {
          const awaitingVendorPaymentDetails = awaitingVendorPaymentNavItemDetails();

          menuItems[0].subNavItems.splice(awaitingVendorPaymentDetails.Index, 0, {
            name: awaitingVendorPaymentDetails.Title,
            link: {
              url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
                ? RouteResources.marketingRouteResources.portalCampaignLists('awaiting-payment')
                : RouteResources.marketingRouteResources.awaitingPaymentApplications(),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
              isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
              campaignAgentApplications: CampaignAgentApplications.VpaPay
            },
            badge:
              badgeData === null
                ? null
                : {
                    content: badgeData.awaitingPaymentApplicationsCount > 0 ? badgeData.awaitingPaymentApplicationsCount.toString() : '',
                    style: BadgeStyle.Danger
                  }
          });
        }

        if (
          AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled &&
          appStateItems.agencyGroupType !== AgencyGroupType.Rentals
        ) {
          menuItems[0].subNavItems.push({
            name: 'Complete',
            link: {
              url: RouteResources.marketingRouteResources.portalCampaignLists('complete'),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
              isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
              campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
                ? CampaignAgentApplications.VpaPay
                : null
            },
            badge:
              badgeData === null
                ? null
                : {
                    content: badgeData.completed > 0 ? badgeData.completed.toString() : '',
                    style: BadgeStyle.Info
                  }
          });
        }

        this.setupAgencyAdminFinanceManagementItems(
          menuItems,
          appStateItems.roles,
          appStateItems.isByoPayNowEnabled,
          appStateItems.hasByoBpayUnreconciledEntries,
          badgeData,
          applicationMode,
          appStateItems.agencyGroupType
        );

        if (canSwitchToAgentApp) {
          menuItems.push({
            name: 'Switch to Agent-App',
            isUserDetailNavigation: true,
            link: {
              url: RouteResources.switchToAgentApp(),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          });
        }

        if (canShowSwitchAccount) {
          menuItems.push(this.createSwitchAccountItem());
        }

        const otherSubNavItems = [];

        otherSubNavItems.push({
          name: 'Users',
          link: {
            url: RouteResources.getListUsersUrl(),
            isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
            isDifferentAngularAppLink: true,
            campaignAgentApplications: CampaignAgentApplications.Legacy
          }
        });

        if (
          AppSettingsService.appSettings.featureFlags.isDownloadAgencyQrCodeEnabled &&
          appStateItems.agencyGroupType !== AgencyGroupType.Rentals
        ) {
          otherSubNavItems.push({
            name: 'Download Payment Resources',
            link: {
              url: RouteResources.marketingRouteResources.downloadAgencyQrCode(),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
              isDifferentAngularAppLink: true,
              campaignAgentApplications: CampaignAgentApplications.VpaPay
            }
          });
        }

        menuItems.push({
          name: 'Other',
          icon: 'fad fa-cogs',
          isExpandedByDefault: true,
          subNavItems: otherSubNavItems
        });

        menuItems.push(this.createLogoutItem());

        return menuItems;
      })
    );
  }

  campaignNavItemsForRentalAgency(badgeData: NavigationMenuBadgeDataForAgencyGroupResponse) {
    return [
      {
        name: 'In Progress',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('in-progress')
            : RouteResources.casAgencyCasAppInProgress(AppSettingsService.appSettings.casUrl),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge:
          badgeData === null
            ? null
            : {
                content: badgeData.casAppInProgress > 0 ? badgeData.casAppInProgress.toString() : '',
                style: BadgeStyle.Info
              }
      },
      {
        name: 'Action Required',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('action-required')
            : RouteResources.marketingRouteResources.actionRequired(),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
          campaignAgentApplications: CampaignAgentApplications.VpaPay
        },
        badge:
          badgeData === null
            ? null
            : {
                content: badgeData.actionRequired > 0 ? badgeData.actionRequired.toString() : '',
                style: BadgeStyle.Danger
              }
      }
    ];
  }

  campaignNavItemsForSalesAgency(badgeData: NavigationMenuBadgeDataForAgencyGroupResponse) {
    return [
      {
        name: 'All',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('all')
            : RouteResources.casAgencyLiveCampaigns(AppSettingsService.appSettings.casUrl),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge:
          badgeData === null
            ? null
            : {
                content: badgeData.live > 0 ? badgeData.live.toString() : '',
                style: BadgeStyle.Info
              }
      },
      {
        name: 'Unsubmitted',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('not-started')
            : RouteResources.casAgencyUnsubmittedCampaigns(AppSettingsService.appSettings.casUrl),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge:
          badgeData === null
            ? null
            : {
                content: badgeData.unsubmitted > 0 ? badgeData.unsubmitted.toString() : '',
                style: BadgeStyle.Info
              }
      },
      {
        name: 'In Progress',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('in-progress')
            : RouteResources.casAgencyCasAppInProgress(AppSettingsService.appSettings.casUrl),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge:
          badgeData === null
            ? null
            : {
                content: badgeData.casAppInProgress > 0 ? badgeData.casAppInProgress.toString() : '',
                style: BadgeStyle.Info
              }
      },
      {
        // DR: This
        name: 'Being Reviewed',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('waiting-for-approval')
            : RouteResources.casAgencyBeingReviewedCampaigns(AppSettingsService.appSettings.casUrl),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge:
          badgeData === null
            ? null
            : {
                content: badgeData.beingReviewed > 0 ? badgeData.beingReviewed.toString() : '',
                style: BadgeStyle.Info
              }
      },
      {
        name: 'Action Required',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('action-required')
            : RouteResources.marketingRouteResources.actionRequired(),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
          campaignAgentApplications: CampaignAgentApplications.VpaPay
        },
        badge:
          badgeData === null
            ? null
            : {
                content: badgeData.actionRequired > 0 ? badgeData.actionRequired.toString() : '',
                style: BadgeStyle.Danger
              }
      },
      {
        name: 'Active',
        link: {
          url: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? RouteResources.marketingRouteResources.portalCampaignLists('active')
            : RouteResources.casAgencyActiveCampaigns(AppSettingsService.appSettings.casUrl),
          isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.VpaPay,
          isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.VpaPay,
          campaignAgentApplications: AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
            ? CampaignAgentApplications.VpaPay
            : null
        },
        badge:
          badgeData === null
            ? null
            : {
                content: badgeData.active > 0 ? badgeData.active.toString() : '',
                style: BadgeStyle.Info
              }
      }
    ];
  }

  createBottomNavMenuItemsForAgent(): Observable<NavigationItem[]> {
    const appStateItems$ = combineLatest([
      this.appStateService.isByoPayNowEnabled$,
      this.appStateService.applicationLoginContext$,
      this.appStateService.isAgentSmartCommissionEnabled$
    ]).pipe(
      map(([isByoPayNowEnabled, applicationLoginContext, isAgentSmartCommissionEnabled]) => ({
        isByoPayNowEnabled,
        applicationLoginContext,
        isAgentSmartCommissionEnabled
      }))
    );

    return combineLatest([
      this.getInitialBadgeDataForAgent$(),
      appStateItems$,
      this.applicationModeService.applicationMode$,
      this.canShowSwitchAccount$,
      this.canSwitchToPortal$
    ]).pipe(
      map(([badgeData, appStateItems, currentApplicationMode, canShowSwitchAccount, canSwitchToPortal]) => {
        let menuItems: NavigationItem[] = [];

        if (appStateItems.applicationLoginContext !== ApplicationLoginContext.AgentApp) {
          return menuItems;
        }

        menuItems = [
          {
            name: NavigationItemConstants.MyListingName,
            icon: 'fad fa-list-alt',
            link: {
              url: AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                ? RouteResources.listAllCampaigns(currentApplicationMode)
                : RouteResources.listings(currentApplicationMode),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          },
          {
            name: NavigationItemConstants.InProgressName,
            icon: 'fad fa-sync-alt',
            link: {
              url: AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                ? RouteResources.inProgressApplications(currentApplicationMode)
                : RouteResources.incompleteApplications(currentApplicationMode),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge: badgeData === null ? null : this.getInProgressBadgeForAgent(badgeData)
          }
        ];

        if (appStateItems.isByoPayNowEnabled && !AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
          menuItems.push({
            name: NavigationItemConstants.AwaitingPaymentName,
            icon: 'fas fa-clock',
            link: {
              url: RouteResources.payNowDirectAwaitingPayment(),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge:
              badgeData === null
                ? null
                : {
                    content: badgeData.awaitingPaymentApplicationsCount.toString(),
                    style: BadgeStyle.Info
                  }
          });
        }

        if (AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
          menuItems.push({
            name: NavigationItemConstants.ActionRequiredName,
            icon: 'fad fa-exclamation-triangle',
            link: {
              url: RouteResources.actionRequiredApplications(currentApplicationMode),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            },
            badge:
              badgeData === null
                ? null
                : {
                    content: badgeData.actionRequiredCampaignsCount.toString(),
                    style: BadgeStyle.Danger
                  }
          });
        }

        if (AppSettingsService.appSettings.featureFlags.isSmartCommissionEnabled && appStateItems.isAgentSmartCommissionEnabled) {
          menuItems.push({
            name: 'More',
            icon: 'fad fa-ellipsis-h',
            action: NavigationItemAction.ToggleShowMoreMenu,
            subNavItems: [
              {
                name: 'SmartCommission',
                icon: 'fad fa-wallet',
                link: {
                  url: RouteResources.smartCommissionAgentSoldCampaigns(currentApplicationMode),
                  isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.SmartComm,
                  isDifferentAngularAppLink: this.campaignAgentApplication !== CampaignAgentApplications.SmartComm,
                  campaignAgentApplications: CampaignAgentApplications.SmartComm
                }
              },
              {
                name: NavigationItemConstants.AccountName,
                icon: 'fad fa-user-circle',
                action: NavigationItemAction.ToggleAccountMenu
              }
            ]
          });
        } else {
          menuItems.push({
            name: NavigationItemConstants.AccountName,
            icon: 'fad fa-user-circle',
            action: NavigationItemAction.ToggleAccountMenu
          });
        }

        if (canSwitchToPortal) {
          menuItems.push({
            name: 'Switch to Portal',
            icon: 'far fa-repeat',
            isUserDetailNavigation: true,
            link: {
              url: RouteResources.switchToPortal(),
              isInternalLink: this.campaignAgentApplication === CampaignAgentApplications.Legacy,
              campaignAgentApplications: CampaignAgentApplications.Legacy
            }
          });
        }

        if (canShowSwitchAccount) {
          menuItems.push(this.createSwitchAccountItem());
        }

        menuItems.push(this.createLogoutItem());

        return menuItems;
      })
    );
  }

  private getInProgressBadgeForAgent(badgeData: NavigationMenuBadgeDataForAgentResponse) {
    if (AppSettingsService.appSettings.featureFlags.showNewListingsComponent) {
      return {
        content: badgeData.inProgressCampaignsCount.toString(),
        style: BadgeStyle.Info
      };
    }

    if (badgeData.dangerIncompleteApplicationsCount > 0) {
      return {
        content: badgeData.dangerIncompleteApplicationsCount.toString(),
        style: BadgeStyle.Danger
      };
    }

    return {
      content: badgeData.incompleteApplicationsCount?.toString(),
      style: BadgeStyle.Info
    };
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppSettingsService } from '@ca/shared/app-state';
import {
  CreditCardChargeScheduleType,
  CreditCardFlowText,
  IContribution,
  PaymentDetailsOptionType
} from 'apps/legacy/src/app/_shared/models';
import { FatZebraTokenResponse } from 'apps/legacy/src/app/_shared/models/fat-zebra/fat-zebra-token-response';
import { FatZebraTransaction } from 'apps/legacy/src/app/_shared/models/fat-zebra/fat-zebra-transaction';
import { Observable } from 'rxjs/internal/Observable';
import { IContributionPaymentBreakdownComponentState } from '../contribution-payment-breakdown/contribution-payment-breakdown.component';
import { FailedFatZebraReference } from '../contributions.component';
import {
  CreditCardWorkflowFormData,
  CreditCardWorkflowState
} from './credit-card-multiple-contributions-modal/credit-card-multiple-contributions-modal.component';
import { PaywayPaymentComponentState } from './payway-iframe/payway-iframe.component';

@Component({
  selector: 'vpa-credit-card-details',
  templateUrl: './credit-card-details.component.html',
  styleUrls: ['./credit-card-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardDetailsComponent implements OnInit {
  @Input() state: ICreditCardState;
  @Input() summaryState$: Observable<IContributionPaymentBreakdownComponentState>;
  @Input() formData: ICreditCardFormData;
  @Output() authorizationApproved: EventEmitter<FatZebraTransaction> = new EventEmitter<FatZebraTransaction>();
  @Output() tokenisationSuccess: EventEmitter<FatZebraTokenResponse> = new EventEmitter<FatZebraTokenResponse>();
  @Output() contributionRemoved = new EventEmitter<IContribution>();
  @Output() authorizationDeclined = new EventEmitter<string>();
  @Output() nextButtonClicked = new EventEmitter<void>();
  @Output() backButtonClicked = new EventEmitter<void>();
  @Output() openModal = new EventEmitter<void>();
  @Output() submit = new EventEmitter<void>();
  @Output() tryAnotherCard = new EventEmitter<void>();
  @Output() savePaywayPayment = new EventEmitter<void>();

  creditCardProcessorModeEnum = CreditCardProcessorMode;
  paymentDetailsOptionType = PaymentDetailsOptionType;

  constructor() {}

  ngOnInit(): void {
    if (window.addEventListener) {
      window.addEventListener('message', this.messageListener);
    }
  }

  messageListener = (event): void => {
    if (event.origin !== AppSettingsService.appSettings.fatZebraOrigin) {
      return;
    }

    // Older browsers will have a query-string style data payload
    // Whereas newer browsers will have an object
    let payload = event.data;
    if (typeof event.data === 'string') {
      if (/\[object/i.test(event.data)) {
        alert('Sorry, it looks like there has been a problem communicating with your browser...'); // Raised if the serialization failed
      }
      // Deserialize into an object
      const pairs = payload.split('&');
      payload = {};
      for (const element of pairs) {
        const kv = element.split('=');
        payload[kv[0]] = kv[1];
      }
    }

    this.handleFatZebraResponse(payload);
  };

  private handleFatZebraResponse(payload: any) {
    if (payload.message !== 'transaction.complete') return;

    payload = payload.data;

    switch (payload.message) {
      case 'Approved':
        this.authorizationApproved.emit({
          amount: payload.amount / 100, // FZ returns the amount as an integer i.e. 200.00 returns 20000
          cardholderName: payload.card_holder,
          expiry: payload.card_expiry,
          referenceCode: payload.reference,
          token: payload.token,
          transactionId: payload.id,
          cardScheme: payload.card_type,
          partialCardNumber: payload.card_number,
          responseCode: payload.r,
          verificationHash: payload.v
        });
        return;
      case 'Declined':
        this.authorizationDeclined.emit(payload.card_number);
        return;
    }

    if (payload.tokenize_only === 'true') {
      this.tokenisationSuccess.emit({
        cardholderName: payload.card_holder,
        expiry: payload.card_expiry,
        cardScheme: payload.card_type,
        partialCardNumber: payload.card_number,
        token: payload.token,
        responseCode: payload.r,
        verificationHash: payload.v
      });
    }
  }

  onContributionRemoved(contribution: IContribution) {
    this.contributionRemoved.emit(contribution);
  }

  onNextButtonClicked(): void {
    this.nextButtonClicked.emit();
  }

  onBackButtonClicked(): void {
    this.backButtonClicked.emit();
  }

  onOpenModalClicked(): void {
    this.openModal.emit();
  }

  onTryAnotherCard(): void {
    this.tryAnotherCard.emit();
  }

  onSubmit(): void {
    this.submit.emit();
  }

  onSavePaywayPayment(): void {
    this.savePaywayPayment.emit();
  }

  get currentRequestedAmountControl() {
    return this.formData.form.get('currentRequestedAmount');
  }

  get currentInfoText() {
    let infoText = '';

    if (this.state.creditCardChargeScheduleType === CreditCardChargeScheduleType.ScheduledForStartDate) {
      infoText = infoText.concat(this.state.paymentSubmissionExplanationText, '. ');
    }
    return infoText.concat('To avoid surcharges, change your payment method to ' + this.state.surchargelessPaymentOptions);
  }
}

export enum CreditCardProcessorMode {
  None,
  FatZebra,
  PayWay
}

export interface ICreditCardState {
  iframeUrl: string;
  isSubmitted: boolean;
  showIframe: boolean;
  creditCardContributions: IContribution[];
  showCreditCardInputs: boolean;
  showModal: boolean;
  creditCardWorkflowState: CreditCardWorkflowState;
  showUnpaidContributionsNotification: boolean;
  allowSubmission: boolean;
  fatZebraErrors: string[];
  failedFatZebraReferences: FailedFatZebraReference[];
  creditCardErrors: string[];
  creditCardProcessorMode: CreditCardProcessorMode;
  paywayState: PaywayPaymentComponentState;
  surchargelessPaymentOptions: string;
  creditCardChargeScheduleType: CreditCardChargeScheduleType;
  paymentSubmissionExplanationText: string;
  paymentSubmissionButtonText: string;
  isByoPayNow: boolean;
  creditCardFlowText: CreditCardFlowText;
  isPaymentTypeLocked: boolean;
}

export interface ICreditCardFormData {
  form: FormGroup;
  workflowFormData: CreditCardWorkflowFormData;
}

export interface CreateErrorSummaryInput {
  showValidationErrors: boolean;
  showNoVendorError: boolean;
  isAnyVendorRepresentatives: boolean;
  isCompanyOnTitle: boolean;
  showApplicationListingDetailErrors: boolean;
  showApplicationFundingDetailErrors: boolean;
  showMissingFileErrors: boolean;
  authorityName: string;
  fileIncludesMarketingSchedule: boolean;
  showRepresentativesErrors: boolean;
  showVendorErrors: boolean;
  showGeneralVendorErrors: boolean;
}

export const defaultCreateErrorSummaryInput: CreateErrorSummaryInput = {
  showValidationErrors: false,
  showNoVendorError: false,
  isAnyVendorRepresentatives: false,
  isCompanyOnTitle: false,
  showApplicationListingDetailErrors: false,
  showApplicationFundingDetailErrors: false,
  showMissingFileErrors: false,
  authorityName: '',
  fileIncludesMarketingSchedule: false,
  showRepresentativesErrors: false,
  showVendorErrors: false,
  showGeneralVendorErrors: false
};

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStateService } from '../app-state.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private appStateService: AppStateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // If the user isn't authenticated, then there is no authToken to add
    if (!this.appStateService.getIsAuthenticated()) return next.handle(req);

    // If the request isn't to one of our API endpoints, adding the authToken is more likely to just break things
    // Whether or not something is determined to be one of our endpoints is based off a couple things:
    // 1 - dev environments will always modify the url to point to localhost (see baseurl interceptor)
    // 2 - for non-dev environments, the request will be to a relative path
    if (!this.isUrlForLocalhostOrRelativePath(req)) return next.handle(req);

    const newRequest = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.appStateService.getAuthToken()}`)
    });

    return next.handle(newRequest);
  }

  private isUrlForLocalhostOrRelativePath = (req: HttpRequest<any>): boolean =>
    req.url.toLowerCase().startsWith('/') || req.url.toLowerCase().includes('localhost');
}

<div class="switch-account-dropdown btn-group" mdbDropdown>
  <button mdbDropdownToggle type="button" class="dropdown-toggle agency-name-btn btn">
    <span class="hide-text">{{ (agencyGroupName$ | async) || 'Your Agency' }}</span>
  </button>

  <div class="dropdown-menu dropdown-primary dropdown-items dropdown-menu-right">
    <div class="dropdown-item agency-name">
      <span class="agency-info">
        <div class="agency-name-main">{{ (agencyGroupName$ | async) || 'Your Agency' }}</div>
        <div class="agency-name-info">{{ agencyUserName$ | async }}</div>
      </span>
    </div>

    <div><a class="dropdown-item" *ngIf="canSwitchToPortal" (click)="switchToPortal(portalUser)">Switch to Portal</a></div>
    <div><a class="dropdown-item" *ngIf="canSwitchToApp" (click)="switchToApp(appUser)">Switch to App</a></div>

    <div *ngIf="canSwitchToCasAdmin || canSwitchToOtherAgencyGroupUsers">
      <div class="dropdown-item switch-account">Switch accounts:</div>
      <div><a class="dropdown-item" *ngIf="canSwitchToCasAdmin" (click)="switchToCasAdmin()">CAS Admin</a></div>
      <div class="divider dropdown-divider" *ngIf="canSwitchToCasAdmin"></div>
      <div *ngIf="canSwitchToOtherAgencyGroupUsers">
        <a class="dropdown-item" *ngFor="let user of otherAgencyGroupUsers" (click)="switchToOtherAgencyGroupUser(user)">
          {{ user.LoginName }} ({{ getDestination(user.Destination) }})
        </a>
      </div>
      <div class="divider dropdown-divider"></div>
    </div>

    <div class="dropdown-item">
      <div class="support">Support</div>
      <div class="dropdown-item-info">{{ supportPhoneNumber }}</div>
      <div class="dropdown-item-info">{{ supportEmailAddress }}</div>
    </div>
  </div>
</div>

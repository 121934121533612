export interface CreditCardFlowText {
  mainLabel: string;
  singleCardOptionText: string;
  multipleCardOptionText: string;
}

export const mapCreditCardFlowText = (isByoPayNow: boolean): CreditCardFlowText =>
  isByoPayNow ? payNowDirectCreditCardText : standardCreditCardFlowText;

export const standardCreditCardFlowText: CreditCardFlowText = {
  mainLabel: 'Would you like to pay with one credit card or multiple?',
  singleCardOptionText: 'One',
  multipleCardOptionText: 'Multiple'
};

export const payNowDirectCreditCardText: CreditCardFlowText = {
  mainLabel: 'Would you like to pay the full balance on one card today?',
  singleCardOptionText: 'Yes',
  multipleCardOptionText: 'No'
};

import { SourceDomain } from './source-domain';

export class CreatePaywayCustomerRequest {
  id: number;
  paymentAmount: number;
  contributionCorrelationId: string;
  singleUseTokenId: string;
  idempotencyKey: string;
  sourceDomain: SourceDomain;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VpaApplicationDocumentsInput } from 'apps/legacy/src/app/applications-shared/models/vpa-application-document-input';
import { FormFileModel } from '../../models/form-file-model';
import { VpaApplicationDocumentModel } from '../../models/vpa-application-document-model';

@Component({
  selector: 'vpa-upload-vpa-application-documents',
  templateUrl: './upload-vpa-application-documents.component.html',
  styleUrls: ['./upload-vpa-application-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadVpaApplicationDocumentsComponent implements OnInit {
  @Input() input: VpaApplicationDocumentsInput;
  @Output() openUploadVpaApplicationDocumentsModalEvent = new EventEmitter();
  @Output() removeFileAtIndex: EventEmitter<number> = new EventEmitter();
  @Output() downloadFileRequested: EventEmitter<FormFileModel> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  openUploadVpaApplicationDocumentsModal(event) {
    event.preventDefault();
    this.openUploadVpaApplicationDocumentsModalEvent.emit();
  }

  removeFile(index: number) {
    this.removeFileAtIndex.emit(index);
  }

  downloadDocument(document: VpaApplicationDocumentModel) {
    this.downloadFileRequested.emit(document.file);
  }

  getErrorDisplay() {
    const documents: string[] = [];

    if (this.input.showSalesAuthorityError) {
      documents.push(this.input.legalTerms.authority);
    }
    if (this.input.showMarketingScheduleError) {
      documents.push('Marketing Schedule');
    }

    return `You need to add a photo of the ${documents.join(' and ')}`;
  }
}

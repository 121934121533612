import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vpa-action-item-responsive',
  templateUrl: './action-item-responsive.component.html',
  styleUrls: ['./action-item-responsive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionItemResponsiveComponent implements OnInit {
  @Input() buttonText: string;
  @Input() iconClass: string;
  @Input() customContainerClass: string;

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onButtonClicked(): void {
    this.buttonClicked.emit();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IContribution, PaywayContributionModel } from 'apps/legacy/src/app/_shared/models';

@Component({
  selector: 'vpa-contributions-summary',
  templateUrl: './contributions-summary.component.html',
  styleUrls: ['./contributions-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContributionsSummaryComponent implements OnInit {
  @Input() contributions: PaywayContributionModel[];
  @Input() useChargeOnSubmission: boolean;
  @Output() contributionRemoved = new EventEmitter<IContribution>();

  constructor() {}

  ngOnInit() {}

  removeContribution(contribution: IContribution): void {
    this.contributionRemoved.emit(contribution);
  }
}

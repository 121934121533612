import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessageResources } from '../validation-message-resources';

export class EmailValidator {
  static validEmail(control: AbstractControl): ValidationErrors {
    return EmailValidator.isEmailValid(control.value)
      ? null
      : {
          invalidEmail: {
            message: ValidationMessageResources.emailInvalid
          }
        };
  }

  static validEmailsString(control: AbstractControl): ValidationErrors {
    if (!control.value || !control.value.trim()) {
      return null;
    }

    const emails = control.value.trim().split('\n');
    return emails.every((e) => EmailValidator.isEmailValid(e))
      ? null
      : {
          invalidEmail: {
            message: ValidationMessageResources.emailInvalid
          }
        };
  }

  private static isEmailValid(email): boolean {
    return new RegExp(/^\w+([-+.'\w])*@\w+([-.\w])*\.\w+([-.\w])*$/).test(email);
  }
}

export function NullableEmailValidator(isRequired = true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!isRequired && (!control.value || !control.value.trim())) {
      return null;
    }

    return EmailValidator.validEmail(control);
  };
}

import { Address } from './address';
import { BaseModel } from './base-model';
import { VendorSignature } from './vendor-signature';

export class Vendor extends BaseModel<Vendor> {
  emailAddress: string;
  firstName: string;
  lastName: string;
  mobile: string;
  isElectedToApply: boolean;
  isDeleted = false;
  representativeActingAs: string = null;
  actingOnBehalfOfVendorName: string;
  vendorImportAggregateId: string;
  vpaApplicationVendorId: number;
  signature: VendorSignature;
  dateOfBirth: Date;
  dateSignatureDocumentLastUploaded: Date;
  dateSignedUsingVendorLinkFlow: Date;
  areTermsAndConditionsAccepted: boolean;
  dateAcceptedIllionTermsAndConditions: Date;
  hasProvidedBankAccounts: boolean;
  address: Address = new Address();

  fromModel(oldModel: Vendor) {
    this.emailAddress = oldModel.emailAddress;
    this.firstName = oldModel.firstName;
    this.lastName = oldModel.lastName;
    this.mobile = oldModel.mobile;
    this.isElectedToApply = oldModel.isElectedToApply;
    this.isDeleted = oldModel.isDeleted;
    this.representativeActingAs = oldModel.representativeActingAs;
    this.actingOnBehalfOfVendorName = oldModel.actingOnBehalfOfVendorName;
    this.vendorImportAggregateId = oldModel.vendorImportAggregateId;
    this.vpaApplicationVendorId = oldModel.vpaApplicationVendorId;
    this.signature = oldModel.signature;
    this.dateOfBirth = oldModel.dateOfBirth;
    this.dateSignatureDocumentLastUploaded = oldModel.dateSignatureDocumentLastUploaded;
    this.dateSignedUsingVendorLinkFlow = oldModel.dateSignedUsingVendorLinkFlow;
    this.areTermsAndConditionsAccepted = oldModel.areTermsAndConditionsAccepted;
    this.dateAcceptedIllionTermsAndConditions = oldModel.dateAcceptedIllionTermsAndConditions;
    this.hasProvidedBankAccounts = oldModel.hasProvidedBankAccounts;

    if (oldModel.address) {
      this.address.fromModel(oldModel.address);
    }

    return this;
  }
}

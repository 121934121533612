<ca-responsive-modal
  *ngIf="content$ | async as content"
  [showModal]="true"
  [hasTitleContent]="true"
  [hasBodyContent]="true"
  [hasFooterContent]="true"
>
  <div slot="title">
    {{ content.title }}
  </div>
  <div slot="body">
    <div class="prompt-modal-message">
      {{ content.message }}
    </div>
    <div class="m-t-10">
      <textarea class="form-control" [formControl]="comments$ | async" rows="4"></textarea>
    </div>
  </div>
  <div slot="footer">
    <button type="button" class="btn btn-secondary m-r-10" mdbWavesEffect (click)="onSuccess(content)">
      {{ content.okButtonWording }}
    </button>
    <button type="button" class="btn btn-primary" mdbWavesEffect (click)="onCancel(content)">
      {{ content.cancelButtonWording }}
    </button>
  </div>
</ca-responsive-modal>

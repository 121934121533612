export abstract class BaseModel<T> {
  constructor() {}

  abstract fromModel(oldModel: T): T;
}

type IConstructor<T> = new () => T;

export function activator<T extends BaseModel<T>>(
  type: IConstructor<BaseModel<T>>,
  oldModel: T
): T {
  const newModel = new type();
  return newModel.fromModel(oldModel);
}

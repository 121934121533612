import { FormGroup } from '@angular/forms';
import { AgentModel } from '@ca/shared/models';
import { Address } from 'apps/legacy/src/app/_shared/models';
import { Agency } from './agency';

export class ApplicationListingDetailsInput {
  constructor(
    form: FormGroup,
    agencies: Agency[],
    isSubmitted: boolean,
    agencyGroupAgents: AgentModel[],
    isCurrentUserAnAgencyGroupUser: boolean,
    showListingAgents: boolean,
    showAddress: boolean,
    address: Address,
    assignedAgentNames: string[]
  ) {
    this.applicationForm = form;
    this.agencies = agencies;
    this.isSubmitted = isSubmitted;
    this.agencyGroupAgents = agencyGroupAgents;
    this.isCurrentUserAnAgencyGroupUser = isCurrentUserAnAgencyGroupUser;
    this.showListingAgents = showListingAgents;
    this.showAddress = showAddress;
    this.address = address;
    this.assignedAgentNames = assignedAgentNames;
  }
  applicationForm: FormGroup;
  agencies: Agency[];
  isSubmitted: boolean;
  agencyGroupAgents: AgentModel[];
  isCurrentUserAnAgencyGroupUser: boolean;
  showListingAgents: boolean;
  showAddress: boolean;
  address: Address;
  assignedAgentNames: string[];
}

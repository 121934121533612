import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { ApplicationRouteService } from '../../services';

@Component({
  selector: 'vpa-select-additional-funding-modal',
  templateUrl: './select-additional-funding-modal.component.html',
  styleUrls: ['./select-additional-funding-modal.component.scss']
})
export class SelectAdditionalFundingModalComponent implements OnInit {
  @Input() isVpaFlexEnabled: boolean;
  @Input() campaignId: number;
  @Input() cpn: string;
  @Input() showPdfApplicationOption: boolean;
  @Output() closeModalEvent = new EventEmitter();
  hasPortalAccess$ = this.appStateService.hasPortalAccess$;

  constructor(private applicationRouteService: ApplicationRouteService, private appStateService: AppStateService) {}

  ngOnInit() {}

  closeModal() {
    this.closeModalEvent.emit();
  }

  createDigitalRequest() {
    this.applicationRouteService.createAdditionalVpaInVpaApply(this.cpn);
  }

  createPdfRequest() {
    this.applicationRouteService.createPDFAdditionalVpaInVpaApply(this.cpn);
  }
}

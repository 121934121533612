<ca-responsive-modal
  *ngIf="input$ | async as input"
  [showModal]="input.showModal"
  [hasTitleContent]="true"
  [hasBodyContent]="true"
  [hasFooterContent]="true"
>
  <div slot="title">
    Upload File
  </div>
  <div slot="body">
    <div>What type of file would you like to upload?</div>
    <div class="row">
      <div class="col-12" [ngClass]="input.authorityDocumentIncludesMarketingSchedule ? 'col-md-9' : 'col-md-6'">
        <div class="m-t-10">
          <mdb-select-2
            id="documentTypeDropdown"
            [formControl]="selectedType"
            placeholder="Select a document type"
            [outline]="true"
            style="margin-bottom: -8px;"
          >
            <mdb-select-option *ngFor="let type of documentsTypes" [value]="type.value">
              {{ type.displayText }}
            </mdb-select-option>
          </mdb-select-2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="choose-file-custom m-t-20">
          <span *ngIf="!input.areFilesBeingProcessed">
            <div class="upload-btn-content">
              <span>
                <i class="fas fa-file-upload m-r-5" style="font-size: 20px;"></i>
                &nbsp;Upload Document&nbsp;
              </span>
            </div>
          </span>
          <span *ngIf="input.areFilesBeingProcessed">
            <i class="far fa-sync-alt fa-spin m-r-5" style="font-size: 18px;"></i>
            Uploading Document ...
          </span>
          <input
            type="file"
            accept="image/*, application/pdf"
            multiple="true"
            id="cameraInput"
            name="cameraInput"
            (change)="onFileSelected($event)"
          />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="input.documentsToBeUploaded.length > 0">
      <div class="col-12">
        <div class="section-title m-t-40">
          Uploaded Files
        </div>
      </div>
      <div class="col-12 col-md-6 fade-in" *ngFor="let document of input.documentsToBeUploaded; let i = index">
        <div class="upload-list">
          <span>
            {{ document.file.fileName }}
          </span>
          <button (click)="removeFile(i)">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div slot="footer">
    <button type="button" class="btn btn-secondary m-r-10" mdbWavesEffect (click)="cancel()">
      Cancel
    </button>
    <button *ngIf="!input.submitDocumentsFromModalDirectly" type="button" class="btn btn-primary m-r-10" mdbWavesEffect (click)="upload()">
      Upload
    </button>
    <button
      *ngIf="input.submitDocumentsFromModalDirectly"
      type="button"
      class="btn btn-primary m-r-10"
      mdbWavesEffect
      (click)="uploadToApi()"
    >
      Upload
    </button>
  </div>
</ca-responsive-modal>

export const formatWithOrdinalSuffix = (num: number) => {
  if ([11, 12, 13].includes(num % 100)) {
    return `${num}th`;
  }

  switch (num % 10) {
    case 1:
      return `${num}st`;

    case 2:
      return `${num}nd`;

    case 3:
      return `${num}rd`;

    default:
      return `${num}th`;
  }
};

export const floatingPointSafeCalculation = (num: number) => {
  // This is needed to handle JS floating point arithmetic
  // https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

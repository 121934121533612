import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'vpa-manual-address-modal',
  templateUrl: './manual-address-modal.component.html',
  styleUrls: ['./manual-address-modal.component.scss']
})
export class ManualAddressModalComponent implements OnInit {
  @Input() modalAddressGroup: FormGroup;
  @Input() shouldShowErrors: boolean;
  @Output() addressSaved = new EventEmitter();
  @Output() modalClosed = new EventEmitter();
  isSubmitted = false;

  constructor() {}

  ngOnInit() {}

  save() {
    this.isSubmitted = true;
    if (this.modalAddressGroup.invalid) {
      return null;
    }

    this.addressSaved.emit();
  }

  close() {
    this.modalClosed.emit();
  }

  get subNumber() {
    return this.modalAddressGroup.get('subNumber');
  }

  get streetNumber() {
    return this.modalAddressGroup.get('streetNumber');
  }

  get street() {
    return this.modalAddressGroup.get('street');
  }

  get suburb() {
    return this.modalAddressGroup.get('suburb');
  }

  get state() {
    return this.modalAddressGroup.get('state');
  }

  get postcode() {
    return this.modalAddressGroup.get('postcode');
  }
}

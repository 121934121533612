import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeWhile } from 'rxjs/operators';
import { ApplicationMode } from './application-mode';

@Injectable({
  providedIn: 'root'
})
export class ApplicationModeService {
  private currentApplicationModeSubject: ReplaySubject<ApplicationMode> = new ReplaySubject();
  readonly applicationMode$ = this.currentApplicationModeSubject.asObservable();
  readonly isLiveMode$ = this.currentApplicationModeSubject.pipe(map((appMode) => appMode === ApplicationMode.Live));
  readonly isTrainingMode$ = this.currentApplicationModeSubject.pipe(map((appMode) => appMode === ApplicationMode.Training));
  allowModeUpdates = true;

  constructor(private location: Location, private router: Router) {
    this.currentApplicationModeSubject.next(this.getApplicationModeByUrl(this.location.path()));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e: NavigationEnd) => this.getApplicationModeByUrl(e.url)),
        distinctUntilChanged(),
        takeWhile(() => this.allowModeUpdates)
      )
      .subscribe((appMode) => this.currentApplicationModeSubject.next(appMode));
  }

  forceApplicationMode(applicationMode: string) {
    this.currentApplicationModeSubject.next(ApplicationMode[applicationMode]);
    this.allowModeUpdates = false;
  }

  private isTrainingModeUrl(url: string) {
    return url.split('/').some((seg) => seg.toLowerCase() === 'training');
  }

  private getApplicationModeByUrl(url: string): ApplicationMode {
    return this.isTrainingModeUrl(url) ? ApplicationMode.Training : ApplicationMode.Live;
  }
}

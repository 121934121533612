<div class="ca-modal fade-in unelected-vendor-error-modal">
  <div class="ca-dialog">
    <div class="ca-content auto-height warning-modal float-down">
      <div class="ca-header">
        <h5 class="modal-title w-100">Application Requires Vendor to Re-sign</h5>
      </div>
      <div class="ca-body">
        <div class="inline-alert">
          <i class="far fa-exclamation-circle"></i>
          <div class="message">
            The following fields have been changed in the application.
            <ul>
              <li *ngFor="let item of triggerResignList">
                {{ item }}
              </li>
            </ul>
            <div>This will require the application to be re-signed by the vendor/s.</div>
            <div class="m-t-10" *ngIf="referenceApplicationFlow && isAgentApplicationFlow">
              'Agent will do it now' has been selected: the vendor needs to re-sign in person with the agent. For the vendor to sign
              remotely, select 'Vendor will do it later' to send them the link.
            </div>
            <div class="m-t-10" *ngIf="referenceApplicationFlow && !isAgentApplicationFlow">
              'Vendor will do it later' has been selected: a link will be sent to the vendor to re-sign. Select 'Agent will do it now' for
              the vendor to re-sign in person with the agent.
            </div>
          </div>
        </div>
      </div>
      <div class="ca-footer">
        <button type="button" class="btn btn-secondary m-r-10" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" mdbWavesEffect (click)="agreeUnsign()">
          Unsign
        </button>
      </div>
    </div>
  </div>
</div>

<div class="action-item-container container" [ngClass]="customContainerClass">
  <div class="d-flex flex-wrap align-items-center">
    <div class="flex-grow-1 main">
      <i class="fas {{ iconClass }}"></i>
      <ng-content></ng-content>
    </div>
    <div>
      <button *ngIf="buttonText" (click)="onButtonClicked()" class="btn btn-sm btn-primary text-nowrap">
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>

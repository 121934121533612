import { AbstractControl } from '@angular/forms';
import { AppSettingsService } from '@ca/shared/app-state';

export function validateApplicantDetails(applicant: AbstractControl, isCompanyOnTitle: boolean, shouldValidateDateOfBirth: boolean = true) {
  const fieldsNeedValidation = ['addressSearchControl', 'email', 'firstName', 'lastName', 'mobile'];

  if (!applicant) {
    return false;
  }

  if (shouldValidateDateOfBirth) {
    fieldsNeedValidation.push('dateOfBirth');
  }

  if (applicant.get('isRepresentative').value) {
    fieldsNeedValidation.push('representations');
  }

  if (isCompanyOnTitle && !AppSettingsService.appSettings.featureFlags.simplifyPndProcess) {
    fieldsNeedValidation.push('companyName');
  }

  return fieldsNeedValidation.every((f) => {
    return applicant.get(f).valid;
  });
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';
import { CaDatePipe } from './ca-date.pipe';
import { GlobalVpaStatusPipe } from './global-vpa-status.pipe';
import { GlobalVpaSubStatusPipe } from './global-vpa-sub-status.pipe';
import { ListNamesPipe } from './list-names.pipe';
import { ReplaceTextPipe } from './replace-text.pipe';
import { SanitisePipe } from './sanitise.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AddressPipe, CaDatePipe, ReplaceTextPipe, SanitisePipe, ListNamesPipe, GlobalVpaSubStatusPipe, GlobalVpaStatusPipe],
  exports: [AddressPipe, CaDatePipe, ReplaceTextPipe, SanitisePipe, ListNamesPipe, GlobalVpaSubStatusPipe, GlobalVpaStatusPipe]
})
export class SharedPipesModule {}

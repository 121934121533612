import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DateFormatOptions } from 'apps/legacy/src/app/_helpers/date-helpers';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vpa-additional-vpa-funding-details',
  templateUrl: './additional-vpa-funding-details.component.html',
  styleUrls: ['./additional-vpa-funding-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalVpaFundingDetailsComponent implements OnInit {
  @Input() additionalVpaForm$: Observable<FormGroup>;
  @Input() isSubmitted$: Observable<boolean>;
  @Input() isPndApplication: boolean;

  requestedAmount$: Observable<AbstractControl>;
  startDate$: Observable<AbstractControl>;

  datePickerOptions: IMyOptions = {
    dateFormat: DateFormatOptions.DDMMYYYY,
    minYear: 1970,
    closeAfterSelect: true
  };

  constructor() {}

  ngOnInit() {
    this.requestedAmount$ = this.additionalVpaForm$.pipe(map((f) => f.get('fundingDetails.requestedAmount')));
    this.startDate$ = this.additionalVpaForm$.pipe(map((f) => f.get('fundingDetails.startDate')));
  }
}

<div [ngClass]="{ v2: useV2Style }">
  <div class="ca-modal fade-in" *ngIf="showModal" [ngClass]="size">
    <div class="ca-dialog">
      <div class="ca-content auto-height float-down" [ngClass]="{ 'warning-modal': isWarningModal }">
        <div class="ca-header" *ngIf="hasTitleContent">
          <h5 class="modal-title w-100"><ng-content select="[slot=title]"></ng-content></h5>
          <ng-content select="[slot=additionalHeader]"></ng-content>
        </div>
        <div class="ca-body" *ngIf="hasBodyContent">
          <div class="inline-alert row">
            <i class="far fa-times-circle col-sm-3" *ngIf="modalType === alertModalSign.Error"></i>
            <i class="far fa-check-circle col-sm-3" *ngIf="modalType === alertModalSign.Success"></i>
            <i class="far fa-exclamation-circle col-sm-3" *ngIf="modalType === alertModalSign.Warning"></i>
            <div class="col" *ngIf="hasBodyContent" [ngClass]="{ 'col-sm-8': modalType !== null }">
              <ng-content select="[slot=body]"></ng-content>
            </div>
          </div>
        </div>
        <div class="ca-footer" *ngIf="hasFooterContent">
          <ng-content select="[slot=footer]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

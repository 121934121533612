import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProblemDetail } from '../models/problem-detail';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private errors: Subject<ProblemDetail> = new Subject();
  constructor() {}

  public onErrors(errors: ProblemDetail): void {
    this.errors.next(errors);
  }

  public clearErrors(): void {
    this.errors.next(null);
  }

  public get errors$(): Observable<ProblemDetail> {
    return this.errors.asObservable();
  }
}

<section class="section-card" [ngClass]="{ 'inline-component-section': !input.isDisplayedInSections }" *ngIf="input">
  <div class="row">
    <div class="col-sm-12">
      <div class="section-title m-b-10">Upload Files</div>
      <div class="section-description">
        Upload any related documentation that has been requested by CampaignAgent Customer Support Team
      </div>
    </div>
  </div>

  <button id="upload-document-button" class="btn btn-primary m-t-15" (click)="openUploadVpaApplicationDocumentsModal($event)">
    <i class="fas fa-file-upload m-r-5" style="font-size: 20px;"></i>
    Upload File
  </button>

  <span
    *ngIf="input.showSalesAuthorityError || input.showMarketingScheduleError"
    class="m-l-10 error-pill text-uppercase font-13 strong-lite"
  >
    {{ getErrorDisplay() }}
  </span>

  <div class="row">
    <div class="col-sm-12" *ngIf="input.allDocuments.length > 0">
      <div class="section-title m-t-40">
        Uploaded Files
      </div>
    </div>
    <div class="col-12 col-lg-4 fade-in" *ngFor="let document of input.allDocuments; let i = index">
      <div class="upload-list">
        <a *ngIf="document.file.documentId" (click)="downloadDocument(document)" class="blue-text">
          {{ document.file.fileName }}
        </a>
        <span *ngIf="!document.file.documentId">
          {{ document.file.fileName }}
        </span>
        <button (click)="removeFile(i)">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</section>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'vpa-sharing-mobile-warning',
  templateUrl: './sharing-mobile-warning.component.html',
  styleUrls: ['./sharing-mobile-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharingMobileWarningComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

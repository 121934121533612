<ng-container *ngIf="showAdditionalProductOffers" [ngSwitch]="depositNowBannerName">
  <ng-container *ngSwitchCase="'Week1-2-A'">
    <div class="BannerContainer week-1-2">
      <div class="row no-gutters">
        <div class="BannerTop col-sm-6">
          <h4>Access up to $500k using the equity in your property.</h4>
          <p class="mb-2">Unlock the equity in your property today, and buy the one you want tomorrow.</p>
        </div>
        <div class="BannerImage col-sm-6">
          <img class="img-fluid" src="https://campaignagent.com.au/mkt/CA-Banners/Graphic.jpg" alt="" />
        </div>
      </div>
      <div class="BannerBody">
        <a class="btn btn-primary btn-orange" target="_blank" [href]="additionalProductInformationUrl">Find out more</a>
      </div>
    </div>
    <div class="m-b-40">
      <input type="checkbox" class="optOutCheckbox" [(ngModel)]="selected" (change)="toggleOptOutOfAdditionalProductOffers(selected)" />
      <label class="mb-0 pl-1">Opt-out from these communications.</label>
      <span>
        For more information on our privacy policy
        <a target="_blank" href="https://campaignagent.com.au/privacy-policy/">click here</a>.
      </span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'Week1-2-B'">
    <div class="BannerContainer week-1-2">
      <div class="row no-gutters">
        <div class="BannerTop col-sm-6">
          <h2>Buy While You Sell.</h2>
          <p class="mb-2">Unlock the equity in your property today, and buy the one you want tomorrow.</p>
        </div>
        <div class="BannerImage col-sm-6">
          <img class="img-fluid" src="https://campaignagent.com.au/mkt/CA-Banners/Graphic.jpg" alt="" />
        </div>
      </div>
      <div class="BannerBody">
        <a class="btn btn-primary btn-orange" target="_blank" [href]="additionalProductInformationUrl">Find out more</a>
      </div>
    </div>
    <div class="m-b-40">
      <input type="checkbox" class="optOutCheckbox" [(ngModel)]="selected" (change)="toggleOptOutOfAdditionalProductOffers(selected)" />
      <label class="mb-0 pl-1">Opt-out from these communications.</label>
      <span>
        For more information on our privacy policy
        <a target="_blank" href="https://campaignagent.com.au/privacy-policy/">click here</a>.
      </span>
    </div>
  </ng-container>
</ng-container>

import { CompanyDetails, Representee } from 'apps/legacy/src/app/applications-shared/models';
import { Address } from './address';
import { PaymentSettlementOption } from './payment-settlement-option';

export class VendorSigningPropertiesModel {
  vpaApplicationVendorId: number;
  fullName: string;
  isCompanyOnTitle: boolean;
  paymentSettlementOption?: PaymentSettlementOption;
  requestedAmount: number;
  chargeCreditCardOnSubmission: boolean;
  payLaterFundingOption: PayLaterFundingOption;
  startDate?: Date;
  payNowDate?: Date;
  payLaterDate?: Date;
  listingAddress: Address;
  companyDetails: CompanyDetails;
  representations: Representee[];
  stagingAmount: number;
}

export enum PayLaterFundingOption {
  OnThe5Th,
  OnThe25Th
}

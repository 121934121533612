import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class NumberValidators {
  static nonZero(control: FormControl): ValidationErrors {
    const error = {
      nonZero: {
        message: 'Invalid input'
      }
    };

    return control.value === 0 ? error : null;
  }

  static positiveNumber(control: FormControl): ValidationErrors {
    const error = {
      positiveNumber: {
        message: 'Value must be greater than zero'
      }
    };

    return control.value > 0 ? null : error;
  }

  static optionalPositiveNumber(control: FormControl): ValidationErrors {
    const error = {
      positiveNumber: {
        message: 'Value must be greater than zero'
      }
    };

    return NumberValidators.isEmpty(control.value) || control.value > 0 ? null : error;
  }

  static optionalPositiveNumberWithZeroRegardedValid(control: FormControl): ValidationErrors {
    const error = {
      positiveNumber: {
        message: 'Value must be greater than zero'
      }
    };

    return NumberValidators.isEmpty(control.value) || control.value >= 0 ? null : error;
  }

  static twoDecimalPlaces(control: FormControl): ValidationErrors {
    const error = {
      twoDecimalPlaces: {
        message: 'Value can have at most two decimal places'
      }
    };

    const pattern = /^\d*(\.\d{1,2})?$/;
    return NumberValidators.isEmpty(control.value) || pattern.test(control.value) ? null : error;
  }

  static isEmpty(value: any): boolean {
    return value == null || value.toString().trim().length === 0;
  }
}

export function MinMaxValidator(prms: any = {}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const val: number = control.value;

    if (isNaN(val)) {
      return {
        numberType: {
          message: 'Value must be a number'
        }
      };
    } else if (!isNaN(prms.min) && !isNaN(prms.max)) {
      return val < prms.min || val > prms.max
        ? {
            numberRange: {
              message: `Value should be between ${prms.min} and ${prms.max}`
            }
          }
        : null;
    } else if (!isNaN(prms.min)) {
      return val < prms.min
        ? {
            numberRange: {
              message: `Value should be no less than ${prms.min}`
            }
          }
        : null;
    } else if (!isNaN(prms.max)) {
      return val > prms.max
        ? {
            numberRange: {
              message: `Value should be no larger than ${prms.max}`
            }
          }
        : null;
    } else if (!isNaN(prms.minOrEqual)) {
      return val <= prms.minOrEqual
        ? {
            numberRange: {
              message: `Value should be larger than ${prms.minOrEqual}`
            }
          }
        : null;
    } else if (!isNaN(prms.maxOrEqual)) {
      return val >= prms.maxOrEqual
        ? {
            numberRange: {
              message: `Value should be less than ${prms.maxOrEqual}`
            }
          }
        : null;
    } else {
      return null;
    }
  };
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[vpaMaxTwoDigitalDecimal]'
})
// https://stackoverflow.com/questions/50722368/limit-input-field-to-two-decimal-places-angular-5/55072025
export class MaxTwoDigitalDecimalDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next = current + (event.key === 'Decimal' ? '.' : event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

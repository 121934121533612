<section class="section-card" *ngIf="!rentalMaintenanceState.isForRentalMaintenance">
  <p class="title">
    What to expect
  </p>
  <div class="progress-tracker">
    <ul class="status-line">
      <li *ngFor="let item of timelineItems" [class.active]="item.isActive">
        <div>
          <span class="status">{{ item.label }}</span>
          <span class="date">{{ item.formattedDate }}</span>
        </div>
      </li>
    </ul>
  </div>
  <div class="row timeline-note">
    <div class="col-12 font-14">
      <p>
        <strong>Payment is due the earlier of:</strong>
        The date the deposit is released. The settlement date. The Pay Later date ({{ payLaterPaymentDueDate | date: 'd MMMM y' }}). The
        date the property is withdrawn from sale. The date of termination or expiry of sales agreement.
      </p>
    </div>
  </div>
</section>

<section class="section-card" *ngIf="rentalMaintenanceState.isForRentalMaintenance">
  <p class="title" style="margin-bottom: 0px;">
    What to expect
  </p>
  <div class="row m-b-10" style="font-size: 14px;" *ngIf="rentalMaintenanceState.isDirectDebit">
    <div class="col-12">
      <em class="desc"
        >The total amount will automatically be deducted via Direct Debit over <strong>2</strong> easy installments of
        <strong>{{ rentalMaintenanceState.installmentAmount | currency }}</strong
        >, each month</em
      >
    </div>
  </div>
  <div class="pm-progress-tracker" *ngIf="rentalMaintenanceState.isDirectDebit">
    <ul class="status-line">
      <li>
        <div>
          <span class="status">Start Date</span>
          <span class="date">{{ rentalMaintenanceState.today | caDate }}</span>
        </div>
      </li>
      <li class="active">
        <div>
          <span class="status">Agreement Signed</span>
          <span class="date">{{ rentalMaintenanceState.today | caDate }}</span>
        </div>
      </li>
      <li>
        <div>
          <span class="status">1st Installment</span>
          <span class="date">Sep 10, 2021</span>
        </div>
      </li>
      <li>
        <div>
          <span class="status">2nd Installment</span>
          <span class="date">Oct 10, 2021</span>
        </div>
      </li>
    </ul>
  </div>
  <div class="row m-b-10" style="font-size: 14px;" *ngIf="!rentalMaintenanceState.isDirectDebit">
    <div class="col-12">
      <em class="desc"
        >The total amount will automatically be deducted from your rental earnings in <strong>2</strong> easy installments of
        <strong>{{ rentalMaintenanceState.installmentAmount | currency }}</strong></em
      >
    </div>
  </div>
  <div class="pm-progress-tracker" *ngIf="!rentalMaintenanceState.isDirectDebit">
    <ul class="status-line">
      <li>
        <div>
          <span class="status">Start Date</span>
          <span class="date">{{ rentalMaintenanceState.today | caDate }}</span>
        </div>
      </li>
      <li class="active">
        <div>
          <span class="status">Agreement Signed</span>
          <span class="date">{{ rentalMaintenanceState.today | caDate }}</span>
        </div>
      </li>
      <li>
        <div>
          <span class="status">1st Installment</span>
          <span class="date">Sep 10, 2021</span>
        </div>
      </li>
      <li>
        <div>
          <span class="status">2nd Installment</span>
          <span class="date">Oct 10, 2021</span>
        </div>
      </li>
    </ul>
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { UserEventsService } from 'apps/legacy/src/app/userEvents/services/userEvents.service';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ApplicationLoginContext } from '../../models';
import { LoginDestination } from '../../models/login-destination';
import { ApplicationRouteService } from '../../services';
import { SwitchAccountService } from '../../services/switch-account.service';

@Component({
  template: ''
})
export class AgentAppRedirectComponent implements OnInit {
  constructor(
    private appStateService: AppStateService,
    private applicationRouteService: ApplicationRouteService,
    private switchAccountService: SwitchAccountService,
    private userEventsService: UserEventsService
  ) {}

  ngOnInit() {
    this.userEventsService.addUserEvent('Shared.AgentAppRedirect');

    combineLatest([
      this.appStateService.getSwitchableLogins$(),
      this.appStateService.old_assumedUserId$,
      this.appStateService.applicationLoginContext$
    ])
      .pipe(
        first(),
        map(([switchableLogins, assumedUserId, applicationLoginContext]) => {
          if (applicationLoginContext === ApplicationLoginContext.AgentApp) return;

          const appUsers =
            switchableLogins &&
            switchableLogins.filter((user) => user.UserId === assumedUserId && user.Destination === LoginDestination.App);

          if (!appUsers || appUsers.length <= 0) {
            return;
          }

          this.switchAccountService.getSwitchUserToken(appUsers[0].UserId, appUsers[0].Destination).subscribe((res) => {
            this.switchAccountService.switchToApp(res.token).subscribe((tokenResponse) => {
              this.applicationRouteService.navigateToCasLoginToken(tokenResponse.token);
            });
          });
        })
      )
      .subscribe();
  }
}

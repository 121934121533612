import { SystemConstants } from './system-constants';

export interface Address {
  subNumber: string;
  streetNumber: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export class AddressModel implements Address {
  subNumber: string;
  streetNumber: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;

  constructor(
    subNumber = '',
    streetNumber = '',
    street = '',
    suburb = '',
    state = '',
    postcode = '',
    country = SystemConstants.defaultCountry
  ) {
    this.subNumber = subNumber;
    this.streetNumber = streetNumber;
    this.street = street;
    this.suburb = suburb;
    this.state = state;
    this.postcode = postcode;
    this.country = country;
  }
}

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { CreateApplicationModel } from 'apps/legacy/src/app/applications-shared/models';
import { validateApplicantDetails } from 'apps/legacy/src/app/_helpers/validate-applicant-details-helpers';
import { Address } from 'apps/legacy/src/app/_shared/models';
import { SystemConstants } from 'apps/legacy/src/app/_shared/models/system-constants';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CreateAndAmendVendorFormService } from './create-and-amend-vendor-form-service';

@Injectable({
  providedIn: 'root'
})
export class CreateApplicationsFormService extends CreateAndAmendVendorFormService {
  constructor(protected formBuilder: FormBuilder, protected datePipe: DatePipe, protected appStateService: AppStateService) {
    super(formBuilder, datePipe, appStateService);
  }

  areFundingDetailsValid(form: FormGroup): boolean {
    return (form.get('applicationFundingDetails') as FormGroup).valid;
  }

  areListingDetailsValid(form: FormGroup): boolean {
    const listingDetails = form.get('applicationListingDetails') as FormGroup;

    if (!listingDetails) {
      return true;
    }

    return listingDetails.valid;
  }

  areRegularVendorsValid(form: FormGroup): boolean {
    return this.filterRegularVendors(form).every((v) => {
      return validateApplicantDetails(v, form.get('vendorQuestions.isCompanyOnTitle').value);
    });
  }

  areRepresentativesValid(form: FormGroup): boolean {
    return this.filterRepresentatives(form).every((v) => {
      return validateApplicantDetails(v, form.get('vendorQuestions.isCompanyOnTitle').value);
    });
  }

  filterVendors(form: FormGroup): FormArray {
    return form.get('vendors') as FormArray;
  }

  isAnyVendors(form: FormGroup): boolean {
    if (form.get('vendorQuestions.isAnyVendorRepresentatives').value) {
      return this.filterRepresentatives(form).length > 0;
    } else {
      return this.filterRegularVendors(form).length > 0;
    }
  }

  isFormValid(form: FormGroup, validateVendors: boolean): boolean {
    if (!this.areFundingDetailsValid(form)) {
      return false;
    }

    if (!this.areListingDetailsValid(form)) {
      return false;
    }

    if (!validateVendors) {
      return true;
    }

    if (form.get('vendorQuestions.isAnyVendorRepresentatives').value && !this.areRepresentativesValid(form)) {
      return false;
    }

    if (!this.areRegularVendorsValid(form)) {
      return false;
    }

    if (!this.isAnyVendors(form)) {
      return false;
    }

    return true;
  }

  mapCreateModelToForm(
    application: CreateApplicationModel,
    isCurrentUserAnAgencyGroupUser: boolean,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ): FormGroup {
    const applicationForm = this.formBuilder.group({
      ...this.mapAgencyId(application),
      ...this.mapFundingDetails(application, true, false, null, application.vpaApplicationStatus),
      ...this.mapCreateVendorDetails(application.vendors, application.address, false, morePowerOfAttorneyFieldsForClassicVpaPay),
      ...this.mapSalesAuthoritySettings(application, application.forceAllowingAuthorityToBeUploadedLater)
    });

    if (!application.isFromCrmListing || application.hasNoListingAgents) {
      applicationForm.addControl(
        'applicationListingDetails',
        this.mapApplicationListingDetails(
          application.address,
          application.agencyId,
          application.listinglessApplicationAgentImportAggregateId,
          isCurrentUserAnAgencyGroupUser
        )
      );
    }

    applicationForm.addControl(
      'vendorQuestions',
      this.mapCreateQuestions(application, application.applicationFlow, application.forceVpaApplicationStartedBy)
    );

    return applicationForm;
  }

  mapCreateFormToModel(
    application: CreateApplicationModel,
    form: FormGroup,
    isCurrentUserAnAgencyGroupUser: boolean,
    isPndFlow: boolean,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ): void {
    this.mapCreateQuestionsToModel(application, form);
    this.mapListingDetailsToModel(application, form, isCurrentUserAnAgencyGroupUser);
    this.mapFundingDetailsToModel(application, form);

    // When pages loads with empty vendor and vendor flow, there will be an empty vendor displaying.
    // For non-PND applications, if the application is submitted in agent flow, this empty vendor shouldn't
    // be mapped to the model.
    if (isPndFlow || !form.value.vendorQuestions?.isAgentApplicationFlow) {
      this.mapCreateApplicantsToModel(application, form, morePowerOfAttorneyFieldsForClassicVpaPay);
    }

    if (isPndFlow) {
      application.shouldSendInviteToNominatedVendor = form.get('shouldSendInviteToNominatedVendor').value;
    }

    // We need to handle signing properties checks regardless of if it is agent or vendor flow
    this.mapVendorSigningProperties(application, form, morePowerOfAttorneyFieldsForClassicVpaPay);

    this.mapSalesAuthoritySettingsToModel(application, form);

    application.morePowerOfAttorneyFieldsForClassicVpaPay = morePowerOfAttorneyFieldsForClassicVpaPay;
  }

  resetFormToInitialState(form: FormGroup, application: CreateApplicationModel, isCurrentUserAnAgencyGroupUser: boolean): void {
    const newForm = this.mapCreateModelToForm(application, isCurrentUserAnAgencyGroupUser);
    form.patchValue(newForm.value);
  }

  isApplicationInVendorFlow(form: FormGroup): boolean {
    return !form.value.vendorQuestions?.isAgentApplicationFlow;
  }

  hasRepresentatives$ = (f$: Observable<FormGroup>): Observable<boolean> =>
    f$.pipe(
      map((f) => f.get('vendorQuestions.isAnyVendorRepresentatives').value),
      distinctUntilChanged()
    );

  agencyIdControl = (f: FormGroup) => f.get('agencyId');
  startDateControl = (f: FormGroup) => f.get('applicationFundingDetails.startDate');
  requestedAmountControl = (f: FormGroup) => f.get('applicationFundingDetails.requestedAmount');
  isCompanyOnTitle = (f: FormGroup) => f.get('vendorQuestions.isCompanyOnTitle').value;
  isCompanyOnTitle$ = (f$: Observable<FormGroup>): Observable<boolean> =>
    f$.pipe(
      map((f) => this.isCompanyOnTitle(f)),
      distinctUntilChanged()
    );

  vendorElectedIndex = (f: FormGroup): number => parseInt(f.get('vendorElectedIndex').value, 10);

  electedVendorId(f: FormGroup): number {
    const vendorControls = this.filterVendors(f).controls;

    if (vendorControls.length === 0 || this.vendorElectedIndex(f) >= vendorControls.length) {
      return null;
    }

    const electedVendor = vendorControls[this.vendorElectedIndex(f)];
    return electedVendor.get('vpaApplicationVendorId').value;
  }

  shouldUploadAuthorityNow$ = (f$: Observable<FormGroup>): Observable<boolean> =>
    f$.pipe(
      map((f) => f.get('shouldUploadAuthorityNow').value),
      distinctUntilChanged()
    );

  isAnyVendorRepresentatives = (f: FormGroup): boolean => f.get('vendorQuestions.isAnyVendorRepresentatives').value;

  private filterRegularVendors(form: FormGroup): AbstractControl[] {
    const vendors = this.filterVendors(form);

    return vendors.controls.filter((c) => !c.get('isRepresentative').value && !c.get('isDeleted').value);
  }

  private filterRepresentatives(form: FormGroup): AbstractControl[] {
    const vendors = this.filterVendors(form);

    return vendors.controls.filter((c) => c.get('isRepresentative').value && !c.get('isDeleted').value);
  }

  private mapListingDetailsToModel(application: CreateApplicationModel, form: FormGroup, isCurrentUserAnAgencyGroupUser: boolean): void {
    if (application.isFromCrmListing) {
      if (application.hasNoListingAgents) {
        application.listinglessApplicationAgentImportAggregateId = form.get(
          'applicationListingDetails.listinglessApplicationAgentImportAggregateId'
        ).value;
      }
      return;
    }

    // Get selected agencyId
    const selectedAgencyId: number = form.get('applicationListingDetails.selectedAgencyId').value;
    // Get address
    const addressGroup = form.get('applicationListingDetails.address');
    const address = new Address().fromModel({
      subNumber: addressGroup.get('subNumber').value,
      streetNumber: addressGroup.get('streetNumber').value,
      street: addressGroup.get('street').value,
      suburb: addressGroup.get('suburb').value,
      state: addressGroup.get('state').value,
      postcode: addressGroup.get('postcode').value,
      country: SystemConstants.defaultCountry
    });

    application.address = address;

    if (!application.cpn) {
      application.agencyId = selectedAgencyId;
      application.listingDetails = { selectedAgencyId, address };

      if (isCurrentUserAnAgencyGroupUser || application.hasNoListingAgents) {
        application.listinglessApplicationAgentImportAggregateId = form.get(
          'applicationListingDetails.listinglessApplicationAgentImportAggregateId'
        ).value;
      }
    }
  }

  changeToVendorFlowAndNominateVendor(form: FormGroup, vendorIndexToNominate: number): void {
    form.get('vendorQuestions.isAgentApplicationFlow').patchValue(false);
    form.get('shouldSendInviteToNominatedVendor').patchValue(true);
    form.get('vendorElectedIndex').patchValue(vendorIndexToNominate);
  }
}

import { VpaApplicationStatus } from '../_shared/models';
import { GlobalVpaSubStatus } from '../_shared/models/global-vpa-sub-status';

export function getGlobalVpaSubStatusDescription(globalVpaSubStatus: string): string {
  switch (globalVpaSubStatus) {
    case GlobalVpaSubStatus[GlobalVpaSubStatus.WaitingForAgent]:
      return 'Waiting For Agents';

    case GlobalVpaSubStatus[GlobalVpaSubStatus.WaitingForVendor]:
      return 'Waiting For Vendors';

    case GlobalVpaSubStatus[GlobalVpaSubStatus.Appraisal]:
      return 'Appraisal';

    case GlobalVpaSubStatus[GlobalVpaSubStatus.Draft]:
      return 'Draft';

    default:
      return '';
  }
}

export function getApplicationStatus(applicationStatus: string): string {
  switch (applicationStatus) {
    case VpaApplicationStatus[VpaApplicationStatus.ApplyInProgress]:
      return 'Application Started';

    case VpaApplicationStatus[VpaApplicationStatus.WaitingForPayment]:
      return 'Awaiting Payment Method';

    case VpaApplicationStatus[VpaApplicationStatus.WaitingForSignature]:
      return 'Awaiting Signatures';

    case VpaApplicationStatus[VpaApplicationStatus.SignedAndSubmitted]:
      return 'Signed & Submitted';

    case VpaApplicationStatus[VpaApplicationStatus.Cancelled]:
      return 'Cancelled';

    case VpaApplicationStatus[VpaApplicationStatus.SignedAwaitingAuthority]:
      return 'Awaiting Authority';

    default:
      return 'Not Started';
  }
}

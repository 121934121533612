import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppSettingsService } from '@ca/shared/app-state';
import { Observable, of } from 'rxjs';

@Injectable()
export class EnvironmentGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return !AppSettingsService.appSettings.production ? of(true) : this.reRouteOnFailure(state.url);
  }

  reRouteOnFailure(returnUrl: string): Observable<boolean> {
    this.router.navigate([''], { queryParams: { returnUrl } });
    return of(false);
  }
}

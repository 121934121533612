import { AbstractControl } from '@angular/forms';

// https://stackoverflow.com/questions/39819123/angular2-find-out-if-formcontrol-has-required-validator
export const hasRequiredValidator = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  // tslint:disable: no-string-literal
  if (abstractControl['controls']) {
    for (const controlName in abstractControl['controls']) {
      if (abstractControl['controls'][controlName]) {
        if (hasRequiredValidator(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};

<div
  class="help-btn show-help"
  (click)="helpHide = !helpHide"
  *ngIf="!helpHide"
  [ngClass]="{ 'offset-section-spacing': offsetSectionSpacing }"
>
  <i class="fas fa-question-circle" aria-hidden="true"></i>
  <span class="hide-text">Help</span>
</div>

<div class="help-section" [ngClass]="{ 'show-help': helpHide }">
  <i class="fa fa-times" aria-hidden="true" (click)="helpHide = !helpHide"></i>

  <p class="help-title" [innerHTML]="helpTitle"></p>

  <div [innerHTML]="helpHtml"></div>

  <p *ngIf="helpVideo">
    <a class="link" (click)="showHelpVideo = true">
      <i class="fab fa-youtube text-danger p-r-10"></i>
      Play a short help video
    </a>
  </p>
</div>

<div class="ca-modal fade-in" *ngIf="showHelpVideo">
  <div class="ca-dialog height-auto" role="document">
    <div class="ca-content float-down">
      <div class="ca-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="showHelpVideo = false">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100">
          Help Video
        </h4>
      </div>
      <div class="ca-body text-center">
        <iframe
          width="100%"
          height="315"
          [src]="sanitize.bypassSecurityTrustResourceUrl(helpVideo)"
          frameborder=" 0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>

        <button class="btn btn-secondary r-100 m-t-30" (click)="showHelpVideo = false">
          <i class="fa fa-times p-r-10"></i>
          Close Help Video
        </button>
      </div>
    </div>
  </div>
</div>

<div class="v2">
  <div class="ca-modal fade-in" *ngIf="state$ | async as state">
    <div class="ca-dialog">
      <div class="ca-content auto-height warning-modal float-down">
        <div class="ca-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="ca-body modal-body">
          <div class="switch-product">
            <h1 class="title">Switch Product</h1>
            <div class="desc">You are currently in the {{ getLoginContextText(state.loginContext) }}</div>

            <div
              class="inverse-section m-b-5"
              *ngIf="state.changeUserDestinationLogin"
              (click)="switchUserDestination(state.changeUserDestinationLogin)"
            >
              <i class="fas fa-external-link-square-alt float-right m-t-5 primary-text"></i>
              Switch to {{ getDestinationText(state.changeUserDestinationLogin.Destination) }}
              <span>{{ getLoginDesc(state.changeUserDestinationLogin.Destination) }}</span>
            </div>

            <div class="inverse-section m-b-5" *ngIf="state.adminLoginGroup.logins.length > 0" (click)="switchToCasAdmin()">
              <i class="fas fa-external-link-square-alt float-right m-t-5 primary-text"></i>
              Switch to {{ getDestinationText(state.adminLoginGroup.destination) }}
            </div>
          </div>

          <div class="switch-account m-t-35" *ngIf="showSwitchableLogins(state.switchableLoginGroup.logins, state.currentUserId)">
            <h1 class="title">Switch Account</h1>
            <div class="desc">You can switch to the following {{ state.switchableLoginGroup.logins.length }} accounts</div>
            <div class="overflow-auto">
              <div
                class="inverse-section m-b-5"
                *ngFor="let account of state.switchableLoginGroup.logins"
                [ngClass]="{ 'current-account': account.UserId === state.currentUserId }"
                (click)="account.UserId !== state.currentUserId && switchUserDestination(account)"
              >
                <span class="primary-text float-right" *ngIf="account.UserId !== state.currentUserId">
                  <i class="fas fa-long-arrow-alt-right m-r-3"></i>
                  <i class="fas fa-user"></i>
                </span>

                {{ account.LoginName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

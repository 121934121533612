import { Component, Input, OnInit } from '@angular/core';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { first } from 'rxjs/operators';
import { ApplicationMode, BreadCrumbsClassState, RouteResources } from '../../models';
import { ApplicationStep } from '../../models/application-step';
import { ApplicationModeService, ApplicationRouteService, ModalService } from '../../services';

@Component({
  selector: 'vpa-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  // TODO - refactor this to be an observable of when the rest of the application steps
  // TODO - are using the facade pattern
  @Input() applicationStep: ApplicationStep;
  @Input() applicationStatus: string;
  @Input() cpn: string;
  @Input() vpaApplicationId: number;
  @Input() isFormDirty: boolean;
  @Input() showSignStepBreadcrumb: boolean;
  @Input() showPaymentStepBreadcrumb: boolean;
  @Input() useLegacyAgreement: boolean;
  breadcrumbsClassState: BreadCrumbsClassState;
  isVendor$ = this.appStateService.isCurrentUserAVpaApplyVendor$;
  applicationMode: ApplicationMode;

  constructor(
    private appStateService: AppStateService,
    private applicationRouteService: ApplicationRouteService,
    private applicationModeService: ApplicationModeService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.setBreadcrumbsState();
    this.applicationModeService.currentApplicationModeSubject.pipe(first()).subscribe((appMode) => (this.applicationMode = appMode));
  }

  tryGoToCreateStep() {
    this.showUnsavedModalOrContinue(RouteResources.createApplication(this.cpn, this.applicationMode), () =>
      this.applicationRouteService.createStep(this.cpn)
    );
  }

  tryGoToApplyStep() {
    if (this.canGoToApplyStep()) {
      this.showUnsavedModalOrContinue(RouteResources.applyApplication(this.vpaApplicationId, this.applicationMode), () =>
        this.applicationRouteService.applyStep(this.vpaApplicationId)
      );
    }
  }

  tryGoToPayStep() {
    if (this.canGoToPayStep()) {
      this.showUnsavedModalOrContinue(RouteResources.payNow(this.vpaApplicationId, this.applicationMode), () =>
        this.applicationRouteService.payNowStep(this.vpaApplicationId)
      );
    }
  }

  tryGoToBankStatementsStep(): void {
    if (this.canGoToBankStatementsStep()) {
      this.showUnsavedModalOrContinue(RouteResources.bankStatements(this.vpaApplicationId, this.applicationMode), () =>
        this.applicationRouteService.bankStatements(this.vpaApplicationId)
      );
    }
  }

  tryGoToSignStep() {
    if (this.canGoToSignStep()) {
      this.showUnsavedModalOrContinue(
        RouteResources.signApplication(this.vpaApplicationId, this.applicationMode, this.useLegacyAgreement),
        () => this.applicationRouteService.signStepOrBankStatements(this.vpaApplicationId, this.useLegacyAgreement)
      );
    }
  }

  canGoToApplyStep() {
    return this.breadcrumbsClassState.isCreateComplete;
  }

  canGoToPayStep() {
    return this.breadcrumbsClassState.isApplyComplete;
  }

  canGoToBankStatementsStep() {
    return this.breadcrumbsClassState.isPayComplete;
  }

  canGoToSignStep() {
    return this.breadcrumbsClassState.isPayComplete;
  }

  private setBreadcrumbsState() {
    this.breadcrumbsClassState = new BreadCrumbsClassState(this.applicationStatus, this.applicationStep);
  }

  private showUnsavedModalOrContinue(route, navigateMethod: () => void) {
    if (this.isFormDirty) {
      this.modalService.openUnsavedChangesWarningModal(route);
    } else {
      navigateMethod();
    }
  }
}

export interface ApplicationBreadcrumbState {
  applicationStep: ApplicationStep;
  applicationStatus: string;
  cpn: string;
  vpaApplicationId: number;
  paymentSettlementOption: string;
  isFormDirty: boolean;
  showSignStepBreadcrumb: boolean;
  showPaymentStepBreadcrumb: boolean;
}

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NullableEmailValidator } from '@ca/shared/utils/validation';
import { AppSettingsService } from '@ca/shared/app-state';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import {
  AmendApplicationModel,
  ApplicationFundingDetails,
  CreateApplicationModel,
  CreateApplicationVendor,
  Representee,
  VendorQuestionsModel
} from 'apps/legacy/src/app/applications-shared/models';
import { RepresentativeAndVendorFormService } from 'apps/legacy/src/app/applications-shared/services/representative-and-vendor-form.service';
import { Address, DateValidation, RepresentativeActingAs } from 'apps/legacy/src/app/_shared/models';
import { DateValidator, EmailValidator, PhoneNumberValidator } from 'apps/legacy/src/app/_shared/validators';

@Injectable({
  providedIn: 'root'
})
export class CreateAndAmendVendorFormService extends RepresentativeAndVendorFormService {
  constructor(protected formBuilder: FormBuilder, protected datePipe: DatePipe, protected appStateService: AppStateService) {
    super(formBuilder, datePipe, appStateService);
  }

  mapCreateVendorDetails(
    vendors: CreateApplicationVendor[],
    address: Address,
    isAmending: boolean,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ) {
    const vendorElectedIndex = this.findVendorElectedToApplyIndex(vendors);
    const formOptions = {
      vendorElectedIndex: [vendorElectedIndex, Validators.required],
      vendors: this.mapCreateVendorFormArray(vendors, isAmending, address, morePowerOfAttorneyFieldsForClassicVpaPay),
      shouldSendInviteToNominatedVendor: [true]
    };

    return formOptions;
  }

  addListingVendors(form: FormGroup, vendors: CreateApplicationVendor[], propertyAddress: Address) {
    const vendorFormGroup = form.get('vendors') as FormArray;

    vendors.forEach((vendor, index) => {
      vendorFormGroup.insert(index, this.mapCreateVendorFormControl(vendor, false, propertyAddress));
    });
  }

  protected mapCreateApplicantsToModel(
    application: CreateApplicationModel | AmendApplicationModel,
    form: FormGroup,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ) {
    const vendorControls = (form.get('vendors') as FormArray).controls;
    const hasOnlyOneVendor = vendorControls.length === 1;
    application.vendors = vendorControls.map((a, i) =>
      this.mapApplicant(
        form,
        a,
        i,
        application.fundingDetails,
        application.vendorQuestions,
        application.vendors,
        application.address,
        hasOnlyOneVendor,
        morePowerOfAttorneyFieldsForClassicVpaPay
      )
    );
  }

  protected mapVendorSigningProperties(
    application: CreateApplicationModel | AmendApplicationModel,
    form: FormGroup,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ) {
    const vendorControls = (form.get('vendors') as FormArray).controls;
    const hasOnlyOneVendor = vendorControls.length === 1;
    application.vendors = vendorControls
      .map((a, i) =>
        this.mapApplicant(
          form,
          a,
          i,
          application.fundingDetails,
          application.vendorQuestions,
          application.vendors,
          application.address,
          hasOnlyOneVendor,
          morePowerOfAttorneyFieldsForClassicVpaPay
        )
      )
      .reduce((prev, curr, ind) => [...prev, { ...application.vendors[ind], signingProperties: curr.signingProperties }], []);
  }

  private mapCreateVendorFormArray(
    vendors: CreateApplicationVendor[],
    isAmendPage: boolean,
    propertyAddress: Address,
    morePowerOfAttorneyFieldsForClassicVpaPay: boolean
  ): FormArray {
    return this.formBuilder.array(
      vendors.map((v) => this.mapCreateVendorFormControl(v, isAmendPage, propertyAddress, morePowerOfAttorneyFieldsForClassicVpaPay))
    );
  }

  private findVendorElectedToApplyIndex(vendors: CreateApplicationVendor[]): number {
    let electedIndex = 0; // default first vendor is elected to apply
    for (let i = 0; i < vendors.length; i++) {
      if (vendors[i].isElectedToApply) {
        electedIndex = i;
      }
    }
    return electedIndex;
  }

  private mapCreateVendorFormControl(
    vendor: CreateApplicationVendor,
    isAmendPage: boolean,
    propertyAddress: Address,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ) {
    const address = !vendor.address || !vendor.address.street ? propertyAddress : vendor.address;
    let vendorOptions: any = {
      ...this.mapAddress(address),
      addressSearchControl: [this.mapSearchAddressControl(address), Validators.required],
      email: [vendor.emailAddress, [EmailValidator.validEmail]],
      firstName: [vendor.firstName, Validators.required],
      lastName: [vendor.lastName, Validators.required],
      mobile: [vendor.mobile, PhoneNumberValidator('AU')],
      isDeleted: [vendor.isDeleted],
      companyName: [
        vendor.companyDetails?.companyName,
        AppSettingsService.appSettings.featureFlags.simplifyPndProcess ? null : Validators.required
      ],
      acn: [vendor.companyDetails?.acn],
      vendorImportAggregateId: [vendor.vendorImportAggregateId],
      vpaApplicationVendorId: [vendor.vpaApplicationVendorId],
      isRepresentative: vendor.representations && vendor.representations.length !== 0,
      signingProperties: vendor.signingProperties
    };

    vendorOptions = {
      ...vendorOptions,
      representations: this.formBuilder.array(
        vendor.representations.map((a) => this.mapRepresentationFormControl(a, morePowerOfAttorneyFieldsForClassicVpaPay))
      )
    };

    if (!isAmendPage) {
      vendorOptions = {
        ...vendorOptions,
        dateOfBirth: [vendor.dateOfBirth ? { type: 'ok', data: new Date(vendor.dateOfBirth) } : { type: 'invalidDate' }]
      };
    } else {
      vendorOptions = {
        ...vendorOptions,
        dateOfBirth: [
          vendor.dateOfBirth ? { type: 'ok', data: new Date(vendor.dateOfBirth) } : { type: 'invalidDate' },
          DateValidator.validDate
        ]
      };
    }

    const vendorGroup = this.formBuilder.group(vendorOptions);

    return vendorGroup;
  }

  private mapRepresentationFormControl(representation: Representee, morePowerOfAttorneyFieldsForClassicVpaPay: boolean) {
    // For classic VPAPay a representee who has a PoA will need all data captured
    if (morePowerOfAttorneyFieldsForClassicVpaPay) {
      const isInitiallyPowerOfAttorney = representation.actingAs === RepresentativeActingAs[RepresentativeActingAs.PowerOfAttorney];

      return this.formBuilder.group({
        representativeActingAs: [representation.actingAs, Validators.required],
        actingOnBehalfOfVendorName: [representation.actingOnBehalfOf, isInitiallyPowerOfAttorney ? null : Validators.required],
        firstName: [representation.firstName, isInitiallyPowerOfAttorney ? Validators.required : null],
        lastName: [representation.lastName, isInitiallyPowerOfAttorney ? Validators.required : null],
        email: [representation.emailAddress, NullableEmailValidator(false)],
        dateOfBirth: [representation.dateOfBirth ? { type: 'ok', data: new Date(representation.dateOfBirth) } : { type: 'invalidDate' }],
        ...this.mapAddress(null, true),
        addressSearchControl: [representation.address],
        showPNDAlternateFields: [false]
      });
    }

    // For PND if a representee has a first name it has been retrieved from a Non Applying Vendor
    // This is an interim solve for backwards compatibility purposes
    if (AppSettingsService.appSettings.featureFlags.simplifyPndProcess && representation.firstName) {
      return this.formBuilder.group({
        representativeActingAs: [representation.actingAs, Validators.required],
        actingOnBehalfOfVendorName: [representation.actingOnBehalfOf, null],
        firstName: [representation.firstName, Validators.required],
        lastName: [representation.lastName, Validators.required],
        showPNDAlternateFields: [true]
      });
    }

    // Otherwise normal PND
    return this.formBuilder.group({
      representativeActingAs: [representation.actingAs, Validators.required],
      actingOnBehalfOfVendorName: [representation.actingOnBehalfOf, Validators.required],
      showPNDAlternateFields: [false]
    });
  }

  private mapApplicant(
    form: FormGroup,
    applicant: AbstractControl,
    vendorIndex: number,
    fundingDetails: ApplicationFundingDetails,
    vendorQuestions: VendorQuestionsModel,
    vendors: CreateApplicationVendor[],
    address: Address,
    hasOnlyOneVendor: boolean,
    morePowerOfAttorneyFieldsForClassicVpaPay: boolean
  ): CreateApplicationVendor {
    const mappedApplicant = new CreateApplicationVendor();

    const addressControl = applicant.get('address');
    if (addressControl) {
      mappedApplicant.address = this.mapFormGroupToAddress(addressControl);
    }

    const dateOfBirthControl = applicant.get('dateOfBirth');
    if (dateOfBirthControl) {
      mappedApplicant.dateOfBirth = DateValidation.dateResultToString(dateOfBirthControl.value);
    }

    const electedVendorIndexControl = form.get('vendorElectedIndex');
    if (electedVendorIndexControl) {
      mappedApplicant.isElectedToApply = parseInt(electedVendorIndexControl.value, 10) === vendorIndex;
    }
    if (hasOnlyOneVendor) {
      mappedApplicant.isElectedToApply = true;
    }

    mappedApplicant.emailAddress = applicant.get('email').value;
    mappedApplicant.firstName = applicant.get('firstName').value;
    mappedApplicant.lastName = applicant.get('lastName').value;
    mappedApplicant.mobile = applicant.get('mobile').value;
    mappedApplicant.isDeleted = applicant.get('isDeleted').value;
    mappedApplicant.vendorImportAggregateId = applicant.get('vendorImportAggregateId').value;
    mappedApplicant.vpaApplicationVendorId = applicant.get('vpaApplicationVendorId').value;
    mappedApplicant.companyDetails.acn = applicant.get('acn').value;
    mappedApplicant.companyDetails.companyName = applicant.get('companyName').value;
    mappedApplicant.isRepresentative = applicant.get('isRepresentative').value;

    if (mappedApplicant.isRepresentative) {
      this.mapRepresentationToModel(mappedApplicant, applicant, morePowerOfAttorneyFieldsForClassicVpaPay);
    }

    mappedApplicant.signingProperties = {
      ...vendors.find((v) => v.vpaApplicationVendorId === mappedApplicant.vpaApplicationVendorId)?.signingProperties,
      listingAddress: address,
      fullName: `${mappedApplicant.firstName} ${mappedApplicant.lastName}`,
      isCompanyOnTitle: vendorQuestions.isCompanyOnTitle,
      companyDetails: mappedApplicant.companyDetails,
      representations: mappedApplicant.representations,
      requestedAmount: fundingDetails.requestedAmount,
      startDate: fundingDetails.startDate,
      payNowDate: fundingDetails.payNowPaymentDueDate,
      payLaterDate: fundingDetails.payLaterPaymentDueDate,
      paymentSettlementOption: fundingDetails.paymentSettlementOption
    };

    return mappedApplicant;
  }

  // This intentionally overrides a function in RepresentativeAndVendorFormService
  protected mapApplicationRepresentationFormControl(representation: Representee, morePowerOfAttorneyFieldsForClassicVpaPay = false) {
    return this.mapRepresentationFormControl(representation, morePowerOfAttorneyFieldsForClassicVpaPay);
  }
}

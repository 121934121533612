import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NumberValidators } from '../validators';

@Injectable({
  providedIn: 'root'
})
export class ContributionsFormService {
  constructor(private formBuilder: FormBuilder) {}

  public mapToForm(currentPaymentMethod: string, currentRequestedAmount: number, fatZebraContributionCount: number) {
    return this.formBuilder.group({
      currentPaymentMethod: this.formBuilder.control(currentPaymentMethod, Validators.required),
      currentRequestedAmount: this.formBuilder.control(currentRequestedAmount, NumberValidators.positiveNumber),
      creditCardAmountWorkflowType: this.formBuilder.control(
        fatZebraContributionCount > 1 || (fatZebraContributionCount === 1 && currentRequestedAmount > 0) ? 'multiple' : 'one'
      ),
      creditCardNumberOfCards: this.formBuilder.control(2, NumberValidators.positiveNumber)
    });
  }

  public resetCurrentRequestAmount(form: FormGroup, currentRequestedAmount: number): void {
    form.get('currentRequestedAmount').patchValue(currentRequestedAmount);
  }

  public resetCreditCardWorkflowType(form: FormGroup): void {
    form.patchValue({ creditCardAmountWorkflowType: 'one' });
  }
}

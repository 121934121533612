import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ApplicationLoginContext } from '@ca/shared/models';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { BaseLayoutComponent } from '../_shared/components/base-layout/base-layout.component';
import { DisplayMode } from '../_shared/models';
import { ApplicationModeService, ApplicationRouteService } from '../_shared/services';

@Component({
  selector: 'vpa-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseLayoutComponent implements OnInit {
  dropdownOpen: boolean;
  isCurrentUserAVendor$ = this.appStateService.isCurrentUserAnyTypeOfVendor$;
  isBrowserExtensionDisplayMode = this.appStateService.getDisplayMode() === DisplayMode.BrowserExtension;
  protected bodyClasses = ['background-primary', 'cad-layout'];
  agencyUserName$: Observable<string> = this.appStateService.getAgencyUserName$();
  isInPortalMode$: Observable<boolean> = this.appStateService.applicationLoginContext$.pipe(
    first(),
    map((loginContext) => loginContext === ApplicationLoginContext.Portal),
    startWith(false)
  );

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected appStateService: AppStateService,
    protected applicationModeService: ApplicationModeService,
    private applicationRouteService: ApplicationRouteService
  ) {
    super(document, renderer, appStateService, applicationModeService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  logoutUser() {
    this.applicationRouteService.logout();
  }
}

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponseBase
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { Injectable } from '@angular/core';

@Injectable()
export class TraceIdentifierInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(theResponse => {
        if (theResponse instanceof HttpResponseBase) {
          const correlationId = theResponse.headers.get('x-correlation-id');
          if (correlationId) {
            Sentry.configureScope(scope =>
              scope.setTag('TraceIdentifier', correlationId)
            );
          }
        }
      })
    );
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileValidationResponse } from 'apps/legacy/src/app/_shared/models/file-validation-response';
import { FormFileModel } from 'apps/legacy/src/app/_shared/models/form-file-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'vpa-additional-vpa-marketing-quotes',
  templateUrl: './additional-vpa-marketing-quotes.component.html',
  styleUrls: ['./additional-vpa-marketing-quotes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalVpaMarketingQuotesComponent implements OnInit {
  @Input() state$: Observable<AdditionalVpaMarketingQuotesComponentState>;

  @Output() fileSelected: EventEmitter<File> = new EventEmitter();
  @Output() removeFileEvent: EventEmitter<File> = new EventEmitter();
  @Output() downloadFileRequested: EventEmitter<FormFileModel> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onFileSelected($event) {
    this.fileSelected.emit($event);
  }

  removeFile(index) {
    this.removeFileEvent.emit(index);
  }

  downloadDocument(document: FormFileModel) {
    this.downloadFileRequested.emit(document);
  }
}

export interface AdditionalVpaMarketingQuotesComponentState {
  marketingQuotes: FormFileModel[];
  areFilesBeingProcessed: boolean;
  isSubmitted: boolean;
  fileValidationState: FileValidationResponse;
}

<ca-responsive-modal
  [showModal]="input.bPayDetails !== null || input.fatZebraDetails !== null"
  [hasTitleContent]="true"
  [hasBodyContent]="true"
  [hasFooterContent]="true"
  [isWarningModal]="false"
  size="lg"
>
  <div slot="title">
    <ng-container *ngIf="input.fatZebraDetails">Details for Credit Card Transaction {{ input.fatZebraDetails.referenceCode }}</ng-container>
    <ng-container *ngIf="input.bPayDetails">Details for BPay Transaction {{ input.bPayDetails.crn }}</ng-container>
  </div>
  <div slot="additionalHeader">
    <button
      *ngIf="input.applications.length > 0"
      id="goToCampaignBtn"
      type="button"
      class="btn btn-secondary"
      mdbWavesEffect
      (click)="onViewCampaign()"
    >
      View Campaign
    </button>
  </div>
  <div slot="body" class="pnd-payment-details-modal-body">
    <div *ngIf="input.fatZebraDetails">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Amount:
          </div>
          <div>
            {{ input.fatZebraDetails.amount }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Surcharge:
          </div>
          <div>
            {{ input.fatZebraDetails.surcharge }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Paid by Vendor:
          </div>
          <div>
            {{ input.fatZebraDetails.paymentDate }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Card Holder Name:
          </div>
          <div>
            {{ input.fatZebraDetails.cardHolderName }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Card Expiry:
          </div>
          <div>
            {{ input.fatZebraDetails.cardExpiry }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Card Number:
          </div>
          <div>
            {{ input.fatZebraDetails.partialCardNumber }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Reference Code:
          </div>
          <div>
            {{ input.fatZebraDetails.referenceCode }}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="input.bPayDetails">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Amount:
          </div>
          <div>
            {{ input.bPayDetails.amount }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Paid by Vendor:
          </div>
          <div>
            {{ input.bPayDetails.paymentDate }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            CRN:
          </div>
          <div>
            {{ input.bPayDetails.crn }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Payer Code:
          </div>
          <div>
            {{ input.bPayDetails.payerCode }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Payment Instruction:
          </div>
          <div>
            {{ input.bPayDetails.paymentInstructionType }}
          </div>
        </div>
        <div class="col-12 col-lg-4" *ngIf="input.bPayDetails.paymentInstructionType !== 'Payment'">
          <div class="input-label">
            Error Correction Reason:
          </div>
          <div>
            {{ input.bPayDetails.errorCorrectionReason }}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-label">
            Payer Ref Num:
          </div>
          <div>
            {{ input.bPayDetails.payerReferenceNumber }}
          </div>
        </div>
      </div>
    </div>

    <hr />
    <label>This payment has been assigned to the following:</label>
    <br />

    <table class="table align-middle" mdbTable small="true" stickyHeader="true" hover="true" striped="true">
      <thead>
        <tr>
          <th>Application</th>
          <th>Cpn</th>
          <th>Address</th>
          <th>Amount Paid</th>
          <th>Paid To Agency</th>
        </tr>
      </thead>
      <tbody #row>
        <tr mdbTableCol *ngFor="let app of input.applications; let i = index">
          <td>
            <a class="link" (click)="onGoToApplication(app.applicationId)"> APP-{{ app.applicationId }} </a>
          </td>
          <td>{{ app.cpn }}</td>
          <td>{{ app.address }}</td>
          <td>{{ app.amount }}</td>
          <td>{{ app.paidToAgencyOn }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div slot="footer">
    <button type="button" id="close" class="btn btn-secondary" mdbWavesEffect (click)="onClose()">
      Close
    </button>
  </div>
</ca-responsive-modal>

import { Vendor } from '../_shared/models';
import { RepresentativeActingAsOptions } from '../_shared/models/representative-acting-as';

export function getFormattedVendorName(vendor: Vendor) {
    const vendorFullName = `${vendor.firstName} ${vendor.lastName}`;
    let representativeStatement = '';

    if (vendor.representativeActingAs != null) {
        representativeStatement = getRepresentationStatement(vendor);
    }
    return `${vendorFullName}${representativeStatement}`;
}

export function getRepresentationStatement(vendor: Vendor) {
    const representationType = RepresentativeActingAsOptions.find(x => {
        return x.value === vendor.representativeActingAs;
    }).label;

    return ` ${representationType} for ${vendor.actingOnBehalfOfVendorName}`;
}

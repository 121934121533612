<vpa-loading-spinner *ngIf="isLoading$ | async"></vpa-loading-spinner>
<div *ngIf="form$ | async as form" [formGroup]="form">
  <vpa-contribution-payment-method
    [state$]="contributionPaymentMethodComponentState$"
    [form$]="form$"
    [isByoPayNow]="(state$ | async).isByoPayNow"
  ></vpa-contribution-payment-method>
  <div *ngIf="paymentDetailsOptions$ | async as paymentDetailsOptions">
    <div *ngIf="currentPaymentDetailsOptionType$ | async as currentPaymentDetailsOptionType">
      <div *ngFor="let paymentDetailsOption of paymentDetailsOptions; trackBy: paymentDetailsOptionTrackingFn">
        <div
          *ngIf="
            paymentDetailsOption.type === paymentDetailsOptionType.Eft && currentPaymentDetailsOptionType === paymentDetailsOptionType.Eft
          "
        >
          <vpa-eft-payment
            [paymentDetailsOption]="paymentDetailsOption"
            [contributionPaymentBreakdown$]="eftContributionsSummaryState$"
          ></vpa-eft-payment>
        </div>

        <div
          *ngIf="
            paymentDetailsOption.type === paymentDetailsOptionType.Bpay && currentPaymentDetailsOptionType === paymentDetailsOptionType.Bpay
          "
        >
          <vpa-bpay-payment
            [paymentDetailsOption]="paymentDetailsOption"
            [contributionPaymentBreakdown$]="bpayContributionsSummaryState$"
          ></vpa-bpay-payment>
        </div>

        <div
          *ngIf="
            paymentDetailsOption.type === paymentDetailsOptionType.Cheque &&
            currentPaymentDetailsOptionType === paymentDetailsOptionType.Cheque
          "
        >
          <vpa-cheque-payment
            [paymentDetailsOption]="paymentDetailsOption"
            [contributionPaymentBreakdown$]="chequeContributionsSummaryState$"
          ></vpa-cheque-payment>
        </div>

        <div *ngIf="paymentDetailsOption.isCreditCard && (isPaymentMethodCreditCard$ | async)">
          <vpa-credit-card-details
            [formData]="creditCardFormData$ | async"
            [state]="creditCardState$ | async"
            [summaryState$]="creditCardContributionsSummaryState$"
            (tokenisationSuccess)="onFatZebraTokenisationSuccess($event)"
            (authorizationApproved)="onFatZebraAuthorizationApproved($event)"
            (authorizationDeclined)="onFatZebraAuthorizationDeclined($event)"
            (contributionRemoved)="onRemoveCreditCardContribution($event)"
            (nextButtonClicked)="onCreditCardNextButtonClicked()"
            (backButtonClicked)="onCreditCardBackButtonClicked()"
            (openModal)="onOpenCreditCardModal()"
            (submit)="onSubmit()"
            (tryAnotherCard)="onTryAnotherCard()"
            (savePaywayPayment)="onSavePayment()"
          ></vpa-credit-card-details>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="validationError$ | async as validationError">
    <div *ngIf="(currentPaymentDetailsOptionType$ | async) !== paymentDetailsOptionType.FatZebra" class="alert alert-warning" role="alert">
      <i class="fa fa-exclamation-circle"></i>
      {{ validationError }}
    </div>
  </div>
</div>

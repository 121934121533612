import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  CreditCardChargeScheduleType,
  FatZebraContributionModel,
  IContribution,
  PaymentDetailsOption,
  PaymentDetailsOptionType,
  PaywayContributionModel,
  SourceDomain
} from '../../models';
import { CreditCardSchemeSurchargeOption } from '../../models/credit-card-scheme-surcharge-option';
import { FatZebraTokenResponse } from '../../models/fat-zebra/fat-zebra-token-response';
import { FatZebraTransaction } from '../../models/fat-zebra/fat-zebra-transaction';
import { PayNowDirectType } from '../../models/pay-now-direct-type';
import { ContributionsFacadeService } from '../../services/contributions-facade.service';
import { BaseComponent } from '../base-component/base-component';
import { IContributionPaymentBreakdownComponentState } from './contribution-payment-breakdown/contribution-payment-breakdown.component';
import { IContributionPaymentMethodComponentState } from './contribution-payment-method/contribution-payment-method.component';
import { ICreditCardFormData, ICreditCardState } from './credit-card-details/credit-card-details.component';

@Component({
  selector: 'vpa-contributions',
  templateUrl: './contributions.component.html',
  styleUrls: ['./contributions.component.scss'],
  providers: [ContributionsFacadeService]
})
export class ContributionsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() state$: Observable<IContributionsComponentState>;
  @Input() updateAutomaticContributionAmounts$: Observable<void>;
  @Output() contributionsUpdated: EventEmitter<IContribution[]> = new EventEmitter();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() nominatedContributionsUpdated: EventEmitter<IContribution[]> = new EventEmitter();
  @Output() submit: EventEmitter<void> = new EventEmitter();
  @Output() currentContributionTypeChanged: EventEmitter<PaymentDetailsOptionType> = new EventEmitter();
  @Output() clearFatZebraErrors: EventEmitter<void> = new EventEmitter();
  @Output() processFatZebraContribution: EventEmitter<FatZebraContributionModel> = new EventEmitter();

  readonly paymentDetailsOptionType = PaymentDetailsOptionType;

  form$: Observable<FormGroup> = this.facade.form$;
  contributions$: Observable<IContribution[]> = this.facade.contributions$;
  isPaywayDisabled$: Observable<boolean> = this.facade.isPaywayDisabled$;
  validationError$: Observable<string> = this.facade.validationError$;
  creditCardState$: Observable<ICreditCardState> = this.facade.creditCardState$;
  creditCardFormData$: Observable<ICreditCardFormData> = this.facade.creditCardFormData$;
  contributionPaymentMethodComponentState$: Observable<IContributionPaymentMethodComponentState> = this.facade
    .contributionPaymentMethodComponentState$;
  eftContributionsSummaryState$: Observable<IContributionPaymentBreakdownComponentState> = this.facade.eftContributionsSummaryState$;
  creditCardContributionsSummaryState$: Observable<IContributionPaymentBreakdownComponentState> = this.facade
    .creditCardContributionsSummaryState$;
  bpayContributionsSummaryState$: Observable<IContributionPaymentBreakdownComponentState> = this.facade.bpayContributionsSummaryState$;
  chequeContributionsSummaryState$: Observable<IContributionPaymentBreakdownComponentState> = this.facade.chequeContributionsSummaryState$;
  currentPaymentDetailsOptionType$: Observable<PaymentDetailsOptionType> = this.facade.currentPaymentDetailsOptionType$;
  paymentDetailsOptions$: Observable<PaymentDetailsOption[]> = this.facade.paymentDetailsOptions$;
  fatZebraErrors$: Observable<string[]> = this.facade.fatZebraErrors$;
  isPaymentMethodCreditCard$: Observable<boolean> = this.facade.isPaymentMethodCreditCard$;
  isLoading$: Observable<boolean> = this.facade.isLoading$;

  constructor(private facade: ContributionsFacadeService) {
    super();
  }

  ngOnInit() {
    this.facade.initialise(this.state$, this.updateAutomaticContributionAmounts$);

    this.contributions$.pipe(this.unsubOnDestroy()).subscribe((contributions) => this.contributionsUpdated.emit(contributions));
    this.isPaywayDisabled$.pipe(this.unsubOnDestroy()).subscribe((isPaywayDisabled) => this.isLoading.emit(isPaywayDisabled));
    this.facade.nominatedContributions$
      .pipe(this.unsubOnDestroy())
      .subscribe((contributions) => this.nominatedContributionsUpdated.emit(contributions));

    this.facade.currentPaymentDetailsOptionType$
      .pipe(this.unsubOnDestroy())
      .subscribe((currentPaymentDetailsOptionType) => this.currentContributionTypeChanged.emit(currentPaymentDetailsOptionType));

    this.facade.clearFatZebraErrors$.pipe(this.unsubOnDestroy()).subscribe((_) => this.clearFatZebraErrors.emit());
    this.facade.processFatZebraContribution$
      .pipe(this.unsubOnDestroy())
      .subscribe((contribution) => this.processFatZebraContribution.emit(contribution));
  }

  ngOnDestroy(): void {
    this.facade.processingCompleted();
    super.ngOnDestroy();
  }

  onFatZebraTokenisationSuccess(tokenResponse: FatZebraTokenResponse): void {
    this.facade.onFatZebraTokenisationSuccess(tokenResponse);
  }

  onFatZebraAuthorizationApproved(transaction: FatZebraTransaction): void {
    this.facade.onFatZebraAuthorizationApproved(transaction);
  }

  onFatZebraAuthorizationDeclined(cardNumber: string) {
    this.facade.onFatZebraAuthorizationDeclined(cardNumber);
  }

  onSavePayment(): void {
    this.facade.trySavePayment();
  }

  onRemoveCreditCardContribution(contribution: PaywayContributionModel) {
    this.facade.removeContribution(contribution);
  }

  onCreditCardNextButtonClicked(): void {
    this.facade.onCreditCardNextButtonClicked();
  }

  onCreditCardBackButtonClicked(): void {
    this.facade.onCreditCardBackButtonClicked();
  }

  onOpenCreditCardModal(): void {
    this.facade.onOpenCreditCardModal();
  }

  onSubmit(): void {
    this.submit.emit();
  }

  onTryAnotherCard(): void {
    this.facade.onTryAnotherCard();
  }

  isValid = (): boolean => this.facade.isValid();

  // This is used so that when the ngFor in the template runs, it will re-use the already created
  // DOM elements, instead of blowing them away each time - this is critical here because of
  // how we use the Payway iframe
  paymentDetailsOptionTrackingFn(index: number, paymentDetailsOption: PaymentDetailsOption) {
    return paymentDetailsOption.type;
  }
}

export interface IContributionsComponentState {
  id: number;
  cpn: string;
  sourceDomain: SourceDomain;
  isDisplayedInSections: boolean;
  isSubmitted: boolean;
  agencyProcessedPaymentInformationMessage: string;
  agencyProcessedPaymentInformationTitle: string;
  showCheque: boolean;
  isByoPayNow: boolean;
  contributions: IContribution[];
  totalAuthorisedAmount: number;
  payNowPaymentDueDate: Date;
  useChargeOnSubmission: boolean;
  paymentDetailsOptions: PaymentDetailsOption[];
  initialPaymentDetailsOption: PaymentDetailsOptionType;
  allowSubmission: boolean;
  fatZebraErrors: string[];
  failedFatZebraReferences: FailedFatZebraReference[];
  isCustomSurchargeDisclaimerIsEnabled: boolean;
  customSurchargeText: string;
  creditCardChargeScheduleType: CreditCardChargeScheduleType;
  paymentSubmissionExplanationText: string;
  paymentSubmissionButtonText: string;
  brandLogoUrl: string;
  payNowDirectCreditCardType: PayNowDirectType;
  creditCardSchemeSurchargeOptions: CreditCardSchemeSurchargeOption;
}

export interface FailedFatZebraReference {
  referenceCode: string;
  message: string;
}

import { AdditionalVpaFundingDetails } from 'apps/legacy/src/app/additionalvpa/models/additional-vpa-funding-details';
import { VpaReceivableDeclineReason } from 'apps/legacy/src/app/additionalvpa/models/create-step/vpa-receivable-decline-reason';
import { CompanyDetails, ReactiveVendorQuestionsModel, Representee } from 'apps/legacy/src/app/applications-shared/models';
import {
  Address,
  LegalTerms,
  PaymentSettlementOption,
  VendorSigningPropertiesModel,
  VpaApplicationStatus
} from 'apps/legacy/src/app/_shared/models';
import { FormFileModel } from 'apps/legacy/src/app/_shared/models/form-file-model';
import { VpaApplicationDocumentModel } from 'apps/legacy/src/app/_shared/models/vpa-application-document-model';
import { Guid } from 'guid-typescript';

export class CreateAdditionalVpaApplyApplicationModel {
  vpaApplicationId: number;
  address: Address;
  vendors: CreateAdditionalVpaApplyVendorModel[];
  applicationStatus?: VpaApplicationStatus;
  cpn: string;
  fundingDetails: AdditionalVpaFundingDetails;
  marketingQuotes: FormFileModel[] = [];
  vpaReceivableDeclineReason: VpaReceivableDeclineReason;
  isCompanyOnTitle: boolean;
  shouldUploadSalesAuthority: boolean;
  areAllPreviousApplicationsPayNowDirect: boolean;
  isVendorFullyEditable: boolean;
  salesAuthorityDocuments?: FormFileModel[];
  marketingScheduleDocuments?: FormFileModel[];
  legalTerms?: LegalTerms;
  vendorQuestions: ReactiveVendorQuestionsModel;
  shouldSubmitForApproval: boolean;
  shouldSendInvitations: boolean;
  isPartiallyPaid: boolean;
  allDocuments: VpaApplicationDocumentModel[];
  paymentSettlementOption: PaymentSettlementOption;
  isMarketingScheduleMandatory: boolean;
  isPndApplication: boolean;
  globalVpaStatus: string;
  shouldSendInviteToNominatedVendor: boolean;
  morePowerOfAttorneyFieldsForClassicVpaPay: boolean;
}

export class CreateAdditionalVpaApplyVendorModel {
  agencyId: number;
  address?: Address;
  dateOfBirth: string;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  isNominated: boolean;
  mobile: string;
  vendorAggregateId: Guid;
  vpaApplicationVendorId?: number;
  representations: Representee[];
  companyDetailsDto: CompanyDetails;
  signingProperties: VendorSigningPropertiesModel;
}

export class CreateAdditionalVpaApplyResponseModel {
  vpaApplicationId: number;
  vendorInvitationSuccessfullySent: boolean;
}

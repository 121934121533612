import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalException } from '../models/global-exception';
import { Globals } from '../models/global-variables';
@Injectable({
  providedIn: 'root'
})
export class GlobalExceptionService {
  constructor(private globals: Globals) {}

  setCurrentExceptionFromHttpError(error: HttpErrorResponse): void {
    const exception: GlobalException = error.error;
    this.globals.exception = exception;
  }

  setCurrentException(error: GlobalException): void {
    this.globals.exception = error;
  }

  setCurrentExceptionFromMessage(errorMessage: string): void {
    const globalException: GlobalException = {
      status: null,
      detail: errorMessage,
      errors: null,
      title: null,
      traceId: null,
      type: null
    };

    this.globals.exception = globalException;
  }
}

import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneNumberType } from './models/PhoneNumberType';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function isE164Format(mobile: string): boolean {
  return /^\+[1-9]\d{1,14}$/.test(mobile);
}

export function isValidAustralianNumber(mobile: string): boolean {
  if (!isE164Format(mobile)) {
    const mobileNumber = phoneNumberUtil.parseAndKeepRawInput(mobile, 'AU');

    return (
      phoneNumberUtil.isValidNumberForRegion(mobileNumber, 'AU') && phoneNumberUtil.getNumberType(mobileNumber) === PhoneNumberType.MOBILE
    );
  }

  return mobile.startsWith('+61');
}

export function isOptionalValidAustralianNumber(mobile: string): boolean {
  if (!mobile) {
    return true;
  }

  return isValidAustralianNumber(mobile);
}

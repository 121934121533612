import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppSettingsService, LoginDestination, SwitchableLogin, SwitchableLoginGroup } from '@ca/shared/app-state';
import { ApplicationLoginContext, RouteResources, SystemConstants } from '@ca/shared/models';
import { Observable } from 'rxjs';
import { SwitchAccountService } from '../switch-account.service';

@Component({
  selector: 'ca-switch-account-modal',
  templateUrl: './switch-account-modal.component.html',
  styleUrls: ['./switch-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchAccountModalComponent {
  @Input() state$: Observable<SwitchAccountModalComponentState>;
  @Output() closeModal: EventEmitter<void> = new EventEmitter();
  @Output() loginSelected: EventEmitter<void> = new EventEmitter();

  supportPhoneNumber = SystemConstants.supportPhoneNumber;
  supportEmailAddress = SystemConstants.supportEmailAddress;

  constructor(private switchAccountService: SwitchAccountService) {}

  showSwitchableLogins(switchableLogins: SwitchableLogin[], currentUserId: number) {
    return switchableLogins.filter((l) => l.UserId !== currentUserId).length > 0;
  }

  onCloseModal(): void {
    this.closeModal.emit();
  }

  onLoginSelected(): void {
    this.loginSelected.emit();
  }

  getLoginContextText(loginContext: ApplicationLoginContext) {
    if (loginContext === ApplicationLoginContext.Portal) {
      return 'Portal';
    }
    return 'Agent App';
  }

  getDestinationText(destination: number) {
    return LoginDestination[destination];
  }

  getLoginDesc(destination: number): string {
    switch (destination) {
      case LoginDestination.Portal:
        return 'Create, Manage and Control all aspects of VPAPay';
      case LoginDestination.App:
        return 'Campaigns and Funding on the go';
      default:
        return null;
    }
  }

  switchToCasAdmin() {
    this.onLoginSelected();
    window.location.href = RouteResources.loginWithGoogleAccount(AppSettingsService.appSettings.casUrl);
  }

  switchUserDestination(user: SwitchableLogin) {
    if (user.Destination === LoginDestination.Portal) {
      this.switchToPortal(user);
    } else {
      this.switchToApp(user);
    }
  }

  private switchToPortal(user: SwitchableLogin) {
    this.switchAccountService.getSwitchUserToken(user.UserId, user.Destination).subscribe((res) => {
      window.location.href = RouteResources.casLoginToken(res.token, AppSettingsService.appSettings.casUrl);
    });
  }

  private switchToApp(user: SwitchableLogin) {
    this.switchAccountService.getSwitchUserToken(user.UserId, user.Destination).subscribe((res) => {
      this.switchAccountService.switchToApp(res.token).subscribe((tokenResponse) => {
        window.location.replace(RouteResources.loginWithCasToken(tokenResponse.token));
      });
    });
  }

  switchToUser(user: SwitchableLogin) {
    this.onLoginSelected();
    if (user.Destination === LoginDestination.Portal) {
      this.switchToPortal(user);
      return;
    }

    this.switchToApp(user);
  }
}

export interface SwitchAccountModalComponentState {
  loginContext: ApplicationLoginContext;
  currentUserId: number;
  changeUserDestinationLogin: SwitchableLogin;
  switchableLoginGroup: SwitchableLoginGroup;
  adminLoginGroup: SwitchableLoginGroup;
}

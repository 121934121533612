import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeWhile } from 'rxjs/operators';
import { ApplicationMode } from '../models/application-mode';

@Injectable({
  providedIn: 'root'
})
export class ApplicationModeService {
  readonly currentApplicationModeSubject: Subject<ApplicationMode>;
  allowModeUpdates = true;

  constructor(private location: Location, private router: Router) {
    this.currentApplicationModeSubject = new BehaviorSubject(this.getApplicationModeByUrl(this.location.path()));

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map((e: NavigationEnd) => this.getApplicationModeByUrl(e.url)),
        distinctUntilChanged(),
        takeWhile(() => this.allowModeUpdates)
      )
      .subscribe(appMode => this.currentApplicationModeSubject.next(appMode));
  }

  forceApplicationMode(applicationMode: string) {
    this.currentApplicationModeSubject.next(ApplicationMode[applicationMode]);
    this.allowModeUpdates = false;
  }

  get currentApplicationMode$(): Observable<ApplicationMode> {
    return this.currentApplicationModeSubject.asObservable();
  }

  get isLiveMode$(): Observable<boolean> {
    return this.currentApplicationModeSubject.pipe(map(appMode => appMode === ApplicationMode.Live));
  }

  get isTrainingMode$(): Observable<boolean> {
    return this.currentApplicationModeSubject.pipe(map(appMode => appMode === ApplicationMode.Training));
  }

  private isTrainingModeUrl(url: string) {
    return url.split('/').some(seg => seg.toLowerCase() === 'training');
  }

  private getApplicationModeByUrl(url: string): ApplicationMode {
    return this.isTrainingModeUrl(url) ? ApplicationMode.Training : ApplicationMode.Live;
  }
}

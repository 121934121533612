<div class="section-title" *ngIf="contributions.length > 0">
  Added Credit Cards
</div>
<div class="section-description" *ngIf="contributions.length > 0">
  Breakdown of payments from each credit card
</div>

<div class="row contributions-summary" *ngIf="contributions.length > 0">
  <div
    class="alert alert-info border-blue m-b-0 m-t-10"
    *ngIf="useChargeOnSubmission"
    role="alert"
    style="border: 2px solid #31708f; border-radius: 4px !important; display: flow-root; font-size: 18px;"
  >
    <i class="fa fa-lg fa-info-circle" style="float: left; margin-right: 10px; margin-top: 4px;"></i>
    <p>Your credit card will be charged once all parties have signed and submitted this agreement</p>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12 cc-container" *ngFor="let contribution of contributions; let i = index">
    <label>Card {{ i + 1 }}</label>
    <div class="credit-card">
      <strong class="cc-name">
        {{ contribution.cardholderName }}
      </strong>
      <strong class="cc-number">
        {{ contribution.partialCardNumber }}
      </strong>
      <strong class="cc-type">
        {{ contribution.cardScheme }}
      </strong>
      <strong class="cc-amount">
        {{ contribution.amount | currency }}
      </strong>
      <strong class="cc-type" *ngIf="contribution.creditCardSurcharge > 0">
        Surcharge
      </strong>
      <strong class="cc-number" *ngIf="contribution.creditCardSurcharge > 0">
        {{ contribution.creditCardSurcharge | currency }}
      </strong>

      <button (click)="removeContribution(contribution)" class="btn remove-btn">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>

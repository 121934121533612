import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { AppSettingsService, AppStateService, AppThemeService } from '@ca/shared/app-state';
import { initSentry } from '@ca/shared/helpers';
import * as Sentry from '@sentry/angular';
import LogRocket from 'logrocket';
import { fromEvent, merge, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { AppStateService as OldAppStateService } from './app-state.service';
import { version } from './version.json';

@Component({
  selector: 'vpa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  userIsOffLine$: Observable<boolean> = merge<boolean>(
    fromEvent(window, 'offline').pipe(map(() => true)),
    fromEvent(window, 'online').pipe(map(() => false))
  ).pipe(startWith(!navigator.onLine));

  ngOnInit(): void {
    this.titleService.setTitle(AppSettingsService.appSettings.environmentTitle);

    if (AppSettingsService.appSettings.useLogRocket) {
      LogRocket.init('f9ujnm/casaoo', {
        network: {
          requestSanitizer: (request) => {
            // sanitize all requests to Illion
            if (request.url.toLowerCase().indexOf('bankstatements.com.au') !== -1) {
              return null;
            }

            if (request.url.toLowerCase().endsWith('api/user/authentication/login')) {
              request.body = '[redacted]';
            }

            if (request.headers.Authorization) {
              request.headers.Authorization = '[redacted]';
            }

            return request;
          }
        }
      });

      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }

    initSentry(AppSettingsService.appSettings.sentryEnvironmentName, version);

    this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe((_) => {
      this.initialiseStateIfNeeded(this.oldAppStateService, this.appStateService);
    });
  }

  ngAfterViewInit(): void {
    this.appThemeService.loadStyle();
  }

  constructor(
    private appThemeService: AppThemeService,
    private titleService: Title,
    private router: Router,
    private oldAppStateService: OldAppStateService,
    private appStateService: AppStateService
  ) {}

  // During the transition period of the legacy app still being around, but referencing newer libraries
  // (ones with the new AppStateService in particular), we need to ensure that if the app state changes in
  // one place, that we also update it in the other. Since this only needs to be done in legacy, we can just
  // have the following function run on NavigationStart events, and make sure that each AppStateService is
  // running with whatever auth token is currently saved to local storage
  private initialiseStateIfNeeded = (legacyAppStateService: OldAppStateService, appStateService: AppStateService): void => {
    if (!legacyAppStateService.isInitialisedFromCurrentAuthToken()) {
      legacyAppStateService.initialiseState();
    }

    if (!appStateService.isInitialisedFromCurrentAuthToken()) {
      appStateService.initialiseState();
    }
  };
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FatZebraContributionModel,
  IContribution,
  ICreditCardContribution,
  PaymentDetailsOptionType
} from 'apps/legacy/src/app/_shared/models';
import { FailedFatZebraReference } from '../../contributions.component';

@Component({
  selector: 'vpa-credit-card-contributions-summary',
  templateUrl: './credit-card-contributions-summary.component.html',
  styleUrls: ['./credit-card-contributions-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardContributionsSummaryComponent implements OnInit {
  @Input() contributions: ICreditCardContribution[];
  @Input() useChargeOnSubmission: boolean;
  @Input() failedTransactionReferences: FailedFatZebraReference[];
  @Output() contributionRemoved = new EventEmitter<IContribution>();

  constructor() {}

  ngOnInit() {}

  removeContribution(contribution: IContribution): void {
    this.contributionRemoved.emit(contribution);
  }

  // The type checking in the below methods is pretty hacky, but it's a quick refactoring to make this actually
  // generic instead of pretending that everything is FatZebra - ideally this component should take in an array
  // of a new model type that includes the CC details, as well as if it is failed
  isFailedContribution(contribution: ICreditCardContribution): boolean {
    if (contribution.paymentDetailsOptionType !== PaymentDetailsOptionType.FatZebra) {
      return false;
    }

    const fatZebraContribution = contribution as FatZebraContributionModel;
    return (
      this.failedTransactionReferences.some((ft) => ft.referenceCode === fatZebraContribution.fatZebraReferenceCode) ||
      fatZebraContribution.transactionError !== null
    );
  }

  getContributionFailureMessage(contribution: ICreditCardContribution): string {
    if (contribution.paymentDetailsOptionType !== PaymentDetailsOptionType.FatZebra) {
      return '';
    }

    const fatZebraContribution = contribution as FatZebraContributionModel;
    return (
      this.failedTransactionReferences.find((ft) => ft.referenceCode === fatZebraContribution.fatZebraReferenceCode)?.message ??
      contribution.transactionError
    );
  }

  shouldShowPaidStamp(contribution: ICreditCardContribution): boolean {
    if (contribution.paymentDetailsOptionType !== PaymentDetailsOptionType.FatZebra) {
      return false;
    }

    const fatZebraContribution = contribution as FatZebraContributionModel;
    return fatZebraContribution.hasBeenPaid;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { ProblemDetail } from '@ca/shared/models';

export function toProblemDetail(response: HttpErrorResponse): ProblemDetail {
  if (response.error && (response.error as ProblemDetail)) {
    return response.error as ProblemDetail;
  }

  return { detail: 'An unknown error occurred.', statusCode: response.status };
}

<div [formGroup]="applicationForm">
  <section class="section-card" [ngClass]="{ 'inline-component-section': !isDisplayedInSections }">
    <div class="row" *ngIf="!showPayNowDetails">
      <div class="col-12 col-lg-6">
        <div>
          <label class="text-uppercase strong">Payment Details</label>
        </div>

        <div class="m-t-15 fade-in animate-750">
          <div>
            <label class="text-uppercase">
              Total Due
            </label>
            <span class="strong">
              {{ fundingDetails.payLaterPaymentDueDate | date | uppercase }}
            </span>
          </div>
          <div>
            <span class="stronger">
              {{ fundingDetails.costs.payLater.totalAuthorisedAmount | currency }}
            </span>
          </div>
          <div class="m-t-15">
            <p class="description font-14">
              Payment is due on the above date.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="showPayNowDetails">
      <div class="col-12 col-lg-12">
        <div class="section-title">{{isByoPayNow ? "Confirm your choice" : "Select Pay Now or Pay Later" }}</div>

        <div *ngIf="isByoPayNow">
          <div id="pay-now" class="payment-preference" [ngClass]="{
            'preference-selected': isPayNowControl.value
          }" (click)="setPayNow()">
            <div class="pill">
              Pay Now
            </div>
            <div class="content">
              <div>
                <label>Total Due</label>
                <span>
                  {{ fundingDetails.costs.payNow.totalAuthorisedAmount | currency }}
                </span>
              </div>
              <div>
                <label>Due By</label>
                <span>{{ fundingDetails.payNowPaymentDueDate | date }}</span>
              </div>
            </div>
          </div>

          <div id="pay-later" class="payment-preference" [ngClass]="{ 'preference-selected': !isPayNowControl.value }"
            (click)="setPayLater()">
            <div class="pill">
              Pay Later
            </div>
            <div class="content">
              <div>
                <label>Total Due</label>
                <span>{{ fundingDetails.costs.payLater.totalAuthorisedAmount | currency }}</span>
              </div>
              <div>
                <label>Due Date</label>
                <span>{{ fundingDetails.payLaterPaymentDueDate | date }}</span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isByoPayNow">
          <div id="pay-later" class="payment-preference" [ngClass]="{ 'preference-selected': !isPayNowControl.value }"
            (click)="setPayLater()">
            <div class="pill">
              Pay Later
            </div>
            <div class="content">
              <div>
                <label>Total Due</label>
                <span>{{ fundingDetails.costs.payLater.totalAuthorisedAmount | currency }}</span>
              </div>
              <div>
                <label>Due Date</label>
                <span>{{ fundingDetails.payLaterPaymentDueDate | date }}</span>
              </div>
            </div>
          </div>

          <div id="pay-now" class="payment-preference" [ngClass]="{
            'preference-selected': isPayNowControl.value
          }" (click)="setPayNow()">
            <div class="pill">
              Pay Now
            </div>
            <div class="content">
              <div>
                <label>Total Due</label>
                <span>
                  {{ fundingDetails.costs.payNow.totalAuthorisedAmount | currency }}
                </span>
              </div>
              <div>
                <label>Due Date</label>
                <span>{{ fundingDetails.payNowPaymentDueDate | date }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</div>
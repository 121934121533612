import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceText'
})
export class ReplaceTextPipe implements PipeTransform {
  transform(value: string, strToReplace: string, replacementStr: string): any {
    if (value) {
      return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
    }
    return '';
  }
}

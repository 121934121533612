import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vpa-deposit-now-banner',
  templateUrl: './deposit-now-banner.component.html',
  styleUrls: ['./deposit-now-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepositNowBannerComponent implements OnInit {
  @Input() showAdditionalProductOffers: boolean;
  @Input() additionalProductInformationUrl: string;
  @Input() depositNowBannerName: string;
  @Output() toggleOptOutOfAdditionalProductOffersEvent = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  toggleOptOutOfAdditionalProductOffers(selected: boolean) {
    this.toggleOptOutOfAdditionalProductOffersEvent.emit(selected);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ApplicationFundingDetails } from 'apps/legacy/src/app/applications-shared/models';
import { UserEventsService } from 'apps/legacy/src/app/userEvents/services/userEvents.service';
import { BaseComponent } from 'apps/legacy/src/app/_shared/components/base-component/base-component';
@Component({
  selector: 'vpa-application-payment-preference',
  templateUrl: './application-payment-preference.component.html',
  styleUrls: ['./application-payment-preference.component.scss']
})
export class ApplicationPaymentPreferenceComponent extends BaseComponent implements OnInit {
  @Input() applicationForm: FormGroup;
  @Input() fundingDetails: ApplicationFundingDetails;
  @Input() isSubmitted: boolean;
  @Input() isDisplayedInSections: boolean;
  @Input() isByoPayNow: boolean;
  @Input() cpn: string;

  isPayNowControl: AbstractControl;

  constructor(private userEventsService: UserEventsService) {
    super();
  }

  ngOnInit() {
    this.isPayNowControl = this.applicationForm.get('applicationFundingDetails.isPayNow');
  }

  setPayLater() {
    this.userEventsService.addUserEvent('Application.ChangePaymentOption.PayLater', this.cpn);
    this.isPayNowControl.setValue(false);
  }

  setPayNow() {
    this.userEventsService.addUserEvent('Application.ChangePaymentOption.PayNow', this.cpn);
    this.isPayNowControl.setValue(true);
  }

  get payNowSavings(): number {
    const payLaterCosts = this.fundingDetails.costs.payLater;
    return payLaterCosts.interestCharge + payLaterCosts.serviceFee + payLaterCosts.latePaymentFee;
  }

  get showPayNowDetails(): boolean {
    return this.fundingDetails && this.fundingDetails.canRequestPayNow;
  }

  get isValid(): boolean {
    return validateApplicationPaymentPreferenceComponent(this.applicationForm);
  }
}

export function validateApplicationPaymentPreferenceComponent(applicationForm: FormGroup): boolean {
  return !applicationForm.get('applicationFundingDetails.isPayNow').invalid;
}

import { Pipe, PipeTransform } from '@angular/core';
import { getFormattedVendorName } from 'apps/legacy/src/app/_helpers/user-helpers';
import { Vendor } from '../models';

@Pipe({
  name: 'vendorName'
})
export class VendorNamePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const vendor = value as Vendor;

    return getFormattedVendorName(vendor);
  }
}

import { PaymentDetailsOptionType } from './payment-details-option';
import { UpfrontPaymentMethod } from './upfront-payment-method';

export interface IContribution {
  contributionId: number;
  amount: number;
  paymentMethod: string;
  paymentDetailsOptionType: PaymentDetailsOptionType;
}

export interface ICreditCardContribution extends IContribution {
  partialCardNumber: string;
  creditCardSurcharge: number;
  cardholderName: string;
  cardScheme: string;
  transactionSurcharge: number;
  hasBeenPaid: boolean;
  canDeleteContribution: boolean;
  transactionError: string;
}

// Fighting with legacy code here. This represents Cheque, EFT, AgencyProcessed
export class AutomaticContributionModel implements IContribution {
  contributionId: number;
  amount: number;
  paymentMethod: string;
  paymentDetailsOptionType: PaymentDetailsOptionType;
}

export class PaywayContributionModel implements IContribution, ICreditCardContribution {
  contributionId: number;
  amount: number;
  paymentMethod: string = UpfrontPaymentMethod[UpfrontPaymentMethod.CreditCard];
  paymentDetailsOptionType: PaymentDetailsOptionType = PaymentDetailsOptionType.Payway;
  paywayCustomerAggregateId: string;
  partialCardNumber: string;
  cardholderName: string;
  cardScheme: string;
  creditCardSurcharge: number;
  transactionSurcharge: number; // This is just here to ensure it displays correctly in the UI, the backend shouldn't care
  hasBeenPaid: boolean;
  canDeleteContribution: boolean;
  transactionError: string;
}

export class FatZebraContributionModel implements IContribution, ICreditCardContribution {
  contributionId: number;
  amount: number;
  paymentMethod: string = UpfrontPaymentMethod[UpfrontPaymentMethod.FatZebra];
  paymentDetailsOptionType: PaymentDetailsOptionType = PaymentDetailsOptionType.FatZebra;
  fatZebraReferenceCode: string;
  fatZebraTransactionId: string;
  cardholderName: string;
  fatZebraCardExpiry: string;
  fatZebraToken: string;
  cardScheme: string;
  partialCardNumber: string;
  fatZebraTransactionAggregateId: string;
  transactionSurcharge: number;
  creditCardSurcharge: number; // This is just to make the interface work, needs refactor for PW and FZ together
  hasBeenPaid: boolean;
  transactionError: string;
  canDeleteContribution: boolean;
}

<div class="validation-message">
  <span *ngIf="!signature && showSignatureError">Please sign to proceed</span>
</div>
<div class="signature-wrapper">
  <signature-pad #signaturePad [options]="options" (onEndEvent)="drawComplete()"></signature-pad>
</div>

<button class="btn clear-signature" (click)="clear()">
  <i class="fa fa-signature"></i>
  Clear and Re-sign
  <span class="name">{{ buttonLabel }}</span>
</button>

<div class="ca-modal fade-in">
  <div class="ca-dialog">
    <div class="ca-content float-down">
      <div class="ca-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 strong">
          How would you like to create your request?
        </h4>
      </div>

      <div class="modal-body">
        <ul class="list-group">
          <li class="list-group-item">
            <a (click)="createDigitalRequest()">
              <div class="content-container">
                <i class="far fa-window-alt"></i>
                <div class="content-description">
                  <h4>Create Digital Request</h4>
                  <p>I want to create a digital request and ask the vendor to electronically sign and authorise.</p>
                </div>
              </div>
            </a>
          </li>

          <li class="list-group-item" *ngIf="showPdfApplicationOption">
            <a (click)="createPdfRequest()">
              <div class="content-container">
                <i class="far fa-window-alt"></i>
                <div class="content-description">
                  <h4>Generate PDF Agreement</h4>
                  <p>Create a PDF agreement for the vendor to complete and sign</p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

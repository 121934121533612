import { Injectable } from '@angular/core';
import { PaginationInfo } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CalculateTablePaginationService {
  constructor() {}

  calculate(info: PaginationInfo, SHOW_N_AROUND: number) {
    const currentPage = info.pageNumber;
    const totalPage = Math.ceil(info.total / info.pageSize);
    const displayPageArray = Array(totalPage)
      .fill(0)
      .map((x, i) => i + 1);

    const numToCutBeforeCurrentPage = currentPage - SHOW_N_AROUND - 2;
    const numToCutAfterCurrentPage = totalPage - currentPage - SHOW_N_AROUND - 1;

    if (numToCutBeforeCurrentPage > 0) {
      displayPageArray.splice(1, numToCutBeforeCurrentPage, -1);
    }

    if (numToCutAfterCurrentPage > 0) {
      displayPageArray.splice(displayPageArray.findIndex((i) => i === currentPage) + SHOW_N_AROUND + 1, numToCutAfterCurrentPage, -1);
    }

    return { currentPage, totalPage, displayPageArray };
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { Subscription } from 'rxjs';
import { ApplicationMode } from '../../models';
import { ApplicationModeService } from '../../services';

export class BaseLayoutComponent implements OnInit, OnDestroy {
  protected bodyClasses: string[];

  applicationMode$: Subscription;
  applicationModeClasses: string[] = ['live-mode', 'training-mode'];

  hideSideMenu = true;
  hideSideMenuClass = 'hide-side-menu';

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected appStateService: AppStateService,
    protected applicationModeService: ApplicationModeService
  ) {
    this.handleApplicationMode();
    this.handleSideMenuDisplay();
  }

  ngOnInit() {
    this.bodyClasses.forEach((c) => this.renderer.addClass(this.document.body, c));
  }

  ngOnDestroy() {
    const removedClasses = this.bodyClasses.concat(...this.applicationModeClasses, this.hideSideMenuClass);
    removedClasses.forEach((c) => this.renderer.removeClass(this.document.body, c));
  }

  private handleApplicationMode(): void {
    this.applicationModeService.currentApplicationMode$.subscribe((appMode) => {
      const applicationModeClass = appMode === ApplicationMode.Live ? 'live-mode' : 'training-mode';

      this.applicationModeClasses.forEach((c) => this.renderer.removeClass(this.document.body, c));
      this.renderer.addClass(this.document.body, applicationModeClass);
    });
  }

  private handleSideMenuDisplay(): void {
    this.appStateService.hideSideMenu$.subscribe((hideSideMenu) => {
      if (hideSideMenu) {
        this.renderer.addClass(this.document.body, this.hideSideMenuClass);
      } else {
        this.renderer.removeClass(this.document.body, this.hideSideMenuClass);
      }
      this.hideSideMenu = hideSideMenu;
    });
  }
}

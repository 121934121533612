import { Pipe, PipeTransform } from '@angular/core';
import { SoldCampaignReceivableStatus } from '../models';

@Pipe({
  name: 'soldCampaignReceivableStatus'
})
export class SoldCampaignReceivableStatusPipe implements PipeTransform {
  transform(status: SoldCampaignReceivableStatus): string {
    switch (status) {
      case SoldCampaignReceivableStatus.Approved:
        return 'Approved';
      case SoldCampaignReceivableStatus.Cancelled:
        return 'Cancelled';
      case SoldCampaignReceivableStatus.Invalid:
        return 'Action Required';
      case SoldCampaignReceivableStatus.Submitted:
        return 'Submitted';
      case SoldCampaignReceivableStatus.Unsubmitted:
        return 'Application Started';
      case SoldCampaignReceivableStatus.WaitingForApproval:
        return 'Waiting For Approval';
      case SoldCampaignReceivableStatus.WaitingForAuthorisation:
        return 'Waiting For Authorisation';
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { getGlobalVpaSubStatusDescription } from 'apps/legacy/src/app/_helpers/application-status-helpers';

@Pipe({
  name: 'globalVpaSubStatus'
})
export class GlobalVpaSubStatusPipe implements PipeTransform {
  transform(globalVpaSubStatus: string): string {
    return getGlobalVpaSubStatusDescription(globalVpaSubStatus);
  }
}

import { Address } from '@ca/shared/models';

export function getFullAddress(address: Address, showState: boolean = false, showCountry: boolean = false): string {
  let fullAddress = '';

  if (!address) {
    return fullAddress;
  }

  if (address.subNumber) {
    fullAddress = fullAddress + address.subNumber + '/';
  }

  if (address.streetNumber) {
    fullAddress = fullAddress + address.streetNumber + ' ';
  }

  if (address.street) {
    fullAddress = fullAddress + address.street;
  }

  if (address.suburb) {
    fullAddress = fullAddress + ', ' + address.suburb;
  }

  if (address.state && showState) {
    fullAddress = fullAddress + ', ' + address.state;
  }

  if (address.country && showCountry) {
    fullAddress = fullAddress + ', ' + address.country;
  }

  return fullAddress;
}

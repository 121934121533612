import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AppSettingsService, AppStateService } from '@ca/shared/app-state';
import { ApplicationMode, ApplicationModeService } from '@ca/shared/application-mode';
import { ApplicationLoginContext, basePathForApplication, CampaignAgentApplications, RouteResources, UserTypes } from '@ca/shared/models';
import { UserEventsService } from '@ca/shared/user-events';
import { of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BasicRouteService {
  protected applicationMode: ApplicationMode;
  protected _forgetCurrentUrlOnNextNavigation: boolean;

  constructor(
    protected router: Router,
    protected applicationModeService: ApplicationModeService,
    protected appState: AppStateService,
    protected userEventsService: UserEventsService,
    @Inject('application') protected campaignAgentApplication: CampaignAgentApplications
  ) {
    this.applicationModeService.applicationMode$.subscribe((appMode) => (this.applicationMode = appMode));
  }

  navigate(app: CampaignAgentApplications, url: string, extras?: NavigationExtras) {
    if (app === this.campaignAgentApplication) {
      if (this._forgetCurrentUrlOnNextNavigation) {
        extras = extras ? extras : ({} as NavigationExtras);
        extras.replaceUrl = true;
        this._forgetCurrentUrlOnNextNavigation = false;
      }

      this.router.navigate([url], extras);
    } else {
      // Make sure the url does not have leading '/'
      let path = `${basePathForApplication(app)}/${url}`;
      if (app !== CampaignAgentApplications.Legacy) {
        // Add preceding '/' to remove the base href for the current application
        path = `/${path}`;
      }
      if (!!extras?.queryParams) {
        path = Object.keys(extras.queryParams).reduce(
          (prev, curr, idx) => `${prev}${idx === 0 ? '?' : '&'}${curr}=${extras.queryParams[curr]}`,
          path
        );
      }
      window.location.href = path;
    }
  }

  home() {
    const routeAgencyGroupUserToHome$ = () =>
      this.appState.applicationLoginContext$.pipe(
        first(),
        map((context) => {
          switch (context) {
            case ApplicationLoginContext.Portal:
              return () =>
                this.navigate(
                  CampaignAgentApplications.VpaPay,
                  AppSettingsService.appSettings.featureFlags.isCampaignListsUsingAngularEnabled
                    ? RouteResources.marketingRouteResources.portalCampaignLists('action-required')
                    : RouteResources.marketingRouteResources.actionRequired()
                );

            case ApplicationLoginContext.AgentApp:
              return () =>
                this.navigate(
                  CampaignAgentApplications.Legacy,
                  AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                    ? RouteResources.listAllCampaigns(this.applicationMode)
                    : RouteResources.listings(this.applicationMode)
                );
          }

          return;
        })
      );

    this.appState.currentUserType$
      .pipe(
        first(),
        switchMap((userType) => {
          switch (userType) {
            case UserTypes.AgencyGroup:
              return routeAgencyGroupUserToHome$();

            // It looks like at the moment only AgencyGroup users use this method, but just incase
            // default to the listings page to keep backwards compatability
            default:
              this.userEventsService.addUserEvent('Legacy.RouteToFallbackHomeDestination');
              return of(() =>
                this.navigate(
                  CampaignAgentApplications.Legacy,
                  AppSettingsService.appSettings.featureFlags.showNewListingsComponent
                    ? RouteResources.listAllCampaigns(this.applicationMode)
                    : RouteResources.listings(this.applicationMode)
                )
              );
          }
        })
      )
      .subscribe((routingFunc) => routingFunc());
  }

  forgetCurrentUrlOnNextNavigation() {
    this._forgetCurrentUrlOnNextNavigation = true;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vpa-pay-now-direct-payment-details-modal',
  templateUrl: './pay-now-direct-payment-details-modal.component.html',
  styleUrls: ['./pay-now-direct-payment-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayNowDirectPaymentDetailsModalComponent implements OnInit {
  @Input() input: PaymentDetailsComponentState;
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() goToApplication: EventEmitter<number> = new EventEmitter();
  @Output() goToCampaign: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClose(): void {
    this.close.emit();
  }

  onGoToApplication(applicationId: number): void {
    this.goToApplication.emit(applicationId);
  }

  onViewCampaign(): void {
    this.goToCampaign.emit(this.input.applications[0].cpn);
  }
}

export interface PaymentDetailsComponentState {
  bPayDetails: PaymentDetailsBPayDetails;
  fatZebraDetails: PaymentDetailsFatZebraDetails;
  applications: PaymentDetailsApplications[];
}

export interface PaymentDetailsBPayDetails {
  paymentDate: Date;
  amount: string;
  crn: string;
  paymentInstructionType: string;
  errorCorrectionReason: string;
  payerReferenceNumber: string;
  payerCode: string;
}

export interface PaymentDetailsFatZebraDetails {
  paymentDate: Date;
  amount: string;
  surcharge: string;
  cardHolderName: string;
  cardExpiry: string;
  partialCardNumber: string;
  referenceCode: string;
}

export interface PaymentDetailsApplications {
  applicationId: number;
  cpn: string;
  address: string;
  amount: string;
  paidToAgencyOn: string;
}

<section [formGroup]="formData.form" class="section-card">
  <div
    class="alert alert-info border-blue m-b-10 m-t-10"
    role="alert"
    style="border: 2px solid #31708f; border-radius: 4px !important; display: flow-root; font-size: 18px;"
  >
    <i class="fa fa-lg fa-info-circle" style="float: left; margin-right: 10px; margin-top: 4px;"></i>
    <div>
      {{ currentInfoText }}
    </div>
  </div>

  <ng-container *ngIf="summaryState$ | async as summaryState">
    <div class="section-title">Total Amount: {{ summaryState.totalAuthorisedAmount | currency }}</div>
    <ng-container
      *ngIf="
        summaryState.paymentDetailsOptionType === paymentDetailsOptionType.FatZebra &&
        summaryState.amountOutstanding < summaryState.totalAuthorisedAmount
      "
    >
      <div class="section-title m-t-10">
        Amount Paid: {{ summaryState.totalAuthorisedAmount - summaryState.amountOutstanding | currency }}
      </div>
      <div class="section-title m-t-10">Amount Remaining: {{ summaryState.amountOutstanding | currency }}</div>
    </ng-container>
  </ng-container>

  <div class="section-title m-t-20">
    {{ state.creditCardFlowText.mainLabel }}
  </div>
  <div class="row m-t-15">
    <div class="col-lg-12 col-md-9 col-sm-12 multi-switch-container">
      <div class="multi-switcher">
        <fieldset [disabled]="state.isPaymentTypeLocked">
          <div class="radio-btn">
            <input id="FZ-One" type="radio" formControlName="creditCardAmountWorkflowType" value="one" />
            <label for="FZ-One">
              {{ state.creditCardFlowText.singleCardOptionText }}
            </label>
          </div>
          <div class="radio-btn">
            <input id="FZ-Multiple" type="radio" formControlName="creditCardAmountWorkflowType" value="multiple" />
            <label for="FZ-Multiple">
              {{ state.creditCardFlowText.multipleCardOptionText }}
            </label>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</section>

<section class="section-card" *ngIf="state.showCreditCardInputs || state.creditCardContributions.length">
  <div class="row">
    <div class="col-lg-7 col-md-9 col-sm-12 contribution-container">
      <div *ngIf="state.showIframe && !state.showModal" [ngSwitch]="state.creditCardProcessorMode">
        <vpa-fat-zebra-iframe
          *ngSwitchCase="creditCardProcessorModeEnum.FatZebra"
          [iframeUrl]="state.iframeUrl"
          id="fat-zebra-iframe"
        ></vpa-fat-zebra-iframe>
        <vpa-payway-iframe
          *ngSwitchCase="creditCardProcessorModeEnum.PayWay"
          [state]="state.paywayState"
          (savePayment)="onSavePaywayPayment()"
          id="payway-iframe"
        ></vpa-payway-iframe>
      </div>

      <div class="summary-container" *ngIf="state.creditCardContributions.length">
        <vpa-credit-card-contributions-summary
          [contributions]="state.creditCardContributions"
          [useChargeOnSubmission]="true"
          [failedTransactionReferences]="state.failedFatZebraReferences"
          (contributionRemoved)="onContributionRemoved($event)"
        ></vpa-credit-card-contributions-summary>
      </div>

      <div *ngIf="!state.showModal" class="action-item-wrapper">
        <div *ngIf="state.fatZebraErrors.length === 0 && !state.creditCardErrors">
          <vpa-action-item
            *ngIf="(summaryState$ | async).amountOutstanding === 0 && state.fatZebraErrors.length === 0"
            iconClass="fa-check-circle"
            customContainerClass="proceed-btn"
            [buttonText]="state.allowSubmission ? state.paymentSubmissionButtonText : ''"
            (buttonClicked)="onSubmit()"
            id="credit-card-submit"
          >
            You are ready to proceed to the next step.
            <div class="section-description">{{ state.paymentSubmissionExplanationText }}</div>
          </vpa-action-item>

          <vpa-action-item *ngIf="state.creditCardContributions.length === 0" iconClass="fa-question-circle">
            Please enter your credit card details above
          </vpa-action-item>

          <vpa-action-item
            *ngIf="state.creditCardContributions.length !== 0 && (summaryState$ | async).amountOutstanding !== 0"
            iconClass="fa-exclamation-triangle"
            buttonText="Add more cards?"
            customContainerClass="add-more-cards-btn"
            (buttonClicked)="onOpenModalClicked()"
          >
            It looks like there is still ${{ (summaryState$ | async).amountOutstanding }} outstanding.
          </vpa-action-item>
        </div>

        <vpa-action-item
          *ngIf="state.fatZebraErrors.length !== 0"
          iconClass="fa-exclamation-circle"
          buttonText="Try another card"
          (buttonClicked)="onTryAnotherCard()"
        >
          We were unable to process the following credit cards:
          <ul>
            <li *ngFor="let error of state.fatZebraErrors">
              {{ error }}
            </li>
          </ul>
        </vpa-action-item>

        <ng-container *ngIf="state.creditCardErrors">
          <vpa-action-item *ngFor="let error of state.creditCardErrors" iconClass="fa-exclamation-circle">
            {{ error }}
          </vpa-action-item>
        </ng-container>
      </div>
      <div *ngIf="state.creditCardProcessorMode === creditCardProcessorModeEnum.PayWay" class="row credit-card-desc">
        <div class="col-12 font-14">
          <p>
            You authorise the debit of your credit/debit card for the Payment Amount above. You understand that a non-refundable card
            surcharge applies to all American Express (2.2%), Visa (0.9%) and MasterCard (0.9%) transactions. You understand that an initial
            charge of $0.01 will be made to confirm that the card is valid and working. Please note that the transaction will appear as
            Campaign Agent Pty Ltd on your card statement.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<vpa-credit-card-multiple-contributions-modal
  *ngIf="state.showModal"
  [state]="state.creditCardWorkflowState"
  [formData]="formData.workflowFormData"
  (nextButtonClicked)="onNextButtonClicked()"
  (backButtonClicked)="onBackButtonClicked()"
  (savePayWayPayment)="onSavePaywayPayment()"
></vpa-credit-card-multiple-contributions-modal>

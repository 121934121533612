import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedNavigationMenuModule } from '@ca/shared/navigation-menu';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MainNavigationComponent } from './main-nav/main-nav.component';
import { HeaderNavComponent } from './main/header-nav/header-nav.component';
import { MainComponent } from './main/main.component';
import { SwitchAccountModalComponent } from './switch-account-modal/switch-account-modal.component';
import { SwitchAccountComponent } from './switch-account/switch-account.component';

@NgModule({
  imports: [CommonModule, RouterModule, MDBBootstrapModulesPro, ReactiveFormsModule, SharedNavigationMenuModule],
  declarations: [MainComponent, MainNavigationComponent, HeaderNavComponent, SwitchAccountComponent, SwitchAccountModalComponent],
  exports: [MainComponent, MainNavigationComponent, HeaderNavComponent, SwitchAccountComponent, SwitchAccountModalComponent]
})
export class SharedMainNavModule {}

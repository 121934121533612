<div class="ca-modal fade-in" [formGroup]="formData.form">
  <div class="ca-dialog">
    <div class="ca-content auto-height warning-modal float-down">
      <div class="ca-header">
        <h5 class="modal-title w-100">{{ state.title }}</h5>
      </div>
      <div class="ca-body modal-body p-b-10">
        <div [ngSwitch]="state.workflowStep">
          <div *ngSwitchCase="workflowStepEnum.SelectNumberOfCards">
            <ng-container [ngSwitch]="state.paymentDetailsOptionType">
              <ng-container *ngSwitchCase="paymentDetailsOptionType.FatZebra">
                I would like to pay with
                <input type="number" class="form-control card-number-input" [formControl]="formData.creditCardNumberOfCardsControl" />
                card/s today
              </ng-container>
              <ng-container *ngSwitchDefault>
                I would like to pay the full balance across
                <input type="number" class="form-control card-number-input" [formControl]="formData.creditCardNumberOfCardsControl" />
                cards
              </ng-container>
            </ng-container>

            <vpa-control-validation-message
              [control]="formData.creditCardNumberOfCardsControl"
              fieldName="Number of cards"
              [isSubmitted]="state.isSubmitted"
            ></vpa-control-validation-message>
          </div>

          <div *ngSwitchCase="workflowStepEnum.SelectAmountForCard">
            I would like to pay
            <div class="input-group card-amount">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                id="search-listing-address-input"
                type="number"
                [formControl]="formData.currentRequestedAmountControl"
                vpaMaxTwoDigitalDecimal
              />
            </div>
            of the remaining ${{ state.amountOutstanding }} on my {{ state.cardNumberWord }} card

            <vpa-control-validation-message
              [control]="formData.currentRequestedAmountControl"
              fieldName="Pay now total"
              [isSubmitted]="state.isSubmitted"
            ></vpa-control-validation-message>

            <div *ngIf="state.isTryingToPayMoreThanOutstanding" class="validation-message">
              <span>You can not pay more than the outstanding value</span>
            </div>
          </div>

          <div *ngSwitchCase="workflowStepEnum.FatZebraIframe">
            <vpa-fat-zebra-iframe *ngIf="state.showIframe" [iframeUrl]="state.iframeUrl"></vpa-fat-zebra-iframe>
          </div>

          <div *ngSwitchCase="workflowStepEnum.PayWayIframe">
            <vpa-payway-iframe
              *ngIf="state.showIframe"
              [state]="state.paywayState"
              (savePayment)="onSavePaywayPayment()"
            ></vpa-payway-iframe>
          </div>

          <ng-container *ngIf="state.creditCardErrors">
            <vpa-action-item *ngFor="let error of state.creditCardErrors" iconClass="fa-exclamation-circle">
              {{ error }}
            </vpa-action-item>
          </ng-container>

          <div class="ca-footer m-t-10">
            <button
              *ngIf="state.buttonDetails.backButtonText"
              type="button"
              class="btn btn-secondary m-r-10"
              mdbWavesEffect
              (click)="onBackButtonClicked()"
            >
              {{ state.buttonDetails.backButtonText }}
            </button>
            <button
              *ngIf="state.buttonDetails.forwardButtonText"
              type="button"
              class="btn btn-primary m-r-10"
              mdbWavesEffect
              (click)="onForwardButtonClicked()"
            >
              {{ state.buttonDetails.forwardButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnelectedVendorResponse } from '../../models';
import { UnelectedVendorErrorsService } from '../../services';

@Component({
  selector: 'vpa-unelected-vendor-error-modal',
  templateUrl: './unelected-vendor-error-modal.component.html',
  styleUrls: ['./unelected-vendor-error-modal.component.scss']
})
export class UnelectedVendorErrorModalComponent implements OnInit {
  unelectedVendorResponse: UnelectedVendorResponse;

  constructor(private route: ActivatedRoute, private unelectedVendorErrorsService: UnelectedVendorErrorsService) {}

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('Token');
    const verifier = this.route.snapshot.queryParamMap.get('Verifier');
    const vpaApplicationId = this.route.snapshot.paramMap.get('applicationId');

    if (token || verifier || vpaApplicationId) {
      this.unelectedVendorErrorsService
        .getUnelectedVendorError({
          token: token || '',
          verifier: verifier || '',
          vpaApplicationId: vpaApplicationId || ''
        })
        .subscribe((response) => (this.unelectedVendorResponse = response));
    }
  }

  close() {
    this.unelectedVendorResponse = null;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { distinctUntilValueChanged } from '@ca/shared/helpers';
import { AgencyGroupUserRoles, UserTypes } from '@ca/shared/models';
import * as Sentry from '@sentry/angular';
import * as jwtDecode from 'jwt-decode';
import LogRocket from 'logrocket';
import { BehaviorSubject, combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { AgencyGroupType } from './agency-group-type';
import { AppSettingsService } from './app-settings-service';
import { AppState } from './app-state';
import { GetVpaApplySettingService } from './get-vpa-apply-setting.service';
import { RegenerateJwtResponse } from './regenerate-jwt-response';
import { SwitchableLogin } from './switchable-login';
import { VpaApplySettings } from './vpa-apply-settings';

declare global {
  interface Window {
    analytics: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private readonly appStateSubject: BehaviorSubject<AppState> = new BehaviorSubject(null);
  readonly appState$ = this.appStateSubject.asObservable().pipe(filter((a) => !!a));
  private authToken: string;

  private readonly applicationBrandingDomainSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  readonly applicationBrandingDomain$ = this.applicationBrandingDomainSubject.asObservable().pipe(filter((a) => !!a));

  // Explicitly use appStateSubject.asObservable() here as appState$ filters out nulls,
  // which are the key to this check
  readonly isAuthenticated$: Observable<boolean> = this.appStateSubject.asObservable().pipe(
    map((a) => !!a),
    distinctUntilChanged()
  );

  readonly currentUserType$: Observable<string> = this.appState$.pipe(
    map((a) => a['CampaignAgent.CurrentUserType']),
    distinctUntilChanged()
  );
  readonly isPendingMfa$: Observable<boolean> = this.appState$.pipe(
    map((a) => a['CampaignAgent.PendingMfa'] && a['CampaignAgent.PendingMfa'].toLowerCase() === 'true'),
    distinctUntilChanged()
  );
  readonly roles$: Observable<string[]> = this.appState$.pipe(
    map((a) => {
      if (Array.isArray(a.role)) {
        return a.role;
      }

      return [a.role];
    })
  );

  readonly applicationLoginContext$: Observable<string> = this.appState$.pipe(
    map((a) => a.ApplicationLoginContext),
    distinctUntilChanged()
  );

  readonly agencyUserName$: Observable<string> = this.appState$.pipe(
    map((a) => a.unique_name),
    distinctUntilChanged()
  );

  readonly actualUserAggregateId$: Observable<string> = this.appState$.pipe(
    map((a) => a.ActualUserAggregateIdClaimType),
    distinctUntilChanged()
  );

  readonly agencyGroupName$: Observable<string> = this.appState$.pipe(
    map((a) => a['CampaignAgent.AgencyGroupName']),
    distinctUntilChanged()
  );

  readonly switchableLogins$: Observable<SwitchableLogin[]> = this.appState$.pipe(
    map((a) => (a.SwitchableLogins ? JSON.parse((a.SwitchableLogins as unknown) as string) : [])),
    distinctUntilValueChanged()
  );

  readonly isByoPayNowEnabled$: Observable<boolean> = this.appState$.pipe(
    map((a) => !!a.IsByoPayNowEnabled && a.IsByoPayNowEnabled.toLocaleLowerCase() === 'true'),
    distinctUntilChanged()
  );

  readonly hasByoBpayUnreconciledEntries$: Observable<boolean> = this.appState$.pipe(
    map((a) => !!a.HasByoBpayUnreconciledEntries && a.HasByoBpayUnreconciledEntries.toLocaleLowerCase() === 'true'),
    distinctUntilChanged()
  );

  readonly isListinglessApplicationsEnabled$: Observable<boolean> = this.appState$.pipe(
    map((a) => a.VpaApplySettings?.IsListinglessApplicationsEnabled),
    distinctUntilChanged()
  );

  readonly hideSideMenu$: Observable<boolean> = this.appState$.pipe(
    map((a) => a['CampaignAgent.HideSideMenu'] && a['CampaignAgent.HideSideMenu'].toLocaleLowerCase() === 'true'),
    distinctUntilChanged()
  );

  readonly assumedUserId$: Observable<number> = this.appState$.pipe(
    map((a) => parseInt(a['CampaignAgent.AssumedUserId'], 10)),
    distinctUntilChanged()
  );

  readonly isImpersonating$: Observable<boolean> = this.appState$.pipe(
    map((a) => parseInt(a['CampaignAgent.AssumedUserId'], 10) !== parseInt(a['CampaignAgent.ActualUserId'], 10)),
    distinctUntilChanged()
  );

  readonly sandboxedCpn$: Observable<string> = this.appState$.pipe(
    map((a) => a['CampaignAgent.SandBoxedCpnClaimType']),
    distinctUntilChanged()
  );

  readonly apiConsumerVpaApplicationAggregateId$: Observable<string> = this.appState$.pipe(
    map((a) => a['Campaignagent.ApiConsumerVpaApplicationAggregateId']),
    distinctUntilChanged()
  );

  readonly agencyAggregateId$: Observable<string> = this.appState$.pipe(
    map((a) => a['Campaignagent.AgencyAggregateId']),
    distinctUntilChanged()
  );

  readonly agencyGroupBrandingSubdomain$: Observable<string> = this.appState$.pipe(
    map((a) => a.VpaApplySettings?.BrandingDomain),
    distinctUntilChanged()
  );

  readonly agencyBrandingSubdomain$: Observable<string> = this.appState$.pipe(
    map((a) => a.AgencyBrandingDomain),
    distinctUntilChanged()
  );

  readonly brandingSubdomain$ = combineLatest([
    this.agencyBrandingSubdomain$.pipe(startWith('')),
    this.applicationBrandingDomain$.pipe(startWith('')),
    this.agencyGroupBrandingSubdomain$.pipe(startWith(''))
  ]).pipe(
    map(([agencyBrandingDomain, applicationBrandingDomain, agencyGroupBrandingSubdomain]) => {
      return agencyBrandingDomain || applicationBrandingDomain || agencyGroupBrandingSubdomain;
    }),
    distinctUntilChanged()
  );

  readonly isCurrentUserAVpaApplyVendor$: Observable<boolean> = this.currentUserType$.pipe(
    map((currentUserType: string) => currentUserType === UserTypes.Vendor),
    distinctUntilChanged()
  );

  readonly isCurrentUserAnAdditionalVpaApplicant$: Observable<boolean> = this.currentUserType$.pipe(
    map((currentUserType: string) => currentUserType === UserTypes.AdditionalVpaApplicant),
    distinctUntilChanged()
  );

  readonly isCurrentUserAnAgencyGroupUser$: Observable<boolean> = this.currentUserType$.pipe(
    map((currentUserType: string) => currentUserType === UserTypes.AgencyGroup),
    distinctUntilChanged()
  );

  readonly isCurrentUserAnAdminUser$: Observable<boolean> = this.currentUserType$.pipe(
    map((currentUserType: string) => currentUserType === UserTypes.Admin),
    distinctUntilChanged()
  );

  readonly isCurrentUserAnyTypeOfVendor$: Observable<boolean> = combineLatest([
    this.isCurrentUserAVpaApplyVendor$,
    this.isCurrentUserAnAdditionalVpaApplicant$
  ]).pipe(
    map(
      ([isCurrentUserAVpaApplyVendor, isCurrentUserAnAdditionalVpaApplicant]) =>
        isCurrentUserAVpaApplyVendor || isCurrentUserAnAdditionalVpaApplicant
    ),
    distinctUntilChanged()
  );

  readonly isCurrentUserAnApiSandboxedUser$: Observable<boolean> = this.currentUserType$.pipe(
    map((currentUserType: string) => currentUserType === UserTypes.ApiSandBoxed),
    distinctUntilChanged()
  );

  readonly isDigitalAdditionalVpaEnabled$: Observable<boolean> = this.appState$.pipe(
    map((a) => a.VpaApplySettings?.IsDigitalAdditionalVpaEnabled),
    distinctUntilChanged()
  );

  readonly isVendorFlowEnabled$: Observable<boolean> = this.appState$.pipe(
    map((a) => a.VpaApplySettings?.IsVendorFlowEnabled),
    distinctUntilChanged()
  );

  readonly getVpaApplySettings$: Observable<VpaApplySettings> = combineLatest([this.isCurrentUserAnAdminUser$, this.appState$]).pipe(
    switchMap(([isCurrentUserAnAdminUser, appState]) => {
      return isCurrentUserAnAdminUser ? this.setAgencyGroupIdForAdmin() : of(appState.VpaApplySettings);
    }),
    distinctUntilValueChanged()
  );

  readonly hasSuperUserRole$: Observable<boolean> = combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
    map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.SuperUser)),
    distinctUntilChanged()
  );

  readonly hasFinanceRole$: Observable<boolean> = combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
    map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.Finance)),
    distinctUntilChanged()
  );

  readonly hasMarketingRole$: Observable<boolean> = combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
    map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.Marketing)),
    distinctUntilChanged()
  );

  readonly hasAgentRole$: Observable<boolean> = combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
    map(([isAgencyGroupUser, roles]) => isAgencyGroupUser && roles.includes(AgencyGroupUserRoles.Agent)),
    distinctUntilChanged()
  );

  readonly hasPortalAccess$: Observable<boolean> = combineLatest([this.isCurrentUserAnAgencyGroupUser$, this.roles$]).pipe(
    map(
      ([isAgencyGroupUser, roles]) =>
        isAgencyGroupUser &&
        (roles.includes(AgencyGroupUserRoles.SuperUser) ||
          roles.includes(AgencyGroupUserRoles.Marketing) ||
          roles.includes(AgencyGroupUserRoles.Finance))
    ),
    distinctUntilChanged()
  );

  readonly isCurrentUserAnAgent$: Observable<boolean> = combineLatest([this.currentUserType$, this.hasAgentRole$]).pipe(
    map(([currentUserType, hasAgentRole]) => currentUserType === UserTypes.Agent || hasAgentRole)
  );

  readonly isCurrentUserAVpaDotMobiAgent$: Observable<boolean> = this.currentUserType$.pipe(
    map((currentUserType) => currentUserType === UserTypes.AgentVpaDotMobi)
  );

  readonly isAgentSmartCommissionEnabled$: Observable<boolean> = this.appState$.pipe(
    map((a) => !!a.IsAgentSmartCommissionEnabled && a.IsAgentSmartCommissionEnabled.toLocaleLowerCase() === 'true'),
    distinctUntilChanged()
  );

  readonly isSigningVendor$: Observable<boolean> = this.appState$.pipe(
    map((a) => !!a.IsSigningVendor && a.IsSigningVendor.toLocaleLowerCase() === 'true'),
    distinctUntilChanged()
  );

  readonly authorityDocumentIncludesMarketingSchedule$: Observable<boolean> = this.appState$.pipe(
    map(
      (a) => !!a.AuthorityDocumentIncludesMarketingSchedule && a.AuthorityDocumentIncludesMarketingSchedule.toLocaleLowerCase() === 'true'
    ),
    distinctUntilChanged()
  );

  readonly isIllionBankStatementsEnabled$: Observable<boolean> = this.appState$.pipe(
    map((a) => a?.IllionBankStatementsSettings?.IsIllionBankStatementsEnabled ?? false),
    distinctUntilChanged()
  );

  readonly isPayLaterEnabled$: Observable<boolean> = this.getVpaApplySettings$.pipe(
    map((v) => v?.AllowPayLater ?? false),
    distinctUntilChanged()
  );

  readonly isPdfFlowEnabled$: Observable<boolean> = this.getVpaApplySettings$.pipe(
    map((v) => v?.IsPdfFlowEnabled ?? false),
    distinctUntilChanged()
  );

  readonly agencyGroupType$: Observable<AgencyGroupType> = this.appState$.pipe(
    map((a) => a.AgencyGroupType),
    distinctUntilChanged()
  );

  readonly isAgentAbleToViewOtherAgentsListing$: Observable<boolean> = this.getVpaApplySettings$.pipe(
    map((v) => v?.IsAgentAbleToViewOtherAgentsListing ?? false),
    distinctUntilChanged()
  );

  readonly canAgentsCreatePdfApplication$: Observable<boolean> = this.getVpaApplySettings$.pipe(
    map((v) => v?.CanAgentsCreatePdfApplication ?? false),
    distinctUntilChanged()
  );

  readonly agentImportAggregateId$: Observable<string> = this.appState$.pipe(
    map((a) => a.AgentImportAggregateId),
    distinctUntilChanged()
  );

  readonly isAgentContributionsEnabled$: Observable<boolean> = this.appState$.pipe(
    this.getValueOrReloadTokenOnBadValue((a) => a.AgentContributionsSettings.IsEnabled),
    distinctUntilChanged()
  );

  constructor(private ngZone: NgZone, private getVpaApplySettingService: GetVpaApplySettingService, private http: HttpClient) {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      return;
    }

    this.setStateFromAuthToken(authToken);
  }

  getAuthToken(): string {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      return '';
    }

    return authToken;
  }

  saveAuthToken(authToken): void {
    localStorage.setItem('authToken', authToken);
    this.setStateFromAuthToken(authToken);
  }

  isInitialisedFromCurrentAuthToken = () => {
    if (!this.appStateSubject.value) {
      return false;
    }

    return this.authToken === localStorage.getItem('authToken');
  };

  initialiseState = (): void => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      return;
    }

    this.setStateFromAuthToken(authToken);
  };

  private setStateFromAuthToken(authToken: string): void {
    this.authToken = authToken;
    try {
      const appState = jwtDecode<AppState>(authToken);
      const currentUserType = appState['CampaignAgent.CurrentUserType'];

      if (currentUserType !== UserTypes.Admin) {
        localStorage.removeItem('agencyGroupId');
      }

      switch (currentUserType) {
        case UserTypes.Agent:
          if (AppSettingsService.appSettings.useLogRocket) {
            LogRocket.identify(appState.AgentImportAggregateId, {
              name: appState.unique_name,
              email: appState.email,
              userType: 'Agent'
            });
          }

          Sentry.setUser({ email: appState.email });
          break;

        case UserTypes.ApiSandBoxed:
          if (AppSettingsService.appSettings.useLogRocket) {
            LogRocket.identify(appState['CampaignAgent.AssumedUserId'], {
              name: appState.unique_name,
              email: appState.email,
              userType: 'Sandboxed User'
            });
          }

          Sentry.setUser({ email: appState.email });
          break;

        case UserTypes.AgencyGroup:
          if (AppSettingsService.appSettings.useLogRocket) {
            LogRocket.identify(appState['CampaignAgent.AssumedUserId'], {
              name: appState.unique_name,
              email: appState.email,
              userType: 'Agency Admin'
            });
          }

          Sentry.setUser({ email: appState.email });
          break;

        case UserTypes.Vendor:
          if (AppSettingsService.appSettings.useLogRocket) {
            LogRocket.identify(appState.VpaApplicationVendorId.toString(), {
              name: appState.unique_name,
              email: appState.email,
              userType: 'Vendor'
            });
          }

          Sentry.setUser({ email: appState.email });
          break;

        case UserTypes.VpaDotMobi:
          if (AppSettingsService.appSettings.useLogRocket) {
            LogRocket.identify(appState['CampaignAgent.AssumedUserId'], {
              name: appState.unique_name,
              email: appState.email,
              userType: 'VpaDotMobi'
            });
          }

          Sentry.setUser({ email: appState.email });
          break;

        case UserTypes.AgencyVpaDotMobi:
          if (AppSettingsService.appSettings.useLogRocket) {
            LogRocket.identify(appState['CampaignAgent.AssumedUserId'], {
              name: appState.unique_name,
              email: appState.email,
              userType: 'AgencyVpaDotMobi'
            });
          }

          Sentry.setUser({ email: appState.email });
          break;

        case UserTypes.Admin:
          if (AppSettingsService.appSettings.useLogRocket) {
            LogRocket.identify(appState['CampaignAgent.AssumedUserId'].toString(), {
              name: appState.unique_name,
              email: appState.email,
              userType: 'Admin'
            });
          }
          break;
      }

      const userTypesAllowedElevio = [UserTypes.Admin, UserTypes.AgencyGroup, UserTypes.ApiSandBoxed];

      if (userTypesAllowedElevio.includes(currentUserType)) {
        const elevioLoad = (elev: any) => {
          // If this function is run after we have cleared an expired token, bail out and handle it on next login
          if (!appState) {
            return;
          }

          elev.setSettings({
            enabled: true,
            hideLauncher: true
          });

          elev.initialize();
          elev.setUser({
            email: appState.email,
            user_hash: appState.ElevioUserHash,
            groups: appState.ElevioGroup
          });
        };

        this.ngZone.runOutsideAngular(() => {
          (window as any)._elev.on('load', elevioLoad.bind(this));
        });
      } else {
        this.ngZone.runOutsideAngular(() => {
          const elev = (window as any)._elev;
          elev.on('load', () => {
            elev.setSettings({
              autoInitialize: false,
              enabled: false
            });
          });
        });
      }

      // These are passed through as the string representation of it's JSON
      // So it doesn't get automatically deserialized
      if (appState?.VpaApplySettings) {
        appState.VpaApplySettings = JSON.parse(appState.VpaApplySettings.toString());
      }

      if (appState?.AgentContributionsSettings) {
        appState.AgentContributionsSettings = JSON.parse(appState.AgentContributionsSettings.toString());
      }

      this.appStateSubject.next(appState);
    } catch (e) {
      console.log(e);
      this.clearState();
      window.location.href = '/logout';
      return;
    }
  }

  clearState() {
    this.appStateSubject.next(null);
    this.applicationBrandingDomainSubject.next(null);
    this.authToken = null;
    localStorage.clear();
  }

  setShowInstallPopup(value: boolean): void {
    localStorage.setItem('showInstallPopup', value ? 'true' : 'false');
  }

  getShowInstallPopup(): boolean {
    return localStorage.getItem('showInstallPopup') === 'true';
  }

  setApplicationBrandingDomain(brandingDomain: string): void {
    this.applicationBrandingDomainSubject.next(brandingDomain);
  }

  private setAgencyGroupIdForAdmin(): Observable<VpaApplySettings> {
    if (!this.getVpaApplySettingService.hasBeenSetup) {
      this.getVpaApplySettingService.setAgencyGroupId(parseInt(localStorage.getItem('agencyGroupId'), 10));
    }
    return this.getVpaApplySettingService.vpaApplySettings$;
  }

  private getValueOrReloadTokenOnBadValue<T>(getter: (appState: AppState) => T, valueToReloadOn?: any): OperatorFunction<AppState, T> {
    const getNewTokenAndCheckValue = () => {
      return this.regenerateJwt().pipe(
        map((newAuthToken) => {
          const newAppState = jwtDecode<AppState>(newAuthToken);
          if (newAppState?.VpaApplySettings) {
            newAppState.VpaApplySettings = JSON.parse(newAppState.VpaApplySettings.toString());
          }
          if (newAppState?.AgentContributionsSettings) {
            newAppState.AgentContributionsSettings = JSON.parse(newAppState.AgentContributionsSettings.toString());
          }
          const value = getter(newAppState);
          if (value !== valueToReloadOn) {
            this.setStateFromAuthToken(newAuthToken);
            return getter(newAppState);
          }

          return handleFailure();
        })
      );
    };

    const handleFailure = () => {
      console.log(`Unable to load token ${getter} from appState`);
      Sentry.captureMessage(`Unable to load token ${getter} from appState`, Sentry.Severity.Warning);
      this.clearState();
      window.location.href = '/login';
      return null;
    };

    return switchMap((appState: AppState) => {
      try {
        const value = getter(appState);
        if (value !== valueToReloadOn) return of(value);

        return getNewTokenAndCheckValue();
      } catch (e) {
        return getNewTokenAndCheckValue();
      }
    });
  }

  private regenerateJwt(): Observable<string> {
    if (this.appStateSubject.value === null) {
      return of(null);
    }

    return this.http.post<RegenerateJwtResponse>('user/authentication/RegenerateJwt', {}).pipe(
      map((res) => {
        return res.authToken;
      }),
      catchError((err) => {
        console.log(err);
        this.clearState();
        window.location.href = '/login';
        return of(null);
      })
    );
  }

  // For testing
  protected updateAppState(appState: AppState) {
    this.appStateSubject.next(appState);
  }
}

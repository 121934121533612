<section class="section-card" *ngIf="state$ | async as state">
  <div class="row section-title">
    <div class="col-11">
      <label class="text-uppercase strong">
        Upload a Quote (Optional)
      </label>
      <p class="description font-14">
        You have the option to upload a quote or marketing schedule that gives a breakdown of the additional marketing. The vendor will be
        able to view this when approving their additional marketing.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="choose-file-custom m-t-10">
        <span *ngIf="!state.areFilesBeingProcessed">
          <div class="upload-btn-content">
            <span>
              <span class="d-lg-none">
                <i class="fas fa-file-upload m-r-5" style="font-size: 20px;"></i>
                Take a Photo of Quotes
              </span>
              <span class="d-none d-lg-block">
                <i class="fas fa-file-upload m-r-5" style="font-size: 20px;"></i>
                Upload Quotes
              </span>
            </span>
          </div>
        </span>
        <span *ngIf="state.areFilesBeingProcessed">
          <i class="far fa-sync-alt fa-spin m-r-5" style="font-size: 18px;"></i>
          Uploading...
        </span>
        <input
          type="file"
          accept="image/*, application/pdf"
          multiple="true"
          id="cameraInput"
          name="cameraInput"
          (change)="onFileSelected($event)"
        />
      </div>

      <div *ngIf="!state.fileValidationState.isValid" class="text-center">
        <span class="error-pill text-uppercase font-13 strong-lite">
          {{ state.fileValidationState.errorMessage }}
        </span>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="state.marketingQuotes.length > 0">
    <div class="col-sm-12">
      <div class="section-title m-t-40">
        Uploaded Quotes
      </div>
    </div>
    <div class="col-12 col-lg-4" *ngFor="let document of state.marketingQuotes; let i = index">
      <div class="upload-list">
        <a *ngIf="document.documentId" (click)="downloadDocument(document)" class="blue-text">
          {{ document.fileName }}
        </a>
        <span *ngIf="!document.documentId">
          {{ document.fileName }}
        </span>
        <button (click)="removeFile(i)">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</section>

import { Address } from '../_shared/models';

export function getAddressFromAutocompleteResponse(
  placeResult: google.maps.places.PlaceResult
): Address {
  const addressComponents = placeResult.address_components;
  return new Address().fromModel({
    subNumber: '',
    streetNumber: getAddressSegment(addressComponents, 'street_number'),
    street: getAddressSegment(addressComponents, 'route'),
    suburb: getAddressSegment(addressComponents, 'locality'),
    state: getAddressSegment(addressComponents, 'administrative_area_level_1'),
    postcode: getAddressSegment(addressComponents, 'postal_code'),
    country: getAddressSegment(addressComponents, 'country')
  });
}

function getAddressSegment(
  addressComponents: google.maps.GeocoderAddressComponent[],
  segmentTypeKey: string
): string {
  const segment = addressComponents.find(
    s => s.types.indexOf(segmentTypeKey) > -1
  );
  if (segment) {
    return segment.long_name;
  }

  return null;
}

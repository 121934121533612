import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startedDateText'
})
export class StartedDateTextPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    if (value > 1) {
      return `Starts in ${value} Days`;
    } else if (value === 1) {
      return `Starts in 1 Day`;
    } else if (value === 0) {
      return 'Starts today';
    } else if (value === -1) {
      return `Started 1 Day Ago`;
    } else {
      return `Started ${Math.abs(value)} Days Ago`;
    }
  }
}

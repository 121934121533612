import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { getFullAddress } from 'apps/legacy/src/app/_helpers/address-helpers';
import { Address, DateValidation, RepresentativeActingAs, Vendor } from 'apps/legacy/src/app/_shared/models';
import { PhoneNumberValidator } from 'apps/legacy/src/app/_shared/validators';
import { DateValidator } from 'apps/legacy/src/app/_shared/validators/date-validator';
import { EmailValidator, NullableEmailValidator } from 'apps/legacy/src/app/_shared/validators/email-validator';
import { ApplicationApplicant, Representee, VendorValidationOptions } from '../models';
import { ApplicationsFormService } from './applications-form.service';
@Injectable({
  providedIn: 'root'
})
export class RepresentativeAndVendorFormService extends ApplicationsFormService {
  constructor(protected formBuilder: FormBuilder, protected datePipe: DatePipe, protected appStateService: AppStateService) {
    super(formBuilder, datePipe, appStateService);
  }

  vendorsToValidate(applicationForm: FormGroup): AbstractControl[] {
    const vendorArray = applicationForm.get('vendors') as FormArray;
    return vendorArray.controls.filter((c) => !c.get('isDeleted').value);
  }

  populateShouldValidateArray(applicationForm: FormGroup): Map<number, boolean> {
    const shouldValidate = new Map<number, boolean>();
    const vendorArray = applicationForm.get('vendors') as FormArray;
    if (vendorArray !== null) {
      for (let i = 0; i < vendorArray.controls.length; i++) {
        shouldValidate.set(i, true);
      }
    }
    return shouldValidate;
  }

  changeVendorElectedToApply(index: number, applicationForm: FormGroup): void {
    applicationForm.patchValue({
      vendorElectedIndex: index
    });
  }

  removeAllRepresentatives(canElectVendor: boolean, applicationForm: FormGroup, isUsingNominatedVendorModal = false) {
    const allVendors = applicationForm.get('vendors').value;
    const allRepresentativeIndexes = allVendors.reduce((arr, v, i) => (v.isRepresentative && arr.push(i), arr), []);

    if (allRepresentativeIndexes.length === 0) {
      return;
    }

    allRepresentativeIndexes
      .slice()
      .reverse()
      .forEach((index) => {
        (applicationForm.get('vendors') as FormArray).removeAt(index);

        this.resetVendorElectedIndex(canElectVendor, applicationForm, index, isUsingNominatedVendorModal);
      });
  }

  resetVendorElectedIndex(canElectVendor: boolean, applicationForm: FormGroup, index: number, isUsingNominatedVendorModal = false) {
    if (!canElectVendor && !isUsingNominatedVendorModal) {
      return false;
    }

    const currentVendorElectedIndex = applicationForm.get('vendorElectedIndex').value as number;

    if (currentVendorElectedIndex === index) {
      applicationForm.patchValue({
        vendorElectedIndex: 0
      });
    }

    // If the removed one is added before the nominated vendor, should keep the current nominated vendor as elected
    if (index < currentVendorElectedIndex) {
      applicationForm.patchValue({
        vendorElectedIndex: currentVendorElectedIndex - 1
      });
    }
  }

  mapAddressToForm(address: Address, isFromPlaceResult: boolean, addressFormGroup: FormGroup) {
    const addressSearchControl = addressFormGroup.get('addressSearchControl');

    addressFormGroup.get('address.subNumber').patchValue(isFromPlaceResult ? this.getSubNumber(addressSearchControl) : address.subNumber);
    addressFormGroup.get('address.streetNumber').patchValue(address.streetNumber);
    addressFormGroup.get('address.street').patchValue(address.street);
    addressFormGroup.get('address.suburb').patchValue(address.suburb);
    addressFormGroup.get('address.state').patchValue(address.state);
    addressFormGroup.get('address.postcode').patchValue(address.postcode);
    addressFormGroup.get('addressSearchControl').patchValue(getFullAddress(address));
  }

  addNewApplicant(
    form: FormGroup,
    isRepresentative: boolean,
    addIsElectedToApply: boolean,
    vendorValidationOptions: VendorValidationOptions,
    propertyAddress: Address,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ) {
    const applicant = new ApplicationApplicant();
    if (addIsElectedToApply) {
      applicant.isElectedToApply = true;
    }

    applicant.isRepresentative = isRepresentative;

    if (isRepresentative) {
      applicant.representations = [new Representee()];
    } else {
      applicant.isNewVendor = true;
    }

    (form.get('vendors') as FormArray).push(
      this.mapApplicationApplicantsFormControl(
        applicant,
        vendorValidationOptions,
        propertyAddress,
        morePowerOfAttorneyFieldsForClassicVpaPay
      )
    );
  }

  protected mapApplicationApplicantsFormControl(
    applicant: ApplicationApplicant,
    vendorValidationOptions: VendorValidationOptions,
    propertyAddress: Address = null,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ) {
    const representationsArray = this.formBuilder.array(
      applicant.representations.map((a) => this.mapApplicationRepresentationFormControl(a, morePowerOfAttorneyFieldsForClassicVpaPay))
    );
    const address = !applicant.address || !applicant.address.street ? propertyAddress : applicant.address;
    const applicantsOptions = {
      ...this.mapAddress(address),
      addressSearchControl: [this.mapSearchAddressControl(!!propertyAddress ? propertyAddress : applicant.address), Validators.required],
      dateOfBirth: [
        applicant.dateOfBirth ? { type: 'ok', data: new Date(applicant.dateOfBirth) } : { type: 'invalidDate' },
        vendorValidationOptions.isDateOfBirthMandatory ? DateValidator.validDate : []
      ],
      email: [applicant.emailAddress, NullableEmailValidator(vendorValidationOptions.isEmailMandatory)],
      firstName: [applicant.firstName, Validators.required],
      lastName: [applicant.lastName, Validators.required],
      mobile: [applicant.mobile, PhoneNumberValidator('AU', vendorValidationOptions.isMobileMandatory)],
      isDeleted: [applicant.isDeleted],
      companyName: [applicant.companyDetails?.companyName, Validators.required],
      acn: [applicant.companyDetails?.acn],
      vendorImportAggregateId: [applicant.vendorImportAggregateId],
      vpaApplicationVendorId: [applicant.vpaApplicationVendorId],
      representations: representationsArray,
      isRepresentative: applicant.isRepresentative ?? representationsArray?.length > 0,
      isNewVendor: applicant.isNewVendor
    };

    const applicantsGroup = this.formBuilder.group(applicantsOptions);

    return applicantsGroup;
  }

  addNewVendor(form: FormGroup, includeExtendedVendorDetails: boolean) {
    (form.get('vendors') as FormArray).push(this.mapVendorFormControl(new Vendor(), includeExtendedVendorDetails));
  }

  removeVendor(vendorGroup: FormGroup) {
    vendorGroup.patchValue({
      isDeleted: true
    });

    // disable validation when vendor deleted
    Object.keys(vendorGroup.controls).forEach((key) => {
      vendorGroup.get(key).clearValidators();
      vendorGroup.get(key).updateValueAndValidity();
    });
  }

  clearNameAtIndex(vendors: FormArray, index: number): void {
    const vendor = vendors.controls[index] as FormGroup;

    vendor.patchValue({
      firstName: '',
      lastName: ''
    });
  }

  addNewRepresentation(representativesGroup: FormGroup, morePowerOfAttorneyFieldsForClassicVpaPay: boolean): void {
    (representativesGroup.get('representations') as FormArray).push(
      this.mapApplicationRepresentationFormControl(new Representee(), morePowerOfAttorneyFieldsForClassicVpaPay)
    );
  }

  mapFormToAddress(addressFormGroup: FormGroup): Address {
    return this.mapFormGroupToAddress(addressFormGroup);
  }

  getNominatedVendorId(form: FormGroup): number {
    const nominatedVendorIndex = form.get('vendorElectedIndex').value;
    const nominatedVendorControl = (form.get('vendors') as FormArray).at(nominatedVendorIndex);
    return nominatedVendorControl?.value.vpaApplicationVendorId;
  }

  updateVendorAddresses(form: FormGroup, address: Address) {
    const vendors = form.get('vendors') as FormArray;

    vendors.controls.forEach((control) => {
      if (control.get('addressSearchControl').value) {
        return;
      }

      this.mapAddressToForm(address, false, control as FormGroup);
    });
  }

  removeListingVendors(form: FormGroup) {
    const vendorsFormGroup = form.get('vendors') as FormArray;
    vendorsFormGroup.value
      .filter((vendor) => vendor.isNewVendor !== true)
      .forEach((v) => {
        vendorsFormGroup.removeAt(0);
      });
  }

  protected mapRepresentationToModel(
    representative: ApplicationApplicant,
    form: AbstractControl,
    morePowerOfAttorneyFieldsForClassicVpaPay = false
  ) {
    const mapRepresentation = (representation: AbstractControl): Representee => {
      const mappedRepresentation = new Representee();

      mappedRepresentation.actingOnBehalfOf = representation.get('actingOnBehalfOfVendorName').value;
      mappedRepresentation.actingAs = representation.get('representativeActingAs').value;

      if (
        morePowerOfAttorneyFieldsForClassicVpaPay &&
        mappedRepresentation.actingAs === RepresentativeActingAs[RepresentativeActingAs.PowerOfAttorney]
      ) {
        mappedRepresentation.firstName = representation.get('firstName').value;
        mappedRepresentation.lastName = representation.get('lastName').value;
        mappedRepresentation.actingOnBehalfOf = `${mappedRepresentation.firstName} ${mappedRepresentation.lastName}`;
        mappedRepresentation.emailAddress = representation.get('email').value;
        mappedRepresentation.dateOfBirth = DateValidation.dateResultToString(representation.get('dateOfBirth').value);

        const hasAddressChanged = Object.values(this.mapFormGroupToAddress(representation.get('address'))).some((v) => v !== null);
        mappedRepresentation.address = hasAddressChanged
          ? Address.getCompleteFullAddress(this.mapFormGroupToAddress(representation.get('address')))
          : representation.get('addressSearchControl').value;
      }

      return mappedRepresentation;
    };

    if ((form.get('representations') as FormArray).controls) {
      representative.representations = (form.get('representations') as FormArray).controls.map((r) => mapRepresentation(r));
    }
  }

  private mapVendorFormControl(vendor: Vendor, includeExtendedVendorDetails: boolean) {
    let vendorOptions: any = {
      vpaApplicationVendorId: [vendor.vpaApplicationVendorId],
      vendorImportAggregateId: [vendor.vendorImportAggregateId],
      firstName: [vendor.firstName, Validators.required],
      lastName: [vendor.lastName, Validators.required],
      mobile: [vendor.mobile, PhoneNumberValidator('AU')],
      email: [vendor.emailAddress, [EmailValidator.validEmail]],
      isDeleted: [vendor.isDeleted]
    };

    if (includeExtendedVendorDetails) {
      vendorOptions = {
        ...vendorOptions,
        representativeActingAs: [vendor.representativeActingAs],
        actingOnBehalfOfVendorName: [vendor.actingOnBehalfOfVendorName],
        isRepresentative: [vendor.representativeActingAs != null],
        dateOfBirth: [
          vendor.dateOfBirth ? { type: 'ok', data: new Date(vendor.dateOfBirth) } : { type: 'invalidDate' },
          DateValidator.validDate
        ],
        addressSearchControl: [this.mapSearchAddressControl(vendor.address)],
        ...this.mapAddress(vendor.address)
      };
    }

    const vendorGroup = this.formBuilder.group(vendorOptions);
    if (vendor.representativeActingAs != null) {
      vendorGroup.get('representativeActingAs').setValidators([Validators.required]);
      vendorGroup.get('representativeActingAs').updateValueAndValidity();
      vendorGroup.get('actingOnBehalfOfVendorName').setValidators([Validators.required]);
      vendorGroup.get('actingOnBehalfOfVendorName').updateValueAndValidity();
    }

    return vendorGroup;
  }

  private getSubNumber(addressSearchControl: AbstractControl) {
    const subNumber = new RegExp('(.*?)(?=[/-])').exec(addressSearchControl.value);
    if (!subNumber) {
      return null;
    }

    return subNumber[0];
  }

  protected mapApplicationRepresentationFormControl(representation: Representee, morePowerOfAttorneyFieldsForClassicVpaPay = false) {
    return this.formBuilder.group({
      representativeActingAs: [representation.actingAs, Validators.required],
      actingOnBehalfOfVendorName: [representation.actingOnBehalfOf, Validators.required]
    });
  }
}

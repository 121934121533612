import { Address } from './address';

export class NonApplyingVendorModel {
  vendorId?: number;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  address: Address;
  dateOfBirth: string;
}

export enum ApplyingVendorType {
  Vendor = 'Vendor',
  Director = 'Director',
  PowerOfAttorney = 'PowerOfAttorney',
  Executor = 'Executor',
  Agent = 'Agent'
}

export enum RepresentativeActingAs {
  PowerOfAttorney = 'PowerOfAttorney',
  Executor = 'Executor'
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BreadcrumbItem } from '../../models';

@Component({
  selector: 'vpa-generic-breadcrumbs',
  templateUrl: './generic-breadcrumbs.component.html',
  styleUrls: ['./generic-breadcrumbs.component.scss']
})
export class GenericBreadcrumbsComponent implements OnInit, OnChanges {
  @Input() breadcrumbItems: BreadcrumbItem[];

  visibleBreadcrumbItems: BreadcrumbItem[];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.visibleBreadcrumbItems = changes.breadcrumbItems.currentValue.filter(bi => bi.shouldDisplay);
  }
}

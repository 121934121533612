import { Injectable } from '@angular/core';
import { PhoneNumberType } from 'apps/legacy/src/app/_shared/models';
import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CheckSharingMobileService {
  readonly AGE_THRESHOLD = 60;
  readonly phoneNumberUtil = PhoneNumberUtil.getInstance();

  constructor() {}

  /*
   * Rule for vendor flow: Vendors over the age of 60 are allowed to share mobile numbers with other vendor over the age of 60.
   *
   * Examples:
   * 1) 60 + 30 = NO
   * 2) 60 + 59 = NO
   * 3) 60 + 60 = Can share
   * 4) 60 + 60 + 59 = Vendors 1 & 2 can share a number with each other, but not vendor 3
   *
   *
   * Rule for agent flow: should detect duplicates regardless of age of vendor
   */
  getSharingMobileVendors(allVendors: any): SharingMobileVendorModel[] {
    const vendors = this.formatToSharingMobileVendorModel(allVendors);

    return this.checkIsSharingMobile(vendors);
  }

  // apply step is special because its representatives and vendors are separate
  getSharingMobileVendorsForApplyStep(allRepresentatives: any, allVendors: any, isVendorFlow: boolean): ApplyStepSharingMobileVendorsModel {
    const representatives = this.formatToSharingMobileVendorModel(allRepresentatives);
    const vendors = this.formatToSharingMobileVendorModel(allVendors);
    return this.checkIsSharingMobileForApplyStep(representatives, vendors, isVendorFlow);
  }

  private formatToSharingMobileVendorModel(vendors: any): SharingMobileVendorModel[] {
    return vendors.map((v, i) => ({
      index: i,
      isMobileValid: this.isMobileNumberValid(v.mobile),
      age: this.calculateAgeByDateOfBirth(v.dateOfBirth),
      mobile: this.standardiseMobileNumber(v.mobile),
      isSharingMobileWithOthers: false
    }));
  }

  private checkIsSharingMobile(allVendors: SharingMobileVendorModel[]): SharingMobileVendorModel[] {
    const vendors = [...allVendors];
    const allValidMobileNumbers = vendors.filter((v) => v.isMobileValid).map((v) => v.mobile);
    return this.getSharingMobilePersons(vendors, allValidMobileNumbers);
  }

  private checkIsSharingMobileForApplyStep(
    allRepresentatives: SharingMobileVendorModel[],
    allVendors: SharingMobileVendorModel[],
    isVendorFlow: boolean
  ): ApplyStepSharingMobileVendorsModel {
    const representatives = [...allRepresentatives];
    const vendors = [...allVendors];
    const allApplicants = [...representatives, ...vendors];

    if (!isVendorFlow) {
      const allValidMobileNumbers = allApplicants.filter((v) => v.isMobileValid).map((v) => v.mobile);
      return {
        representatives: this.getSharingMobilePersons(representatives, allValidMobileNumbers),
        vendors: this.getSharingMobilePersons(vendors, allValidMobileNumbers)
      };
    } else {
      const allValidMobileNumbersForVendorsOverThreshholdAge = allApplicants
        .filter((v) => v.isMobileValid && v.age >= this.AGE_THRESHOLD)
        .map((v) => v.mobile);
      const allValidMobileNumbersForVendorsUnderThreshholdAge = allApplicants
        .filter((v) => v.isMobileValid && v.age < this.AGE_THRESHOLD)
        .map((v) => v.mobile);

      return {
        representatives: this.getSharingMobilePersonsConsideringAge(
          representatives,
          allValidMobileNumbersForVendorsOverThreshholdAge,
          allValidMobileNumbersForVendorsUnderThreshholdAge
        ),
        vendors: this.getSharingMobilePersonsConsideringAge(
          vendors,
          allValidMobileNumbersForVendorsOverThreshholdAge,
          allValidMobileNumbersForVendorsUnderThreshholdAge
        )
      };
    }
  }

  private getSharingMobilePersons(persons: SharingMobileVendorModel[], allValidMobileNumbers: string[]): SharingMobileVendorModel[] {
    persons.forEach(
      (v) => (v.isSharingMobileWithOthers = v.isMobileValid && allValidMobileNumbers.filter((m) => m === v.mobile).length > 1)
    );

    return persons.filter((v) => v.isSharingMobileWithOthers === true);
  }

  private getSharingMobilePersonsConsideringAge(
    persons: SharingMobileVendorModel[],
    allValidMobileNumbersForVendorsOverThreshholdAge: string[],
    allValidMobileNumbersForVendorsUnderThreshholdAge: string[]
  ): SharingMobileVendorModel[] {
    persons.forEach(
      (v) =>
        (v.isSharingMobileWithOthers =
          v.isMobileValid &&
          ((v.age >= this.AGE_THRESHOLD && allValidMobileNumbersForVendorsUnderThreshholdAge.filter((m) => m === v.mobile).length > 0) ||
            (v.age < this.AGE_THRESHOLD && allValidMobileNumbersForVendorsOverThreshholdAge.filter((m) => m === v.mobile).length > 0)))
    );

    return persons.filter((v) => v.isSharingMobileWithOthers === true);
  }

  private calculateAgeByDateOfBirth(dateOfBirth: any): number {
    if (!dateOfBirth) {
      return -1;
    }

    if (!dateOfBirth.type) {
      const date = (dateOfBirth as unknown) as Date;
      return moment().diff(date, 'years', false);
    }

    if (dateOfBirth.type !== 'invalidDate') {
      return moment().diff(dateOfBirth.data, 'years', false);
    }

    return -1;
  }

  private isMobileNumberValid(rawNumber: string): boolean {
    if (!rawNumber) {
      return false;
    }

    let isValid = false;
    try {
      const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(rawNumber, 'AU');
      isValid =
        this.phoneNumberUtil.isValidNumberForRegion(phoneNumber, 'AU') &&
        this.phoneNumberUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE;
    } catch (e) {
      isValid = false;
    }

    return isValid;
  }

  private standardiseMobileNumber(mobile: string) {
    if (!this.isMobileNumberValid(mobile)) {
      return;
    }

    const splitOn = (slicable, ...indices) => [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]));
    const result = `${mobile.length === 9 ? '0' : ''}${mobile}`;
    return splitOn(result, 4, 7).join(' ');
  }
}

export class SharingMobileVendorModel {
  index: number;
  isMobileValid: boolean;
  age: number;
  mobile: string | undefined;
  isSharingMobileWithOthers: boolean;
}

export class ApplyStepSharingMobileVendorsModel {
  representatives: SharingMobileVendorModel[];
  vendors: SharingMobileVendorModel[];
}

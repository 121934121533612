import { ApplicationStep } from './application-step';
import { VpaApplicationStatus } from './vpa-application-status';

export class BreadCrumbsClassState {
  applicationStatus: string;
  applicationStep: ApplicationStep;

  constructor(applicationStatus: string, applicationStep: ApplicationStep) {
    this.applicationStep = applicationStep;
    this.applicationStatus = applicationStatus;
  }

  get isCreateCurrent() {
    return this.applicationStep === ApplicationStep.Create;
  }

  get isApplyCurrent() {
    return this.applicationStep === ApplicationStep.Apply;
  }

  get isPayCurrent() {
    return this.applicationStep === ApplicationStep.PayNow;
  }

  get isSignCurrent() {
    return this.applicationStep === ApplicationStep.Sign;
  }

  get isCreateComplete() {
    return (
      this.applicationStatus !== null &&
      this.applicationStatus !== VpaApplicationStatus[VpaApplicationStatus.EmptyTrainingApplicationCreated]
    );
  }

  get isApplyComplete() {
    return this.isCreateComplete && this.applicationStatus !== VpaApplicationStatus[VpaApplicationStatus.ApplyInProgress];
  }

  get isPayComplete() {
    return this.isApplyComplete && this.applicationStatus !== VpaApplicationStatus[VpaApplicationStatus.WaitingForPayment];
  }

  get isApplicationComplete() {
    return this.applicationStatus === VpaApplicationStatus[VpaApplicationStatus.SignedAndSubmitted];
  }
}

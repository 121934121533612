import { Injectable } from '@angular/core';
import { PageEventTracker } from '@ca/shared/user-events';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderNavContentService {
  constructor() {}

  // Deprecated
  private title: Subject<string> = new BehaviorSubject('');
  // Deprecated
  private hasUpdates: Subject<boolean> = new BehaviorSubject(false);
  // Deprecated
  private showSearchBar: Subject<boolean> = new BehaviorSubject(false);

  private pageEventTracker: PageEventTracker = null;
  private pageEventTrackerSubject: BehaviorSubject<PageEventTracker> = new BehaviorSubject<PageEventTracker>(this.pageEventTracker);
  pageEventTracker$: Observable<PageEventTracker> = this.pageEventTrackerSubject.asObservable();

  reset() {
    this.setTitle('');
    this.setHasUpdates(false);
    this.setPageEventTracker(null);
    this.setShowSearchBar(false);
  }

  getTitle$(): Observable<string> {
    // setTitle done by facade initialisation will cause ExpressionChangedAfterItHasBeenCheckedError,
    // so make the observable refresh as part of the asynchronous refresh by addding delay(0)
    return this.title.asObservable().pipe(delay(0));
  }

  setTitle(title: string) {
    this.title.next(title);
  }

  getHasUpdates$(): Observable<boolean> {
    return this.hasUpdates.asObservable();
  }

  setHasUpdates(hasUpdates: boolean) {
    this.hasUpdates.next(hasUpdates);
  }

  setPageEventTracker(pageEventTracker: PageEventTracker) {
    this.pageEventTracker = pageEventTracker;
    this.pageEventTrackerSubject.next(this.pageEventTracker);
  }

  getShowSearchBar$(): Observable<boolean> {
    return this.showSearchBar.asObservable();
  }

  setShowSearchBar(showHeaderSearch: boolean) {
    this.showSearchBar.next(showHeaderSearch);
  }
}

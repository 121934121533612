<section
  *ngIf="state$ | async as state"
  [formGroup]="form$ | async"
  class="section-card"
  [ngClass]="{ 'inline-component-section': !state.isDisplayedInSections }"
>
  <div class="row">
    <div class="col-md-7 order-md-1 order-2">
      <div class="row" *ngIf="!state.agencyProcessedPaymentInformationMessage">
        <div class="col-12">
          <strong>Please specify your payment method</strong>
          <p class="description font-14">
            You can split payments across multiple credit cards or pay the entire balance via {{ state.fullPaymentMethod }}
          </p>
        </div>
      </div>
      <div class="row m-t-15" *ngIf="!state.agencyProcessedPaymentInformationMessage">
        <div
          class="col-lg-12 col-md-9 col-sm-12 multi-switch-container"
          [ngClass]="{ 'small-switcher': state.paymentOptions.length === 2 }"
        >
          <div class="multi-switcher">
            <fieldset [disabled]="state.isPaymentTypeLocked">
              <div class="radio-btn" *ngFor="let option of state.paymentOptions">
                <input
                  [id]="option.paymentMethod.toLowerCase()"
                  type="radio"
                  formControlName="currentPaymentMethod"
                  [value]="option.paymentMethod"
                />
                <label [for]="option.paymentMethod.toLowerCase()" [ngClass]="option.paymentMethod.toLowerCase()">
                  {{ option.displayName }}
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="row m-t-15" *ngIf="state.isByoPayNow">
        <vpa-action-item iconClass="fa-question-circle" *ngIf="state.isCustomSurchargeDisclaimerIsEnabled">
          {{ state.customSurchargeText }}
        </vpa-action-item>
        <vpa-action-item
          iconClass="fa-question-circle"
          *ngIf="state.payNowDirectCreditCardType === PayNowDirectType.CaPay && state.isPaymentMethodCreditCard"
        >
          Please note that the credit card surcharges will appear as a separate transaction on your bank account statement.
          <ng-container *ngIf="state.creditCardSchemeSurchargeOptions !== null">
            <div *ngFor="let surchargeOption of state.creditCardSchemeSurchargeOptions">
              {{ surchargeOption.schemeName }} surcharge: {{ surchargeOption.surchargeInfo.surcharge | currency }} ({{
                surchargeOption.surchargeInfo.surchargePercentageString
              }})
            </div>
          </ng-container>
        </vpa-action-item>
      </div>

      <div class="row" *ngIf="state.agencyProcessedPaymentInformationMessage">
        <div class="col-12">
          <strong>
            {{ state.agencyProcessedPaymentInformationTitle }}
          </strong>
        </div>
      </div>

      <div class="row m-t-15 m-b-20" *ngIf="state.agencyProcessedPaymentInformationMessage">
        <div class="col-12">
          {{ state.agencyProcessedPaymentInformationMessage }}
        </div>
      </div>
    </div>
    <div class="col-md-5 d-flex justify-content-center order-md-2 order-1 m-b-10">
      <img *ngIf="state.brandLogoUrl" src="{{ state.brandLogoUrl }}" class="brand-logo-image" />
    </div>
  </div>
</section>

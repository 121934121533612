<section class="section-card">
  <div class="row">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-12 m-b-10">
          <div class="section-title">Cheque Payment Details</div>
          <div class="section-description">Below is the information needed to pay via Cheque</div>
        </div>
      </div>

      <vpa-help-section [helpText]="'chequeDetails'" [offsetSectionSpacing]="false"></vpa-help-section>

      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="row">
            <div class="col-12 col-md-4 m-t-10 section-info">
              <label>Name on cheque</label>
              <div>
                {{ paymentDetailsOption.chequeAccountName }}
              </div>
            </div>
            <div class="col-12 col-md-8 m-t-10 section-info">
              <label>Address</label>
              <div>
                {{ paymentDetailsOption.address }}
              </div>
            </div>
            <div class="col-8 col-md-12 m-t-15 section-info highlight-border">
              <label>Payment reference code</label>
              <div>
                {{ paymentDetailsOption.paymentReferenceCode }}
              </div>
            </div>
            <div class="col-12 m-t-15 section-info">
              <label>Important details</label>
              <div>
                Please write the payment reference code on the back of the cheque to ensure it is allocated to the correct campaign.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vpa-contribution-payment-breakdown class="col-lg-6" [state$]="contributionPaymentBreakdown$"></vpa-contribution-payment-breakdown>
  </div>
</section>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IContribution } from 'apps/legacy/src/app/_shared/models';

@Component({
  selector: 'vpa-payway-iframe',
  templateUrl: './payway-iframe.component.html',
  styleUrls: ['./payway-iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaywayIFrameComponent implements OnInit {
  @Input() state: PaywayPaymentComponentState;
  @Output() savePayment: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSavePayment(): void {
    this.savePayment.emit();
  }
}

export interface PaywayPaymentComponentState {
  paywayContributions: IContribution[];
  amountOutstanding: number;
  amountRequested: number;
  isSubmitted: boolean;
  isRequestedAmountGreaterThanOutstanding: boolean;
  isTrainingMode: boolean;
  isPaywayDisabled: boolean;
  isPaywayCreditCardInputValid: boolean;
  paywayErrorMessage: string;
  isFullyPaid: boolean;
  useChargeOnSubmission: boolean;
}

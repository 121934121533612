export class UserTypes {
  static readonly Agent = 'Agent';
  static readonly Vendor = 'Vendor';
  static readonly AgencyGroup = 'AgencyGroup';
  static readonly ApiSandBoxed = 'ApiSandBoxed';
  static readonly Admin = 'CaAdmin';
  static readonly AdditionalVpaApplicant = 'AdditionalVpaApplicant';
  static readonly VpaDotMobi = 'VpaDotMobi';
  static readonly AgencyVpaDotMobi = 'AgencyVpaDotMobi';
  static readonly AgentVpaDotMobi = 'AgentVpaDotMobi';
}

import { ApplicationMode } from '@ca/shared/application-mode';
import { Guid } from 'guid-typescript';
import { CreditCardScheme } from '..';

const getRouteUrl = (url: string, mode?: ApplicationMode) => {
  return mode === ApplicationMode.Training ? `training/${url}` : url;
};

class VpaPayManagementRouteResources {
  public static createApplication(cpnOrApplicationId: string, mode: ApplicationMode): string {
    if (cpnOrApplicationId) {
      return getRouteUrl(`vpa-pay-management/create-pnd/${cpnOrApplicationId}`, mode);
    }
    return getRouteUrl(`vpa-pay-management/create-pnd`, mode);
  }

  public static createAdditionalApplication(cpn, mode: ApplicationMode): string {
    return getRouteUrl(`vpa-pay-management/additional/create-pnd/${cpn}`, mode);
  }

  public static createAgentContributions(cpnOrApplicationId: string, mode: ApplicationMode): string {
    if (cpnOrApplicationId) {
      return getRouteUrl(`vpa-pay-management/create-agent-contributions/${cpnOrApplicationId}`, mode);
    }
    return getRouteUrl(`vpa-pay-management/create-agent-contributions`, mode);
  }
}

class PayNowDirectRouteResources {
  public static pndPayNowPaymentType(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pnd/applications/${vpaApplicationId}/pay-now-payment-type`, mode);
  }

  public static pndBPayPayment(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pnd/applications/${vpaApplicationId}/bpay-payment`, mode);
  }

  public static pndCreditCardPayment = (vpaApplicationId: number, scheme: CreditCardScheme, mode: ApplicationMode): string =>
    getRouteUrl(`pnd/applications/${vpaApplicationId}/credit-card/${scheme}`, mode);

  public static pndPaymentSuccess = (vpaApplicationId: number, mode: ApplicationMode): string =>
    getRouteUrl(`pnd/applications/${vpaApplicationId}/payment-success`, mode);

  public static pndVendorPaymentInAgentPresence = (apiConsumerVpaApplicationAggregateId: Guid, mode: ApplicationMode): string =>
    getRouteUrl(`pnd/applications/${apiConsumerVpaApplicationAggregateId}/vendor-payment-in-agent-presence`, mode);

  public static agentFlowEmbeddedPayment = (apiConsumerVpaApplicationAggregateId: string, mode: ApplicationMode): string =>
    getRouteUrl(`pnd/applications/${apiConsumerVpaApplicationAggregateId}/agent-flow-embedded`, mode);
}

class PayLaterRouteResources {
  public static aboutPayLater(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pay-later/applications/${vpaApplicationId}/about-pay-later`, mode);
  }

  public static signingPage(applicationId: number, vendorId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pay-later/applications/${applicationId}/vendors/${vendorId}/sign`, mode);
  }

  public static confirmVendorDetails(applicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pay-later/applications/${applicationId}/confirm-vendor-details`, mode);
  }
}

class NextGenPaymentsPayLaterRouteResources {
  public static aboutPayLater(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pay-later/applications/${vpaApplicationId}/about-pay-later`, mode);
  }

  public static signingPage(applicationId: number, vendorId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pay-later/applications/${applicationId}/vendors/${vendorId}/sign`, mode);
  }

  public static confirmVendorDetails(applicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`pay-later/applications/${applicationId}/confirm-vendor-details`, mode);
  }
}

class MarketingRouteResources {
  public static awaitingPaymentApplications(): string {
    return getRouteUrl(`marketing/awaiting-payment`);
  }

  public static actionRequired(): string {
    return getRouteUrl(`marketing/actions-required`);
  }

  public static downloadAgencyQrCode(): string {
    return getRouteUrl(`marketing/download-agency-qr-code`);
  }

  public static portalCampaignLists(campaignListName: string, searchTerm?: string): string {
    return campaignListName === 'search'
      ? getRouteUrl(`marketing/campaigns/search?searchTerm=${searchTerm}`)
      : getRouteUrl(`marketing/campaigns/${campaignListName}`);
  }
}

class VpaDotMobiRouteResources {
  public static vpaDotMobiUserSelection = (applicationAggregateId: string, mode: ApplicationMode): string =>
    `vendor-marketing/api-consumer-applications/${applicationAggregateId}/user-selection`;

  public static vpaDotMobiAddUser = (applicationAggregateId: string, mode: ApplicationMode): string =>
    `vendor-marketing/api-consumer-applications/${applicationAggregateId}/add-user`;

  public static vpaDotMobiAgentSelection = (applicationAggregateId: string, mode: ApplicationMode): string =>
    `vendor-marketing/api-consumer-applications/${applicationAggregateId}/agent-selection`;

  public static vpaDotMobiAgentHowToProceed = (applicationAggregateId: string, mode: ApplicationMode): string =>
    `vendor-marketing/api-consumer-applications/${applicationAggregateId}/agent-how-to-proceed`;

  public static vpaDotMobiConfirmAmount = (applicationAggregateId: string, mode: ApplicationMode): string =>
    `vendor-marketing/api-consumer-applications/${applicationAggregateId}/confirm-amount`;

  public static vpaDotMobiVendorLogin = (userAggregateId: string, mode: ApplicationMode): string =>
    `vendor-marketing/api-consumer-vendors/${userAggregateId}/login`;

  public static vpaDotMobiCode = (casUrl: string, code: string): string => `${casUrl}vpa-dot-mobi/code/${code}`;

  public static vpaDotMobiTakeOverApplication = (applicationId: number): string =>
    `vendor-marketing/applications/${applicationId}/take-over`;

  public static vpaDotMobiTakeOverDifferentApplication = (applicationId: number): string =>
    `vendor-marketing/applications/${applicationId}/take-over-different-quote`;

  public static vpaDotMobiSignDifferentApplication = (applicationId: number): string =>
    `vendor-marketing/applications/${applicationId}/different-quote-ready-for-signing`;

  public static agentWelcome = (applicationAggregateId: string, mode: ApplicationMode): string =>
    `vendor-marketing/api-consumer-applications/${applicationAggregateId}/agent-welcome`;

  public static agentDetails = (applicationAggregateId: string, mode: ApplicationMode): string =>
    `vendor-marketing/api-consumer-applications/${applicationAggregateId}/agent-details`;

  public static agentLogin = (userAggregateId: string, mode: ApplicationMode): string => `vendor-marketing/agents/${userAggregateId}/login`;
}

class AgencyVpaDotMobiRouteResources {
  public static addUser = (vpaDotMobiPaymentCode: string, mode: ApplicationMode): string =>
    `agency-vendor-marketing/agencies/${vpaDotMobiPaymentCode}`;

  public static vendorLogin = (vpaDotMobiPaymentCode: string, userAggregateId: string, mode: ApplicationMode): string =>
    `agency-vendor-marketing/agencies/${vpaDotMobiPaymentCode}/vendors/${userAggregateId}/login`;

  public static completedApplications = (cpn: string, mode: ApplicationMode): string =>
    `agency-vendor-marketing/completed-applications/${cpn}`;

  public static propertiesList = (mode: ApplicationMode): string => `agency-vendor-marketing/properties`;

  public static createApplication = (mode: ApplicationMode): string => `agency-vendor-marketing/create-application`;

  public static confirmAmount = (cpn: string, mode: ApplicationMode): string =>
    `agency-vendor-marketing/applications/${cpn}/confirm-amount`;

  public static takeOverApplication = (vpaApplicationId: number, mode: ApplicationMode): string =>
    `agency-vendor-marketing/applications/${vpaApplicationId}/take-over`;
}

export class RouteResources {
  public static vpaPayManagementRouteResources = VpaPayManagementRouteResources;
  public static payNowDirectRouteResources = PayNowDirectRouteResources;
  public static payLaterRouteResources = PayLaterRouteResources;
  public static marketingRouteResources = MarketingRouteResources;
  public static vpaDotMobiRouteResources = VpaDotMobiRouteResources;
  public static agencyVpaDotMobiRouteResources = AgencyVpaDotMobiRouteResources;
  public static nextGenPaymentsPayLaterRouteResources = NextGenPaymentsPayLaterRouteResources;

  public static listings(mode: ApplicationMode): string {
    return getRouteUrl(`listings`, mode);
  }

  public static smartCommissionAvailableForFunding(mode: ApplicationMode) {
    return getRouteUrl(`smart-commission/available-for-funding`, mode);
  }

  public static smartCommissionFundedCampaigns(mode: ApplicationMode) {
    return getRouteUrl(`smart-commission/funded`, mode);
  }

  public static smartCommissionAgentSoldCampaigns(mode: ApplicationMode) {
    return getRouteUrl(`smart-commission/agent-sold-campaigns`, mode);
  }

  public static smartCommissionSoldCampaignsAdminSearchList(mode: ApplicationMode) {
    return getRouteUrl(`smart-commission/sold-campaigns`, mode);
  }

  public static listAllCampaigns(mode: ApplicationMode): string {
    return getRouteUrl(`applications/all`, mode);
  }

  public static applicationSummary(id, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/summary`, mode);
  }

  public static applicationCpnSummary(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`applications/campaigns/${cpn}/summary`, mode);
  }

  public static createApplication(cpnOrApplicationId: string, mode: ApplicationMode): string {
    return cpnOrApplicationId ? getRouteUrl(`applications/${cpnOrApplicationId}/create`, mode) : getRouteUrl(`applications/create`, mode);
  }

  public static createPdfApplication(cpn: string, mode: ApplicationMode): string {
    if (cpn) {
      return getRouteUrl(`applications/${cpn}/create-pdf`, mode);
    }
    return getRouteUrl(`applications/create-pdf`, mode);
  }

  public static createAdditionalVpaApplyPdf(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`applications/additional/${cpn}/create-pdf`, mode);
  }

  public static reviewPdfApplication(vpaApplicationId: number, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${vpaApplicationId}/review-pdf`, mode);
  }

  public static applyApplication(id, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/apply`, mode);
  }

  public static payNow(id, mode: ApplicationMode) {
    return getRouteUrl(`applications/${id}/pay-now`, mode);
  }

  public static bankStatements(id: number, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/bank-statements`, mode);
  }

  public static signApplication(id: number, mode: ApplicationMode, useLegacyAgreement: boolean): string {
    return useLegacyAgreement ? getRouteUrl(`applications/${id}/sign`, mode) : getRouteUrl(`applications/${id}/signv2`, mode);
  }

  public static incompleteApplications(mode: ApplicationMode): string {
    return getRouteUrl('applications/incomplete', mode);
  }

  public static inProgressApplications(mode: ApplicationMode): string {
    return getRouteUrl('applications/in-progress', mode);
  }

  public static actionRequiredApplications(mode: ApplicationMode): string {
    return getRouteUrl('applications/action-required', mode);
  }

  public static amendApplication(id, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${id}/amend`, mode);
  }

  public static vendorThankYouPage(cpn, mode: ApplicationMode): string {
    return getRouteUrl(`applications/${cpn}/thank-you`, mode);
  }

  public static thankYouPageForApplication = (applicationId: number, mode: ApplicationMode) =>
    getRouteUrl(`pay-later/applications/${applicationId}/thank-you`, mode);

  public static uploadBrfFilePage(): string {
    return 'byo-pay-now/brf/upload';
  }

  public static managePayments(): string {
    return 'finance/manage-payments';
  }

  public static manageApplicationPayments(applicationAggregateId: Guid): string {
    return `byo-pay-now/manage/${applicationAggregateId}`;
  }

  public static payNowDirectAwaitingPayment(): string {
    return `byo-pay-now/awaiting-payment`;
  }

  public static applicationCancelSuccess(): string {
    return '/applications/cancellation-success';
  }

  public static passwordlessSms(): string {
    return '/sms';
  }

  public static login(): string {
    return '/login';
  }

  public static applicationVendorLogin(): string {
    return '/login/vendor-login';
  }

  public static loginWithoutSlash(): string {
    return 'login';
  }

  public static logout(): string {
    return 'logout';
  }

  public static switchToAgentApp(): string {
    return 'agent-app';
  }

  public static switchToPortal(): string {
    return 'portal';
  }

  public static vendorLogout(): string {
    return 'pnd/vendor-logout';
  }

  public static casAgencyLiveCampaigns(casUrl: string): string {
    return casUrl + 'agency/campaigns/Live2';
  }

  public static casAgencyMissingCrmData(casUrl: string): string {
    return casUrl + 'agency/campaigns/ImportFailures';
  }

  public static casAgencyCasAppInProgress(casUrl: string): string {
    return casUrl + 'agency/VpaApply';
  }

  public static casAgencyActionRequired(casUrl: string): string {
    return casUrl + 'agency/campaigns/ActionRequired';
  }

  public static casAgencyUnsubmittedCampaigns(casUrl: string): string {
    return casUrl + 'agency/campaigns/ByStatus/Unsubmitted';
  }

  public static casAgencyBeingReviewedCampaigns(casUrl: string): string {
    return casUrl + 'agency/campaigns/ByStatus/WaitingForApproval';
  }

  public static casAgencyInvalidCampaigns(casUrl: string): string {
    return casUrl + 'agency/campaigns/ByStatus/Invalid';
  }

  public static casAgencyActiveCampaigns(casUrl: string): string {
    return casUrl + 'agency/campaigns/ByStatus/Approved';
  }

  public static casAgencyRejectedCampaigns(casUrl: string): string {
    return casUrl + 'agency/campaigns/ByStatus/Rejected';
  }

  public static casAgencyWithdrawnCampaigns(casUrl: string): string {
    return casUrl + 'agency/campaigns/ByStatus/Withdrawn';
  }

  public static casAgencyCompleteCampaigns(casUrl: string): string {
    return casUrl + 'agency/campaigns/ByStatus/Completed';
  }

  public static casAgencyPaymentReminders(casUrl: string): string {
    return casUrl + 'agency/paymentReminders';
  }

  public static casAgencySettings(casUrl: string): string {
    return casUrl + 'agency/settings';
  }

  public static casappAgencySettings(): string {
    return '/users/agency-settings';
  }

  public static reconciliation(): string {
    return 'finance/reconciliation';
  }

  public static casAgencyArchive(casUrl: string): string {
    return casUrl + 'agency/Archive/List';
  }

  public static casAgencyReconciliationReport(casUrl: string): string {
    return casUrl + 'agency/reports/reconciliation';
  }

  public static casAgencyCasAppAccess(casUrl: string): string {
    return casUrl + 'agency/vpaApply/agentSettings';
  }

  public static showImportFailureDetails(importFailureId: number, casUrl: string): string {
    return `${casUrl}agency/campaigns/ImportFailure/${importFailureId}`;
  }

  public static generateCampaignStatement(cpn: string, casUrl: string): string {
    return `${casUrl}agency/Statements/${cpn}/Generate`;
  }

  public static addVpaReceivable(campaignId: number, casUrl: string): string {
    return `${casUrl}agency/campaigns/${campaignId}/VpaReceivables`;
  }

  public static agencyUpdateVpaDetails(campaignId: number, vpaReceivableId: number, casUrl: string): string {
    return `${casUrl}agency/campaigns/${campaignId}/VpaReceivables/${vpaReceivableId}/Update`;
  }

  public static agencyUpdateTotalAuthorisedAmount(campaignId: number, vpaReceivableId: number, casUrl: string): string {
    return `${casUrl}agency/campaigns/${campaignId}/VpaReceivables/${vpaReceivableId}/UpdateTotalFundingAmount`;
  }

  public static agencyRequestCancellation(campaignId: number, vpaReceivableId: number, casUrl: string): string {
    return `${casUrl}agency/campaigns/${campaignId}/VpaReceivables/${vpaReceivableId}/RequestCancellation`;
  }

  public static directToCasVendorAdd(campaignId: number, casUrl: string): string {
    return `${casUrl}agency/campaigns/${campaignId}/ManageCampaign#manage_vendor_section?action=addVendor`;
  }

  public static directToCasVendorEdit(campaignId: number, casUrl: string): string {
    return `${casUrl}agency/campaigns/${campaignId}/ManageCampaign#manage_vendor_section`;
  }

  public static viewDocument(campaignId: number, documentId: number, casUrl: string): string {
    return `${casUrl}agency/campaigns/${campaignId}/documents/${documentId}`;
  }

  public static casLoginToken(token: string, casUrl: string): string {
    return `${casUrl}Account/LoginWithToken?Token=${token}`;
  }

  public static loginWithGoogleAccount(casUrl: string): string {
    return `${casUrl}Account/LoginWithGoogleAccount`;
  }

  public static loginWithCasToken(token: string): string {
    return `/login/casLoginToken?token=${token}`;
  }

  public static adminAgencyGroupUsersList(networkId: number, agencyGroupId: number, casUrl: string): string {
    return `${casUrl}admin/onboarding/Networks/${networkId}/AgencyGroups/${agencyGroupId}#users`;
  }

  public static adminVpaReceivableReview(campaignId: number, vpaReceivableId: number, casUrl: string): string {
    return `${casUrl}admin/Campaigns/${campaignId}/VpaReceivables/${vpaReceivableId}/review`;
  }

  public static adminManageCampaign(campaignId: number, casUrl: string): string {
    return `${casUrl}admin/Campaigns/${campaignId}/manage`;
  }

  public static casAdminHome(casUrl: string): string {
    return `${casUrl}admin/home`;
  }

  public static getListUsersUrl(): string {
    return 'users';
  }

  public static getCreateAgencyGroupUserUrl(): string {
    return '/users/create';
  }

  public static getEditAgencyGroupUserUrl(userId: number): string {
    return `/users/${userId}/edit`;
  }

  public static getCreateAgencyGroupUserAsAdminUrl(networkId: number, agencyGroupId: number): string {
    return `/users/network/${networkId}/agencyGroup/${agencyGroupId}/create`;
  }

  public static getEditAgencyGroupUserAsAdminUrl(networkId: number, agencyGroupId: number, userId: number): string {
    return `/users/network/${networkId}/agencyGroup/${agencyGroupId}/user/${userId}/edit`;
  }

  public static createAdditionalVpa(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`additionalvpa/${cpn}/create`, mode);
  }

  public static createAdditionalVpaInVpaApply(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`applications/additional/${cpn}/create`, mode);
  }

  public static createPDFAdditionalVpaInVpaApply(cpn: string, mode: ApplicationMode): string {
    return getRouteUrl(`applications/additional/${cpn}/create-pdf`, mode);
  }

  public static additionalVpaApplyStep(additionalVpaApplicationId: number, mode: ApplicationMode) {
    return getRouteUrl(`additionalvpa/${additionalVpaApplicationId}/apply`, mode);
  }

  public static additionalVpaContributionStep(additionalVpaApplicationId: number, mode: ApplicationMode) {
    return getRouteUrl(`additionalvpa/${additionalVpaApplicationId}/contributions`, mode);
  }

  public static additionalVpaSigningStep(additionalVpaApplicationId: number, mode: ApplicationMode) {
    return getRouteUrl(`additionalvpa/${additionalVpaApplicationId}/sign`, mode);
  }

  public static componentList = (): string => 'component-list';

  public static terminateImpersonation = (casUrl: string): string => `${casUrl}admin/users/impersonation/terminate`;
}

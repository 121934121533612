import { Address, BaseModel, VendorSigningPropertiesModel } from 'apps/legacy/src/app/_shared/models';
import { CompanyDetails } from './application-applicant-model';
import { Representee } from './representee-model';

export class CreateApplicationVendor extends BaseModel<CreateApplicationVendor> {
  address: Address = new Address();
  dateOfBirth: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  mobile: string;
  isDeleted = false;
  isRepresentative: boolean;
  vendorImportAggregateId: string;
  vpaApplicationVendorId: number;
  representations: Representee[] = [];
  isElectedToApply: boolean;
  companyDetails: CompanyDetails = new CompanyDetails();
  signingProperties: VendorSigningPropertiesModel;

  fromModel(oldModel: CreateApplicationVendor) {
    if (oldModel.address) {
      this.address.fromModel(oldModel.address);
    }

    this.dateOfBirth = oldModel.dateOfBirth;
    this.emailAddress = oldModel.emailAddress;
    this.firstName = oldModel.firstName;
    this.lastName = oldModel.lastName;
    this.mobile = oldModel.mobile;
    this.isDeleted = oldModel.isDeleted;
    this.vendorImportAggregateId = oldModel.vendorImportAggregateId;
    this.vpaApplicationVendorId = oldModel.vpaApplicationVendorId;
    this.representations = oldModel.representations;
    this.isRepresentative = oldModel.representations && oldModel.representations.length > 0;
    this.isElectedToApply = oldModel.isElectedToApply;
    this.signingProperties = oldModel.signingProperties;

    return this;
  }
}

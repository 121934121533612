import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmendApplicationModel } from 'apps/legacy/src/app/applications-shared/models';
import { SharedApplicationApiService } from 'apps/legacy/src/app/applications-shared/services';
import { DocumentSourceDomain, FatZebraContributionModel } from 'apps/legacy/src/app/_shared/models';
import { DigitalDomainSource } from 'apps/legacy/src/app/_shared/models/digital-domain-source';
import { FatZebraTokenCaptureModel } from 'apps/legacy/src/app/_shared/models/fat-zebra/fat-zebra-token-capture-model';
import { VpaApplicationDocumentModel } from 'apps/legacy/src/app/_shared/models/vpa-application-document-model';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CreateAdditionalVpaApplyApplicationModel } from '../../applications-shared/models/create-additional-vpa-apply-model';
import { ApiResources } from '../../_shared/models/api-resources';
import {
  AmendApplicationResponse,
  ApplyApplicationModel,
  BankStatementsModel,
  CreatePdfApplicationModel,
  CreatePdfApplicationModelResponse,
  IResendInvitationsResponse,
  ReviewPdfApplicationModel,
  SignApplicationV2Model
} from '../models';
import { ActionLogsModel } from '../models/action-log-model';
import { ApplicationSummaryModel } from '../models/application-summary-model';
import { ContributionsModel } from '../models/contributions-model';
import { FatZebraUrlResponse } from '../models/contributions/fat-zebra-url-response';
import {
  CreateAdditionalVpaApplyPdfResponseModel,
  CreateAdditionalVpaPdfModel
} from '../models/create-additional-vpa-apply/create-additional-vpa-pdf-model';
import { CreateSupportTicketModel, SubjectItem } from '../models/create-support-ticket-model';
import { IncompleteApplicationsResponse } from '../models/incomplete-applications-response';
import { PayLaterCostsModel } from '../models/pay-later-costs-model';
import { SignApplicationModel } from '../models/sign-application-model';
import { UploadAuthorityModel } from '../models/upload-authority-model';
import { UploadMarketingScheduleModel } from '../models/upload-marketing-schedule-model';
import { VendorThankYouModel } from '../models/vendor-thank-you-model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsApiService extends SharedApplicationApiService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  submitApplicationForReview(vpaApplicationId: number): Observable<void> {
    return this.http.put<void>(ApiResources.submitApplicationForReview(vpaApplicationId), null);
  }

  getPayLaterCosts(agencyId: number, payNowDate: Date, payNowTotal: number): Observable<PayLaterCostsModel> {
    return this.http.get<PayLaterCostsModel>(ApiResources.getPayLaterCosts(agencyId, payNowDate, payNowTotal));
  }

  completeContributionStep(contributions: ContributionsModel): Observable<void> {
    return this.http.put<void>(ApiResources.completeContributionStep(contributions.vpaApplicationId), contributions);
  }

  processFatZebraPayment = (applicationId: number, contribution: FatZebraContributionModel): Observable<number> =>
    this.http.put<number>(ApiResources.processFatZebraPayment(applicationId), contribution);

  processCaPayFatZebraTokenPayment = (
    applicationId: number,
    fatZebraTokenModel: FatZebraTokenCaptureModel
  ): Observable<FatZebraContributionModel> =>
    this.http.put<FatZebraContributionModel>(ApiResources.processCaPayFatZebraTokenPayment(applicationId), fatZebraTokenModel);

  getApplicationContributions(vpaApplicationId): Observable<ContributionsModel> {
    return this.http.get<ContributionsModel>(ApiResources.getApplicationContributions(vpaApplicationId));
  }

  getFatZebraIframeUrl(vpaApplicationId: number, paymentAmount: number): Observable<FatZebraUrlResponse> {
    return this.http.get<FatZebraUrlResponse>(ApiResources.getFatZebraUrl(vpaApplicationId, paymentAmount));
  }

  signApplicationByClick(vpaApplicationId: string, signedApplication: SignApplicationModel): Observable<void> {
    return this.http.put<void>(ApiResources.signApplicationByClick(vpaApplicationId), signedApplication);
  }

  signApplicationByClickV2(vpaApplicationId: number, signedApplication: SignApplicationV2Model): Observable<void> {
    return this.http.put<void>(ApiResources.signApplicationByClickV2(vpaApplicationId), signedApplication);
  }

  getApplicationDocument(cpn: string, documentId: number, documentSourceDomain: DocumentSourceDomain): Observable<Blob> {
    return this.http.get<Blob>(ApiResources.getApplicationDocument(cpn, documentId, documentSourceDomain.toString()), {
      responseType: 'blob' as 'json'
    });
  }

  getApplicationDocumentByVpaApplicationId(vpaApplicationId: number, documentId: number): Observable<Blob> {
    return this.http.get<Blob>(ApiResources.getApplicationDocumentByVpaApplicationId(vpaApplicationId, documentId), {
      responseType: 'blob' as 'json'
    });
  }

  getPdfApplicationRequest(cpn): Observable<CreatePdfApplicationModel> {
    return this.http.get<CreatePdfApplicationModel>(ApiResources.getCreatePdfApplication(cpn));
  }

  getReviewPdfApplicationRequest(vpaApplicationId): Observable<ReviewPdfApplicationModel> {
    return this.http.get<ReviewPdfApplicationModel>(ApiResources.getReviewPdfApplicationRequest(vpaApplicationId));
  }

  getApplicationSummary(vpaApplicationId: number): Observable<ApplicationSummaryModel> {
    return this.http.get<ApplicationSummaryModel>(ApiResources.getSummary(vpaApplicationId));
  }

  uploadSalesAuthorityDocuments(vpaApplicationId: number, model: UploadAuthorityModel): Observable<UploadAuthorityModel> {
    return this.http.put<UploadAuthorityModel>(ApiResources.uploadSalesAuthorityDocuments(vpaApplicationId), model);
  }

  uploadMarketingScheduleDocuments(
    vpaApplicationId: number,
    model: UploadMarketingScheduleModel
  ): Observable<UploadMarketingScheduleModel> {
    return this.http.put<UploadMarketingScheduleModel>(ApiResources.uploadMarketingScheduleDocuments(vpaApplicationId), model);
  }

  uploadApplicationDocumentsForWaitingForApprovalCampaign(
    vpaApplicationId: number,
    documents: VpaApplicationDocumentModel[]
  ): Observable<VpaApplicationDocumentModel[]> {
    return this.http.put<VpaApplicationDocumentModel[]>(
      ApiResources.uploadApplicationDocumentsForWaitingForApprovalCampaign(vpaApplicationId),
      documents
    );
  }

  getApplyApplicationRequest(applicationId: number): Observable<ApplyApplicationModel> {
    return this.http.get<ApplyApplicationModel>(ApiResources.applyApplication(applicationId));
  }

  getSignApplicationRequest(vpaApplicationId: string): Observable<SignApplicationModel> {
    return this.http.get<SignApplicationModel>(ApiResources.getApplicationToSign(vpaApplicationId));
  }

  getSignApplicationV2Request(vpaApplicationId: number): Observable<SignApplicationV2Model> {
    return this.http.get<SignApplicationV2Model>(ApiResources.getApplicationToSignV2(vpaApplicationId));
  }

  downloadVendorAuthorisationDocument(vpaReceivableId: number): Observable<Blob> {
    return this.http.get<Blob>(ApiResources.downloadVendorAuthorisationDocument(vpaReceivableId), { responseType: 'blob' as 'json' });
  }

  getAmendApplicationRequest(applicationId: number): Observable<AmendApplicationModel> {
    return this.http.get<AmendApplicationModel>(ApiResources.amendApplication(applicationId));
  }

  amendApplication(application: AmendApplicationModel): Observable<AmendApplicationResponse> {
    return this.http.put<AmendApplicationResponse>(ApiResources.amendApplication(application.vpaApplicationId), application);
  }

  signApplication(vpaApplicationId: number, signedApplication: SignApplicationModel): Observable<void> {
    return this.http.put<void>(ApiResources.signApplication(vpaApplicationId), signedApplication);
  }

  signApplicationV2(vpaApplicationId: number, signedApplication: SignApplicationV2Model): Observable<void> {
    return this.http.put<void>(ApiResources.signApplicationV2(vpaApplicationId), signedApplication);
  }

  getAgreementSectionPdf(vpaApplicationId: number, mergeTemplateAggregateId: string, version: number): Observable<Blob> {
    return this.http.get<Blob>(ApiResources.getAgreementSectionPdf(vpaApplicationId, mergeTemplateAggregateId, version), {
      responseType: 'blob' as 'json'
    });
  }

  upsertApplyApplication(application: ApplyApplicationModel): Observable<void> {
    return this.http.put<void>(ApiResources.applyApplication(application.vpaApplicationId), application);
  }

  upsertCreatePdfApplication(application: CreatePdfApplicationModel): Observable<CreatePdfApplicationModelResponse> {
    if (application.cpn) {
      return this.http.put<CreatePdfApplicationModelResponse>(ApiResources.upsertCreatePdfApplication(application.cpn), application);
    } else {
      return this.http.post<CreatePdfApplicationModelResponse>(ApiResources.upsertCreateListinglessPdfApplication(), application);
    }
  }

  generatePdfVendorAgreement(vpaApplicationId: number): Observable<Blob> {
    return this.http.get<Blob>(ApiResources.generatePdfVendorAgreement(vpaApplicationId), { responseType: 'blob' as 'json' });
  }

  upsertReviewPdfApplication(application: ReviewPdfApplicationModel): Observable<void> {
    return this.http.put<void>(ApiResources.upsertReviewPdfApplication(application.vpaApplicationId), application);
  }

  resendSingleSigningVendorInvitation(vpaApplicationId: number, vpaApplicationVendorId: number): Observable<void> {
    return this.http.post<void>(ApiResources.resendSingleSigningVendorInvitation(vpaApplicationId, vpaApplicationVendorId), null);
  }

  resendApplicantInvitations(additionalApplicationId: number): Observable<IResendInvitationsResponse> {
    return this.http.post<IResendInvitationsResponse>(ApiResources.resendApplicantInvitation(additionalApplicationId), null);
  }

  getIncompleteApplications(
    pageSize: number,
    pageNumber: number,
    showMyApplicationsOnly: boolean
  ): Observable<IncompleteApplicationsResponse> {
    return this.http.get<IncompleteApplicationsResponse>(
      ApiResources.getIncompleteApplications(pageSize, pageNumber, showMyApplicationsOnly)
    );
  }

  createEmptyTrainingApplication(): Observable<HttpResponseBase> {
    return this.http.post<HttpResponseBase>(ApiResources.createEmptyTrainingApplication(), null);
  }

  removeCancellationRequest(campaignId: number, vpaReceivableId: number): Observable<HttpResponseBase> {
    return this.http.post<HttpResponseBase>(ApiResources.removeCancellationRequest(campaignId, vpaReceivableId), null);
  }

  cancelFundingRequest(
    campaignId?: number,
    vpaReceivableId?: number,
    vpaApplicationId?: number,
    vpaApplicationAggregateId?: Guid,
    additionalVpaAggregateId?: Guid
  ): Observable<HttpResponseBase> {
    return this.http.post<HttpResponseBase>(ApiResources.cancelFundingRequest(), {
      campaignId,
      vpaReceivableId,
      vpaApplicationId,
      vpaApplicationAggregateId,
      additionalVpaAggregateId
    });
  }

  convertLegacyPndToPayLater(vpaApplicationAggregateId: Guid): Observable<HttpResponseBase> {
    return this.http.post<HttpResponseBase>(ApiResources.convertLegacyPndToPayLater(), {
      vpaApplicationAggregateId
    });
  }

  getCpnSummary(cpn: string): Observable<ApplicationSummaryModel> {
    return this.http.get<ApplicationSummaryModel>(ApiResources.getCpnSummary(cpn));
  }

  getActionLogs(aggregate: Guid, domain: DigitalDomainSource): Observable<ActionLogsModel> {
    return this.http.get<ActionLogsModel>(ApiResources.getActionLogs(aggregate, domain));
  }

  getBanksStatementsModel(vpaApplicationId: number): Observable<BankStatementsModel> {
    return this.http.get<BankStatementsModel>(ApiResources.getBankStatements(vpaApplicationId));
  }

  acceptIllionTerms(vpaApplicationId: number): Observable<void> {
    return this.http.put<void>(ApiResources.acceptIllionTerms(vpaApplicationId), null);
  }

  declineIllionTerms(vpaApplicationId: number): Observable<void> {
    return this.http.put<void>(ApiResources.declineIllionTerms(vpaApplicationId), null);
  }

  changeToPayNow(vpaApplicationId: number): Observable<void> {
    return this.http.put<void>(ApiResources.changeToPayNow(vpaApplicationId), null);
  }

  getCreateSupportTicketSubjects(): Observable<SubjectItem[]> {
    return this.http.get<SubjectItem[]>(ApiResources.getCreateSupportTicketSubjects());
  }

  submitCreateSupportTicketModel(model: CreateSupportTicketModel): Observable<void> {
    return this.http.post<void>(ApiResources.submitCreateSupportTicketModel(), model);
  }

  getVendorThankYouRequest(cpnOrApplicationId: string): Observable<VendorThankYouModel> {
    return this.http.get<VendorThankYouModel>(ApiResources.getVendorThankYouRequest(cpnOrApplicationId));
  }

  getCreateAdditionalVpa(cpn: string, apiConsumerVpaApplicationAggregateId?: string): Observable<CreateAdditionalVpaApplyApplicationModel> {
    let params = {};

    if (apiConsumerVpaApplicationAggregateId) params = { ...params, apiConsumerVpaApplicationAggregateId };

    return this.http.get<CreateAdditionalVpaApplyApplicationModel>(ApiResources.createAdditionalVpa(cpn), {
      params
    });
  }

  getCreateAdditionalVpaPdf(cpn: string): Observable<CreateAdditionalVpaPdfModel> {
    return this.http.get<CreateAdditionalVpaPdfModel>(ApiResources.createAdditionalVpaPdf(cpn));
  }

  upsertCreateAdditionalVpaPdf(request: CreateAdditionalVpaPdfModel): Observable<CreateAdditionalVpaApplyPdfResponseModel> {
    return this.http.put<CreateAdditionalVpaApplyPdfResponseModel>(ApiResources.createAdditionalVpaPdf(request.cpn), request);
  }
}

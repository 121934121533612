<span *ngIf="isSubmitted && control.invalid && control.errors.required && !skipValidation">
  {{ required }}
</span>
<span *ngIf="isSubmitted && control.invalid && control.errors.positiveNumber && !skipValidation">
  {{ positiveNumber }}
</span>
<span *ngIf="isSubmitted && control.invalid && control.errors.twoDecimalPlaces && !skipValidation">
  {{ twoDecimalPlaces }}
</span>
<span *ngIf="isSubmitted && control.invalid && control.errors.invalidEmail && !skipValidation">
  {{ email }}
</span>
<span *ngIf="isSubmitted && control.invalid && control.errors.invalidDate && !skipValidation">
  {{ invalidDate }}
</span>
<span *ngIf="isSubmitted && control.invalid && control.errors.invalidMobileNumber && !skipValidation">
  {{ validPhoneNumber }}
</span>
<span *ngIf="isSubmitted && control.invalid && control.errors.pattern && !skipValidation">
  {{ pattern }}
</span>
<span *ngIf="isSubmitted && control.invalid && control.errors.nonZero && !skipValidation">
  {{ control.errors.nonZero.message }}
</span>
<span *ngIf="isSubmitted && control.invalid && control.errors.numberRange && !skipValidation">
  {{ control.errors.numberRange.message }}
</span>

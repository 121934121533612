import { Component, OnInit } from '@angular/core';
import { BasicRouteService } from '@ca/shared/utils/router';

@Component({
  template: ''
})
export class HomeRoutingComponent implements OnInit {
  constructor(private routeService: BasicRouteService) {}

  ngOnInit() {
    this.routeService.home();
  }
}

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationFlow } from '@ca/shared/models';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { Address, PaymentSettlementOption, VpaApplicationStatus } from 'apps/legacy/src/app/_shared/models';
import { NumberValidators } from 'apps/legacy/src/app/_shared/validators/number-validators';
import { ApplicationFundingDetails, IHasAgency, IHasFundingDetails, IHasSalesAuthority, IHasVendorQuestions } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsFormService {
  isCurrentUserAnAgencyGroupUser: boolean;

  constructor(protected formBuilder: FormBuilder, protected datePipe: DatePipe, protected appStateService: AppStateService) {}

  protected mapAgencyId(application: IHasAgency) {
    return {
      agencyId: [application.agencyId, Validators.required]
    };
  }

  protected mapFundingDetails(
    application: IHasFundingDetails,
    isFromCreateStep: boolean = false,
    isFromPdfCreateStep: boolean = false,
    preselectedPaymentSettlementOption: PaymentSettlementOption = null,
    vpaApplicationStatus: VpaApplicationStatus = null
  ) {
    let dateFormatted = '';
    let requestedAmount = null;
    let sellingPriceLowerLimit = null;
    let sellingPriceUpperLimit = null;
    if (application.fundingDetails) {
      if (vpaApplicationStatus !== VpaApplicationStatus.EmptyTrainingApplicationCreated) {
        dateFormatted = this.datePipe.transform(application.fundingDetails.startDate, 'dd/MM/yyyy');

        requestedAmount = application.fundingDetails.requestedAmount;

        if (application.fundingDetails.sellingPriceLowerLimit !== 0) {
          sellingPriceLowerLimit = application.fundingDetails.sellingPriceLowerLimit;
        }

        if (application.fundingDetails.sellingPriceUpperLimit !== 0) {
          sellingPriceUpperLimit = application.fundingDetails.sellingPriceUpperLimit;
        }
      }
    }

    this.appStateService.isCurrentUserAnAgencyGroupUser$.subscribe((value) => {
      this.isCurrentUserAnAgencyGroupUser = value;
    });

    return {
      applicationFundingDetails: this.formBuilder.group({
        startDate: [dateFormatted, Validators.required],
        requestedAmount: [requestedAmount, [NumberValidators.positiveNumber, NumberValidators.twoDecimalPlaces]],
        isPayNow: [this.isPayNowSelected(application.fundingDetails, isFromPdfCreateStep, preselectedPaymentSettlementOption)],
        sellingPriceLowerLimit: [
          sellingPriceLowerLimit,
          isFromCreateStep || isFromPdfCreateStep
            ? [NumberValidators.twoDecimalPlaces, NumberValidators.positiveNumber]
            : [NumberValidators.twoDecimalPlaces]
        ],
        sellingPriceUpperLimit: [sellingPriceUpperLimit, NumberValidators.twoDecimalPlaces],
        isProvidingSellingRange: [
          application.fundingDetails.sellingPriceLowerLimit !== application.fundingDetails.sellingPriceUpperLimit ||
            (application.fundingDetails.sellingPriceLowerLimit === 0 && application.fundingDetails.sellingPriceUpperLimit === 0)
        ]
      })
    };
  }

  private isPayNowSelected(
    fundingDetails: ApplicationFundingDetails,
    isFromPdfCreateStep: boolean = false,
    preselectedPaymentSettlementOption: PaymentSettlementOption = null
  ): boolean {
    if (isFromPdfCreateStep) return !!fundingDetails;

    if (fundingDetails?.canRequestPayNow) {
      if (preselectedPaymentSettlementOption) return preselectedPaymentSettlementOption === PaymentSettlementOption.PayNow;

      return (
        ((fundingDetails as ApplicationFundingDetails)?.paymentSettlementOption ?? PaymentSettlementOption.PayLater) ===
        PaymentSettlementOption.PayNow
      );
    }

    return false;
  }

  protected mapVendorQuestions(application: IHasVendorQuestions) {
    return {
      vendorQuestions: this.formBuilder.group({
        isAnyVendorRepresentatives: [application.vendorQuestions.isAnyVendorRepresentatives],
        isCompanyOnTitle: [application.vendorQuestions.isCompanyOnTitle]
      })
    };
  }

  protected mapApplicationListingDetails(
    address: Address,
    agencyId: number,
    listinglessApplicationAgentImportAggregateId: string,
    isCurrentUserAnAgencyGroupUser: boolean
  ): FormGroup {
    return this.formBuilder.group({
      selectedAgencyId: [agencyId, NumberValidators.nonZero],
      addressSearchControl: [address !== null ? Address.getFullAddress(address) : '', Validators.required],
      ...this.mapAddress(address !== null ? address : new Address()),
      ...this.mapListinglessApplicationAgentImportAggregateId(listinglessApplicationAgentImportAggregateId, isCurrentUserAnAgencyGroupUser)
    });
  }

  protected mapListinglessApplicationAgentImportAggregateId(
    listinglessApplicationAgentImportAggregateId: string,
    isCurrentUserAnAgencyGroupUser: boolean
  ) {
    if (!isCurrentUserAnAgencyGroupUser) return {};

    return {
      listinglessApplicationAgentImportAggregateId: [listinglessApplicationAgentImportAggregateId, Validators.required]
    };
  }

  protected mapCreateQuestions(
    application: IHasVendorQuestions,
    applicationFlow: ApplicationFlow,
    forceVpaApplicationStartedBy: ApplicationFlow
  ) {
    let isAgentApplicationFlow: boolean;
    if (forceVpaApplicationStartedBy) {
      isAgentApplicationFlow = forceVpaApplicationStartedBy === ApplicationFlow.Agent;
    } else {
      isAgentApplicationFlow = applicationFlow === ApplicationFlow.Agent;
    }

    return this.formBuilder.group({
      isAgentApplicationFlow: [isAgentApplicationFlow],
      isAnyVendorRepresentatives: [application.vendorQuestions.isAnyVendorRepresentatives],
      isCompanyOnTitle: [application.vendorQuestions.isCompanyOnTitle]
    });
  }

  protected mapSalesAuthoritySettings(application: IHasSalesAuthority, forceAllowingAuthorityToBeUploadedLater: boolean) {
    // If we are forcing the authority upload to be optional, then we want to default the option as upload later
    return {
      shouldUploadAuthorityNow: [
        application.salesAuthorityDocuments?.length > 0
          ? true
          : forceAllowingAuthorityToBeUploadedLater
          ? false
          : application.shouldUploadAuthorityNow
      ]
    };
  }

  protected mapAddress(address: Address, isOptional = false) {
    return {
      addressSearchControl: [address !== null ? Address.getFullAddress(address) : ''],
      address: this.formBuilder.group({
        subNumber: [address?.subNumber],
        streetNumber: [address?.streetNumber, isOptional ? null : Validators.required],
        street: [address?.street, isOptional ? null : Validators.required],
        suburb: [address?.suburb, isOptional ? null : Validators.required],
        state: [address?.state, isOptional ? null : Validators.required],
        postcode: [address?.postcode, isOptional ? null : Validators.required],
        country: [address?.country]
      })
    };
  }

  protected mapSearchAddressControl(address: Address) {
    if (!address?.street) {
      return '';
    }

    return Address.getFullAddress(address);
  }

  private mapBaseFundingDetailsToModel(application: IHasFundingDetails, form: FormGroup): void {
    if (!application.fundingDetails) {
      application.fundingDetails = new ApplicationFundingDetails();
    }

    const requestedAmount = form.get('applicationFundingDetails.requestedAmount');
    if (requestedAmount) {
      application.fundingDetails.requestedAmount = requestedAmount.value;
    }

    const startDate = form.get('applicationFundingDetails.startDate');
    if (startDate) {
      application.fundingDetails.startDate = startDate.value;
    }
  }

  protected mapFundingDetailsToModel(application: IHasFundingDetails, form: FormGroup, isFromCreatePdfStep: boolean = false) {
    this.mapBaseFundingDetailsToModel(application, form);

    application.fundingDetails.sellingPriceLowerLimit = form.get('applicationFundingDetails.sellingPriceLowerLimit').value
      ? form.get('applicationFundingDetails.sellingPriceLowerLimit').value
      : 0;

    application.fundingDetails.sellingPriceUpperLimit =
      form.get('applicationFundingDetails.isProvidingSellingRange').value &&
      form.get('applicationFundingDetails.sellingPriceUpperLimit').value
        ? form.get('applicationFundingDetails.sellingPriceUpperLimit').value
        : application.fundingDetails.sellingPriceLowerLimit || 0;

    if (!isFromCreatePdfStep) {
      const isPayNow = form.get('applicationFundingDetails.isPayNow');
      if (isPayNow) {
        (application.fundingDetails as ApplicationFundingDetails).paymentSettlementOption = isPayNow.value
          ? PaymentSettlementOption.PayNow
          : PaymentSettlementOption.PayLater;
      }
    }
  }

  protected mapSalesAuthoritySettingsToModel(application: IHasSalesAuthority, form: FormGroup): void {
    application.shouldUploadAuthorityNow = form.get('shouldUploadAuthorityNow').value;
  }

  protected mapCreateQuestionsToModel(application: IHasVendorQuestions, form: FormGroup): void {
    application.vendorQuestions.isCompanyOnTitle = form.get('vendorQuestions.isCompanyOnTitle').value;

    application.vendorQuestions.isAnyVendorRepresentatives = form.get('vendorQuestions.isAnyVendorRepresentatives').value;

    // If there are Representatives while the REPRESENTATION switcher is off, only keep normal vendors
    const applicantsFormArray = (form.get('vendors') as FormArray).controls;
    if (!form.get('vendorQuestions.isAnyVendorRepresentatives').value) {
      (form.get('vendors') as FormArray).controls = applicantsFormArray.filter((c) => !c.get('isRepresentative').value);
    }

    application.applicationFlow = form.get('vendorQuestions.isAgentApplicationFlow').value
      ? ApplicationFlow[ApplicationFlow.Agent]
      : ApplicationFlow[ApplicationFlow.Vendor];
  }

  protected mapFormGroupToAddress(form: AbstractControl): Address {
    return new Address().fromModel({
      subNumber: form.get('subNumber').value,
      streetNumber: form.get('streetNumber').value,
      street: form.get('street').value,
      suburb: form.get('suburb').value,
      state: form.get('state').value,
      postcode: form.get('postcode').value,
      country: form.get('country').value
    });
  }
}

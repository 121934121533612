import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService, AppStateService as NewAppStateService } from '@ca/shared/app-state';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(private appStateService: AppStateService, private router: Router, private newAppStateService: NewAppStateService) {}

  ngOnInit() {
    parent?.postMessage('campaignAgentProcessingCompleted', '*');

    this.appStateService.clearState();
    this.newAppStateService.clearState();
    window.location.href = AppSettingsService.appSettings.casUrl + 'account/logout';
  }
}

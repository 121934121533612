import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@ca/shared/base-components';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { PromptContent } from '../../models/prompt-content';
import { PromptContentService } from '../../services/prompt-content.service';

@Component({
  selector: 'ca-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromptModalComponent extends BaseComponent implements OnInit {
  content$: Observable<PromptContent> = this.contentService.content$;
  comments$: Observable<FormControl> = this.contentService.comments$;

  constructor(public modalRef: MDBModalRef, private contentService: PromptContentService) {
    super();
  }

  ngOnInit(): void {}

  onSuccess(content: PromptContent): void {
    if (content.successAction) {
      content.successAction(this.contentService.getComments());
    }

    this.modalRef.hide();
    this.contentService.clearContent();
  }

  onCancel(content: PromptContent): void {
    this.modalRef.hide();
    this.contentService.clearContent();

    if (content.cancelAction) {
      content.cancelAction();
    }
  }
}

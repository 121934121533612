import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@ca/shared/base-components';
import { AlertModalSign } from '../../models/alert-modal-sign';

@Component({
  selector: 'ca-responsive-modal-v2',
  templateUrl: './responsive-modal-v2.component.html',
  styleUrls: ['./responsive-modal-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsiveModalV2Component extends BaseComponent {
  @Input() modalId = 'modalV2';
  @Input() showModal: boolean;
  @Input() showModalCloseButton: boolean;
  @Input() hasTitleContent: boolean;
  @Input() hasBodyContent: boolean;
  @Input() hasFooterContent: boolean;
  @Input() forceFullHeight: boolean;
  @Input() showBackdrop = true;
  @Input() size: string;
  @Input() useV2Style = false;
  @Output() closeModalEvent: EventEmitter<void> = new EventEmitter();

  @ViewChild('titleSlot') titleSlot: ElementRef;
  @ViewChild('bodySlot') bodySlot: ElementRef;
  @ViewChild('footerSlot') footerSlot: ElementRef;

  get labelId() {
    return `${this.modalId}Label`;
  }

  get modalSize() {
    if (!this.size) {
      return null;
    }

    return `modal-${this.size}`;
  }

  readonly alertModalSign = AlertModalSign;

  constructor() {
    super();
  }

  closeModal() {
    this.closeModalEvent.emit();
  }
}

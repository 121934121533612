export enum CampaignAgentApplications {
  Legacy = 'Legacy',
  SmartComm = 'SmartComm',
  VpaPay = 'VpaPay',
  ReturningVendorPortal = 'ReturningVendorPortal',
  NextGenPayments = 'NextGenPayments'
}

export const basePathForApplication = (app: CampaignAgentApplications): string => {
  switch (app) {
    case CampaignAgentApplications.Legacy:
      return '';

    case CampaignAgentApplications.ReturningVendorPortal:
      return 'rvp';

    case CampaignAgentApplications.SmartComm:
      return 'smartComm';

    case CampaignAgentApplications.VpaPay:
      return 'vpapay';

    case CampaignAgentApplications.NextGenPayments:
      return 'ngp';

    default:
      throw new Error(`Could not get basePath for CampaignAgentApplications: ${app}`);
  }
};

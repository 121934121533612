import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VpaApplySettings } from './vpa-apply-settings';

@Injectable({
  providedIn: 'root'
})
export class GetVpaApplySettingService {
  private vpaApplySettingsSubject: BehaviorSubject<VpaApplySettings> = new BehaviorSubject<VpaApplySettings>(null);
  vpaApplySettings$: Observable<VpaApplySettings> = this.vpaApplySettingsSubject.asObservable();
  hasBeenSetup = false;

  constructor(private http: HttpClient) {}

  setAgencyGroupId(agencyGroupId: number) {
    localStorage.setItem('agencyGroupId', agencyGroupId.toString());
    this.hasBeenSetup = true;
    this.http.get<VpaApplySettings>(`agencyGroupSettings/${agencyGroupId}`).subscribe((res) => {
      this.vpaApplySettingsSubject.next(res);
    });
  }
}

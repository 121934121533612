import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@ca/shared/app-state';
import * as Sentry from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalException } from '../_shared/models/global-exception';
import { GlobalExceptionService } from '../_shared/services';

@Injectable()
export class GlobalExceptionInterceptor implements HttpInterceptor {
  constructor(private globalExceptionService: GlobalExceptionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        // If or we're calling an external URL, just keep going.
        if (!req.url.startsWith(AppSettingsService.appSettings.baseApiUrl)) {
          return throwError(err);
        }

        // Explicitly set the traceidentifier here.
        // It's also set in traceIdentifier.intercepter - but we want to be sure that it's capured on all errors.
        const correlationId = err.headers.get('x-correlation-id');
        if (correlationId) {
          Sentry.configureScope((scope) => {
            scope.setTag('TraceIdentifier', correlationId);
          });
        }

        switch (err.status) {
          case 400:
          case 404:
            this.globalExceptionService.setCurrentExceptionFromHttpError(err);
            return throwError(err);
          case 500:
            this.globalExceptionService.setCurrentException(new GlobalException(500));
            return throwError(err);
          default:
            return throwError(err);
        }
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'apps/legacy/src/app/app-state.service';
import { Observable } from 'rxjs';
import { ApplicationRouteService } from '../../services';

@Component({
  selector: 'vpa-impersonation-banner',
  templateUrl: './impersonation-banner.component.html',
  styleUrls: ['./impersonation-banner.component.scss']
})
export class ImpersonationBannerComponent implements OnInit {
  userIsImpersonating: boolean;
  agencyUserName$: Observable<string>;
  agencyGroupName$: Observable<string>;

  constructor(private appStateService: AppStateService, private applicationRouteService: ApplicationRouteService) {}

  ngOnInit() {
    this.appStateService.isImpersonating$.subscribe((isImpersonating) => {
      this.userIsImpersonating = isImpersonating;

      this.agencyUserName$ = this.appStateService.getAgencyUserName$();
      this.agencyGroupName$ = this.appStateService.getAgencyGroupName$();
    });
  }

  stop() {
    this.appStateService.clearState();
    this.applicationRouteService.terminateImpersonation();
  }
}

export const getObjectFromSessionStorageByKey = (key: string) => {
  const value = sessionStorage.getItem(key);

  const pairs = value?.split('&');
  const res = {};

  if (!!pairs) {
    for (const element of pairs) {
      const kv = element.split('=');
      res[kv[0]] = kv[1];
    }
  }

  return res;
};

<section class="section-card">
  <div [formGroup]="additionalVpaForm$ | async">
    <div formGroupName="fundingDetails">
      <div class="row section-title">
        <div class="col-12">
          <ng-container *ngIf="isPndApplication"><div class="section-title">Additional Funding Details</div></ng-container>
          <ng-container *ngIf="!isPndApplication"
            ><label class="text-uppercase strong">
              Additional Funding Details
            </label></ng-container
          >
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 m-t-15">
          <label for="requestedAmount" [class.text-uppercase]="!isPndApplication">
            Pay Now Total
          </label>
          <span class="description font-14 m-l-10">(Total is exclusive of our 5.9% fee)</span>
          <div class="input-group">
            <div class="input-group-prepend invis-primary-color">
              <span class="input-group-text">$</span>
            </div>
            <input
              class="form-control unsign-pay-now-total"
              type="text"
              [formControlName]="'requestedAmount'"
              mask="separator.2"
              thousandSeparator=","
            />
          </div>
          <vpa-control-validation-message
            [control]="requestedAmount$ | async"
            [fieldName]="'Additional Funded Amount'"
            [isSubmitted]="isSubmitted$ | async"
          ></vpa-control-validation-message>
        </div>

        <div class="col-12 col-lg-6 m-t-15">
          <label for="startDate" [class.text-uppercase]="!isPndApplication">
            Pay Now Date
          </label>
          <mdb-date-picker
            class="child-input-no-margin bg-on-white"
            name="startDate"
            [options]="datePickerOptions"
            [placeholder]="'Select date'"
            [formControlName]="'startDate'"
            [outlineInput]="true"
          ></mdb-date-picker>
          <vpa-control-validation-message
            [control]="startDate$ | async"
            [fieldName]="'Start Date'"
            [isSubmitted]="isSubmitted$ | async"
          ></vpa-control-validation-message>
        </div>
      </div>
    </div>
  </div>
</section>

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caDate'
})
export class CaDatePipe extends DatePipe implements PipeTransform {
  transform(value: any): any {
    try {
      return super.transform(value, 'MMM d, yyyy');
    } catch (error) {
      // Catches InvalidPipeArgument error in MS Edge Legacy that does not support angular date parser
      // In this case we simply return the input parameter
      return value;
    }
  }
}

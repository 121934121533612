import { Component, OnInit } from '@angular/core';
import { GlobalException } from '../../models/global-exception';
import { Globals } from '../../models/global-variables';
import { ProblemDetailTypes } from '../../models/problem-detail-types';
import { ValidationMessageResources } from '../../models/validation-message-resources';

@Component({
  selector: 'vpa-global-exception-display',
  templateUrl: './global-exception-display.component.html',
  styleUrls: ['./global-exception-display.component.scss']
})
export class GlobalExceptionDisplayComponent implements OnInit {
  constructor(public globals: Globals) {}

  private readonly errorsToIgnore: string[] = [ProblemDetailTypes.FatZebraProcessingError, ProblemDetailTypes.PaywayAccountValidationError];

  ngOnInit() {}

  isShow() {
    return !!this.globals.exception;
  }

  getErrorMessages(exception: GlobalException): string[] {
    let errorMessages: string[] = [];
    if (exception.status === 500) {
      errorMessages.push(ValidationMessageResources.httpError500);
    } else if (this.errorsToIgnore.indexOf(exception.type) >= 0) {
      return errorMessages;
    } else if (exception.detail) {
      errorMessages.push(exception.detail);
    } else if (exception.errors) {
      for (const exceptionError of Object.keys(exception.errors)) {
        const errors = exception.errors[exceptionError] as string[];
        errorMessages = errorMessages.concat(errors);
      }
    }

    if (errorMessages.length === 0) {
      errorMessages.push(ValidationMessageResources.httpError500);
    }

    return errorMessages;
  }

  showUnelectedVendorError() {
    return this.globals.exception && this.globals.exception.type === ProblemDetailTypes.CasAppUnelectedVendorErrorType;
  }
}

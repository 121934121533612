export interface Badge {
  content: string;
  style: BadgeStyle;
}

export enum BadgeStyle {
  Danger = 'danger',
  Default = 'default',

  // For CAD only
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Dark = 'dark'
}

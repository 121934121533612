import { ReconciliationRequestModel } from 'apps/legacy/src/app/finance/models';
import { AgentSmartCommissionTermsRequest } from 'apps/legacy/src/app/smart-commission/models/agent-request-smart-commission.model';
import { Guid } from 'guid-typescript';
import { DigitalDomainSource } from './digital-domain-source';

export class ApiResources {
  public static getApplicationCreationContextData(cpn: string, isAdditionalVpa: boolean, vpaApplicationId: number): string {
    if (vpaApplicationId) {
      return `payNowDirect/applications/null/context?vpaApplicationId=${vpaApplicationId}`;
    } else if (cpn) {
      return `payNowDirect/applications/${cpn}/context?isAdditionalVpa=${isAdditionalVpa}`;
    } else {
      return `payNowDirect/applications/context`;
    }
  }

  public static submitApplicationForReview(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/submitForReview`;
  }

  public static getPayLaterCosts(agencyId: number, payNowDate: Date, payNowTotal: number): string {
    const payNowDateString = `${payNowDate.getFullYear()}-${payNowDate.getMonth() + 1}-${payNowDate.getDate()}`;
    return `vpaApply/applications/calculatePayLaterCosts?agencyId=${agencyId}&payNowDate=${payNowDateString}&payNowTotal=${payNowTotal}`;
  }

  public static getFatZebraUrl(vpaApplicationId: number, paymentAmount: number): string {
    return `vpaApply/applications/${vpaApplicationId}/fatZebra/getIframeUrl?paymentAmount=${paymentAmount}`;
  }

  public static calculateCostsAndDueDates(agencyId: number, payNowDate: Date, payNowTotal: number, vpaApplicationId?: number): string {
    const payNowDateString = `${payNowDate.getFullYear()}-${payNowDate.getMonth() + 1}-${payNowDate.getDate()}`;
    return !!vpaApplicationId
      ? `vpaApply/applications/calculateCostsAndDueDates?agencyId=${agencyId}&payNowDate=${payNowDateString}&payNowTotal=${payNowTotal}&vpaApplicationId=${vpaApplicationId}`
      : `vpaApply/applications/calculateCostsAndDueDates?agencyId=${agencyId}&payNowDate=${payNowDateString}&payNowTotal=${payNowTotal}`;
  }

  public static applyApplication(id: number): string {
    return `vpaApply/applications/${id}/apply`;
  }

  public static completeContributionStep(id: number) {
    return `vpaApply/applications/${id}/contributionsv2`;
  }

  public static processFatZebraPayment = (id: number) => `vpaApply/applications/${id}/contributionsv2/processFatZebraPayment`;
  public static processCaPayFatZebraTokenPayment = (id: number) =>
    `vpaApply/applications/${id}/contributionsv2/process-ca-pay-fatzebra-token-payment`;

  public static getCreateApplication(cpn): string {
    if (cpn) {
      return `vpaApply/applications/${cpn}/create`;
    } else {
      return `vpaApply/applications/create`;
    }
  }

  public static getCreatePdfApplication(cpn): string {
    if (cpn) {
      return `vpaApply/applications/${cpn}/create-pdf`;
    } else {
      return `vpaApply/applications/create-pdf`;
    }
  }

  public static getReviewPdfApplicationRequest(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/review-pdf`;
  }

  public static upsertCreateApplicationWithoutListing(): string {
    return `vpaApply/applications/createApplication`;
  }

  public static upsertCreateApplication(cpn): string {
    return `vpaApply/applications/${cpn}/createApplication`;
  }

  public static upsertCreatePdfApplication(cpn): string {
    return `vpaApply/applications/${cpn}/create-pdf`;
  }

  public static upsertCreateListinglessPdfApplication(): string {
    return `vpaApply/applications/create-pdf`;
  }

  public static upsertReviewPdfApplication(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/submit-pdf-agreement`;
  }

  public static createVpaApplyPaywayCustomer(id: number): string {
    return `vpaApply/applications/${id}/contributionsv2/createPaywayCustomer`;
  }

  public static createAdditionalVpaCreditCardCustomer(id: number): string {
    return `additionalVpa/${id}/contributionsv2/createPaywayCustomer`;
  }

  public static getActionLogs(aggregate: Guid, domain: DigitalDomainSource): string {
    return `vpaApply/applications/${domain}/${aggregate}/actionLogs`;
  }

  public static getApplicationContributions(id: number) {
    return `vpaApply/applications/${id}/contributionsv2`;
  }

  public static getApplicationDocument(cpn: string, documentId: number, documentSourceDomain: string): string {
    return `vpaApply/applications/${cpn}/documents/${documentSourceDomain}/${documentId}`;
  }

  public static getApplicationDocumentByVpaApplicationId(vpaApplicationId: number, documentId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/documents/${documentId}`;
  }

  public static getApplicationToSign(id): string {
    return `vpaApply/applications/${id}/sign`;
  }

  public static getApplicationToSignV2(id: number): string {
    return `vpaApply/applications/${id}/signv2`;
  }

  public static amendApplication(id: number): string {
    return `vpaApply/applications/${id}/amendv2`;
  }

  public static getPaywayScriptSource(): string {
    return 'https://api.payway.com.au/rest/v1/payway.js';
  }

  public static getSummary(id): string {
    return `vpaApply/applications/${id}/summary`;
  }

  public static uploadSalesAuthorityDocuments(id): string {
    return `vpaApply/applications/${id}/summary/uploadAuthority`;
  }

  public static uploadMarketingScheduleDocuments(id): string {
    return `vpaApply/applications/${id}/summary/uploadMarketingSchedule`;
  }

  public static uploadApplicationDocumentsForWaitingForApprovalCampaign(id): string {
    return `vpaApply/applications/${id}/summary/uploadDocumentsForWaitingForApprovalCampaign`;
  }

  public static downloadVendorAuthorisationDocument(id): string {
    return `vpaApply/applications/${id}/downloadVendorAuthorisation`;
  }

  public static signApplication(id: number): string {
    return `vpaApply/applications/${id}/sign`;
  }

  public static signApplicationByClick(id): string {
    return `vpaApply/applications/${id}/signByClick`;
  }

  public static signApplicationV2(id: number): string {
    return `vpaApply/applications/${id}/signv2`;
  }

  public static signApplicationByClickV2(id: number): string {
    return `vpaApply/applications/${id}/signByClickv2`;
  }

  public static getAgreementSectionPdf(applicationId: number, mergeTemplateAggregateId: string, version: number): string {
    return `vpaApply/applications/${applicationId}/GetPdfForAgreement/${mergeTemplateAggregateId}/${version}`;
  }

  public static checkSigningVendorToken(token: string): string {
    return `vpaApply/login/signingVendor/check?Token=${token}`;
  }

  public static loginSigningVendor(): string {
    return 'vpaApply/login/signingVendor';
  }

  public static loginWithCasToken(token: string): string {
    return `user/authentication/Token?token=${token}`;
  }

  public static generatePdfVendorAgreement(id: number): string {
    return `vpaApply/applications/${id}/generate-pdf-agreement`;
  }

  public static resendSingleSigningVendorInvitation(applicationId: number, applicationVendorId: number): string {
    return `vpaApply/applications/${applicationId}/vendors/${applicationVendorId}/resend-invitation`;
  }

  public static resendApplicantInvitation(additionalApplicationId: number): string {
    return `additionalVpa/${additionalApplicationId}/applicants/resendInvitation`;
  }

  public static regenerateJwt(): string {
    return 'user/authentication/RegenerateJwt';
  }

  public static addFeedback(id): string {
    return `vpaApply/applications/${id}/feedback`;
  }

  public static getUnelectedVendorError(token, verifier, vpaApplicationId): string {
    return `vpaApply/login/unelectedVendorErrorReport?token=${token}&verifier=${verifier}&vpaApplicationId=${vpaApplicationId}`;
  }

  public static getIncompleteApplications(pageSize: number, pageNumber: number, showMyApplicationsOnly: boolean): string {
    // tslint:disable-next-line: max-line-length
    return `vpaApply/applications/incomplete?pageSize=${pageSize}&pageNumber=${pageNumber}&showMyApplicationsOnly=${showMyApplicationsOnly}&ShowVpaApplyApplicationsOnly=false`;
  }

  public static createEmptyTrainingApplication(): string {
    return 'vpaApply/applications/createEmptyTrainingApplication';
  }

  public static getNavMenuBadgeDataForAgent(): string {
    return 'vpaApply/navMenuBadgeData/agent?OnlyCountVpaApply=false';
  }

  public static getNavMenuBadgeDataForAgencyGroup(): string {
    return 'vpaApply/navMenuBadgeData/agencygroup';
  }

  public static removeCancellationRequest(campaignId: number, vpaReceivableId: number): string {
    return `vpaApply/applications/${campaignId}/VpaReceivables/${vpaReceivableId}/RemoveCancellationRequest`;
  }

  public static cancelFundingRequest(): string {
    return `vpaApply/applications/AgencyApplicationCancel`;
  }

  public static convertLegacyPndToPayLater(): string {
    return `vpaApply/applications/AgencyConvertByoPayNowToPayLater`;
  }

  public static convertPndToPayLater(): string {
    return `vpaApply/applications/ConvertPndToPayLater`;
  }

  public static loadVendorInvitation(token: string, verifier: string): string {
    return `user/registration/invitation/load?token=${token}&verifier=${verifier}`;
  }

  public static requestVendorMobileNumberUpdate(): string {
    return `user/registration/mobile/update`;
  }

  public static addUserEvent(): string {
    return `feedback/UserEvents`;
  }

  public static addAnonymousUserEvent(token: string, verifier: string): string {
    return `feedback/AnonymousUserEvents?token=${token}&verifier=${verifier}`;
  }

  public static getAgencyGroupUsersList(): string {
    return 'user/agencyGroupUsers/list';
  }

  public static getAgencySettingQuestions(): string {
    return 'user/agencySettings';
  }

  public static createAgencyGroupUser(agencyGroupId?: number): string {
    return agencyGroupId ? `user/agencyGroupUsers/agencyGroup/${agencyGroupId}/users/create` : 'user/agencyGroupUsers/create';
  }

  public static getShareListingAgents(agencyGroupId?: number): string {
    return agencyGroupId
      ? `user/agencyGroupUsers/agencyGroup/${agencyGroupId}/users/create/shareListingAgents`
      : 'user/agencyGroupUsers/create/shareListingAgents';
  }

  public static getSwitchUserToken(userId: number, loginDestination: string): string {
    return `user/authentication/switchUserAccounts/${userId}/${loginDestination}`;
  }

  public static switchToApp(token: string): string {
    return `user/authentication/Token?token=${token}`;
  }

  public static getAgencyGroupUser(userId: number, agencyGroupId?: number): string {
    return agencyGroupId ? `user/agencyGroupUsers/agencyGroup/${agencyGroupId}/user/${userId}` : `user/agencyGroupUsers/${userId}`;
  }

  public static disableAgencyGroupUser(userId: number): string {
    return `user/agencyGroupUsers/${userId}/disable`;
  }

  public static editAgencyGroupUser(): string {
    return 'user/agencyGroupUsers/edit';
  }

  public static editAgentUserSoldCampaignSettings(userId: number): string {
    return `user/agencyGroupUsers/${userId}/editAgentUserSoldCampaignSettings`;
  }

  public static sendInvitation(userId: number): string {
    return `user/AgencyGroupUserInvitation/ReSendInvitation/${userId}`;
  }

  public static reactivateUser(userId: number): string {
    return `user/agencyGroupUser/${userId}/enable`;
  }

  public static getVpaApplySettingsForAdmin(agencyGroupId: number): string {
    return `agencyGroupSettings/${agencyGroupId}`;
  }

  public static getCpnSummary(cpn: string): string {
    return `vpaApply/applications/campaigns/${cpn}/summary`;
  }

  public static getAdditionalVpaDocument(cpn: string, documentId: number): string {
    return `additionalvpa/${cpn}/documents/${documentId}`;
  }

  public static editVendor(campaignId: number, vpaPayVendorId: number): string {
    return `vpaApply/applications/${campaignId}/vpaPayVendor/${vpaPayVendorId}/edit`;
  }

  public static getBankStatements(vpaApplicationId: number): string {
    return `vpaApply/bankstatements/${vpaApplicationId}`;
  }

  public static acceptIllionTerms(vpaApplicationId: number): string {
    return `vpaApply/bankstatements/${vpaApplicationId}/acceptIllionTermsAndConditions`;
  }

  public static declineIllionTerms(vpaApplicationId: number): string {
    return `vpaApply/bankstatements/${vpaApplicationId}/declineIllionTermsAndConditions`;
  }

  public static changeToPayNow(vpaApplicationId: number): string {
    return `vpaApply/bankstatements/${vpaApplicationId}/payNow`;
  }

  public static getCreateSupportTicketSubjects(): string {
    return 'Support/SupportRequests/GetSubjects';
  }

  public static submitCreateSupportTicketModel(): string {
    return 'Support/SupportRequests';
  }

  public static getVendorThankYouRequest(cpnOrApplicationId: string): string {
    return `vpaApply/applications/${cpnOrApplicationId}/thankYou`;
  }

  public static downloadInvoice(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/downloadInvoice`;
  }

  public static downloadReceipt(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/downloadReceipt`;
  }

  public static downloadVendorStatement(cpn: string, vpaApplicationId: number): string {
    return `vpaApply/applications/${cpn}/statements/download/${vpaApplicationId}`;
  }

  public static uploadBrfFile(): string {
    return 'bPayRemittanceReadStore/BRFUpload';
  }

  public static getSearchManagePaymentResult(): string {
    return 'financePortal/manage-payments';
  }

  public static getReconciliationResult(request: ReconciliationRequestModel): string {
    return `financePortal/reconciliation?tradingEntityId=${request.tradingEntityId}&fromDate=${request.fromDate}&toDate=${request.toDate}&showBpay=${request.showBpay}&showCreditCard=${request.showCreditCard}&showPayLater=${request.showPayLater}&searchKeywords=${request.searchKeywords}&page=${request.page}&pageSize=${request.pageSize}`;
  }

  public static getPaymentDetails(paymentAggregateId: Guid): string {
    return `vpaApply/payNowDirect/paymentDetails/${paymentAggregateId}`;
  }

  public static getTradingEntities(): string {
    return 'financePortal/reconciliation/tradingEntities';
  }

  public static downloadReconciliationCsv(): string {
    return 'financePortal/reconciliation/downloadCsv';
  }

  public static downloadReconciliationOfx(): string {
    return 'financePortal/reconciliation/downloadOfx';
  }

  public static downloadSearchManagePaymentCsv(): string {
    return 'financePortal/manage-payments/DownloadCsv';
  }

  public static getApplicationPayments(applicationAggregateId: string): string {
    return `vpaApply/applications/${applicationAggregateId}/payments`;
  }

  public static markApplicationFullyPaid(applicationAggregateId: string): string {
    return `vpaApply/applications/${applicationAggregateId}/markAsPaid`;
  }

  public static createAdditionalVpa(cpn: string): string {
    return `vpaApply/applications/additional/${cpn}/create`;
  }

  public static createAdditionalVpaPdf(cpn: string): string {
    return `vpaApply/applications/additional/${cpn}/create-pdf`;
  }

  public static checkVendorSigningProperties(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/signingStatusv2`;
  }

  public static getQrCodeForVendor(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/qrcode/vendor`;
  }

  public static getVendorApplicationProgressLog(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/qrcode/vendor/progressLog`;
  }

  public static getVendorCompletionReceipt(vpaApplicationId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/qrcode/vendor/completionReceipt`;
  }

  public static updateOptInOptOut(vpaApplicationId: number, applicantId: number): string {
    return `vpaApply/applications/${vpaApplicationId}/applicants/${applicantId}/DepositLendingOptInOptOut`;
  }

  public static getSmartCommissionAgentTermsAndConditions(request: AgentSmartCommissionTermsRequest): string {
    const queryParameters = [
      `soldCampaignReceivableId=${request.receivableId ?? ''}`,
      `fundingToBeTransferredOnDate=${request.fundingToBeTransferredOnDate ?? ''}`,
      `totalAuthorisedAmount=${request.totalAuthorisedAmount ?? ''}`,
      `totalEstimatedFees=${request.totalEstimatedFees ?? ''}`,
      `totalFundedAmount=${request.totalFundedAmount ?? ''}`
    ];

    return `smartCommission/${request.cpn}/terms-conditions?${queryParameters.join('&')}`;
  }

  public static getSmartCommissionAgentPdfTermsAndConditions(request: AgentSmartCommissionTermsRequest): string {
    const queryParameters = [
      `soldCampaignReceivableId=${request.receivableId ?? ''}`,
      `fundingToBeTransferredOnDate=${request.fundingToBeTransferredOnDate ?? ''}`,
      `totalAuthorisedAmount=${request.totalAuthorisedAmount ?? ''}`,
      `totalEstimatedFees=${request.totalEstimatedFees ?? ''}`,
      `totalFundedAmount=${request.totalFundedAmount ?? ''}`
    ];

    return `smartCommission/${request.cpn}/pdf-terms-conditions?${queryParameters.join('&')}`;
  }

  public static getManagePaymentDetails(applicationId: number): string {
    return `financePortal/vpaApplications/${applicationId}/details`;
  }

  public static adjustApplicationPayNowAmount(applicationId: number): string {
    return `financePortal/vpaApplications/${applicationId}/adjustPayNowAmount`;
  }

  public static getSearchAdminManageSmartCommissionResult(): string {
    return `smartCommission/adminManageSmartCommission`;
  }

  public static getAgentSoldCampaigns(): string {
    return `smartCommission/agent/soldCampaigns`;
  }

  public static getMarketingAwaitingPaymentApplications(pageSize: number, page: number): string {
    const queryParameters = [`pageSize=${pageSize ?? ''}`, `page=${page ?? ''}`];

    return `marketing/applications/awaiting-payment?${queryParameters.join('&')}`;
  }
}

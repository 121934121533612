import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationMessageResources } from '../../models/validation-message-resources';

@Component({
  selector: 'vpa-control-validation-message-v2',
  templateUrl: './control-validation-message-v2.component.html',
  styleUrls: ['./control-validation-message-v2.component.scss']
})
export class ControlValidationMessageV2Component implements OnInit {
  @Input() fieldName: string;
  @Input() isSubmitted: boolean;
  @Input() skipValidation: boolean;
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() patternMessage: string;
  control: FormControl;

  constructor() {}

  ngOnInit() {
    this.control = this.formGroup.get(this.controlName) as FormControl;
  }

  get required() {
    return this.fieldName + ' is required';
  }

  get positiveNumber() {
    return this.fieldName + ' must be greater than zero';
  }

  get twoDecimalPlaces() {
    return this.fieldName + ' can have at most two decimal places';
  }

  get invalidDate() {
    return ValidationMessageResources.invalidDate;
  }

  get email() {
    return ValidationMessageResources.emailInvalid;
  }

  get validPhoneNumber() {
    return ValidationMessageResources.mobileInvalid;
  }

  get pattern() {
    return this.patternMessage ?? ValidationMessageResources.invalidPattern;
  }
}

export enum PaymentSettlementOption {
  PayNow = 'PayNow',
  PayLater = 'PayLater'
}

export enum VpaPaymentSettlementOption {
  PayNow = 'Pay Now',
  PayLater = 'Pay Later'
}

export enum CreditCardChargeScheduleType {
  ChargeImmediately = 'ChargeImmediately',
  ScheduledForStartDate = 'ScheduledForStartDate'
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResources } from 'apps/legacy/src/app/_shared/models/api-resources';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { SourceDomain } from '../models';
import { CreatePaywayCustomerError } from '../models/create-payway-customer-error';
import { CreatePaywayCustomerRequest } from '../models/create-payway-customer-request';
import { CreatePaywayCustomerResponse } from '../models/create-payway-customer-response';
import { CreateCardCustomerApiService } from './create-card-customer-api.service';

declare var payway: any;

@Injectable({
  providedIn: 'root'
})
export class PaywayService {
  creditCardFrame = null;
  createCreditCardCustomerRequest: CreatePaywayCustomerRequest = new CreatePaywayCustomerRequest();
  private response: Subject<CreatePaywayCustomerResponse> = new Subject();
  private requestFailed: Subject<CreatePaywayCustomerError> = new Subject();

  constructor(private creditCardCustomerApiService: CreateCardCustomerApiService) {}

  addPaywayScript() {
    return new Promise((resolve, reject) => {
      const scripttagElement = document.createElement('script');
      scripttagElement.src = ApiResources.getPaywayScriptSource();
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    });
  }

  createCreditCardFrame(options) {
    payway.createCreditCardFrame(options, this.createdCallback);
    this.setContributionCorrelationId();
  }

  getPaywayToken(id: number, paymentAmount: number, sourceDomain: SourceDomain) {
    this.createCreditCardCustomerRequest.id = id;
    this.createCreditCardCustomerRequest.paymentAmount = paymentAmount;
    this.createCreditCardCustomerRequest.sourceDomain = sourceDomain;
    this.creditCardFrame.getToken(this.tokenCallback);
  }

  private createdCallback = (err, frame) => {
    this.creditCardFrame = frame;
    if (err) {
      console.log(err);
    }
  };

  private prepareCreateCreditCardCustomerRequest(singleUseTokenId) {
    this.createCreditCardCustomerRequest.idempotencyKey = Guid.create().toString();
    this.createCreditCardCustomerRequest.singleUseTokenId = singleUseTokenId;
  }

  private setContributionCorrelationId() {
    this.createCreditCardCustomerRequest.contributionCorrelationId = Guid.create().toString();
  }

  private tokenCallback = (err, data) => {
    if (err) {
      const errorReponse = new CreatePaywayCustomerResponse();
      errorReponse.error = 'Invalid credit card details';
      this.response.next(errorReponse);
    } else {
      this.prepareCreateCreditCardCustomerRequest(data.singleUseTokenId);

      switch (this.createCreditCardCustomerRequest.sourceDomain) {
        case SourceDomain.VpaApply:
          this.creditCardCustomerApiService.createVpaApplyCreditCardCustomer(this.createCreditCardCustomerRequest).subscribe(
            (res) => {
              this.response.next(res);
            },
            (resErr: HttpErrorResponse) => {
              this.requestFailed.next({
                messages: resErr.error.errors.global
              });
            }
          );
          break;

        case SourceDomain.AdditionalVpa:
          this.creditCardCustomerApiService.createAdditionalVpaCreditCardCustomer(this.createCreditCardCustomerRequest).subscribe(
            (res) => {
              this.response.next(res);
            },
            (resErr: HttpErrorResponse) => {
              this.requestFailed.next({
                messages: resErr.error.errors.global
              });
            }
          );
          break;
      }

      this.creditCardFrame.destroy();
      this.creditCardFrame = null;
    }
  };

  get response$(): Observable<CreatePaywayCustomerResponse> {
    return this.response.asObservable();
  }

  get requestFailed$(): Observable<CreatePaywayCustomerError> {
    return this.requestFailed.asObservable();
  }
}

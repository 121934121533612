import { AlertModalSign } from './alert-modal-sign';

export class ModalContent {
  title = 'Success';
  message: string;
  successAction: () => void;
  proceedButtonWording = 'Ok';
  sign = AlertModalSign.Success;
  showCancelButton = false;
  cancelButtonWording: string;
  cancelAction: () => void;
  isWarningModal = true;
}

<div class="section-title" *ngIf="contributions.length > 0">
  Added Credit Cards
</div>

<div class="row contributions-summary" *ngIf="contributions.length > 0">
  <div class="col-lg-6 col-md-6 col-sm-12 cc-container" *ngFor="let contribution of contributions; let i = index">
    <label>Card {{ i + 1 }}</label>
    <div
      class="credit-card"
      [attr.id]="'fat-zebra-card-' + i"
      [ngClass]="{ 'failed-transaction': isFailedContribution(contribution), 'paid-transaction': contribution.hasBeenPaid }"
    >
      <span *ngIf="shouldShowPaidStamp(contribution)" class="stamp is-approved">
        Paid
      </span>

      <div *ngIf="isFailedContribution(contribution)" class="float-right">
        <strong> {{ getContributionFailureMessage(contribution) }} </strong>
      </div>

      <div>
        <strong class="cc-name">
          {{ contribution.cardholderName }}
        </strong>
        <strong class="cc-number">
          {{ contribution.partialCardNumber }}
        </strong>
        <strong class="cc-type">
          {{ contribution.cardScheme }}
        </strong>
        <strong class="cc-amount">
          {{ contribution.amount | currency }}
        </strong>
        <strong class="cc-type">
          Surcharge
        </strong>
        <strong class="cc-amount">
          {{ (contribution.transactionSurcharge !== 0 ? contribution.transactionSurcharge : contribution.creditCardSurcharge) | currency }}
        </strong>
      </div>

      <button (click)="removeContribution(contribution)" class="btn remove-btn" *ngIf="contribution.canDeleteContribution">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>

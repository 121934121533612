import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResources } from '../models/api-resources';
import { LoginDestination } from '../models/login-destination';
import { SwitchUserTokenResponse } from '../models/switch-user-token-response';

@Injectable({
  providedIn: 'root'
})
export class SwitchAccountService {
  constructor(private http: HttpClient) {}

  getSwitchUserToken(userId: number, destination: LoginDestination): Observable<SwitchUserTokenResponse> {
    const loginDestination: string = LoginDestination[destination];
    return this.http.post<SwitchUserTokenResponse>(ApiResources.getSwitchUserToken(userId, loginDestination), null);
  }

  switchToApp(token: string): Observable<SwitchUserTokenResponse> {
    return this.http.get<SwitchUserTokenResponse>(ApiResources.switchToApp(token));
  }
}

import { FormGroup } from '@angular/forms';
import { FileValidationResponse, FormFileModel, LegalTerms, VpaApplicationDocumentModel } from 'apps/legacy/src/app/_shared/models';

export class SalesAuthorityReactiveInput {
  legalTerms: LegalTerms;
  salesAuthorityDocuments: FormFileModel[];
  marketingScheduleDocuments?: FormFileModel[];
  allDocuments?: VpaApplicationDocumentModel[];
  shouldUploadAuthorityNow: boolean;
  applicationForm: FormGroup;
  areFilesBeingProcessed: boolean;
  isSalesAuthorityMandatory: boolean;
  isMarketingScheduleMandatory: boolean;
  isSubmitted: boolean;
  fileValidationState: FileValidationResponse;
  isDisplayedInSections: boolean;
  requestedAmount: number;
  canUploadMarketingSchedules: boolean;
  showUploadedFiles: boolean;
  authorityDocumentIncludesMarketingSchedule: boolean;
}

export const REQUESTED_AMOUNT_THRESHOLD = 10000;

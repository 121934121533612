import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FilteredListItem } from '../../models';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'vpa-filtered-list',
  templateUrl: './filtered-list.component.html',
  styleUrls: ['./filtered-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilteredListComponent extends BaseComponent implements OnInit {
  @Input() items$: Observable<FilteredListItem[]>;
  @Input() label: string;
  @Output() itemSelected: EventEmitter<FilteredListItem> = new EventEmitter();

  formControl: AbstractControl = new FormControl();
  filteredItems$: Observable<FilteredListItem[]>;
  itemsToDisplay$: Observable<FilteredListItem[]>;

  private searchTerm$: Observable<string> = this.formControl.valueChanges.pipe(
    startWith(''),
    map((_) => (!!this.formControl.value ? this.formControl.value : ''))
  );

  constructor() {
    super();
  }

  ngOnInit() {
    this.filteredItems$ = combineLatest([this.items$, this.searchTerm$]).pipe(
      map(([items, searchTerm]) => items.filter((i) => i.name.search(new RegExp(searchTerm, 'i')) !== -1))
    );
    this.itemsToDisplay$ = this.filteredItems$.pipe(map((x) => x.slice(0, 10)));
  }

  onItemClicked(item: FilteredListItem) {
    this.itemSelected.emit(item);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SignaturePadModule } from 'angular2-signaturepad';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPrintModule } from 'ngx-print';
import { BaseModalsModule } from '../base-modals/base-modals.module';
import { ListingSearchTypePipe } from '../listings/pipes';
import { ActionItemResponsiveComponent } from './components/action-item-responsive/action-item-responsive.component';
import { ActionItemComponent } from './components/action-item/action-item.component';
import { AdditionalVpaFundingDetailsComponent } from './components/additional-vpa-funding-details/additional-vpa-funding-details/additional-vpa-funding-details.component';
import { AdditionalVpaMarketingQuotesComponent } from './components/additional-vpa-marketing-quotes/additional-vpa-marketing-quotes.component';
import { AdditionalVpaReadonlyMarketingQuotesComponent } from './components/additional-vpa-readonly-marketing-quotes/additional-vpa-readonly-marketing-quotes.component';
import { AddressDisclaimerComponent } from './components/address-disclaimer/address-disclaimer.component';
import { AgentAppRedirectComponent } from './components/agent-app-redirect/agent-app-redirect-component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { AmendManualAddressComponent } from './components/amend-manual-address/amend-manual-address.component';
// tslint:disable-next-line: max-line-length
import { ApplicationPaymentPreferenceComponent } from './components/application-payment-preference/application-payment-preference.component';
import { BpayBillBoxComponent } from './components/bpay-bill-box/bpay-bill-box.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ConfirmNaturalPersonModalComponent } from './components/confirm-natural-person-modal/confirm-natural-person-modal.component';
import { BpayPaymentComponent } from './components/contributions/bpay-payment/bpay-payment.component';
import { ChequePaymentComponent } from './components/contributions/cheque-payment/cheque-payment.component';
import { ContributionPaymentBreakdownComponent } from './components/contributions/contribution-payment-breakdown/contribution-payment-breakdown.component';
import { ContributionPaymentMethodComponent } from './components/contributions/contribution-payment-method/contribution-payment-method.component';
import { ContributionsSummaryComponent } from './components/contributions/contributions-summary/contributions-summary.component';
import { ContributionsComponent } from './components/contributions/contributions.component';
import { CreditCardContributionsSummaryComponent } from './components/contributions/credit-card-details/credit-card-contributions-summary/credit-card-contributions-summary.component';
import { CreditCardDetailsComponent } from './components/contributions/credit-card-details/credit-card-details.component';
import { CreditCardMultipleContributionsModalComponent } from './components/contributions/credit-card-details/credit-card-multiple-contributions-modal/credit-card-multiple-contributions-modal.component';
import { FatZebraIframeComponent } from './components/contributions/credit-card-details/fat-zebra-iframe/fat-zebra-iframe.component';
import { PaywayIFrameComponent } from './components/contributions/credit-card-details/payway-iframe/payway-iframe.component';
import { EftPaymentComponent } from './components/contributions/eft-payment/eft-payment.component';
import { ControlValidationMessageV2Component } from './components/control-validation-message-v2/control-validation-message-v2.component';
import { ControlValidationMessageComponent } from './components/control-validation-message/control-validation-message.component';
import { DepositNowBannerComponent } from './components/deposit-now-banner/deposit-now-banner.component';
import { FilteredListComponent } from './components/filtered-list/filtered-list.component';
import { GenericBreadcrumbsComponent } from './components/generic-breadcrumbs/generic-breadcrumbs.component';
import { GlobalExceptionDisplayComponent } from './components/global-exception-display/global-exception-display.component';
import { HelpSectionComponent } from './components/help-section/help-section.component';
import { HomeRoutingComponent } from './components/home-routing/home-routing-component';
import { HorizontalApplicationTimelineComponent } from './components/horizontal-application-timeline/horizontal-application-timeline.component';
import { ImpersonationBannerComponent } from './components/impersonation-banner/impersonation-banner.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MainNavigationComponent } from './components/main-nav/main-nav.component';
import { ManualAddressModalComponent } from './components/manual-address/manual-address-modal/manual-address-modal.component';
import { ManualAddressComponent } from './components/manual-address/manual-address.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PayNowDirectPaymentDetailsModalComponent } from './components/pay-now-direct-payment-details-modal/pay-now-direct-payment-details-modal.component';
import { PaymentBreakdownComponent } from './components/payment-breakdown/payment-breakdown.component';
import { PortalRedirectComponent } from './components/portal-redirect/portal-redirect-component';
import { PromptModalComponent } from './components/prompt-modal/prompt-modal.component';
import { ResignNotificationModalComponent } from './components/resign-notification-modal/resign-notification-modal.component';
import { SelectAdditionalFundingModalComponent } from './components/select-additional-funding-modal/select-additional-funding-modal.component';
import { SharingMobileWarningComponent } from './components/sharing-mobile-warning/sharing-mobile-warning.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { SwitchAccountModalComponent } from './components/switch-account-modal/switch-account-modal.component';
import { SwitchAccountComponent } from './components/switch-account/switch-account.component';
import { UnelectedVendorErrorModalComponent } from './components/unelected-vendor-error-modal/unelected-vendor-error-modal.component';
import { UploadVpaApplicationDocumentsModalComponent } from './components/upload-vpa-application-documents/upload-vpa-application-documents-modal/upload-vpa-application-documents-modal.component';
import { UploadVpaApplicationDocumentsComponent } from './components/upload-vpa-application-documents/upload-vpa-application-documents.component';
import { VpaDatePickerComponent } from './components/vpa-datepicker/vpa-datepicker.component';
import { MaxTwoDigitalDecimalDirective } from './directives/max-two-digital-decimal.directive';
import {
  AddressPipe,
  ApplicationSearchTypePipe,
  CaDatePipe,
  GlobalVpaStatusPipe,
  GlobalVpaSubStatusPipe,
  ReplaceTextPipe,
  SanitisePipe,
  SoldCampaignReceivableStatusPipe,
  VendorNamePipe
} from './pipes';
import { ListNamesPipe } from './pipes/list-names.pipe';
import { StartedDateTextPipe } from './pipes/started-date-text.pipe';

@NgModule({
  declarations: [
    MainNavigationComponent,
    NotificationsComponent,
    ControlValidationMessageComponent,
    ControlValidationMessageV2Component,
    BreadcrumbsComponent,
    GlobalExceptionDisplayComponent,
    LoadingSpinnerComponent,
    AlertModalComponent,
    AddressPipe,
    ApplicationSearchTypePipe,
    ReplaceTextPipe,
    HelpSectionComponent,
    VendorNamePipe,
    CaDatePipe,
    ListNamesPipe,
    SanitisePipe,
    ListingSearchTypePipe,
    StartedDateTextPipe,
    SoldCampaignReceivableStatusPipe,
    UnelectedVendorErrorModalComponent,
    PaymentBreakdownComponent,
    ImpersonationBannerComponent,
    GenericBreadcrumbsComponent,
    SignaturePadComponent,
    ResignNotificationModalComponent,
    ConfirmNaturalPersonModalComponent,
    SwitchAccountComponent,
    SwitchAccountModalComponent,
    SelectAdditionalFundingModalComponent,
    VpaDatePickerComponent,
    ApplicationPaymentPreferenceComponent,
    ContributionsComponent,
    ContributionsSummaryComponent,
    ContributionPaymentMethodComponent,
    ContributionPaymentBreakdownComponent,
    GlobalVpaStatusPipe,
    AddressDisclaimerComponent,
    FilteredListComponent,
    GlobalVpaSubStatusPipe,
    CreditCardDetailsComponent,
    EftPaymentComponent,
    BpayPaymentComponent,
    ChequePaymentComponent,
    PaywayIFrameComponent,
    HorizontalApplicationTimelineComponent,
    CreditCardContributionsSummaryComponent,
    ManualAddressComponent,
    ManualAddressModalComponent,
    AmendManualAddressComponent,
    CreditCardMultipleContributionsModalComponent,
    BpayBillBoxComponent,
    FatZebraIframeComponent,
    ActionItemComponent,
    ActionItemResponsiveComponent,
    AdditionalVpaFundingDetailsComponent,
    AdditionalVpaMarketingQuotesComponent,
    AdditionalVpaReadonlyMarketingQuotesComponent,
    SharingMobileWarningComponent,
    UploadVpaApplicationDocumentsComponent,
    UploadVpaApplicationDocumentsModalComponent,
    MaxTwoDigitalDecimalDirective,
    PayNowDirectPaymentDetailsModalComponent,
    PromptModalComponent,
    AgentAppRedirectComponent,
    PortalRedirectComponent,
    HomeRoutingComponent,
    DepositNowBannerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    InfiniteScrollModule,
    MDBBootstrapModulesPro,
    FormsModule,
    ReactiveFormsModule,
    SignaturePadModule,
    NgxPrintModule,
    NgxDaterangepickerMd.forRoot(),
    BaseModalsModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    MainNavigationComponent,
    NotificationsComponent,
    ControlValidationMessageComponent,
    ControlValidationMessageV2Component,
    BreadcrumbsComponent,
    GlobalExceptionDisplayComponent,
    RouterModule,
    InfiniteScrollModule,
    MDBBootstrapModulesPro,
    AlertModalComponent,
    LoadingSpinnerComponent,
    AddressPipe,
    ApplicationSearchTypePipe,
    ReplaceTextPipe,
    HelpSectionComponent,
    VendorNamePipe,
    CaDatePipe,
    ListNamesPipe,
    SanitisePipe,
    ListingSearchTypePipe,
    StartedDateTextPipe,
    SoldCampaignReceivableStatusPipe,
    UnelectedVendorErrorModalComponent,
    NgxPrintModule,
    PaymentBreakdownComponent,
    ImpersonationBannerComponent,
    GenericBreadcrumbsComponent,
    SignaturePadComponent,
    ResignNotificationModalComponent,
    ConfirmNaturalPersonModalComponent,
    SwitchAccountComponent,
    SwitchAccountModalComponent,
    SelectAdditionalFundingModalComponent,
    VpaDatePickerComponent,
    ApplicationPaymentPreferenceComponent,
    ContributionsComponent,
    GlobalVpaStatusPipe,
    AddressDisclaimerComponent,
    FilteredListComponent,
    GlobalVpaSubStatusPipe,
    HorizontalApplicationTimelineComponent,
    ManualAddressComponent,
    ManualAddressModalComponent,
    AmendManualAddressComponent,
    BpayBillBoxComponent,
    ActionItemComponent,
    ActionItemResponsiveComponent,
    AdditionalVpaFundingDetailsComponent,
    AdditionalVpaMarketingQuotesComponent,
    AdditionalVpaReadonlyMarketingQuotesComponent,
    NgxDaterangepickerMd,
    SharingMobileWarningComponent,
    UploadVpaApplicationDocumentsComponent,
    UploadVpaApplicationDocumentsModalComponent,
    MaxTwoDigitalDecimalDirective,
    PayNowDirectPaymentDetailsModalComponent,
    PromptModalComponent,
    AgentAppRedirectComponent,
    PortalRedirectComponent,
    DepositNowBannerComponent
  ]
})
export class SharedModule {}

import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'vpa-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements AfterViewInit {
  @Input() buttonLabel: string;
  @Input() options: object;
  @Output() signatureAdded: EventEmitter<string> = new EventEmitter();
  @Output() signatureCleared: EventEmitter<void> = new EventEmitter();
  @ViewChild('signaturePad', { static: false }) public signaturePad: SignaturePad;
  showSignatureError = false;

  public base64Signature: string = null;

  get signature(): string {
    return this.base64Signature;
  }

  set signature(value: string) {
    this.base64Signature = value;
  }

  public ngAfterViewInit(): void {
    this.signaturePad.set('backgroundColor', 'white');
    this.signaturePad.set('canvasWidth', document.querySelector('.content').clientWidth - 45);
    this.signaturePad.clear();
  }

  public drawComplete(): void {
    this.signaturePad.set('backgroundColor', 'white');
    const base64Unformatted = this.signaturePad.toDataURL('image/jpeg');
    this.signature = base64Unformatted.split(',').pop();
    this.signatureAdded.emit(this.signature);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
    this.signatureCleared.emit();
  }

  public validateIfSigned(): boolean {
    if (!this.signature) {
      this.showSignatureError = true;
      return false;
    }
    return true;
  }
}

<div [ngClass]="{ v2: useV2Style$ | async }">
  <div class="ca-modal fade-in" *ngIf="content$ | async as content">
    <div class="ca-dialog">
      <div class="ca-content auto-height float-down" (ngClass)="({ 'warning-modal': content.isWarningModal })">
        <div class="ca-header">
          <h5 class="modal-title w-100">{{ content.title }}</h5>
        </div>
        <div class="ca-body">
          <div class="inline-alert">
            <i class="far fa-times-circle" *ngIf="content.sign === alertModalSign.Error"></i>
            <i class="far fa-check-circle" *ngIf="content.sign === alertModalSign.Success"></i>
            <i class="far fa-exclamation-circle" *ngIf="content.sign === alertModalSign.Warning"></i>
            <div class="message" style="align-self: center;">
              {{ content.message }}
            </div>
          </div>
        </div>
        <div class="ca-footer">
          <button type="button" class="btn btn-secondary m-r-10" mdbWavesEffect (click)="onSuccess(content)">
            {{ content.proceedButtonWording }}
          </button>
          <button type="button" class="btn btn-primary" mdbWavesEffect (click)="onCancel(content)" *ngIf="content.showCancelButton">
            {{ content.cancelButtonWording }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

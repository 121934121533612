import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import { ProblemDetail } from '../../models/problem-detail';
import { ProblemDetailTypes } from '../../models/problem-detail-types';

@Component({
  selector: 'vpa-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  problemDetail: ProblemDetail;

  constructor(private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.notificationsService.errors$
      .subscribe(problemDetail => this.problemDetail = problemDetail);
  }

  get showUnelectedVendorError(): boolean {
    return this.problemDetail && this.problemDetail.type === ProblemDetailTypes.CasAppUnelectedVendorErrorType;
  }
}

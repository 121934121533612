import { Component, Input, OnInit } from '@angular/core';
import { ContributionsModel } from 'apps/legacy/src/app/applications/models/contributions-model';
import { isSelectedPaymentMethod as isSelectedPaymentMethodHelper } from 'apps/legacy/src/app/_helpers/selected-payment-method-helpers';
import { PaymentDetailsOptionType, PaywayContributionModel } from '../../models';

@Component({
  selector: 'vpa-payment-breakdown',
  templateUrl: './payment-breakdown.component.html',
  styleUrls: ['./payment-breakdown.component.scss']
})
export class PaymentBreakdownComponent implements OnInit {
  @Input() contributionsRequest: ContributionsModel;
  @Input() currentPaymentMethod: string;
  @Input() isUsingCreditCard: boolean;
  @Input() totalAuthorisedAmount: number;
  @Input() totalAdded: number;
  @Input() outstanding: number;
  @Input() offsetSectionSpacing: boolean;
  @Input() shouldPadLeft: boolean;

  isSelectedPaymentMethod(paymentMethod: string) {
    return isSelectedPaymentMethodHelper(this.currentPaymentMethod, paymentMethod);
  }

  constructor() {}

  ngOnInit() {}

  get totalSurcharge(): number {
    return this.contributionsRequest.contributions
      .filter((c) => c.paymentDetailsOptionType === PaymentDetailsOptionType.Payway)
      .reduce((prev, curr) => prev + ((curr as PaywayContributionModel).creditCardSurcharge || 0), 0);
  }
}

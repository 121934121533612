import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwitchableAccountsService {
  constructor() {}

  private toggleSwitchableAccountsMenuSubject = new Subject<void>();
  readonly toggleSwitchableAccountsMenu$: Observable<void> = this.toggleSwitchableAccountsMenuSubject.asObservable();

  toggleSwitchableAccountsMenu() {
    this.toggleSwitchableAccountsMenuSubject.next();
  }
}

<section class="section-card" *ngIf="documents$ | async as documents">
  <div class="row section-title m-b-15">
    <div class="col-12">
      <div class="section-title">View Supplied Quotes</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-4" *ngFor="let document of documents; let i = index">
      <div class="upload-list">
        <a *ngIf="document.documentId" (click)="downloadDocument(document, $event)" class="blue-text">
          {{ document.fileName }}
        </a>
        <span *ngIf="!document.documentId">{{ document.fileName }}</span>
        <button *ngIf="document.documentId" (click)="downloadDocument(document, $event)">
          <i class="fa fa-download"></i>
        </button>
      </div>
    </div>
  </div>
</section>

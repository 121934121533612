<section class="section-card m-t-15" [ngClass]="{ 'inline-component-section': !isDisplayedInSections }">
  <div [formGroup]="ManualAddressGroup" class="address-section">
    <div class="row">
      <div class="col-3">
        <label for="subNumber" class="text-uppercase font-13">
          Sub #
        </label>
        <div name="subNumber">
          <input class="form-control" type="text" formControlName="subNumber" name="subNumber" />
        </div>
      </div>
      <div class="col-3 p-l-0">
        <label for="streetNumber" class="text-uppercase font-13">
          Street #
        </label>
        <div name="streetNumber">
          <input class="form-control" type="text" formControlName="streetNumber" id="streetNumber" name="streetNumber" />
        </div>
        <vpa-control-validation-message
          [control]="streetNumber"
          [fieldName]="'Street number'"
          [isSubmitted]="isSubmitted || shouldShowErrors"
        ></vpa-control-validation-message>
      </div>
      <div class="col-6 p-l-0">
        <label for="street" class="text-uppercase font-13">Street</label>
        <div name="street">
          <input class="form-control" type="text" formControlName="street" id="street" name="street" />
        </div>
        <vpa-control-validation-message
          [control]="street"
          [fieldName]="'Street'"
          [isSubmitted]="isSubmitted || shouldShowErrors"
        ></vpa-control-validation-message>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label for="suburb" class="text-uppercase font-13">Suburb</label>
        <div name="suburb">
          <input class="form-control" type="text" formControlName="suburb" id="suburb" name="suburb" />
        </div>
        <vpa-control-validation-message
          [control]="suburb"
          [fieldName]="'Suburb'"
          [isSubmitted]="isSubmitted || shouldShowErrors"
        ></vpa-control-validation-message>
      </div>
      <div class="col-3 p-l-0">
        <label for="state" class="text-uppercase font-13">State</label>
        <div name="state">
          <input class="form-control" type="text" formControlName="state" id="state" name="state" />
        </div>
        <vpa-control-validation-message
          [control]="state"
          [fieldName]="'State'"
          [isSubmitted]="isSubmitted || shouldShowErrors"
        ></vpa-control-validation-message>
      </div>
      <div class="col-3 p-l-0">
        <label for="postcode" class="text-uppercase font-13">
          Postcode
        </label>
        <div name="postcode">
          <input class="form-control" type="text" formControlName="postcode" id="postcode" name="postcode" />
        </div>
        <vpa-control-validation-message
          [control]="postcode"
          [fieldName]="'Postcode'"
          [isSubmitted]="isSubmitted || shouldShowErrors"
        ></vpa-control-validation-message>
      </div>
    </div>
  </div>
</section>

import { Pipe, PipeTransform } from '@angular/core';
import { getFullAddress } from '@ca/shared/helpers';
import { Address } from '@ca/shared/models';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  transform(value: any, showState: boolean = false, showCountry: boolean = false): any {
    const address = value as Address;

    return getFullAddress(address, showState, showCountry);
  }
}

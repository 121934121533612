<section class="section-card">
  <div class="p-b-20 row">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-12 m-b-10">
          <div class="section-title">EFT Account Information</div>
          <div class="section-description">
            Below are the account details for the EFT payment.
            <br />
            Please note these details will be emailed to you upon confirmation.
          </div>
        </div>
      </div>

      <vpa-help-section [helpText]="'eftDetails'" [offsetSectionSpacing]="false"></vpa-help-section>

      <div class="row">
        <div class="col-12 col-lg-9">
          <div class="row">
            <div class="col-12 m-t-10">
              <label class="input-label">Account name</label>
              <div class="section-title">
                {{ paymentDetailsOption.accountName }}
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-3 m-t-15">
              <label class="input-label">BSB</label>
              <div class="section-title">
                {{ paymentDetailsOption.bsb }}
              </div>
            </div>
            <div class="col-8 col-md-5 col-lg-5 m-t-15">
              <label class="input-label">Account number</label>
              <div class="section-title">
                {{ paymentDetailsOption.accountNumber }}
              </div>
            </div>
            <div class="col-4 col-md-4 col-lg-4 m-t-15">
              <label class="input-label">Bank name</label>
              <div class="section-title">
                {{ paymentDetailsOption.bankName }}
              </div>
            </div>
            <div class="col-8 col-md-12 m-t-15 highlight-border">
              <label class="input-label">Payment reference code</label>
              <div class="section-title">{{ paymentDetailsOption.paymentReferenceCode }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vpa-contribution-payment-breakdown class="col-lg-6" [state$]="contributionPaymentBreakdown$"></vpa-contribution-payment-breakdown>
  </div>
</section>

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationMessageResources } from '../../models/validation-message-resources';

@Component({
  selector: 'vpa-control-validation-message',
  templateUrl: './control-validation-message.component.html',
  styleUrls: ['./control-validation-message.component.scss']
})
export class ControlValidationMessageComponent implements OnInit {
  @Input() control: FormControl;
  @Input() fieldName: string;
  @Input() isSubmitted: boolean;
  @Input() skipValidation: boolean;
  constructor() {}

  ngOnInit() {}

  get required() {
    return this.fieldName + ' is required';
  }

  get positiveNumber() {
    return this.fieldName + ' must be greater than zero';
  }

  get twoDecimalPlaces() {
    return this.fieldName + ' can have at most two decimal places';
  }

  get invalidDate() {
    return ValidationMessageResources.invalidDate;
  }

  get email() {
    return ValidationMessageResources.emailInvalid;
  }

  get validPhoneNumber() {
    return ValidationMessageResources.mobileInvalid;
  }
}

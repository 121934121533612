import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EftPaymentDetailsOption } from 'apps/legacy/src/app/_shared/models';
import { Observable } from 'rxjs';
import { IContributionPaymentBreakdownComponentState } from '../contribution-payment-breakdown/contribution-payment-breakdown.component';

@Component({
  selector: 'vpa-eft-payment',
  templateUrl: './eft-payment.component.html',
  styleUrls: ['./eft-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EftPaymentComponent implements OnInit {
  @Input() paymentDetailsOption: EftPaymentDetailsOption;
  @Input() contributionPaymentBreakdown$: Observable<IContributionPaymentBreakdownComponentState>;

  constructor() {}

  ngOnInit(): void {}
}

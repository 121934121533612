import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CADocumentType, VpaApplicationDocumentModel } from 'apps/legacy/src/app/_shared/models/vpa-application-document-model';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { BaseComponent } from '../../base-component/base-component';

@Component({
  selector: 'vpa-upload-vpa-application-documents-modal',
  templateUrl: './upload-vpa-application-documents-modal.component.html',
  styleUrls: ['./upload-vpa-application-documents-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadVpaApplicationDocumentsModalComponent extends BaseComponent implements OnInit {
  @Input() input$: Observable<UploadVpaApplicationDocumentsModalState>;
  @Output() fileSelected: EventEmitter<object> = new EventEmitter();
  @Output() removeFileAtIndex: EventEmitter<number> = new EventEmitter();
  @Output() cancelUploadFilesEvent = new EventEmitter();
  @Output() confirmUploadFilesEvent = new EventEmitter();
  @Output() confirmUploadFilesToApiEvent = new EventEmitter();

  documentsTypes: any;

  selectedType = new FormControl(CADocumentType.SalesAuthority);

  constructor() {
    super();
  }

  ngOnInit() {
    this.input$
      .pipe(
        filter((input) => input.showModal),
        tap((input) => {
          this.documentsTypes = input.authorityDocumentIncludesMarketingSchedule
            ? [
                { displayText: 'Combined Sales Authority and Marketing Schedule', value: CADocumentType.SalesAuthority },
                { displayText: 'Vendor Identification', value: CADocumentType.VendorIdentification }
              ]
            : [
                { displayText: 'Sales Authority', value: CADocumentType.SalesAuthority },
                { displayText: 'Marketing Schedule', value: CADocumentType.MarketingSchedule },
                { displayText: 'Vendor Identification', value: CADocumentType.VendorIdentification }
              ];
        }),
        this.unsubOnDestroy()
      )
      .subscribe();
  }

  onFileSelected($event) {
    this.fileSelected.emit({ file: $event, type: this.selectedType.value });
  }

  removeFile(index: number) {
    this.removeFileAtIndex.emit(index);
  }

  cancel() {
    this.cancelUploadFilesEvent.emit();
  }

  upload() {
    this.confirmUploadFilesEvent.emit();
  }

  uploadToApi() {
    this.confirmUploadFilesToApiEvent.emit();
  }
}

export interface UploadVpaApplicationDocumentsModalState {
  showModal: boolean;
  documentsToBeUploaded: VpaApplicationDocumentModel[];
  areFilesBeingProcessed: boolean;
  submitDocumentsFromModalDirectly?: boolean;
  authorityDocumentIncludesMarketingSchedule: boolean;
}

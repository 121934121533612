<div class="main-layout">
  <div
    *ngIf="!isBrowserExtensionDisplayMode"
    class="main-header-row"
    [ngClass]="{ 'vendor-row': isCurrentUserAVendor$ | async, portal: isInPortalMode$ | async }"
  >
    <div class="col p-0">
      <!--  Agent Top Bar  -->
      <ca-header-nav *ngIf="!(isCurrentUserAVendor$ | async)"></ca-header-nav>
      <!--  End Agent Top Bar  -->

      <!--  Vendor Top Bar  -->
      <div class="logo-wrapper standard-page vendor-topbar" *ngIf="isCurrentUserAVendor$ | async">
        <div class="vendor-logo">
          <img src="/assets/img/new-ca-logo.svg" />
        </div>

        <div class="vendor-topbar-info">
          <div class="title-only">CampaignAgent</div>
        </div>

        <div class="vendor-dropdown">
          <div class="dropdown">
            <button class="btn btn-default dropdown-toggle" type="button" (click)="toggleDropdown()">
              <span class="agency-username">{{ agencyUserName$ | async }}</span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1" *ngIf="dropdownOpen">
              <li (click)="logoutUser()"><a>Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
      <!--  End Vendor Top Bar  -->
    </div>
  </div>

  <div class="main-content-row" [ngClass]="{ 'vendor-row': isCurrentUserAVendor$ | async }">
    <div class="container parent-content-container full-width-container-tablet">
      <vpa-impersonation-banner></vpa-impersonation-banner>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettingsService, AppStateService, LoginDestination, SwitchableLogin } from '@ca/shared/app-state';
import { ApplicationMode, ApplicationModeService } from '@ca/shared/application-mode';
import { BaseFacadeService, IComponentState } from '@ca/shared/base-facade';
import { ApplicationLoginContext, UserTypes } from '@ca/shared/models';
import {
  ApplicationModeItem,
  ApplicationRouteService,
  NavigationItem,
  NavigationItemAction,
  NavigationLink,
  NavMenuService,
  SwitchableAccountsService
} from '@ca/shared/navigation-menu';
import { PageEventTracker, UserEventsService } from '@ca/shared/user-events';
import { combineLatest, merge, Observable, of, ReplaySubject, Subject, throwError, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { HeaderNavModel } from '../../../../models/header-nav-model';
import { SwitchAccountModalService } from '../../../../switch-account-modal.service';
import { SwitchAccountModalComponentState } from '../../../../switch-account-modal/switch-account-modal.component';
import { HeaderNavContentService } from '../header-nav-content.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderNavFacadeService extends BaseFacadeService<IHeaderNavState, HeaderNavModel> {
  protected state: IHeaderNavState = {
    showMenu: false,
    isLargeScreen: false,
    isSmallScreen: false,
    showSwitchAccountsModal: false,
    navItems: []
  };

  agencyUserName$: Observable<string> = this.appStateService.agencyUserName$;
  agencyGroupName$: Observable<string> = this.appStateService.agencyGroupName$;
  isCurrentUserAVendor$: Observable<boolean> = this.appStateService.isCurrentUserAnyTypeOfVendor$;
  private appModes$: Observable<ApplicationModeItem[]> = this.navMenuService.instantiateApplicationModeItems();
  showTrainingSwitch$: Observable<boolean> = this.appModes$.pipe(
    map((appModes) => appModes && appModes.length > 0),
    distinctUntilChanged()
  );
  isTrainingMode$: Observable<boolean> = this.applicationModeService.isTrainingMode$;
  isInPortalMode$: Observable<boolean> = this.appStateService.applicationLoginContext$.pipe(
    first(),
    map((loginContext) => loginContext === ApplicationLoginContext.Portal),
    startWith(false)
  );
  showMenu$: Observable<boolean> = combineLatest([this.state$, this.isInPortalMode$]).pipe(
    map(([s, isInPortalMode]) => {
      if (this.isSmartCommModule()) {
        return isInPortalMode ? s.showMenu || s.isLargeScreen : s.showMenu;
      }

      return (!s.isSmallScreen || isInPortalMode) && (s.showMenu || s.isLargeScreen);
    }),
    distinctUntilChanged()
  );
  navItems$: Observable<NavigationItem[]> = this.state$.pipe(map((s) => s.navItems.filter((n) => !n.isUserDetailNavigation)));
  userDetailsNavItems$: Observable<NavigationItem[]> = this.state$.pipe(map((s) => s.navItems.filter((n) => n.isUserDetailNavigation)));
  canShowPortalSwitchAccountMenu$: Observable<boolean> = this.mapToShowPortalSwitchAccountMenu$(
    this.appStateService.switchableLogins$,
    this.appStateService.assumedUserId$
  );

  showSwitchAccountsModal$: Observable<boolean> = this.state$.pipe(map((s) => s.showSwitchAccountsModal));
  switchAccountModalComponentState$: Observable<
    SwitchAccountModalComponentState
  > = this.switchAccountModalService.getSwitchAccountModalComponentState$();

  pageEventTracker$: Observable<PageEventTracker> = this.headerNavContentService.pageEventTracker$;

  private stopUpdatingBadgeData = new Subject<boolean>();

  private form: FormGroup = null;
  private formSubject: ReplaySubject<FormGroup> = new ReplaySubject<FormGroup>();
  form$: Observable<FormGroup> = this.formSubject.asObservable();

  constructor(
    @Inject('currentAppName') protected currentAppName: string,
    private router: Router,
    private appStateService: AppStateService,
    private applicationRouteService: ApplicationRouteService,
    private applicationModeService: ApplicationModeService,
    private navMenuService: NavMenuService,
    private switchAccountModalService: SwitchAccountModalService,
    private userEventsService: UserEventsService,
    private headerNavContentService: HeaderNavContentService,
    private switchableAccountsService: SwitchableAccountsService
  ) {
    super();
  }

  init(innerWidth: number) {
    this.configureForm();
    this.configureMenuForScreenSize(innerWidth);
    this.refreshNavItems(true);

    this.navMenuService
      .onRefreshData$()
      .pipe(this.unsubOnProcessingCompleted())
      .subscribe((_) => this.refreshNavItems(false));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((_) => {
        this.headerNavContentService.reset();
      });

    this.headerNavContentService.setShowSearchBar(this.canRouteShowSearchBar(this.router.url));
    this.setupPortalHeaderOnRouteChanged();

    this.switchableAccountsService.toggleSwitchableAccountsMenu$.subscribe((_) => {
      this.pushStateUpdate((state) => ({
        ...state,
        showSwitchAccountsModal: !this.state.showSwitchAccountsModal
      }));
    });
  }

  protected setPageEventTracker(moduleName: string, pageName: string) {
    super.setPageEventTracker(moduleName, pageName);
    this.headerNavContentService.setPageEventTracker(this.pageEventTracker);
  }

  private configureForm() {
    this.form = new FormBuilder().group({
      searchTerm: new FormControl('')
    });
    this.formSubject.next(this.form);
    this.form.valueChanges.pipe(this.unsubOnProcessingCompleted()).subscribe((_) => this.formSubject.next(this.form));
  }

  isSmartCommModule(): boolean {
    return this.currentAppName === 'smartComm';
  }

  private setupPortalHeaderOnRouteChanged() {
    const urls = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.url),
      distinctUntilChanged()
    );

    merge(urls, of(''))
      .pipe(
        filter((url) => url !== ''),
        map((url) => this.canRouteShowSearchBar(url))
      )
      .subscribe((canRouteShowSearchBar) => this.headerNavContentService.setShowSearchBar(canRouteShowSearchBar));
  }

  private canRouteShowSearchBar(url: string) {
    return true;
    /*
    Ames: as a result of Daniel's feedback, we want to always show the search bar.
    The logic below is old logic for re-inclusion if there are any issues.
    const routeToHideSearchBar = ['finance', 'correspondence', 'users'];
    return !routeToHideSearchBar.includes(url.split('/').filter((s) => !!s)[0]);
    */
  }

  onShowHelpMenu() {
    this.pageEventTracker$.pipe(first()).subscribe((pageEventTracker) => {
      if (pageEventTracker) {
        this.userEventsService.addPageTrackingEventWithDeviceInformation(pageEventTracker, 'HelpButtonPressed');
        return;
      }

      this.userEventsService.addPageTrackingEventWithDeviceInformation(
        new PageEventTracker('Unknown', 'Unknown'),
        'HelpButtonPressed',
        null,
        {
          url: this.router.url
        }
      );
    });
  }

  refreshNavItems(initialLoad: boolean): void {
    this.appStateService.currentUserType$
      .pipe(
        first(),
        switchMap((currentUserType) => {
          switch (currentUserType) {
            case UserTypes.Agent:
              // Auto update the nav items with badge data every 60 seconds
              return timer(0, 60000).pipe(
                switchMap((_) => this.navMenuService.instantiateNavigationItems(initialLoad)),
                catchError((err, _) => {
                  if (err instanceof HttpErrorResponse && err.status === 0) {
                    this.stopUpdatingBadgeData.next(false);
                    this.stopUpdatingBadgeData.complete();
                    return of([]);
                  }

                  throwError(err);
                }),
                takeUntil(this.stopUpdatingBadgeData)
              );

            // For users that we don't need to update the data for
            default:
              return this.navMenuService.instantiateNavigationItems(initialLoad);
          }
        })
      )
      .subscribe((navItems) => {
        this.pushStateUpdate((state) => ({
          ...state,
          navItems
        }));
      });
  }

  setApplicationMode(newMode: ApplicationMode) {
    this.applicationModeService.applicationMode$.pipe(first()).subscribe((applicationMode) => {
      if (newMode !== applicationMode) {
        this.applicationRouteService.switchApplicationMode(newMode);
      }
    });
  }

  changeApplicationMode(switchToTraining: boolean) {
    this.applicationRouteService.switchApplicationMode(switchToTraining ? ApplicationMode.Training : ApplicationMode.Live);
  }

  onClickNavItem(navItem: NavigationItem) {
    if (navItem.link) {
      this.navigateToItem(navItem.link);
    }

    if (navItem.action) {
      this.runAction(navItem.action);
    }
  }

  private navigateToItem(link: NavigationLink) {
    this.pushStateUpdate((state) => ({
      ...state,
      showMenu: false,
      showSwitchAccountsModal: false
    }));

    if (!link) {
      return;
    } else if (link.isInternalLink) {
      this.applicationRouteService.navigate(link.campaignAgentApplications, link.url, link.extras);
    } else if (link.isDifferentAngularAppLink) {
      this.applicationRouteService.navigate(link.campaignAgentApplications, link.url, link.extras);
    } else {
      window.location.href = link.url;
    }
  }

  private runAction(navAction: NavigationItemAction) {
    switch (navAction) {
      case NavigationItemAction.ToggleSwitchableAccountsMenu:
        this.toggleSwitchableAccountsMenu();
        break;
    }
  }

  toggleMenu() {
    this.pushStateUpdate((state) => ({
      ...state,
      showMenu: !state.showMenu
    }));
  }

  toggleSwitchableAccountsMenu() {
    this.pushStateUpdate((state) => ({
      ...state,
      showSwitchAccountsModal: !state.showSwitchAccountsModal
    }));
  }

  configureMenuForScreenSize(innerWidth: number) {
    this.pushStateUpdate((state) => ({
      ...state,
      isLargeScreen: innerWidth >= 1200,
      isSmallScreen: innerWidth < 768
    }));
  }

  onLoginSelected() {
    this.pushStateUpdate((state) => ({
      ...state,
      showMenu: false,
      showSwitchAccountsModal: false
    }));
  }

  onSwitchAccountsModalClosed() {
    this.pushStateUpdate((state) => ({
      ...state,
      showSwitchAccountsModal: false
    }));
  }

  submitSearch() {
    const searchTerm = this.form.get('searchTerm').value;
    window.location.href = `${AppSettingsService.appSettings.casUrl}agency/campaigns/Search?SearchTerm=${searchTerm}`;
  }

  mapToShowPortalSwitchAccountMenu$(
    switchableLogins$: Observable<SwitchableLogin[]>,
    assumedUserId$: Observable<number>
  ): Observable<boolean> {
    return combineLatest([assumedUserId$, switchableLogins$]).pipe(
      map(([assumedUserId, switchableLogins]) => {
        if (!switchableLogins) return false;

        return switchableLogins.filter((user) => user.UserId !== assumedUserId || user.Destination !== LoginDestination.Portal).length > 0;
      })
    );
  }
}

export interface IHeaderNavState extends IComponentState<HeaderNavModel> {
  showMenu: boolean;
  isLargeScreen: boolean;
  isSmallScreen: boolean;
  showSwitchAccountsModal: boolean;
  navItems: NavigationItem[];
}

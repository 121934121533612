import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationSearchType } from '../models';

@Pipe({
  name: 'applicationSearchType'
})
export class ApplicationSearchTypePipe implements PipeTransform {
  transform(searchType: ApplicationSearchType): string {
    switch (searchType) {
      case ApplicationSearchType.MyApplicationsOnly:
        return 'My Applications';

      case ApplicationSearchType.AllApplications:
        return 'All Applications';
    }
  }
}

import { Injectable } from '@angular/core';
import { DateResult } from './vpa-date';

@Injectable({
  providedIn: 'root'
})
export class DateValidation {
  public static dateResultToString = (dateResult: DateResult): string | null => {
    switch (dateResult.type) {
      case 'ok':
        return DateValidation.formatDateParts(dateResult.data.getDate(), dateResult.data.getMonth(), dateResult.data.getFullYear());
      case 'invalidDate':
        return null;
      default:
        // this is to stop angular overwriting a control value accessor after ng on init
        if (dateResult !== '') {
          const dateAlreadySet = dateResult as any;
          return DateValidation.formatDateParts(dateAlreadySet.getDate(), dateAlreadySet.getMonth(), dateAlreadySet.getFullYear());
        }
        return null;
    }
  };

  private static formatDateParts = (day: number, month: number, year: number): string => {
    const formattedMonth = (month + 1).toString().length > 1 ? month + 1 : `0${month + 1}`;
    const formattedDay = day.toString().length > 1 ? day : `0${day}`;
    return `${year}-${formattedMonth}-${formattedDay}`;
  };
}

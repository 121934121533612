import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { PromptContent } from '../models/prompt-content';

@Injectable({
  providedIn: 'root'
})
export class PromptContentService {
  private content: ReplaySubject<PromptContent> = new ReplaySubject(1);
  content$: Observable<PromptContent> = this.content.asObservable();

  private formControl: FormControl = null;
  private formSubject: ReplaySubject<FormControl> = new ReplaySubject<FormControl>();
  comments$: Observable<FormControl> = this.formSubject.asObservable();

  constructor(private formBuilder: FormBuilder) {
    this.formControl = this.formBuilder.control('', Validators.required);
    this.formSubject.next(this.formControl);
  }

  public setContent = (modal: PromptContent): void => this.content.next(modal);

  public clearContent(): void {
    this.content.next(null);
    this.formControl.reset();
  }

  public getComments = (): string => this.formControl.value;
}

<div
  class="header-container"
  [ngClass]="{ app: (isInPortalMode$ | async) === false, portal: isInPortalMode$ | async, 'smart-commission': isSmartCommModule }"
>
  <header class="cad-header">
    <div class="menu-trigger" (click)="toggleMenu()">
      <i class="fal fa-bars"></i>
    </div>
    <div class="ca-logo"><img src="/assets/img/new-ca-logo.svg" /></div>

    <div class="middle-container">
      <div class="search-inner" *ngIf="isInPortalMode$ | async">
        <form [formGroup]="searchForm$ | async">
          <button class="search-btn" type="submit" (click)="submit()"><i class="fad fa-search"></i></button>
          <input
            id="searchTerm"
            type="text"
            class="form-control-sm border-0"
            formControlName="searchTerm"
            placeholder="Search for a property"
          />
        </form>
      </div>

      <div class="smart-commission-title" *ngIf="isSmartCommModule && (isInPortalMode$ | async) === false">
        <h4>SmartCommission</h4>
      </div>
    </div>

    <div class="announcement-container help" (click)="showHelpMenu()">
      <div class="announcement-icon">
        <i class="fal fa-question-circle"></i>
        <span class="announcement-badge"></span>
      </div>
    </div>
  </header>

  <div class="side-menu" [ngClass]="{ show: showMenu$ | async }">
    <div class="ca-nav shadow-lg">
      <ul>
        <li *ngFor="let nav of navItems$ | async" class="hover" [ngClass]="{ 'nav-header': nav.subNavItems }">
          <a class="nav-header-title" (click)="onClickNavItem(nav)">
            <i class="{{ nav.icon }}"></i>
            {{ nav.name }}
            <span *ngIf="nav.badge" class="cad-badge" [ngClass]="'cad-badge-' + nav.badge.style">
              {{ nav.badge.content }}
            </span>
          </a>
          <ul class="submenu" *ngIf="nav.subNavItems">
            <li *ngFor="let subNav of nav.subNavItems" class="hover">
              <a (click)="onClickNavItem(subNav)">
                {{ subNav.name }}
                <span *ngIf="subNav.badge?.content" class="cad-badge" [ngClass]="'cad-badge-' + subNav.badge.style">
                  {{ subNav.badge.content }}
                </span>
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <div class="user-details">
        <hr />

        <ul>
          <li class="mb-2">
            <div>{{ agencyUserName$ | async }}</div>
            <small>{{ agencyGroupName$ | async }}</small>
          </li>
          <li *ngIf="showTrainingSwitch$ | async">
            <span>Training Mode</span>
            <!-- TODO: To be replaced when migrated to Bootstrap 5 -->
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="switchToTraining"
                [checked]="isTrainingMode$ | async"
                (change)="changeApplicationMode($event)"
              />
              <label class="custom-control-label" for="switchToTraining"></label>
            </div>
          </li>
          <li *ngFor="let userNav of userDetailsNavItems$ | async">
            <a (click)="onClickNavItem(userNav)">{{ userNav.name }}</a>
          </li>
          <li>
            <small> Customer Support: {{ supportPhoneNumber }} </small>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ca-switch-account-modal
  *ngIf="showSwitchAccountsModal$ | async"
  [state$]="switchAccountModalComponentState$"
  (closeModal)="onSwitchAccountsModalClosed()"
  (loginSelected)="onLoginSelected()"
></ca-switch-account-modal>

import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { AppSettingsService } from '@ca/shared/app-state';
import { ApplicationMode } from '@ca/shared/application-mode';
import { CampaignAgentApplications, RouteResources } from '@ca/shared/models';
import { BasicRouteService } from '@ca/shared/utils/router';

@Injectable({
  providedIn: 'root'
})
export class ApplicationRouteService extends BasicRouteService {
  applicationCpnSummary(cpn: string) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.applicationCpnSummary(cpn, this.applicationMode));
  }

  applicationSummary(vpaApplicationId: number, extras?: NavigationExtras) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.applicationSummary(vpaApplicationId, this.applicationMode), extras);
  }

  createAdditionalVpaInVpaApply(cpn: string) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.createAdditionalVpaInVpaApply(cpn, this.applicationMode));
  }

  createAdditionalVpa(cpn: string) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.createAdditionalVpa(cpn, this.applicationMode));
  }

  createPdfApplication(cpn: string) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.createPdfApplication(cpn, this.applicationMode));
  }

  createAdditionalVpaApplyPdf(cpn: string) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.createAdditionalVpaApplyPdf(cpn, this.applicationMode));
  }

  createPDFAdditionalVpaInVpaApply(cpn: string) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.createPDFAdditionalVpaInVpaApply(cpn, this.applicationMode));
  }

  reviewPdfApplication(vpaApplicationId: number) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.reviewPdfApplication(vpaApplicationId, this.applicationMode));
  }

  createStep(cpnOrApplicationId: string = null, extras?: NavigationExtras) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.createApplication(cpnOrApplicationId, this.applicationMode), extras);
  }

  createAgentContributions(cpnOrApplicationId: string = null, extras?: NavigationExtras) {
    this.navigate(
      CampaignAgentApplications.Legacy,
      RouteResources.vpaPayManagementRouteResources.createAgentContributions(cpnOrApplicationId, this.applicationMode),
      extras
    );
  }

  reconciliation(extras?: NavigationExtras) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.reconciliation(), extras);
  }

  amendApplication(vpaApplicationId: number, campaignId?: number, vpaReceivableId?: number) {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.amendApplication(vpaApplicationId, this.applicationMode), {
      queryParams: { campaignId, vpaReceivableId }
    });
  }

  applicationCancelSuccess() {
    this.navigate(CampaignAgentApplications.Legacy, RouteResources.applicationCancelSuccess());
  }

  pndPayNowPaymentType(vpaApplicationId: number) {
    this.navigate(
      CampaignAgentApplications.Legacy,
      RouteResources.payNowDirectRouteResources.pndPayNowPaymentType(vpaApplicationId, this.applicationMode)
    );
  }

  pndAgentFlowEmbeddedPayment = (apiConsumerVpaApplicationAggregateId: string) =>
    this.navigate(
      CampaignAgentApplications.Legacy,
      RouteResources.payNowDirectRouteResources.agentFlowEmbeddedPayment(apiConsumerVpaApplicationAggregateId, this.applicationMode)
    );

  pndVendorLogout = () => this.navigate(CampaignAgentApplications.Legacy, RouteResources.vendorLogout());

  getCreatePndApplicationRoute(cpnOrApplicationId: string = null): string {
    return RouteResources.vpaPayManagementRouteResources.createApplication(cpnOrApplicationId, this.applicationMode);
  }

  switchApplicationMode(mode: ApplicationMode) {
    this.navigate(
      CampaignAgentApplications.Legacy,
      AppSettingsService.appSettings.featureFlags.showNewListingsComponent
        ? RouteResources.listAllCampaigns(mode)
        : RouteResources.listings(mode)
    );
  }
}

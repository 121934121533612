import { Injectable } from '@angular/core';

const apiKey = 'AIzaSyD29mW9awBELg1Ic_HnCPWjuehjH_h0s2Q';

@Injectable({
  providedIn: 'root'
})
export class StreetviewService {
  constructor() {}

  getStreetViewImage(fullAddress: string): string {
    return `https://maps.googleapis.com/maps/api/streetview?size=1020x680&location=${fullAddress}&key=${apiKey}`;
  }
}

import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface AppFeatures {
  isSmartCommissionEnabled: boolean;
  isSmartCommMfaEnabled: boolean;
  useNewUnsigningAndResigningModel: boolean;
  uploadMarketingSchedules: boolean;
  morePowerOfAttorneyFieldsForClassicVpaPay: boolean;
  isQrCodeFlowEnabled: boolean;
  isSmartCommissionDiscountEnabled: boolean;
  simplifyPndProcess: boolean;
  enableApplicationSummaryV2: boolean;
  enableStagingFinancing: boolean;
  enableManagePaymentV2: boolean;
  isDownloadAgencyQrCodeEnabled: boolean;
  enableUpdateTotalFundedAmountModal: boolean;
  morePOAFieldsForPND: boolean;
  showStagingHelpArticle: boolean;
  isAgentContributionsEnabled: boolean;
  showNewListingsComponent: boolean;
  isCampaignListsUsingAngularEnabled: boolean;
  payNowRentalPaymentsEnabled: boolean;
  isStagingForQRWorkflowEnabled: boolean;
  useCADPayLaterComponents: boolean;
  isCreateAgentContributionsFromModalEnabled: boolean;
  isPayLaterAgentContributionEnabled: boolean;
}

export interface AppSettings {
  environment: Environment;
  environmentTitle: string;
  production: boolean;
  baseApiUrl: string;
  useLogRocket: boolean;
  ignoreAuth: boolean;
  showComponentList: boolean;
  sentryEnvironmentName: string;
  casUrl: string;
  illionUrl: string;
  fatZebraOrigin: string;
  domain_client_id: string;
  domain_client_secret: string;
  staging_elevio_article_id: number;
  featureFlags: AppFeatures;
}

export enum Environment {
  Development = 'Development',
  Production = 'Production',
  Uat = 'UAT',
  Local = 'Local',
  LocalUat = 'Local-UAT',
  Training = 'Training'
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  static defaultAppSettings: AppSettings = {
    environment: Environment.Development,
    environmentTitle: 'Development',
    production: false,
    baseApiUrl: 'https://localhost',
    useLogRocket: false,
    ignoreAuth: false,
    showComponentList: true,
    sentryEnvironmentName: 'development',
    casUrl: 'https://localhost/',
    illionUrl: 'https://test.bankstatements.com.au/api/v1',
    fatZebraOrigin: 'https://paynow.pmnts-sandbox.io',
    domain_client_id: 'client_c0563fef27556ad84e090120a446d3b1',
    domain_client_secret: 'secret_ec5a211211e540d55eb98ddd5a6fd0a8',
    staging_elevio_article_id: 377,
    featureFlags: {
      isSmartCommissionEnabled: true,
      isSmartCommMfaEnabled: true,
      useNewUnsigningAndResigningModel: true,
      uploadMarketingSchedules: true,
      morePowerOfAttorneyFieldsForClassicVpaPay: true,
      isQrCodeFlowEnabled: true,
      isSmartCommissionDiscountEnabled: true,
      simplifyPndProcess: true,
      enableApplicationSummaryV2: true,
      enableStagingFinancing: true,
      enableManagePaymentV2: true,
      isDownloadAgencyQrCodeEnabled: true,
      enableUpdateTotalFundedAmountModal: true,
      morePOAFieldsForPND: true,
      showStagingHelpArticle: false,
      isAgentContributionsEnabled: false,
      showNewListingsComponent: true,
      isCampaignListsUsingAngularEnabled: false,
      payNowRentalPaymentsEnabled: true,
      isStagingForQRWorkflowEnabled: true,
      useCADPayLaterComponents: false,
      isCreateAgentContributionsFromModalEnabled: true,
      isPayLaterAgentContributionEnabled: false
    }
  };

  public static appSettings: AppSettings = AppSettingsService.defaultAppSettings;
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  setAppSettings(): Promise<AppSettings> {
    return this.httpClient
      .get<AppSettings>('appSettings/appSettings.json')
      .toPromise()
      .then((config) => (AppSettingsService.appSettings = config));
  }
}
